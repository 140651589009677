import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import EmptyData from '../../../../../components/Charts/emptyData';

interface PieChartProps {
  data: number[];
  categories?: string[] | string[][];
  height?: number;
  width?: number;
  colors: string[];
  labels?: string[];
  legendWidth?: number;
  startAngle?: number;
  endAngle?: number;
  customLegend?: (
    seriesName: string,
    opts: { seriesIndex: number; w: any }
  ) => string;
  onClick?: (event: any, chartContext: any, config: any) => void;
  needsRequiredFilters: boolean;
}

export function PieChart2({
  data,
  width,
  height,
  labels,
  colors,
  legendWidth,
  startAngle = -135,
  endAngle = 225,
  customLegend,
  onClick,
  needsRequiredFilters,
}: PieChartProps) {
  const emptyData = data.filter((item) => item > 0).length === 0;
  const [chartData, setChartData] = useState<ApexOptions>();

  useEffect(() => {
    const abbreviateLegend = (label: string, maxLength: number) => {
      if (label.length <= maxLength) return label;
      return label.slice(0, maxLength - 3) + '...';
    };

    setChartData({
      series: data,
      chart: {
        type: 'pie',
        events: {
          dataPointSelection: function (event, chartContext, config) {
            if (onClick && config.dataPointIndex >= 0 && labels) {
              onClick(event, chartContext, {
                ...config,
                selectedLabel: labels[config.dataPointIndex],
              });
            }
          },
        },
        width: width || 284,
      },
      labels,
      responsive: [
        {
          breakpoint: 1400,
          options: {
            chart: {
              width: '100%',
            },
            legend: {
              position: 'right',
              width: legendWidth ? Math.min(legendWidth * 0.9, 200) : 126,
              formatter: function (seriesName, opts) {
                const abbreviatedName = abbreviateLegend(seriesName, 25);
                if (customLegend) {
                  return customLegend(abbreviatedName, opts);
                }
                return abbreviatedName;
              },
            },
          },
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: '100%',
            },
            legend: {
              width: legendWidth ? Math.min(legendWidth * 0.8, 180) : 112,
              formatter: function (seriesName, opts) {
                const abbreviatedName = abbreviateLegend(seriesName, 20);
                if (customLegend) {
                  return customLegend(abbreviatedName, opts);
                }
                return abbreviatedName;
              },
            },
          },
        },
        {
          breakpoint: 992,
          options: {
            legend: {
              width: legendWidth ? Math.min(legendWidth * 0.7, 150) : 98,
              formatter: function (seriesName, opts) {
                const abbreviatedName = abbreviateLegend(seriesName, 15);
                if (customLegend) {
                  return customLegend(abbreviatedName, opts);
                }
                return abbreviatedName;
              },
            },
          },
        },
        {
          breakpoint: 768,
          options: {
            legend: {
              position: 'bottom',
              width: '100%',
              formatter: function (seriesName, opts) {
                const abbreviatedName = abbreviateLegend(seriesName, 10);
                if (customLegend) {
                  return customLegend(abbreviatedName, opts);
                }
                return abbreviatedName;
              },
            },
          },
        },
      ],
      colors,
      legend: {
        position: 'right',
        offsetY: 0,
        height: undefined,
        fontSize: '12px',
        formatter: function (
          seriesName: string,
          opts: { seriesIndex: number; w: any }
        ) {
          if (customLegend) {
            return customLegend(seriesName, opts);
          }
          return seriesName;
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 1,
            minAngleToShowLabel: 0,
          },
          startAngle,
          endAngle,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
        formatter: function (val, opts) {
          if (typeof val === 'number') {
            return `${val.toFixed()}%`;
          }
          return String(val);
        },
      },
    });
  }, [
    data,
    colors,
    labels,
    height,
    legendWidth,
    width,
    endAngle,
    startAngle,
    customLegend,
    onClick,
  ]);

  return (
    <>
      {chartData ? (
        <div
          style={{ position: 'relative', width: '100%', height: height || 350 }}
        >
          {emptyData && (
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10,
              }}
            >
              <EmptyData needsRequiredFilters={needsRequiredFilters} />
            </div>
          )}
          <ReactApexChart
            options={chartData}
            series={chartData.series}
            type="pie"
            width="100%"
            height="100%"
          />
        </div>
      ) : undefined}
    </>
  );
}
