import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useStyles } from './styles';

interface Props {
  text?: string;
}

export const AlertInfo = ({ text }: Props) => {
  const styles = useStyles();

  return (
    <div className={styles.alertInfo}>
      <InfoIcon style={{ color: '#19B1F5' }} />
      <p>{text}</p>
    </div>
  );
};
