/* eslint-disable react-hooks/exhaustive-deps */

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AlertInfo } from '../../components/AlertInfo';
import ReturnButton from '../../components/ReturnButton';
import { SideMenu } from '../../components/SideMenu';
import { Label } from '../../components/Typo';
import { CreateNewAgenda } from './components/createNewAgenda';
import { Periods } from './components/periodList';
import { ScheduleItem } from './components/scheduleItem';
import { useStyles } from './styles';
import { getUsersAgenda } from '../../lib/profissionais';
import { getProfessionalSchedule } from '../../lib/professionalSchedule';
import EditAgenda from './components/EditAgenda';
import api from '../../api';
import { EstablishmentContext } from '../../context/EstablishmentContext';
import { getUserToken } from '../../lib/auth';
import { LoadingContext } from '../../context/LoadingContext';
import { toast } from 'react-toastify';

export interface ProfessionalSchedule {
  profissionalId: string;
  estabelecimentoSaudeId: string;
  procedimento: string;
  n_dias: string;
  intervalo: string;
  idade_min: string;
  idade_max: string;
  sexo: string;
  periods: Periods;
  ativo: boolean;
  start_at: Date;
}

export const ScheduleSetting = () => {
  const styles = useStyles();
  const [createNewAgenda, setCreateNewAgenda] = useState(false);
  const [editAgenda, setEditAgenda] = useState(false);
  const [selectedProfessional, setSelectedProfessional] = useState<any>({});
  const [professionalSchedule, setProfessionalSchedule] = useState<
    ProfessionalSchedule[]
  >([]);

  const [loadingProfissionais, setLoadingProfissionais] = useState(false);
  const [listProfessionals, setListProfessionals] = useState([]);
  const [scheduleHPV, setScheduleHPV] = useState<any>();
  const [noHasSchedule, setNoHasSchedule] = useState(false);
  const [showComponentHasSchedule, setShowComponentHasSchedule] =
    useState<any>(false);

  const { currentEstablishment } = useContext(EstablishmentContext);

  const location = useLocation();

  const { setLoading } = useContext(LoadingContext);

  const handleCancel = ({ origin }) => {
    if (origin === 'submit') {
      setLoading(true);
      getScheduleProfessional(selectedProfessional.idProfissional);
    } else {
      setSelectedProfessional({});
    }
    setCreateNewAgenda(false);
    setEditAgenda(false);
    setNoHasSchedule(false);
  };

  const handleSetProfessionalSchedule = ({
    profissionalId,
    estabelecimentoSaudeId,
    procedimento,
    n_dias,
    intervalo,
    idade_min,
    idade_max,
    sexo,
    periods,
    ativo,
    start_at,
  }: ProfessionalSchedule) => {
    const updatedArray = [
      ...professionalSchedule,
      {
        profissionalId,
        estabelecimentoSaudeId,
        procedimento,
        n_dias,
        intervalo,
        idade_min,
        idade_max,
        sexo,
        periods,
        ativo,
        start_at,
      },
    ];

    setProfessionalSchedule(updatedArray);
  };

  const professionalHasSchedule = useCallback(() => {
    const schedule = professionalSchedule.filter(
      (proSchedule) =>
        proSchedule.profissionalId === selectedProfessional?.pessoa?.id
    );

    return schedule.length > 0;
  }, [professionalSchedule, selectedProfessional]);

  const getOptionsProfessionals = async () => {
    setLoadingProfissionais(true);
    const response = await getUsersAgenda({
      estabelecimentoId: String(currentEstablishmentId),
    });

    const optionsWithEstablishment = response.data.map((item) => {
      return {
        ...item,
        nomeprofissional: item.label,
      };
    });

    setListProfessionals(optionsWithEstablishment);
    setLoadingProfissionais(false);
  };

  const currentEstablishmentId = localStorage.getItem('id_last_establishment');

  const perfilProfissional = getUserToken().permissao_atual?.nome;

  const getScheduleProfessional = async (profissionalId) => {
    setLoading(true);

    try {
      const getProfessionalEstablishment = await api.get('/profissionais', {
        params: {
          id: profissionalId,
        },
      });

      const idEstablishmentProfessional =
        perfilProfissional === 'Padrão'
          ? currentEstablishment.id
          : getProfessionalEstablishment.data.data[0].estabelecimentos[0]
              .estabelecimento.id;

      if (idEstablishmentProfessional) {
        const responseSchedule = await getProfessionalSchedule({
          profissional_id: profissionalId,
          estabelecimento_id: idEstablishmentProfessional,
        });

        if (responseSchedule.data['Teste de HPV']) {
          setScheduleHPV(responseSchedule.data['Teste de HPV']);
          setShowComponentHasSchedule(true);
        } else {
          setNoHasSchedule(true);
        }
        setShowComponentHasSchedule(true);
      } else {
        toast.error(
          'Ocorreu um erro ao tentar buscar os dados de estabelecimento do profissional, contate o suporte.',
          { position: 'bottom-right' }
        );
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getOptionsProfessionals();
  }, [editAgenda, createNewAgenda]);

  useEffect(() => {
    if (location.state.selectedProfessional) {
      getScheduleProfessional(
        location.state.selectedProfessional.idProfissional
      );
      setSelectedProfessional(location.state.selectedProfessional);
    }
  }, [location.state]);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage="/schedule" />
          <Box className={styles.header}>
            <div className={styles.title}>
              <Typography variant="h4" fontWeight="bold" color="text.primary">
                Configuração de Agenda
              </Typography>

              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
              >
                <Link key="1" to="/home" className={styles.link}>
                  <Typography key="3" variant="body1" color="text.secundary">
                    Home
                  </Typography>
                </Link>
                <Link key="1" to="/agenda-digital" className={styles.link}>
                  <Typography key="3" variant="body1" color="text.secundary">
                    Agenda Digital
                  </Typography>
                </Link>
                <Typography key="2" color="text.secundary">
                  Configuração de Agenda
                </Typography>
              </Breadcrumbs>
            </div>
          </Box>

          <Divider />

          <Box my={3} display="flex" gap={3} flexWrap="wrap">
            <Box>
              <Label labelcolor="rgba(0, 0, 0, 0.87)">Unidade de Saúde</Label>
              <Typography mt={0.5} variant="body1">
                {selectedProfessional?.nomeFantasiaEstabelecimento ?? ''}
              </Typography>
            </Box>

            <Box flex={1}>
              <AlertInfo
                text="Para configurar agenda em outra unidade de saúde ao qual
              trabalha, mude de estabelecimento no menu superior"
              />
            </Box>
          </Box>

          {!createNewAgenda && !editAgenda && (
            <Box mt={3} display="flex" justifyContent="space-between" gap={3}>
              <Box flex={1}>
                <Autocomplete
                  options={listProfessionals}
                  noOptionsText={
                    loadingProfissionais ? 'Carregando...' : 'Não há opções'
                  }
                  onChange={(_, newValue) => {
                    setScheduleHPV(null);
                    setShowComponentHasSchedule(false);
                    setNoHasSchedule(true);
                    setSelectedProfessional(newValue || '');
                    if (newValue) {
                      getScheduleProfessional(newValue.idProfissional);
                    }
                  }}
                  getOptionLabel={(option: any) => option?.label ?? ''}
                  filterOptions={(options, { inputValue }) =>
                    options.filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    )
                  }
                  value={selectedProfessional || null}
                  isOptionEqualToValue={(option, value) =>
                    option.idProfissional === value.idProfissional
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Profissional"
                      size="small"
                      placeholder="Selecione o profissional da sua UBS"
                    />
                  )}
                />
              </Box>
            </Box>
          )}
        </Box>

        {createNewAgenda && (
          <CreateNewAgenda
            setNoHasSchedule={setNoHasSchedule}
            handleClickCancel={handleCancel}
            selectedProfessional={selectedProfessional}
            handleSetProfessionalSchedule={handleSetProfessionalSchedule}
            professionalSchedule={professionalSchedule}
            savedProfessionalSchedule={professionalSchedule.filter(
              (proSchedule) => proSchedule.profissionalId === '1'
            )}
          />
        )}

        {!createNewAgenda &&
          !editAgenda &&
          noHasSchedule &&
          selectedProfessional.idProfissional &&
          showComponentHasSchedule && (
            <Box mt={3} display="flex" flexDirection="column" gap={1}>
              <ScheduleItem
                initialCheck={scheduleHPV?.ativado}
                scheduleId={scheduleHPV?.id}
                handleClick={() =>
                  !!scheduleHPV ? setEditAgenda(true) : setCreateNewAgenda(true)
                }
                title={`Agenda de Teste de HPV - ${
                  scheduleHPV ? 'Configurada' : 'Não configurada'
                }`}
                showSwitch={!!scheduleHPV}
              />
            </Box>
          )}

        {!noHasSchedule &&
          selectedProfessional.idProfissional &&
          showComponentHasSchedule && (
            <Box mt={3} display="flex" flexDirection="column" gap={1}>
              <ScheduleItem
                initialCheck={scheduleHPV?.ativado}
                scheduleId={scheduleHPV?.id}
                handleClick={() =>
                  !!scheduleHPV ? setEditAgenda(true) : setCreateNewAgenda(true)
                }
                title={`Agenda de Teste de HPV - ${
                  scheduleHPV ? 'Configurada' : 'Não configurada'
                }`}
                showSwitch={!!scheduleHPV}
              />
            </Box>
          )}

        {editAgenda && (
          <EditAgenda
            handleClickCancel={handleCancel}
            selectedProfessional={selectedProfessional}
            handleSetProfessionalSchedule={handleSetProfessionalSchedule}
            professionalSchedule={professionalSchedule}
            schedule={scheduleHPV}
            savedProfessionalSchedule={professionalSchedule.filter(
              (proSchedule) => proSchedule.profissionalId === '1'
            )}
          />
        )}
      </Container>
    </SideMenu>
  );
};
