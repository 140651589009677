import moment from 'moment';

const useSetPayload = ({
  watch,
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  equipesSelected,
  selectedResultado,
  dtColetaFinal,
  dtColetaIni,
  dtResultadoIni,
  dtResultadoFinal,
  selectedGlandular,
  selectedEscamosa,
  selectedCarater,
  selectedOrigem,
  selectedLaboratorio,
  selectedHasLesao,
  setSelectedHasLesao,
}: any) => {
  const setPayload = () => {
    const estados = estadosSelected.map((estado) => {
      if (estado === 'Sem Vinculação') {
        return 'null';
      } else {
        return estado;
      }
    });
    const arrayLab = selectedLaboratorio?.map((lab) => lab.value);

    let payload: any;
    return new Promise((resolve, reject) => {
      payload = {
        estado: estados.length > 0 ? estados.join(', ') : undefined,
        municipioId:
          municipiosSelected?.length > 0
            ? municipiosSelected.join(', ')
            : undefined,
        estabelecimento:
          estabelecimentosSelected.length > 0
            ? estabelecimentosSelected.join(', ')
            : undefined,
        rede: '',
        origem: selectedOrigem?.map((item) => item.value).join(', '),
        benigno: selectedCarater?.map((item) => item.value).join(', '),
        neoplasiaGlandular: selectedGlandular
          ?.map((item) => item.value)
          .join(', '),
        neoplasiaEscamosa: selectedEscamosa
          ?.map((item) => item.value)
          .join(', '),
        laboratorioLaudoId:
          arrayLab?.length > 0 ? arrayLab?.join(', ') : undefined,

        dt_resultado_inicio: dtResultadoIni
          ? moment(dtResultadoIni, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : undefined,

        dt_resultado_fim: dtResultadoFinal
          ? moment(dtResultadoFinal, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : undefined,

        dt_coleta_fim: dtColetaFinal
          ? moment(dtColetaFinal, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : undefined,

        dt_coleta_inicio: dtColetaIni
          ? moment(dtColetaIni, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : undefined,

        profissionalLaudoId: watch().profissionalLaudoId?.idprofissional,

        // periodo_inicio: dtColetaIni
        //   ? moment(dtColetaIni, 'DD-MM-YYYY').format('YYYY-MM-DD')
        //   : undefined,
        // periodo_fim: dtColetaFinal
        //   ? moment(dtColetaFinal, 'DD-MM-YYYY').format('YYYY-MM-DD')
        //   : undefined,
        motivo: watch().motivo ?? undefined,
        search: watch().search ?? undefined,
        size: watch().size,
        page: watch().page,
      };

      resolve(payload);
    });
  };

  return { setPayload };
};

export default useSetPayload;
