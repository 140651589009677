import { Button, Paper, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { LoadingContext } from '../../context/LoadingContext';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';
import { maskCpf } from '../../components/maskCpf';
import { phoneMask } from '../../utils/phoneMask';
import { Link } from 'react-router-dom';

const PageRequestTokenTestAgenda = () => {
  const { setLoading } = useContext(LoadingContext);
  const [telefone, setTelefone] = useState('');
  const [code, setCode] = useState<any>('');

  const getCode = async () => {
    setLoading(true);
    const response = await fetch(
      'https://api.hom.jordana.digitalcare2u.com.br/auth-externo/criar',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          telefone: `55${telefone
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(' ', '')
            .trim()}`,
        }),
      }
    )
      .then((res) => res.json())
      .catch((err) => err);

    if (response.codigo) {
      setCode(response.codigo);
    } else {
      setCode(response?.error?.message);
    }

    setLoading(false);
  };

  return (
    <div
      style={{
        backgroundColor: 'gainsboro',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        elevation={4}
        sx={{
          width: '300px',
          height: 'fit-content',
          padding: '50px 0px 30px 0px',
          margin: 'auto',
          marginTop: '150px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <StyledAsteriskTextField
          label="Digite o telefone"
          value={telefone}
          onChange={(e) => {
            setTelefone(phoneMask(e.target.value));
            setCode('');
          }}
          inputProps={{ maxLength: 15 }}
        />

        <Button
          // disabled={telefone.length !== 15}
          type="button"
          onClick={getCode}
          variant="contained"
          sx={{ marginTop: '15px' }}
        >
          PEGAR CÓDIGO
        </Button>
        {code?.length > 15 ? (
          <p
            style={{
              width: '190px',
              textAlign: 'center',
              marginTop: '15px',
              marginBottom: '0',
            }}
          >
            {code}
          </p>
        ) : (
          <Link
            to={`${window.location.origin}/login-agenda?code=${code}`}
            style={{ width: '190px', textAlign: 'center', marginTop: '15px' }}
          >
            {code}
          </Link>
        )}
      </Paper>
    </div>
  );
};

export default PageRequestTokenTestAgenda;
