import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  Dialog,
} from '@mui/material';
import { ContainerButtonsModal, useStyles } from './styles';
import React, { useEffect, useState } from 'react';
import YellowAlert from '../../../../assets/imgs/yellowAlert.svg';
import { activationProfessionalSchedule } from '../../../../lib/professionalSchedule';
import { PerfilContext } from '../../../../context/PerfilContext';

interface Props {
  title?: string;
  handleClick?: () => void;
  showSwitch?: boolean;
  scheduleId?: number;
  initialCheck?: boolean;
}

export const ScheduleItem = ({
  title,
  handleClick,
  showSwitch,
  scheduleId,

  initialCheck,
}: Props) => {
  const styles = useStyles();
  const [checked, setChecked] = useState(initialCheck);
  const [openModalActive, setOpenModalActive] = useState(false);
  const { hasPermission } = React.useContext(PerfilContext);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === false) {
      setOpenModalActive(true);
    } else {
      await activationProfessionalSchedule({
        id: scheduleId,
        ativado: event.target.checked,
      });

      setChecked(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModalActive(false);
  };

  const confirmDesactivation = async () => {
    const response = await activationProfessionalSchedule({
      id: scheduleId,
      ativado: false,
    });

    if (response.status === 200) {
      setChecked(false);
      handleCloseModal();
    } else {
      setChecked(true);
    }
  };

  useEffect(() => {
    setChecked(initialCheck);
  }, [initialCheck]);

  return (
    <>
      <Dialog
        open={openModalActive}
        onClose={handleCloseModal}
        sx={{
          '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            padding: '16px 48px',
          },
        }}
      >
        <img src={YellowAlert} alt="" width="88" />
        <Typography>ATENÇÃO</Typography>
        <p>
          A inativação da agenda atual vai resultar no <br />
          cancelamento de todos os horários que já estão <br />
          agendados, deseja continuar?
        </p>
        <ContainerButtonsModal>
          <Button variant="contained" onClick={confirmDesactivation}>
            Continuar
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleCloseModal();
              setChecked(true);
            }}
          >
            Cancelar
          </Button>
        </ContainerButtonsModal>
      </Dialog>
      <div className={styles.container}>
        <Typography variant="body2">{title}</Typography>

        <Box display="flex">
          {showSwitch && (
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={checked ? 'Agenda ativa' : 'Agenda inativa'}
            />
          )}

          {showSwitch && hasPermission('UPDATE_AGENDA') && (
            <Button variant="outlined" onClick={handleClick}>
              Editar agenda
            </Button>
          )}
          {!showSwitch && hasPermission('CREATE_AGENDA') && (
            <Button variant="outlined" onClick={handleClick}>
              {'Configurar Agenda'}
            </Button>
          )}
        </Box>
      </div>
    </>
  );
};
