import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const TableHeaders = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Nome Completo da Mulher
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Nº do Cartão SUS
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Nome Completo da Mãe
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            CPF
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Data de Nascimento
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Idade
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Nacionalidade
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Raça / Cor
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            CEP
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Logradouro
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Número
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Complemento
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Bairro
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Ponto de Referência
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            UF
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Município
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Estabelecimento <br />
            de Realização <br />
            do Teste
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Rede de Atendimento
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            CNES do Laboratório
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Laboratório Responsável pelo Laudo
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Número do Protocolo do Exame no SISCAN
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Data da Coleta
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Origem
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Margens
            <br /> Cirúrgicas
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Data do Resultado
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Lesões de Caráter
            <br />
            Benigno
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Pré-Neoplásico
            <br />
            Escamosa
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Pré-Neoplásico
            <br />
            Glandular
          </Typography>
        </TableCell>

        {/* <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Metaplasia Escamosa
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Pólipo Endocervical
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Cervicite Crônica Inespecífica
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Alterações Citoarquiteturais compatíveis com Ação Vital (HPV)
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Pré-Neoplásico Escamosa
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Adenocarcinoma in situ
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Adenocarcinoma invasor
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Outras Neoplasias Malignas
          </Typography>
        </TableCell> */}

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Diagnóstico Descritivo
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Profissional Responsável pelo Resultado
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            CBO do Profissional
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            CNS do Profissional
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            INE do
            <br /> Profissional
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;
