import { useContext, useState } from 'react';
import { CardDetailPatientConvocation } from '../CardDetailPatientConvocation';
import { Pagination, TablePagination } from '@mui/material';
import { getConvocationsHistoric } from '../../../../../lib/convocation';
import { LoadingContext } from '../../../../../context/LoadingContext';
import useSetPayload from '../FiltersHistoric/hooks/useSetPayload';

export const ListHistoric = ({
  tableData,
  watch,
  setTableData,
  setValue,
  loadingList,
  setLoadingList,
}) => {
  const [openedCard, setOpenedCard] = useState<any>(null);

  const { setLoading } = useContext(LoadingContext);

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected: watch('estado'),
    municipiosSelected: watch('municipio'),
    estabelecimentosSelected: watch('estabelecimentoId'),
  });

  const data = tableData?.items
    ?.map((item, index) => {
      const inProgress = item?.status_convocacao?.includes('Pendente');
      const outConvocation = item?.status_convocacao.includes('Fora');
      const concluded = item?.status_convocacao === 'Concluido';

      const getStatus = () => {
        if (inProgress) {
          return 'Em Andamento';
        }

        if (outConvocation) {
          return 'Fora da Convocatória';
        }

        if (concluded) {
          return 'Concluída';
        }

        return item?.status_convocacao;
      };

      return {
        ...item,
        patientName: item?.paciente_nome,
        cpf: item?.paciente_cpf,
        convocation: item?.convocacoes,
        dtAgendamento: new Date(),
        dtResult: new Date(),
        status: getStatus(),
        // attendedAt: item.index,
        index,
      };
    })
    .sort((a, b) => a.status.localeCompare(b.status));

  const colorBorder = {
    Concluída: '#7BC67E',
    'Teste Realizado': '#64B6F7',
    'Em Andamento': '#F6B681',
    Cancelada: '#F88078',
    'Fora da Convocatória': '#7986CB',
    Agendada: '#e188b9',
  };

  const loadConvocationList = async ({ page, limit }) => {
    setPayload().then(async (payload: any) => {
      const response = await getConvocationsHistoric({
        data: { ...payload, page, limit },
      });
      setTableData(response);
      setLoadingList(false);

      setLoading(false);
    });
  };

  const handlePageChange = (e, newPage) => {
    setTableData([]);
    setLoadingList(true);
    setValue('page', newPage + 1);
    loadConvocationList({ page: newPage + 1, limit: watch('limit') });
  };

  const handleSizeChange = (e) => {
    setTableData([]);
    setLoadingList(true);
    setValue('limit', e.target.value);
    loadConvocationList({
      page: e.target.value > tableData.total ? 1 : watch('page'),
      limit: e.target.value,
    });
  };

  return (
    <div style={{ width: '100%' }}>
      {loadingList ? (
        <p style={{ marginLeft: '15px' }}>Carregando dados...</p>
      ) : (
        <>
          {data?.map((item) => {
            const nameSplit = item?.patientName.split(' ');

            const nameFormatted = nameSplit.map((namePart: string) => {
              if (namePart.length === 2 || namePart.length === 3) {
              }

              if (
                (namePart.length === 2 || namePart.length === 3) &&
                namePart[0] === 'D'
              ) {
                return namePart.toLowerCase();
              } else {
                return `${String(namePart[0]).toUpperCase()}${String(
                  namePart.slice(1, namePart.length).toLowerCase()
                )}`;
              }
            });

            return (
              <div style={{ display: 'flex', width: '100%' }}>
                <div
                  style={{
                    width: '16px',
                    marginBottom: '24px',
                    marginLeft: '32px',
                    marginRight: '24px',
                    borderRadius: '19px',
                    backgroundColor: colorBorder[item.status],
                  }}
                />
                <div style={{ width: '100%' }}>
                  <CardDetailPatientConvocation
                    objConvocation={item}
                    convocation={item?.convocation}
                    cpf={item?.cpf}
                    dtAgendamento={item?.dtAgendamento}
                    dtResult={item?.dtResult}
                    patientName={nameFormatted.join(' ')}
                    status={item?.status}
                    opened={openedCard === item?.index}
                    setOpenedCard={setOpenedCard}
                    index={item?.index}
                  />
                </div>
              </div>
            );
          })}
        </>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 25, 50, 100]}
        component="div"
        count={tableData?.total || 0}
        rowsPerPage={watch('limit') || 0}
        page={tableData?.page - 1 || 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleSizeChange}
        labelRowsPerPage={'Itens por página'}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} de ${
            count !== -1 ? count : `mais do que ${to}`
          }`;
        }}
      />{' '}
    </div>
  );
};
