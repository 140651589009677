import { styled } from '@material-ui/styles';

export const AgendaHeader = styled('header')({
  width: '100%',
  height: '59px',
  backgroundColor: '#238884',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '24px',
  paddingRight: '24px',
});

export const ContentContainer = styled('div')({
  padding: '30px 25px',

  '@media (min-width: 965px)': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const Label = styled('label')({
  fontSize: '12px',
  color: 'rgba(0, 0, 0, 0.60)',
});

export const Value = styled('p')({
  margin: 0,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.87)',
});
