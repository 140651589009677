import moment from 'moment';
import { getLabelFormatedLastConvocation } from '../../../Utils/getLabelFormatedLastConvocation';
import DynamicHeaders from '../../Details/Components/DynamicHeaders';
import DynamicRows from '../../Details/Components/DynamicRows';

const ResumeTestRealized = ({
  objConvocation,
  opened,
  patientName,
  cpf,
  status,
  setOpenedCard,
  index,
}) => {
  const hasAgendaConvocacao = objConvocation.agendamentos.length > 0;
  return (
    <>
      <table className="table-detail-patient">
        <thead>
          <tr>
            <DynamicHeaders
              opened={opened}
              HeadersWhenHided={() => (
                <>
                  <>
                    <th>Convocação</th>
                    <th>Data do Agendamento</th>
                    <th>Confirmou a presença?</th>
                  </>
                </>
              )}
            />
          </tr>
        </thead>
        <tbody>
          <tr>
            <DynamicRows
              status={status}
              setOpenedCard={setOpenedCard}
              objConvocation={objConvocation}
              patientName={patientName}
              cpf={cpf}
              index={index}
              opened={opened}
              firstRowConfig={{
                text: getLabelFormatedLastConvocation(
                  objConvocation.tipo_ultima_convocatoria
                ).text,
                tooltip: getLabelFormatedLastConvocation(
                  objConvocation.tipo_ultima_convocatoria
                ).tooltip,
              }}
              secondRowConfig={{
                text: moment(
                  objConvocation.agendamentos?.find(
                    (agendamento) => agendamento.status === 1
                  )?.data
                ).format('DD/MM/YYYY'),
              }}
              thirdRowConfig={{
                text: objConvocation.confirmacao_presenca
                  ? 'Sim'
                  : 'Em aguardo',
              }}
            />
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ResumeTestRealized;
