/* eslint-disable react-hooks/exhaustive-deps */
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../api';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import { useStyles } from '../RegisterExam.style';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getUserToken } from '../../../lib/auth';
import { toast } from 'react-toastify';
import { validarData } from '../../../utils/validateBirth';
import AnmamneseForm from '../Anamnese';
import { LoadingContext } from '../../../context/LoadingContext';
import alert from '../../../assets/imgs/alert.png';
import { PerfilContext } from '../../../context/PerfilContext';

interface MtdColeta {
  id: number;
  nome: string;
}

enum LocalColeta {
  Residencia = 'RESIDENCIA',
  ServicoSaude = 'SERVICO_SAUDE',
  UnidadeMovel = 'UNIDADE_MOVEL',
  Outro = 'OUTRO',
}

enum InspecaoColo {
  Normal = 'NORMAL',
  Ausente = 'AUSENTE',
  Alterado = 'ALTERADO',
  NaoVisualizado = 'NAO_VISUALIZADO',
}

enum ExameStatus {
  ResultadoDisponivel = 'RESULTADO_DISPONIVEL',
  ResultadoNaoEntregue = 'RESULTADO_NAO_ENTREGUE',
  Entregue = 'ENTREGUE',
  Recomendado = 'RECOMENDADO',
  Coletado = 'COLETADO',
  EnviadoParaAnalise = 'ENVIADO_PARA_ANALISE',
  EmAnalise = 'EM_ANALISE',
  Disponivel = 'DISPONIVEL',
  NaoEntregue = 'NAO_ENTREGUE',
  Falha = 'FALHA',
}

export enum Escolha {
  Sim = 'SIM',
  Nao = 'NAO',
  NaoSabe = 'NAO_SABE',
  Default = '',
}

export enum Motivo {
  Rastreamento = 'RASTREAMENTO',
  Repeticao = 'REPETICAO',
  Seguimento = 'SEGUIMENTO',
}

export interface Anamnese {
  motivo: Motivo;
  papanicolaou: Escolha;
  ano_papanicolaou: number;
  diu: Escolha;
  gravida: Escolha;
  anticoncepcional: Escolha;
  hormonio: Escolha;
  radioterapia: Escolha;
  menstruacao: Escolha;
  dt_menstruacao_desconhecida: boolean;
  dt_menstruacao: string;
  sangramento_relacao: Escolha;
  sangramento_menopausa: Escolha;
}

interface Clinico {
  inspecao: InspecaoColo;
  dst: boolean;
  dt_coleta: Date | string;
  responsavelId: number | null;
  autocoleta: boolean | null;
  responsavelColetaId: number | null;
  local_coleta: LocalColeta;
  outro_local_coleta: string;
  n_kit: string;
}

interface Inputs {
  realizou_atendimento: any;
  tipo: string;
  status: ExameStatus;
  anamnese?: Anamnese;
  clinico?: Clinico | null;
  pessoaId: number;
  estabelecimentoSaudeId: number;
  profissionalId?: number | null;
  tipoId: number;
  atendimento_status: string | null;
  atendimento_observacao: string | null;
}

const schema = Yup.object({
  realizou_atendimento: Yup.string().required('Campo Obrigatório'),
  atendimento_status: Yup.string()
    .when('realizou_atendimento', {
      is: (realizouAtendimentoValue) => {
        return realizouAtendimentoValue === 'nao';
      },
      then: Yup.string().required('Campo Obrigatório').nullable(),
    })
    .nullable(),
  anamnese: Yup.object({}).when('realizou_atendimento', {
    is: (realizouAtendimentoValue) => {
      return realizouAtendimentoValue === 'sim';
    },
    then: Yup.object({
      motivo: Yup.string().required('Campo Obrigatório'),
      papanicolaou: Yup.string().required('Campo Obrigatório'),
      ano_papanicolaou: Yup.number().when('papanicolaou', {
        is: (papanicolaou: any) => {
          return papanicolaou === Escolha.Sim;
        },
        then: Yup.number().required('Campo Obrigatório').nullable(),
      }),
      hpv: Yup.string().required('Campo Obrigatório'),
      diu: Yup.string().required('Campo Obrigatório'),
      gravida: Yup.string().required('Campo Obrigatório'),
      anticoncepcional: Yup.string().required().required('Campo Obrigatório'),
      radioterapia: Yup.string().required('Campo Obrigatório'),
      dt_menstruacao_desconhecida: Yup.boolean().default(false),
      dt_menstruacao: Yup.string().when('dt_menstruacao_desconhecida', {
        is: (dt_menstruacao_desconhecida: boolean) => {
          return dt_menstruacao_desconhecida === false;
        },
        then: Yup.string().required('Campo Obrigatório'),
        otherwise: Yup.string(),
      }),
      sangramento_relacao: Yup.string().required('Campo Obrigatório'),
      sangramento_menopausa: Yup.string().when('menopausa', {
        is: (menopausa) => {
          return menopausa === 'SIM';
        },
        then: Yup.string().required('Campo Obrigatório').nullable(),
        otherwise: Yup.string().nullable(),
      }),

      hormonio: Yup.string().when('menopausa', {
        is: (menopausa) => {
          return menopausa === 'SIM';
        },
        then: Yup.string().required('Campo Obrigatório').nullable(),
        otherwise: Yup.string().nullable(),
      }),
    }),
  }),

  clinico: Yup.object({
    responsavelColetaId: Yup.number().required('Campo Obrigatório').nullable(),
  }).when('realizou_atendimento', {
    is: (realizouAtendimentoValue) => {
      return realizouAtendimentoValue === 'sim';
    },
    then: Yup.object({
      dt_coleta: Yup.string().required('Campo Obrigatório'),
      inspecao: Yup.string().required('Campo Obrigatório'),
      dst: Yup.boolean().required('Campo Obrigatório'),
      responsavelId: Yup.number().required('Campo Obrigatório'),
      responsavelColetaId: Yup.number()
        .required('Campo Obrigatório')
        .nullable(),
      autocoleta: Yup.boolean().required('Campo Obrigatório').nullable(),
      local_coleta: Yup.string().required('Campo Obrigatório'),
      outro_local_coleta: Yup.string(),
      // n_kit: Yup.string().required('Campo Obrigatório'),
    }),
  }),

  pessoaId: Yup.number().required('Campo Obrigatório'),
  profissionalId: Yup.number().when('realizou_atendimento', {
    is: (realizouAtendimentoValue) => {
      return realizouAtendimentoValue === 'sim';
    },

    then: Yup.number().required('Campo Obrigatório'),
    otherwise: Yup.number(),
  }),
  estabelecimentoSaudeId: Yup.number().required('Campo Obrigatório'),
  tipoId: Yup.number().required('Campo Obrigatório'),
  atendimento_observacao: Yup.string().when('atendimento_status', {
    is: (atendimentoStatusValue) => {
      return atendimentoStatusValue === 'OUTRO';
    },
    then: Yup.string().required(
      'É necessário informar qual outro motivo a paciente não realizou o procedimento.'
    ),
    otherwise: Yup.string(),
  }),
});

const styleRadio = {
  '&.Mui-checked': {
    color: '#238884',
  },
};

const styleFormLabel = {
  '&.Mui-focused': {
    color: '#238884',
  },

  '& .MuiFormLabel-asterisk': {
    color: '#d32f2f',
  },
};

export const HpvForm = ({ data, patientId, state }) => {
  const styles = useStyles();
  const [openModalConfirmationRegister, setOpenModalConfirmationRegister] =
    React.useState(false);
  const [pacienteData, setPacienteData] = React.useState<any>();
  const [responsaveis, setResponsaveis] = React.useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [metodoColeta, setMetodoColeta] = React.useState<MtdColeta[]>([]);
  const [selectedOption, setSelectedOption] = React.useState<any>();

  const [outroMotivoNaoAtendida, setOutroMotivoNaoAtendida] =
    React.useState('');
  const [checkedMenstruacao, setCheckedMenstruacao] =
    React.useState<boolean>(false);
  const [openDialogCancel, setOpenDialogCancel] =
    React.useState<boolean>(false);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    clearErrors,
    setError,
    watch,
  } = useForm<Inputs>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    setValue('tipo', 'Teste de HPV');
    setValue('tipoId', 1);
    setValue('status', ExameStatus.Coletado);
  }, []);

  const permissaoId = getUserToken().permissao_atual?.id;

  const location = useLocation();

  const isPadrao =
    permissaoId === 1 ||
    permissaoId === 9 ||
    permissaoId === 10 ||
    permissaoId === 7;
  const isAdminMunicipal =
    getUserToken().permissao_atual?.id === 3 &&
    getUserToken().permissao_atual?.nome === 'Admin Municipal';

  // const rhf = watch();

  const postForm = async (payload) => {
    try {
      await api.post('/hpv', payload);

      navigate(`/pacientes/${patientId}`);
    } catch (err: any) {
      toast.error(err.error?.message, { position: 'bottom-right' });
    }
  };

  const [payloadForm, setPayloadForm] = React.useState<any>();

  const onSubmit = async (data: Inputs) => {
    let payload;

    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      return;
    }

    if (!selectedOption) {
      setError('realizou_atendimento', {
        type: 'manual',
        message: 'Campo Obrigatório',
      });

      return;
    }
    if (selectedOption === 'nao') {
      payload = {
        atendimento_status: data.atendimento_status,
        status: 'NAO_ATENDIDA',
        pessoaId: data.pessoaId,
        atendimento_observacao: data.atendimento_observacao,
        profissionalId: data.profissionalId,
      };
    } else {
      payload = {
        ...data,
        anamnese: {
          ...data.anamnese,
          dt_menstruacao_desconhecida: checkedMenstruacao,
        },
        atendimento_status: 'REALIZADO',
      };
    }

    if (payload?.anamnese?.sangramento_menopausa === undefined) {
      payload = {
        ...payload,
        anamnese: {
          ...payload.anamnese,
          sangramento_menopausa: null,
        },
      };
    }

    if (selectedOption === 'nao') {
      delete payload.anamnese;
    }

    if (state.origin === 'notification') {
      setOpenModalConfirmationRegister(true);
      setPayloadForm(payload);
    } else {
      postForm(payload);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue('anamnese.dt_menstruacao', '');
    }
    setValue('anamnese.dt_menstruacao_desconhecida', event.target.checked);
    setCheckedMenstruacao(event.target.checked);
    clearErrors('anamnese.dt_menstruacao');
  };

  let navigate = useNavigate();

  const handleGetMetodoColeta = async () => {
    const res = await api.get('/metodo-coleta');

    setMetodoColeta(res.data);
  };

  const handleClickOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };

  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCloseConfirmCancel = () => {
    navigate(`/pacientes/${data.patientData.id}`);
  };

  //select option change campos
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const currentEstablishmentId = localStorage.getItem('id_last_establishment');

  React.useEffect(() => {
    handleGetMetodoColeta();
  }, []);

  React.useEffect(() => {
    setPacienteData(location.state);
  }, []);
  const { setLoading } = React.useContext(LoadingContext);
  React.useEffect(() => {
    const state: any = location.state;
    async function loadResponsaveis() {
      setValue('pessoaId', patientId);
      setValue('estabelecimentoSaudeId', Number(currentEstablishmentId));
      const response = await api.get('/profissionais/cbo/list', {
        params: {
          cboTypes: 'ENFERMEIRO, MÉDICO',
        },
      });

      const profissionais = response.data.map((profissional) => {
        return {
          idprofissional: profissional.id,
          nomeprofissional: `${profissional.pessoa.nome}`,
          label: `${profissional.pessoa.nome}`,
        };
      });

      setResponsaveis(profissionais);
    }
    loadResponsaveis();
  }, [isAdminMunicipal]);

  const getCurrentProfessional = async (cpf) => {
    setLoading(true);
    try {
      const responseProfissionaisList = await api.get(
        '/profissionais/cbo/list',
        {
          params: {
            cboTypes: 'ENFERMEIRO, MÉDICO',
          },
        }
      );

      const profissionais = responseProfissionaisList.data.map(
        (profissional) => {
          return {
            idprofissional: profissional.id,
            nomeprofissional: `${profissional.pessoa.nome}`,
            label: `${profissional.pessoa.nome}`,
          };
        }
      );

      setResponsaveis(profissionais);

      if (responseProfissionaisList.status === 200) {
        const response = await api.get('/profissionais', { params: { cpf } });

        setValue('clinico.responsavelColetaId', response.data?.data[0]?.id);
        setValue('clinico.responsavelId', response.data?.data[0]?.id);
        setValue('profissionalId', response.data?.data[0]?.id);
        setLoading(false);
      }
    } catch (error) {
      toast.error('Recarregue a página', { position: 'bottom-right' });
    }
  };

  const realizouAtendimentoValue = watch('realizou_atendimento');

  React.useEffect(() => {
    if (isPadrao) {
      getCurrentProfessional(getUserToken().pessoa.cpf);
    }
  }, [realizouAtendimentoValue]);

  // React.useEffect(() => {
  //   for (let key in errors) {
  //     if (errors[key].message) {
  //       toast.error(`${key} - ${errors[key]?.message}`, {
  //         position: 'bottom-right',
  //       });
  //     } else {
  //       Object.keys(errors[key]).forEach((item) => {
  //         const error = errors[key][item];

  //         toast.error(`${item} - ${error?.message || error}`, {
  //           position: 'bottom-right',
  //         });
  //       });
  //     }
  //   }
  // }, [errors]);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  React.useEffect(() => {
    if (state.origin === 'notification') {
      setValue(
        'clinico.dt_coleta',
        moment.utc(state.dt_coleta).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      );
    }
  }, []);

  const { hasPermission } = React.useContext(PerfilContext);

  React.useEffect(() => {
    if (!hasPermission('CREATE_EXAME_HPV')) {
      navigate('/home');
    }
  }, []);

  return (
    <Box
      mt={3}
      sx={{
        '@media screen and (min-width: 1366px)': {
          width: '1200px',
        },
      }}
    >
      <Modal
        open={openModalConfirmationRegister}
        onClose={() => setOpenModalConfirmationRegister(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            padding: '48px 24px',
            maxWidth: '464px',
            borderRadius: '12px !important',
          }}
        >
          <p style={{ fontSize: '24px', fontWeight: 700 }}>
            Você confirma esse <br />
            cadastro?
          </p>
          <img src={alert} width={104} />
          <p
            style={{
              fontWeight: 400,
              fontSize: '14px',
              marginTop: '32px',
              marginBottom: '0',
            }}
          >
            Cadastro de um Teste de HPV para o Resultado da paciente{' '}
            {state.patientData.nome} com o número de requisição no GAL:{' '}
            {state.gal_id} e Data de Coleta:{' '}
            {moment(state.dt_coleta).format('DD/MM/YYYY')}
          </p>

          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt="32px"
          >
            <Button
              variant="contained"
              // disabled={disableButton}
              onClick={() => {
                postForm(payloadForm);
              }}
              startIcon={<CheckIcon />}
            >
              CONFIRMAR
            </Button>

            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setOpenModalConfirmationRegister(false);
              }}
              startIcon={<ClearIcon />}
            >
              CANCELAR
            </Button>
          </Box>
        </Paper>
      </Modal>
      <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
        <DialogTitle>Cancelar Cadastro de exame</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja realmente cancelar o cadastro de exame ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogCancel}>Não</Button>
          <Button onClick={handleCloseConfirmCancel} autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
        <i>* Resposta obrigatória</i>
      </p>

      <Box sx={{ marginBottom: '24px' }}>
        <Paper
          variant="outlined"
          sx={{
            p: '32px',
          }}
        >
          <Typography variant="h5" fontWeight="700">
            Informações sobre o atendimento
          </Typography>

          <Divider style={{ marginTop: '24px', marginBottom: '24px' }} />
          <FormLabel
            required
            sx={styleFormLabel}
            error={!!errors.realizou_atendimento}
          >
            Realizou o Procedimento?
          </FormLabel>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="realizou_atendimento"
                control={control}
                render={({ field: { onChange } }) => (
                  <RadioGroup
                    value={selectedOption}
                    onChange={(e) => {
                      handleOptionChange(e);
                      onChange(e);
                      setValue('clinico.autocoleta', null);
                      setValue('clinico.responsavelColetaId', null);
                      setValue('clinico.responsavelId', null);
                      setValue('profissionalId', null);
                      setValue('atendimento_status', null);
                    }}
                    row
                  >
                    <FormControlLabel
                      value={`sim`}
                      control={<Radio sx={styleRadio} />}
                      label="Sim"
                    />
                    <FormControlLabel
                      value={`nao`}
                      control={<Radio sx={styleRadio} />}
                      label="Não"
                    />
                  </RadioGroup>
                )}
              />
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {errors?.realizou_atendimento?.message}
              </FormHelperText>
            </Grid>

            {selectedOption === 'sim' && (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{ flexDirection: 'column', display: 'flex' }}
                >
                  <FormControl error={!!errors?.clinico?.autocoleta}>
                    <FormLabel
                      required
                      error={!!errors?.clinico?.autocoleta}
                      sx={styleFormLabel}
                    >
                      Método de Coleta de HPV
                    </FormLabel>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          onChange={onChange}
                          value={value ? value : ''}
                          row
                          sx={{ gap: 3 }}
                        >
                          <FormControlLabel
                            value={false}
                            control={<Radio sx={styleRadio} />}
                            label="Profissional de Saúde"
                          />
                          <FormControlLabel
                            value={true}
                            disabled
                            control={<Radio sx={styleRadio} />}
                            label="Autocoleta"
                          />
                        </RadioGroup>
                      )}
                      name="clinico.autocoleta"
                      control={control}
                    />
                    <FormHelperText>
                      {errors?.clinico?.autocoleta?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {watch('clinico.autocoleta') !== undefined && (
                  <>
                    <Grid item xs={12} md={10.5}>
                      <Controller
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              {isPadrao ? (
                                <StyledAsteriskTextField
                                  disabled
                                  required
                                  fullWidth
                                  value={getUserToken().pessoa.nome}
                                  label={
                                    String(watch('clinico.autocoleta')) ===
                                    'true'
                                      ? 'Profissional Responsável pela Orientação da Autocoleta'
                                      : 'Profissional Responsável pela Coleta'
                                  }
                                  size="small"
                                  variant="outlined"
                                  className={styles.textField}
                                />
                              ) : (
                                // <></>
                                <StyledAsteriskTextField
                                  required
                                  select
                                  fullWidth
                                  disabled={isPadrao}
                                  label={
                                    String(watch('clinico.autocoleta')) ===
                                    'true'
                                      ? 'Profissional Responsável pela Orientação da Autocoleta'
                                      : 'Profissional Responsável pela Coleta'
                                  }
                                  size="small"
                                  variant="outlined"
                                  className={styles.textField}
                                  error={
                                    !!errors.clinico?.responsavelColetaId &&
                                    true
                                  }
                                  helperText={
                                    errors.clinico?.responsavelColetaId?.message
                                  }
                                  value={value ? value : ''}
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                    setValue(
                                      'clinico.responsavelId',
                                      Number(e.target.value)
                                    );
                                    setValue(
                                      'profissionalId',
                                      Number(e.target.value)
                                    );
                                  }}
                                >
                                  {responsaveis.map(
                                    (option: any, index: number) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={option.idprofissional}
                                        >
                                          {String(
                                            option.nomeprofissional
                                          ).toUpperCase()}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </StyledAsteriskTextField>
                              )}
                            </>
                          );
                        }}
                        name="clinico.responsavelColetaId"
                        control={control}
                      />
                    </Grid>
                    {String(watch('clinico.autocoleta')) === 'true' && (
                      <>
                        <Grid item xs={12} md={3}>
                          <FormControl
                            error={!!errors.clinico?.dt_coleta && true}
                          >
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={ptBR}
                            >
                              <div>
                                <Controller
                                  control={control}
                                  name="clinico.dt_coleta"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <DatePicker
                                      disabled={!!state.origin}
                                      mask="__/__/____"
                                      onChange={(date) => {
                                        const dataOriginal = date;

                                        const dataFormatada = moment(
                                          dataOriginal,
                                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z (ZZ)'
                                        ).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                                        onChange(dataFormatada);
                                      }}
                                      value={value ? value : null}
                                      renderInput={(params) => (
                                        <StyledAsteriskTextField
                                          {...params}
                                          error={
                                            !!errors.clinico?.dt_coleta && true
                                          }
                                          disabled={!!state.origin}
                                          fullWidth
                                          required
                                          sx={{ width: '100%' }}
                                          helperText={
                                            errors.clinico?.dt_coleta?.message
                                          }
                                          onBlur={() => {
                                            const isValid = validarData(
                                              moment(
                                                watch('clinico.dt_coleta')
                                              ).format('DD/MM/YYYY')
                                            );

                                            if (isValid) {
                                              clearErrors('clinico.dt_coleta');
                                            } else {
                                              setError('clinico.dt_coleta', {
                                                type: 'manual',
                                                message:
                                                  'Digite uma data válida.',
                                              });
                                            }
                                          }}
                                          label="Data da coleta"
                                          size="small"
                                          variant="outlined"
                                          className={styles.textField}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </div>
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <Controller
                            render={({ field: { onChange, value } }) => (
                              <StyledAsteriskTextField
                                select
                                label="Local da Coleta"
                                required
                                size="small"
                                variant="outlined"
                                fullWidth
                                className={styles.textField}
                                onChange={onChange}
                                error={!!errors.clinico?.local_coleta}
                                helperText={
                                  errors.clinico?.local_coleta?.message
                                }
                                value={value ? value : ''}
                              >
                                <MenuItem value={LocalColeta.Residencia}>
                                  Residência da mulher
                                </MenuItem>
                                <MenuItem value={LocalColeta.ServicoSaude}>
                                  Serviço de Saúde
                                </MenuItem>
                                <MenuItem value={LocalColeta.UnidadeMovel}>
                                  Unidade Móvel
                                </MenuItem>
                                <MenuItem value={LocalColeta.Outro}>
                                  Outro Local
                                </MenuItem>
                              </StyledAsteriskTextField>
                            )}
                            name="clinico.local_coleta"
                            control={control}
                          />
                        </Grid>
                        {watch().clinico?.local_coleta ===
                          LocalColeta.Outro && (
                          <Grid
                            item
                            xs={12}
                            md={4.5}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <StyledAsteriskTextField
                                  label="Outro Local"
                                  onChange={onChange}
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  style={{ backgroundColor: '#fafafa' }}
                                  type="text"
                                />
                              )}
                              name="clinico.n_kit"
                              control={control}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {selectedOption === 'nao' && (
              <Grid item xs={12} md={10.5}>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <>
                      {isPadrao ? (
                        <StyledAsteriskTextField
                          disabled
                          required
                          fullWidth
                          value={getUserToken().pessoa.nome}
                          label="Profissional responsável pelo atendimento"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                        />
                      ) : (
                        <StyledAsteriskTextField
                          required
                          select
                          fullWidth
                          disabled={isPadrao}
                          label="Profissional responsável pelo atendimento"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          error={!!errors.clinico?.responsavelColetaId && true}
                          helperText={
                            errors.clinico?.responsavelColetaId?.message
                          }
                          value={value ? value : ''}
                          onChange={(e) => {
                            clearErrors('clinico.responsavelColetaId');
                            onChange(e.target.value);
                            setValue(
                              'clinico.responsavelColetaId',
                              Number(e.target.value)
                            );
                            setValue(
                              'clinico.responsavelId',
                              Number(e.target.value)
                            );
                            setValue('profissionalId', Number(e.target.value));
                          }}
                        >
                          {responsaveis.map((option: any, index: number) => {
                            return (
                              <MenuItem
                                key={index}
                                value={option.idprofissional}
                              >
                                {String(option.nomeprofissional).toUpperCase()}
                              </MenuItem>
                            );
                          })}
                        </StyledAsteriskTextField>
                      )}
                    </>
                  )}
                  name="profissionalId"
                  control={control}
                />
              </Grid>
            )}
          </Grid>

          {/* <p>{errors?.realizou_atendimento?.message}</p> */}
        </Paper>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {String(watch('clinico.autocoleta')) === 'false' && (
          <AnmamneseForm
            errors={errors}
            control={control}
            watch={watch}
            styles={styles}
            checkedMenstruacao={checkedMenstruacao}
            setCheckedMenstruacao={setCheckedMenstruacao}
            clearErrors={clearErrors}
            idPatient={data?.patientData?.id}
            setValue={setValue}
            screenMode="register"
            setError={setError}
          />
        )}

        {String(watch('clinico.autocoleta')) === 'false' && (
          <Box my={3}>
            <Paper variant="outlined">
              <Box p={3}>
                <Typography variant="h5" fontWeight="bold">
                  Exame Clínico
                </Typography>

                <Box my={3}>
                  <Divider />
                </Box>

                <Grid
                  container
                  spacing={2}
                  // rowSpacing={3}
                  // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          label="Inspeção do Colo"
                          onChange={onChange}
                          value={value ? value : ''}
                          select
                          fullWidth
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          error={!!errors.clinico?.inspecao}
                          helperText={errors.clinico?.inspecao?.message}
                        >
                          <MenuItem value={InspecaoColo.Normal}>
                            Normal
                          </MenuItem>
                          <MenuItem value={InspecaoColo.Ausente}>
                            Ausente (anomalias congênitas ou retirado
                            cirurgicamente)
                          </MenuItem>
                          <MenuItem value={InspecaoColo.Alterado}>
                            Alterado
                          </MenuItem>
                          <MenuItem value={InspecaoColo.NaoVisualizado}>
                            Colo Não Visualizado
                          </MenuItem>
                        </StyledAsteriskTextField>
                      )}
                      name="clinico.inspecao"
                      control={control}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Alert
                      severity="warning"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      Na presença de colo alterado, com{' '}
                      <b>lesão sugestiva de câncer</b>, não aguardar o resultado
                      do TESTE HPV para <b>encaminhar</b> a mulher para{' '}
                      <b>colposcopia.</b>
                    </Alert>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl error={!!errors?.clinico?.dst}>
                      <FormLabel
                        required
                        error={!!errors?.clinico?.dst}
                        sx={styleFormLabel}
                        id="demo-row-radio-buttons-group-label"
                      >
                        Sinais sugestivos de doenças sexualmente transmissíveis?{' '}
                      </FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            onChange={onChange}
                            value={value ? value : ''}
                            sx={{ gap: 3 }}
                            row
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio sx={styleRadio} />}
                              label="Sim"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio sx={styleRadio} />}
                              label="Não"
                            />
                          </RadioGroup>
                        )}
                        name="clinico.dst"
                        control={control}
                      />
                      <FormHelperText>
                        {errors?.clinico?.dst?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormControl error={!!errors.clinico?.dt_coleta && true}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={ptBR}
                      >
                        <Controller
                          control={control}
                          name="clinico.dt_coleta"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <DatePicker
                              disabled={state.origin === 'notification'}
                              mask="__/__/____"
                              onChange={(date) => {
                                const dataOriginal = date;

                                const dataFormatada = moment(
                                  dataOriginal,
                                  'ddd MMM DD YYYY HH:mm:ss [GMT]Z (ZZ)'
                                ).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                                onChange(dataFormatada);
                              }}
                              value={value ? value : null}
                              renderInput={(params) => (
                                <StyledAsteriskTextField
                                  {...params}
                                  disabled={state.origin === 'notification'}
                                  error={!!errors.clinico?.dt_coleta && true}
                                  required
                                  helperText={
                                    errors.clinico?.dt_coleta?.message
                                  }
                                  onBlur={() => {
                                    const isValid = validarData(
                                      moment(watch('clinico.dt_coleta')).format(
                                        'DD/MM/YYYY'
                                      )
                                    );

                                    if (isValid) {
                                      clearErrors('clinico.dt_coleta');
                                    } else {
                                      setError('clinico.dt_coleta', {
                                        type: 'manual',
                                        message: 'Digite uma data válida.',
                                      });
                                    }
                                  }}
                                  label="Data da coleta"
                                  size="small"
                                  variant="outlined"
                                  className={styles.textField}
                                  fullWidth
                                />
                              )}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={4.5}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          select
                          label="Local da Coleta"
                          required
                          size="small"
                          variant="outlined"
                          fullWidth
                          className={styles.textField}
                          onChange={onChange}
                          error={!!errors.clinico?.local_coleta}
                          helperText={errors.clinico?.local_coleta?.message}
                          value={value ? value : ''}
                        >
                          <MenuItem value={LocalColeta.Residencia}>
                            Residência da mulher
                          </MenuItem>
                          <MenuItem value={LocalColeta.ServicoSaude}>
                            Serviço de Saúde
                          </MenuItem>
                          <MenuItem value={LocalColeta.UnidadeMovel}>
                            Unidade Móvel
                          </MenuItem>
                          <MenuItem value={LocalColeta.Outro}>
                            Outro Local
                          </MenuItem>
                        </StyledAsteriskTextField>
                      )}
                      name="clinico.local_coleta"
                      control={control}
                    />
                  </Grid>
                  {watch().clinico?.local_coleta === LocalColeta.Outro && (
                    <Grid
                      item
                      xs={12}
                      md={4.5}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            label="Outro Local"
                            onChange={onChange}
                            size="small"
                            fullWidth
                            variant="outlined"
                            style={{ backgroundColor: '#fafafa' }}
                            type="text"
                          />
                        )}
                        name="clinico.outro_local_coleta"
                        control={control}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Paper>
          </Box>
        )}

        {selectedOption === 'nao' && (
          <Box my={3}>
            <Paper variant="outlined">
              <Box p={3}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ color: !!errors?.atendimento_status ? '#d32f2f' : '' }}
                >
                  Por que o procedimento não foi realizado?{' '}
                  <span style={{ color: '#d32f2f' }}>*</span>
                </Typography>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {errors?.atendimento_status?.message}
                </FormHelperText>
                <Box my={3}>
                  <Divider />
                </Box>
                <Grid item xs={12}>
                  <Controller
                    name="atendimento_status"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(e) => {
                          onChange(e);
                          clearErrors('atendimento_observacao');
                        }}
                      >
                        <FormControlLabel
                          value={'NAO_REALIZOU'}
                          control={<Radio sx={styleRadio} />}
                          label="Compareceu mas não realizou o exame"
                        />
                        <FormControlLabel
                          value={'MENSTRUADA'}
                          control={<Radio sx={styleRadio} />}
                          label="Estava Menstruada  "
                        />
                        <FormControlLabel
                          value={'NAO_FAIXA_ETARIA'}
                          control={<Radio sx={styleRadio} />}
                          label="Não está na faixa etária"
                        />
                        <FormControlLabel
                          value={'NAO_ATIVIDADE_SEXUAL'}
                          control={<Radio sx={styleRadio} />}
                          label="Não iniciou atividade sexual"
                        />
                        <FormControlLabel
                          value={'HISTERECTOMIZADA'}
                          control={<Radio sx={styleRadio} />}
                          label="Histerectomizada por patologia benigna"
                        />
                        <FormControlLabel
                          value={'OUTRO'}
                          control={<Radio sx={styleRadio} />}
                          label="Outro"
                        />
                      </RadioGroup>
                    )}
                  />
                </Grid>
                <Controller
                  name="atendimento_observacao"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledAsteriskTextField
                        required={watch('atendimento_status') === 'OUTRO'}
                        multiline
                        rows={3}
                        sx={{ width: '100%' }}
                        label="Observações e informações adicionais"
                        onChange={(e) => onChange(e.target.value)}
                        error={!!errors.atendimento_observacao}
                        helperText={errors.atendimento_observacao?.message}
                        value={value}
                      />
                    );
                  }}
                />
              </Box>
            </Paper>
          </Box>
        )}

        <Box display="flex" justifyContent="space-between">
          <Button
            sx={{
              backgroundColor: '#17BEBB',
            }}
            variant="contained"
            type="submit"
            startIcon={<CheckIcon />}
            disabled={Object.keys(errors).length > 0}
          >
            Cadastrar
          </Button>

          <Button
            variant="outlined"
            color="error"
            onClick={handleClickOpenDialogCancel}
            startIcon={<ClearIcon />}
          >
            Cancelar
          </Button>
        </Box>
      </form>
    </Box>
  );
};
