import api from '../api';

export async function getPeople(cpf, page?: number, size?: number) {
  try {
    const res = await api.get(`/pessoas?page=${page}&size=${size}&cpf=${cpf}`);
    return res.data.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getPeopleById(id) {
  try {
    const res = await api.get(`/pessoas/${id}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getPeopleNoParams() {
  try {
    const res = await api.get(`/pacientes`);
    return res.data.data;
  } catch (error) {
    console.error(error);
  }
}

export async function postPeople(data) {
  return await api
    .post('/pessoas', { ...data })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
}
