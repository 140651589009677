import {
  Divider,
  Table,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import ButtonWithMenu from '../../ButtonWithMenu';
import TableHeaders from './Components/ColumnsHeaders';
import Rows from './Components/Rows';
import { useStyles } from '.';
import useRequestFilter from '../../../pages/Reports/ReportsHistopatologia/FiltersReportsHistopatologia/hooks/useRequestFilter';
import useSetPayload from '../../../pages/Reports/ReportsHistopatologia/FiltersReportsHistopatologia/hooks/useSetPayload';
import { DownloadFilesReportsHistopatologia } from '../../../lib/Reports/downloadFilesReportsHistopatologia';
import TotalPatientsTablesReports from '../../TotalPatientsTablesReports';

export const TableReportsHistopatologia = ({
  tableData = {
    page: 1,
    count: 25,
    totalItems: 0,
    totalPages: 1,
    data: [],
  },
  setValue,
  watch,
  setTableData,
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  equipesSelected,
  showTableData,
  selectedResultado,
  dtColetaFinal,
  dtColetaIni,
  dtResultadoIni,
  dtResultadoFinal,
  selectedGlandular,
  selectedEscamosa,
  selectedCarater,
  selectedOrigem,
  selectedHasLesao,
  setSelectedHasLesao,
  setLoadingTable,
  loadingTable,
}) => {
  const [openModalAmostragem, setOpeModalAmostragem] = useState(false);

  const page = tableData?.page || 1;
  const count = tableData?.count || 25;
  const totalItems = tableData?.totalItems || 0;

  const firstRowDisplayed = (page - 1) * count + 1;
  const lastRowDisplayed = Math.min(page * count, totalItems);

  const stringToModalExportActualList = `${firstRowDisplayed}–${lastRowDisplayed} de ${totalItems}`;

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    selectedResultado,
    dtColetaFinal,
    dtColetaIni,
    dtResultadoIni,
    dtResultadoFinal,
    selectedGlandular,
    selectedEscamosa,
    selectedCarater,
    selectedOrigem,
    selectedHasLesao,
    setSelectedHasLesao,
  });
  const { loadReportsHistopatologia } = useRequestFilter({
    setTableData,
    setLoadingTable,
  });
  const classes = useStyles();

  const handleSizeChange = (e) => {
    setTableData([]);

    setValue('size', e.target.value);
    setPayload().then((payload) => loadReportsHistopatologia({ payload }));
  };

  const handlePageChange = (e, newPage) => {
    setTableData([]);
    setValue('page', newPage + 1);
    setPayload().then((payload) => loadReportsHistopatologia({ payload }));
  };

  const prepareDownload = async ({ selectedOption, sizeFile }) => {
    setPayload().then(async (payload: any) => {
      if (sizeFile === 'all_list') {
        delete payload.size;
        delete payload.page;
      }

      const file = await DownloadFilesReportsHistopatologia({
        fileType: selectedOption,
        payload,
      });

      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `relatorio-histopatologia.${String(selectedOption).toLowerCase()}`
      );
      document.body.appendChild(link);
      link.click();

      setOpeModalAmostragem(false);
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TotalPatientsTablesReports
          totalItems={tableData?.totalItems}
          watch={watch}
        />
        <ButtonWithMenu
          disabled={!showTableData}
          prepareDownload={prepareDownload}
          openModalAmostragem={openModalAmostragem}
          setOpeModalAmostragem={setOpeModalAmostragem}
          stringToModalExportActualList={stringToModalExportActualList}
          totalItems={totalItems}
          title="Exportar Relatório Geral Histopatologia"
        />
      </div>
      <Typography
        variant="h4"
        fontWeight="bold"
        style={{ marginBottom: '30px', fontSize: '24px', marginTop: '35px' }}
      >
        Listagem
      </Typography>
      <Divider />
      <TableContainer>
        {showTableData ? (
          <>
            {loadingTable ? (
              <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                Carregando Dados...
              </div>
            ) : (
              <>
                <TableHeaders />
                <Rows tableData={tableData} />
              </>
            )}
          </>
        ) : (
          <>
            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
              Selecione um filtro.
            </div>
          </>
        )}
      </TableContainer>
      <TablePagination
        component="div"
        count={totalItems}
        page={Math.max(0, page - 1)}
        onPageChange={handlePageChange}
        rowsPerPage={count}
        onRowsPerPageChange={handleSizeChange}
        labelRowsPerPage={'Itens por página'}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} de ${
            count !== -1 ? count : `mais do que ${to}`
          }`;
        }}
        rowsPerPageOptions={[10, 25, 50]}
      />
    </>
  );
};
