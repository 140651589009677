export const DisabledCalendarPlus = () => {
  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.667969" width="64" height="64" rx="32" fill="#9E9E9E" />
      <path
        d="M45.168 18.25H20.168C19.4776 18.25 18.918 18.8096 18.918 19.5V44.5C18.918 45.1904 19.4776 45.75 20.168 45.75H45.168C45.8583 45.75 46.418 45.1904 46.418 44.5V19.5C46.418 18.8096 45.8583 18.25 45.168 18.25Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.168 15.75V20.75"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.168 15.75V20.75"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.918 25.75H46.418"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.043 35.75H28.293"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.668 31.375V40.125"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const CalendarPlus = () => {
  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.667969" width="64" height="64" rx="32" fill="#238884" />
      <path
        d="M45.168 18.25H20.168C19.4776 18.25 18.918 18.8096 18.918 19.5V44.5C18.918 45.1904 19.4776 45.75 20.168 45.75H45.168C45.8583 45.75 46.418 45.1904 46.418 44.5V19.5C46.418 18.8096 45.8583 18.25 45.168 18.25Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.168 15.75V20.75"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.168 15.75V20.75"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.918 25.75H46.418"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.043 35.75H28.293"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.668 31.375V40.125"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
