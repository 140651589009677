import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import api from '../../../api';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import { Inputs } from '../../../types/Citologia/types';
import { useStyles } from '../RegisterExam.style';
import { Escolha, Motivo } from './Hpv';
import { schemaCitologia } from './schemCitologia';
import { getUserToken } from '../../../lib/auth';
import AnmamneseForm from '../Anamnese';
import { toast } from 'react-toastify';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DownloadIcon from '@mui/icons-material/Download';
import { maskCpf } from '../../../components/maskCpf';
import laboratory from '../../../assets/imgs/laboratoryIcon.svg';
import { LaboratoryRounded } from '../../../assets/imgs/LaboratoryRounded';
import { handleOpenHpvList } from '../../../utils/CitologiaReflexoUtils/getHpvList';
import { ModalHpvCitologiaReflexo } from '../../../components/ModalHpvCitologiaReflexo';
import { schema } from './schemaCitologiaReflexo';
import moment from 'moment';
import { PerfilContext } from '../../../context/PerfilContext';

const CitologiaReflexo = ({ location, data, view }) => {
  const [openDialogCancel, setOpenDialogCancel] = useState<boolean>(false);
  const [openModalHpv, setOpenModalHpv] = useState(false);
  const [hpvList, setHpvList] = useState([]);
  const [hpvSelected, setHpvSelected] = useState<any>();
  const [objHpv, setObjHpv] = useState<any>();
  const [optionsResponsaveis, setOptionsResponsaveis] = useState<any>([]);
  const [optionsEstabelecimentos, setOptionsEstabelecimentos] = useState<any>(
    []
  );
  const [cnesEstabelecimento, setCnesEstabelecimento] = useState();
  const [disableInputResponsavel, setDisableInputResponsavel] = useState(false);
  const [disableLaboratorio, setDisableLaboratorio] = useState(false);

  const styleFormLabel = {
    '&.Mui-focused': {
      color: '#238884',
    },

    '& .MuiFormLabel-asterisk': {
      color: 'red',
    },
  };

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
    reset,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      rede_atendimento: null,
      laboratorio_responsavel: { nome_fantasia: '', id: '' },
      dt_coleta: null,
      outras_neoplasias_malignas: null,
      dt_resultado: '',
      resultado: '',
      profissionalId: {},
      cbo: null,
      cns: null,
      tipo: null,
      pessoaId: null,
      cnes_laboratorio: null,
      atipiaEscamosaIndeterminada: null,
      atipiaGlandularIndeterminada: null,
      atipiaOrigemIndefinida: null,
      atipiaEscamosa: null,
      atipiaGlandular: null,
      protocolo_siscan: '',
    } as any,
  });
  const rhf = watch();
  const styles = useStyles();
  const navigate = useNavigate();
  const handleClickOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };

  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCloseConfirmCancel = () => {
    navigate(`/pacientes/${data.patientData.id}`);
  };

  const user: any = getUserToken();

  const onSubmit = async (data) => {
    try {
      const resProf = await api.get('/profissionais', {
        params: { cpf: user?.pessoa?.cpf },
      });

      const objToPostCitologia = {
        dt_coleta: moment(data.dt_resultado).format('YYYY-MM-DD'),
        tipo: 'Reflexo',
        clinico: null,
        anamnese: null,
        profissionalId: resProf.data?.data[0]?.id,
        estabelecimentoSaudeId: user.estabelecimento_saude?.id,
        pessoaId: location.state.patientData.id,
      };

      const response = await api.post('/citologia', { ...objToPostCitologia });

      if (response.status === 201) {
        const responseResultado = await api.post(
          `/citologia/${response.data.id}/resultado`,
          {
            ...data,
            cnes_laboratorio: data?.laboratorio_responsavel?.cnes,
            laboratorio_responsavel:
              data?.laboratorio_responsavel?.nome_fantasia,
            profissionalId: data?.profissionalId?.id,
            dt_resultado: moment(data.dt_resultado).format('YYYY-MM-DD'),
          }
        );

        if (responseResultado.status === 201) {
          navigate(`/pacientes/${location.state.patientData.id}`);
        }
      }
    } catch (err: any) {
      toast.error(err.error.message, { position: 'bottom-right' });
      console.error(err);
    }
  };

  const hasResult = location?.state?.resultData;

  const generatePDF = async () => {
    try {
      const response = await api.get(
        `/reports/citologia/exame/resultado/${hasResult.id}`,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        }
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
    }
  };

  const getOptionsProfissionais = async (estab_id) => {
    if (!estab_id) {
      return;
    }
    const response = await api.get('/profissionais/cbo/list', {
      params: { cboType: 'MÉDICO', estabelecimentoId: estab_id },
    });

    const currentUser = getUserToken();

    const userLoggedIsMedicoLaboratorio = response.data.find(
      (responsavel) => responsavel?.pessoa?.id === currentUser?.pessoa.id
    );

    const isAdminLaboratorio =
      currentUser.permissao_atual?.contexto === 'LABORATÓRIO' &&
      currentUser.permissao_atual?.nome === 'Admin Estabelecimento';

    setOptionsResponsaveis(response.data);
  };

  const getOptionsEstabelecimentos = async () => {
    /*const response = await api.get('/estabelecimentos-saude', {
      params: { id: '1474' }, //TODO: depois pegar por rota os laboratorios
    });*/
    const options = [
      {
        id: 1474,
        nome_fantasia: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
        cnes: 2712105,
      },
      {
        id: 4691,
        nome_fantasia: 'US 376 POLICLINICA SALOMAO KELNER',
        cnes: 6897029,
      },
      {
        id: 158,
        nome_fantasia: 'US 144 POLICLINICA CLEMENTINO FRAGA',
        cnes: '0000647',
      },
      {
        id: 61,
        nome_fantasia: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
        cnes: '0000779',
      },
    ] as any;

    setOptionsEstabelecimentos(options);
  };

  const dtNascimentoPaciente = location?.state?.patientData
    ? location?.state?.patientData?.dt_nascimento?.split('-')
    : '';

  const outrasNeoplasias = watch('outras_neoplasias_malignas');
  useEffect(() => {
    if (outrasNeoplasias === true) {
      setValue('atipiaEscamosa', null);
      setValue('atipiaGlandular', null);
      setValue('atipiaOrigemIndefinida', null);
      setValue('atipiaGlandularIndeterminada', null);
      setValue('atipiaEscamosaIndeterminada', null);
    }
  }, [outrasNeoplasias]);
  useEffect(() => {
    if (hasResult) {
      setValue('rede_atendimento', hasResult.rede_atendimento);

      setValue('dt_resultado', new Date(hasResult.dt_resultado + 'T00:00:00'));
      setValue('resultado', hasResult.resultado);
      setValue(
        'atipiaEscamosaIndeterminada',
        hasResult.atipiaEscamosaIndeterminada
      );
      setValue(
        'atipiaGlandularIndeterminada',
        hasResult.atipiaGlandularIndeterminada
      );
      setValue('atipiaOrigemIndefinida', hasResult.atipiaOrigemIndefinida);
      setValue('atipiaGlandular', hasResult.atipiaGlandular);
      setValue('atipiaEscamosa', hasResult.atipiaEscamosa);
      setValue(
        'outras_neoplasias_malignas',
        hasResult.outras_neoplasias_malignas
      );
      setValue('profissionalId', hasResult.profissional?.pessoa?.nome);
      setCnesEstabelecimento(hasResult.cnes_laboratorio);
      setValue('laboratorio_responsavel', hasResult.laboratorio_responsavel);
      setValue('cbo', hasResult.profissional?.cbo);
      setValue('cns', hasResult.profissional?.cns);
      setValue('protocolo_siscan', hasResult.protocolo_siscan);
    }
  }, []);

  useEffect(() => {
    setValue('tipo', 'Reflexo');
    setValue('pessoaId', location?.state?.patientData?.id);
    //setValue('cnes_laboratorio', '2712105');
    setValue('rede_atendimento', 'Rede SUS');
  }, [location?.state?.patientData?.id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    //getOptionsProfissionais();
    getOptionsEstabelecimentos();
  }, []);

  const currentUser = getUserToken();

  const errorsFields = errors as any;

  const isAdminLaboratorio =
    currentUser.permissao_atual?.contexto === 'LABORATÓRIO' &&
    currentUser.permissao_atual?.nome === 'Admin Estabelecimento';

  const isMedicoLaboratorio =
    currentUser.permissao_atual?.contexto === 'LABORATÓRIO' &&
    currentUser.permissao_atual?.id === 7;

  const isOutrosLaboratorio =
    currentUser.permissao_atual?.contexto === 'LABORATÓRIO' &&
    currentUser.permissao_atual?.id === 8;

  const laboratorioUserId = getUserToken().estabelecimento_saude?.id;

  const setProfissional = async (cpf) => {
    try {
      if (!!!hasResult) {
        const response = await api.get('/profissionais', { params: { cpf } });

        const obj = {
          id: response.data.data[0].id,
          cns: response.data.data[0].cns,
          cbo: response.data.data[0].cbo,

          pessoa: {
            id: response.data.data[0].pessoa.id,
            nome: response.data.data[0].pessoa.nome,
          },
        };

        setValue('profissionalId', obj);
        setValue('cbo', obj.cns);
        setValue('cns', obj.cbo);
      }
    } catch (err) {}
  };

  useEffect(() => {
    const laboratorioSelected = optionsEstabelecimentos?.find(
      (lab) => lab.id === laboratorioUserId
    );
    if (isAdminLaboratorio || isMedicoLaboratorio || isOutrosLaboratorio) {
      if (!!!hasResult) {
        setDisableLaboratorio(true);
        getOptionsProfissionais(laboratorioSelected?.id);
        setCnesEstabelecimento(laboratorioSelected?.cnes);
        setValue('laboratorio_responsavel', laboratorioSelected);
      }
    }

    if (isMedicoLaboratorio) {
      const pessoa = getUserToken();
      setProfissional(pessoa.pessoa.cpf);
      const profissional = optionsResponsaveis.find(
        (resp) => resp?.pessoa?.id === pessoa?.pessoa?.id
      );

      setDisableInputResponsavel(true);
    }
  }, [
    isAdminLaboratorio,
    isMedicoLaboratorio,
    isOutrosLaboratorio,
    optionsEstabelecimentos,
  ]);

  useEffect(() => {
    if (isMedicoLaboratorio) {
      getOptionsProfissionais(laboratorioUserId);
    }
  }, []);

  useEffect(() => {
    if (isMedicoLaboratorio) {
      const pessoa = getUserToken();
      const profissional = optionsResponsaveis.find(
        (resp) => resp?.pessoa?.id === pessoa?.pessoa?.id
      );

      setDisableInputResponsavel(true);

      // setValue('profissionalId', profissional);
      // setValue('cbo', profissional?.cbo);
      // setValue('cns', profissional?.cns);
    }
  }, [optionsResponsaveis, isMedicoLaboratorio]);

  const { hasPermission } = useContext(PerfilContext);

  useEffect(() => {
    if (!hasPermission('VIEW_EXAME_CITOLOGIA_RESULTADO')) {
      navigate('/home');
    }
  }, []);

  return (
    <>
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
      >
        <Button
          startIcon={<ContentPasteIcon />}
          onClick={() =>
            handleOpenHpvList({
              idPatient: data?.examData?.pessoa?.id,
              setHpvList: setHpvList,
              setOpenModalHpv: setOpenModalHpv,
            })
          }
          variant="contained"
        >
          VISUALIZAR EXAME: TESTE DE HPV
        </Button>
        <Button
          variant="outlined"
          onClick={() => generatePDF()}
          startIcon={<DownloadIcon />}
        >
          EXPORTAR
        </Button>
      </Box>
      <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
        <i>* Resposta obrigatória</i>
      </p>

      <Paper
        variant="outlined"
        sx={{ borderRadius: '10px', marginTop: '24px' }}
      >
        <Box p={3}>
          <Typography variant="h5" fontWeight="bold">
            Informações de Identificação
          </Typography>
          <Box my={3}>
            <Divider />
          </Box>
          <Box display="flex" justifyContent="space-between" pr={10}>
            <div>
              <p style={{ fontSize: '12px', color: '#00000099', margin: 0 }}>
                Nome
              </p>
              <p style={{ color: '#202020', margin: 0 }}>
                {location?.state?.patientData?.nome}
              </p>
            </div>
            <div>
              <p style={{ fontSize: '12px', color: '#00000099', margin: 0 }}>
                CPF
              </p>
              <p style={{ color: '#202020', margin: 0 }}>
                {maskCpf(location?.state?.patientData?.cpf)}
              </p>
            </div>
            <div>
              <p style={{ fontSize: '12px', color: '#00000099', margin: 0 }}>
                Nº do Cartão SUS
              </p>
              <p style={{ color: '#202020', margin: 0 }}>
                {location?.state?.patientData?.cns}
              </p>
            </div>
            <div>
              <p style={{ fontSize: '12px', color: '#00000099', margin: 0 }}>
                Data de Nascimento
              </p>
              <p style={{ color: '#202020', margin: 0 }}>
                {dtNascimentoPaciente[2] +
                  '/' +
                  dtNascimentoPaciente[1] +
                  '/' +
                  dtNascimentoPaciente[0]}
              </p>
            </div>
          </Box>
        </Box>
      </Paper>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box my={3}>
          <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
            <Box p={3}>
              <Typography variant="h5" fontWeight="bold">
                Identificação do Laboratório
              </Typography>

              <Box my={3}>
                <Divider />
              </Box>

              <FormControl>
                <FormLabel
                  required
                  sx={styleFormLabel}
                  id="rede-atendimento"
                  error={!!errors.rede_atendimento}
                  disabled={view}
                >
                  Rede de Atendimento
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup onChange={onChange} value={'Rede SUS'} row>
                      <FormControlLabel
                        value="Rede SUS"
                        control={<Radio />}
                        label="Rede SUS"
                        disabled
                      />
                      <FormControlLabel
                        value="Saúde Suplementar"
                        control={<Radio />}
                        label="Saúde suplementar"
                        disabled
                      />
                    </RadioGroup>
                  )}
                  name="rede_atendimento"
                  control={control}
                />
                <FormHelperText>
                  {errors?.rede_atendimento?.message}
                </FormHelperText>
              </FormControl>

              <Box mt={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="CNES do Laboratório"
                      size="small"
                      value={cnesEstabelecimento || ''}
                      variant="outlined"
                      className={styles.textField}
                      onChange={() => {}}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={9}>
                    <Controller
                      name="laboratorio_responsavel"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            {view ? (
                              <TextField
                                value={value ? value : ''}
                                variant="outlined"
                                label="Laboratório Responsável pelo Resultado"
                                size="small"
                                fullWidth
                                disabled
                                sx={{ backgroundColor: view ? '#F0F0F0' : '' }}
                              />
                            ) : (
                              <Autocomplete
                                options={optionsEstabelecimentos}
                                value={value ? value : { nome_fantasia: '' }}
                                onChange={(_, newValue) => {
                                  onChange(newValue ?? {});
                                  setCnesEstabelecimento(newValue?.cnes);
                                  getOptionsProfissionais(newValue?.id);
                                  setValue('cnes_laboratorio', newValue?.cnes);
                                  setValue('profissionalId', {});
                                  setValue('cbo', '');
                                  setValue('cns', '');
                                }}
                                disabled={disableLaboratorio || view}
                                sx={{ backgroundColor: view ? '#F0F0F0' : '' }}
                                getOptionLabel={(option: any) =>
                                  option?.nome_fantasia
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    variant="outlined"
                                    label="Laboratório responsavél pelo Resultado"
                                    size="small"
                                    error={
                                      !!errorsFields?.laboratorio_responsavel
                                        ?.id
                                    }
                                    helperText={
                                      errorsFields?.laboratorio_responsavel?.id
                                        ?.message
                                    }
                                    fullWidth
                                  />
                                )}
                              />
                            )}
                          </>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          label="Número do Protocolo do Exame no SISCAN"
                          size="small"
                          type="number"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          disabled={view}
                        />
                      )}
                      name="protocolo_siscan"
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>

          <Box my={3} />
          <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
            <Box p={3}>
              <Typography variant="h5" fontWeight="bold">
                Resultado do Exame
              </Typography>

              <Box my={3}>
                <Divider />
              </Box>

              <FormControl error={!!errors?.dt_coleta && true}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <div>
                    <Controller
                      control={control}
                      name="dt_resultado"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          onChange={onChange}
                          label="Data do Resultado"
                          mask="__/__/____"
                          disabled={view}
                          value={value ? value : null}
                          renderInput={(params) => (
                            <StyledAsteriskTextField
                              required
                              {...params}
                              style={{ marginTop: '20px' }}
                              size="small"
                              error={!!errors?.dt_resultado}
                              variant="outlined"
                              // className={styles.textField}
                              fullWidth
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {errors?.dt_resultado?.message}
                    </FormHelperText>
                  </div>
                </LocalizationProvider>
              </FormControl>

              <Box mt={3}>
                <FormControl error={!!errors?.resultado}>
                  <FormLabel required sx={styleFormLabel} id="resultado">
                    Resultado
                  </FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        onChange={onChange}
                        value={value ? value : ''}
                        row
                      >
                        <FormControlLabel
                          value="Negativo"
                          control={<Radio />}
                          label="Negativo"
                          disabled={view}
                        />
                        <FormControlLabel
                          value="Alterações Epiteliais"
                          control={<Radio />}
                          label="Com Alterações Epiteliais"
                          disabled={view}
                        />

                        <FormControlLabel
                          value="Insatisfatório"
                          control={<Radio />}
                          label="Insatisfatório"
                          disabled={view}
                        />
                      </RadioGroup>
                    )}
                    name="resultado"
                    control={control}
                  />
                  <FormHelperText>{errors?.resultado?.message}</FormHelperText>
                </FormControl>
              </Box>

              {rhf.resultado === 'Alterações Epiteliais' && (
                <Box mt={3}>
                  <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                    <Box p={3}>
                      <Typography variant="body1" color="text.secondary">
                        Células Atípicas de Significado Indeterminado
                      </Typography>

                      <Box my={3}>
                        <Divider />
                      </Box>

                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography variant="body1" color="text.secondary">
                            Escamosas:
                          </Typography>

                          <Controller
                            name="atipiaEscamosaIndeterminada"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <RadioGroup
                                  onChange={(_, newValue) => {
                                    onChange({ id: Number(newValue) });
                                  }}
                                  value={value}
                                  row
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={
                                      <Radio checked={value?.id === 1} />
                                    }
                                    label="Possivelmente não neoplásicas (ASC-US)"
                                    disabled={view}
                                  />
                                  <FormControlLabel
                                    value={2}
                                    control={
                                      <Radio checked={value?.id === 2} />
                                    }
                                    label="Não se pode afastar lesão de alto grau (ASC-H)"
                                    disabled={view}
                                  />
                                </RadioGroup>
                              );
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="body1" color="text.secondary">
                            Glandulares:
                          </Typography>
                          <Controller
                            name="atipiaGlandularIndeterminada"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <RadioGroup
                                  onChange={(_, newValue) => {
                                    onChange({ id: Number(newValue) });
                                  }}
                                  value={value ? value : ''}
                                  row
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={
                                      <Radio checked={value?.id === 1} />
                                    }
                                    label="Possivelmente não neoplásicas"
                                    disabled={view}
                                  />
                                  <FormControlLabel
                                    value={2}
                                    control={
                                      <Radio checked={value?.id === 2} />
                                    }
                                    disabled={view}
                                    label="Não se pode afastar lesão de alto grau"
                                  />
                                </RadioGroup>
                              );
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="body1" color="text.secondary">
                            De origem indefinida:
                          </Typography>

                          <Controller
                            name="atipiaOrigemIndefinida"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <RadioGroup
                                  onChange={(_, newValue) => {
                                    onChange({ id: Number(newValue) });
                                  }}
                                  value={value ? value : ''}
                                  row
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={
                                      <Radio checked={value?.id === 1} />
                                    }
                                    label="Possivelmente não neoplásicas"
                                    disabled={view}
                                  />
                                  <FormControlLabel
                                    value={2}
                                    control={
                                      <Radio checked={value?.id === 2} />
                                    }
                                    label="Não se pode afastar lesão de alto grau"
                                    disabled={view}
                                  />
                                </RadioGroup>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>

                  <Paper
                    variant="outlined"
                    sx={{ borderRadius: '10px', marginTop: '24px' }}
                  >
                    <Box p={3}>
                      <Grid item xs={12}>
                        <Typography variant="body1" color="text.secondary">
                          Atipias em Células Escamosas
                        </Typography>
                        <Box my={3}>
                          <Divider />
                        </Box>

                        <Controller
                          name="atipiaEscamosa"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <RadioGroup
                                onChange={(_, newValue) => {
                                  onChange({ id: Number(newValue) });
                                }}
                                value={value ? value : ''}
                              >
                                <FormControlLabel
                                  value={1}
                                  control={<Radio checked={value?.id === 1} />}
                                  label="Lesão intraepitelial de baixo grau (compreendendo o efeito citopático pelo HPV e noplasia intraepitelial cervical grau I"
                                  disabled={view}
                                />
                                <FormControlLabel
                                  value={2}
                                  control={<Radio checked={value?.id === 2} />}
                                  label="Lesão intraepitelial de alto grau (compreendendo neoplasias intraepiteliais cervicais grau II e III"
                                  disabled={view}
                                />
                                <FormControlLabel
                                  value={3}
                                  control={<Radio checked={value?.id === 3} />}
                                  label="Lesão intraepitelial de alto grau, não podendo excluir microinvasão"
                                  disabled={view}
                                />
                                <FormControlLabel
                                  value={4}
                                  control={<Radio checked={value?.id === 4} />}
                                  label="Carcinoma epidermóide invasor"
                                  disabled={view}
                                />
                              </RadioGroup>
                            );
                          }}
                        />
                        {/* <FormGroup>
                            <Controller
                              name="atipia_escamosa.grau_I"
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={<Radio {...field} />}
                                  label="Lesão intraepitelial de baixo grau (compreendendo o efeito citopático pelo HPV e noplasia intraepitelial cervical grau I"
                                />
                              )}
                            />
                            <Controller
                              name="atipia_escamosa.grau_II"
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={<Radio {...field} />}
                                  label="Lesão intraepitelial de alto grau (compreendendo neoplasias intraepiteliais cervicais grau II e III"
                                />
                              )}
                            />
                            <Controller
                              name="atipia_escamosa.alto_grau"
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={<Radio {...field} />}
                                  label="Lesão intraepitelial de alto grau, não podendo excluir microinvasão"
                                />
                              )}
                            />
                            <Controller
                              name="atipia_escamosa.carcinoma"
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={<Radio {...field} />}
                                  label="Carcinoma epidermóide invasor"
                                />
                              )}
                            />
                          </FormGroup> */}
                      </Grid>
                    </Box>
                  </Paper>

                  <Paper
                    variant="outlined"
                    sx={{
                      borderRadius: '10px',
                      marginTop: '24px',
                      marginBottom: '24px',
                    }}
                  >
                    <Box p={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography variant="body1" color="text.secondary">
                            Atipias em Células Glandulares
                          </Typography>
                          <Box my={3}>
                            <Divider />
                          </Box>

                          <Controller
                            name="atipiaGlandular"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <RadioGroup
                                  onChange={(_, newValue) => {
                                    onChange({ id: Number(newValue) });
                                  }}
                                  value={value ? value : ''}
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={
                                      <Radio checked={value?.id === 1} />
                                    }
                                    label="Adenocarcinoma ‘in situ’"
                                    disabled={view}
                                  />
                                  <FormControlLabel
                                    value={2}
                                    control={
                                      <Radio checked={value?.id === 2} />
                                    }
                                    label="Adenocarcinoma invasor cervical"
                                    disabled={view}
                                  />
                                  <FormControlLabel
                                    value={3}
                                    control={
                                      <Radio checked={value?.id === 3} />
                                    }
                                    label="Adenocarcinoma invasor endometrial"
                                    disabled={view}
                                  />
                                  <FormControlLabel
                                    value={4}
                                    control={
                                      <Radio checked={value?.id === 4} />
                                    }
                                    label="Adenocarcinoma invasor em outras especificações"
                                    disabled={view}
                                  />
                                </RadioGroup>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                  <Grid item xs={12}>
                    <Controller
                      name="outras_neoplasias_malignas"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={onChange}
                                value={value}
                                checked={value}
                              />
                            }
                            disabled={view}
                            label="Outras Neoplasias Malignas"
                          />
                        );
                      }}
                    />
                  </Grid>
                </Box>
              )}
            </Box>
          </Paper>

          <Paper
            variant="outlined"
            sx={{ borderRadius: '10px', marginTop: '24px' }}
          >
            <Box p={3}>
              <Typography variant="h5" fontWeight="bold">
                Profissional Responsável
              </Typography>
              <Box my={3}>
                <Divider />
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="profissionalId"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          {view ? (
                            <TextField
                              value={value ? value : ''}
                              variant="outlined"
                              label="Profissional Responsável pelo Resultado"
                              size="small"
                              fullWidth
                              disabled
                              sx={{ backgroundColor: view ? '#F0F0F0' : '' }}
                            />
                          ) : (
                            <Autocomplete
                              options={optionsResponsaveis}
                              value={value || ''}
                              onChange={(_, newValue) => {
                                onChange(newValue);
                                setValue('cbo', newValue?.cbo);
                                setValue('cns', newValue?.cns);
                              }}
                              disabled={
                                disableInputResponsavel ||
                                view ||
                                !cnesEstabelecimento
                              }
                              sx={{ backgroundColor: view ? '#F0F0F0' : '' }}
                              getOptionLabel={(option: any) =>
                                option?.pessoa?.nome || ''
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  error={!!errorsFields?.profissionalId}
                                  helperText={
                                    errorsFields?.profissionalId?.id?.message
                                  }
                                  variant="outlined"
                                  label="Profissional Responsável pelo Resultado"
                                  size="small"
                                  fullWidth
                                />
                              )}
                            />
                          )}
                        </>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    control={control}
                    name="cbo"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <StyledAsteriskTextField
                          fullWidth
                          required
                          value={value ? value : ''}
                          label="CBO do Profissional"
                          size="small"
                          disabled
                          variant="outlined"
                          sx={{ backgroundColor: '#F0F0F0' }}
                        />
                      );
                    }}
                  ></Controller>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    control={control}
                    name="cns"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <StyledAsteriskTextField
                          fullWidth
                          required
                          value={value ? value : ''}
                          label="CNS do Profissional"
                          size="small"
                          disabled
                          variant="outlined"
                          sx={{ backgroundColor: '#F0F0F0' }}
                        />
                      );
                    }}
                  ></Controller>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box display="flex" justifyContent="space-between" marginTop="16px">
          {view ? (
            <Button
              sx={{
                backgroundColor: '#238884',
              }}
              variant="contained"
              onClick={() =>
                navigate(`/pacientes/${location.state.patientData.id}`)
              }
              startIcon={<CheckIcon />}
            >
              VOLTAR
            </Button>
          ) : (
            <>
              <Button
                sx={{
                  backgroundColor: '#238884',
                }}
                variant="contained"
                type="submit"
                startIcon={<CheckIcon />}
              >
                Cadastrar
              </Button>

              <Button
                variant="outlined"
                color="error"
                onClick={handleClickOpenDialogCancel}
                startIcon={<ClearIcon />}
              >
                Cancelar
              </Button>
            </>
          )}

          {/* <Dialog open={openModalHpv} onClose={() => setOpenModalHpv(false)}>
            <DialogTitle>
              <Typography variant="h6" fontWeight="bold">
                Visualizar Exame: Teste de HPV
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Paper
                variant="outlined"
                style={{ padding: '16px', borderRadius: '12px' }}
              >
                <Box display="flex" alignItems="center" gap="24px">
                  <LaboratoryRounded />
                  <Typography variant="h6" fontWeight="bold">
                    Qual teste de HPV você deseja visualizar?
                  </Typography>
                </Box>

                <Box my={3}>
                  <Divider />
                </Box>
                <Box>
                  <RadioGroup
                    onChange={(_, newValue) => setHpvSelected(newValue)}
                    value={hpvSelected}
                  >
                    {hpvList?.map((hpvItem: any) => {
                      const dateHpv = hpvItem?.hpv?.dt_coleta.split('-');
                      return (
                        <>
                          <FormControlLabel
                            value={hpvItem?.hpv.id}
                            onClick={() => setObjHpv(hpvItem)}
                            control={<Radio />}
                            label={`Teste de HPV (${
                              dateHpv[2] + '/' + dateHpv[1] + '/' + dateHpv[0]
                            })`}
                          />
                        </>
                      );
                    })}
                  </RadioGroup>
                </Box>
              </Paper>
            </DialogContent>
            <DialogActions
              sx={{ justifyContent: 'center', gap: '24px', padding: '16px' }}
            >
              <Button
                sx={{
                  backgroundColor: '#238884',
                }}
                onClick={handleRedirectToHpv}
                variant="contained"
                startIcon={<CheckIcon />}
              >
                CONTINUAR
              </Button>

              <Button
                variant="outlined"
                color="error"
                onClick={() => setOpenModalHpv(false)}
                startIcon={<ClearIcon />}
              >
                CANCELAR
              </Button>
            </DialogActions>
          </Dialog> */}

          <ModalHpvCitologiaReflexo
            data={data}
            hpvList={hpvList}
            hpvSelected={hpvSelected}
            objHpv={objHpv}
            openModalHpv={openModalHpv}
            setHpvSelected={setHpvSelected}
            setObjHpv={setObjHpv}
            setOpenModalHpv={setOpenModalHpv}
          />

          <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
            <DialogTitle>Cancelar Cadastro de exame</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Deseja realmente cancelar o cadastro de exame ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogCancel}>Não</Button>
              <Button onClick={handleCloseConfirmCancel} autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </form>
    </>
  );
};

export default CitologiaReflexo;
