import { ContentContainer, Label, Value } from '../styles';

import { Typography, Grid, Button } from '@mui/material';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import calendar from '../../../assets/imgs/iconCalendar.svg';
import clock from '../../../assets/imgs/iconClock.svg';

const ScheduleRealized = ({ citizenObj, objSchedule, handleDownload }) => {
  return (
    <>
      <ContentContainer>
        <Grid
          container
          spacing={1}
          sx={{
            '@media (min-width: 965px)': {
              boxShadow: '0px 0px 14px -8px black',
              width: '350px',
              padding: '13px',
            },
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',

                gap: '6px',
              }}
            >
              <Typography
                color="#3B873E"
                fontWeight={700}
                fontSize="20px"
                marginTop="13px"
                marginLeft="8px"
              >
                Agendamento Confirmado
              </Typography>
              <CheckCircleOutlineIcon sx={{ color: '#3B873E' }} />
            </div>
          </div>
          <Grid item xs={12}>
            <Label>Nome</Label>
            <Value>{citizenObj.nome}</Value>
          </Grid>
          <Grid item xs={6}>
            <Label>CPF</Label>
            <Value>{citizenObj.cpf}</Value>
          </Grid>
          <Grid item xs={6}>
            <Label>Data de Nascimento</Label>
            <Value>{citizenObj.dtNascimento}</Value>
          </Grid>
          <Grid item xs={7} display="flex" alignItems="center">
            <img src={calendar} />
            {objSchedule?.day}
          </Grid>
          <Grid item xs={5} display="flex" alignItems="center">
            <img src={clock} />
            <Value>{objSchedule?.hour}</Value>
          </Grid>

          <Grid item xs={12}>
            <Label>Local do Procedimento</Label>
            <Value style={{ fontWeight: '400' }}>
              {citizenObj.localProcedimento}
            </Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Endereço</Label>
            <Value>{citizenObj.endereco}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Procedimento</Label>
            <Value>Teste de HPV</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Profissional</Label>
            <Value>{objSchedule?.profissional}</Value>
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ width: '100%', marginTop: '8px', marginBottom: '11px' }}
              variant="contained"
              type="button"
              onClick={handleDownload}
            >
              BAIXAR COMPROVANTE
            </Button>
          </Grid>
        </Grid>
      </ContentContainer>
    </>
  );
};

export default ScheduleRealized;
