import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box } from '@mui/material';
import CustomTablePagination from '../../../../../components/PanelCustomTablePagination';

interface DataItem {
  municipio: string;
  normais: number;
  anormais: number;
  totaisNormais: number;
  totaisAnormais: number;
}

interface TipoAchadosStackedBarsChartProps {
  data: {
    data: DataItem[];
    totals: {
      totalNormais: number;
      totalAnormais: number;
    };
    localizacaoType: string;
  };
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
  onClick?: (tipoAchado: string) => void;
}

const TipoAchadosStackedBarsChart: React.FC<
  TipoAchadosStackedBarsChartProps
> = ({
  data,
  totalItems,
  currentPage,
  itemsPerPage,
  onPageChange,
  onRowsPerPageChange,
  onClick,
}) => {
  const paginatedData = data.data;
  const [chartData, setChartData] = useState<ApexCharts.ApexOptions>();

  useEffect(() => {
    setChartData({
      series: [
        {
          name: 'Achados colposcópicos normais',
          data: paginatedData.map((item) => item.normais),
        },
        {
          name: 'Achados colposcópicos anormais',
          data: paginatedData.map((item) => item.anormais),
        },
      ],
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: function (event, chartContext, config) {
            if (config.dataPointIndex > -1 && onClick) {
              const clickedItem = paginatedData[config.dataPointIndex];
              const seriesName =
                config.w.globals.seriesNames[config.seriesIndex];
              const tipoAchado =
                seriesName === 'Achados colposcópicos normais' ? '1' : '2';
              console.log('TipoAchados clicked:', {
                municipio: clickedItem.municipio,
                tipoAchado,
                seriesName,
              });
              onClick(tipoAchado);
            }
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '26px',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return Math.round(val as number) + '%';
        },
        style: {
          fontSize: '16px',
          fontWeight: 500,
          colors: ['#fff'],
        },
      },
      xaxis: {
        categories: paginatedData.map((item) => item.municipio),
        labels: {
          show: true,
          formatter: function (val) {
            return Math.round(val as unknown as number) + '%';
          },
          style: {
            fontSize: '16px',
            fontWeight: 500,
            colors: ['#757575'],
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: '16px',
            fontWeight: 500,
            colors: ['#757575'],
          },
        },
        tickAmount: 4,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Roboto, sans-serif',
        markers: {
          radius: 12,
        },
        itemMargin: {
          horizontal: 10,
        },
        labels: {
          colors: ['#000000'],
        },
        formatter: function (seriesName, opts) {
          const total =
            seriesName === 'Achados colposcópicos normais'
              ? data.totals.totalNormais
              : data.totals.totalAnormais;
          return `${seriesName} (${total})`;
        },
      },
      colors: ['#25A2A8', '#C77700'],
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const dataPoint = paginatedData[dataPointIndex];
          const seriesName = w.globals.seriesNames[seriesIndex];
          const value =
            seriesName === 'Achados colposcópicos normais'
              ? dataPoint.normais
              : dataPoint.anormais;
          const total =
            seriesName === 'Achados colposcópicos normais'
              ? dataPoint.totaisNormais
              : dataPoint.totaisAnormais;
          const percentage = Math.round(value);

          return `
            <div style="padding: 10px;">
              <strong>${seriesName}</strong><br>
              ${data.localizacaoType}: ${dataPoint.municipio}<br>
              Total: ${total}<br>
              Porcentagem: ${percentage}%
            </div>
          `;
        },
      },
    });
  }, [data, onClick]);

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  return chartData ? (
    <Box>
      <ReactApexChart
        options={chartData}
        series={chartData.series}
        type="bar"
        height={paginatedData.length * 50 + 100}
      />
      <CustomTablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalItems}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={itemsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={'Itens por página'}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} de ${
            count !== -1 ? count : `mais do que ${to}`
          }`;
        }}
      />
    </Box>
  ) : null;
};

export default TipoAchadosStackedBarsChart;
