import { useState } from 'react';
import { Button, Collapse, Paper } from '@mui/material';

import arrowLeft from '../../../../../../../assets/ArrowLeft.svg';
import ListConvocations from '../Components/ListConvocations';
import ScheduleItens from '../Components/ScheduleItens/ScheduleItens';
import ComponentHpv from '../Components/ComponentHpv';
import ListSchedules from '../Components/ListSchedules';

const DetailScheduled = ({ convocationsList, objConvocation }) => {
  const [showAllConvocations, setShowAllConvocations] = useState(false);
  const [showAllSchedules, setShowAllSchedules] = useState(false);

  const schedules = objConvocation.agendamentos ?? [];

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        gap: showAllConvocations || showAllSchedules ? '12px' : '',
        flexDirection:
          showAllConvocations || showAllSchedules ? 'column' : 'row',
        borderRadius: '10px',
        padding: '32px',
        position: 'relative',
      }}
    >
      {(showAllConvocations || showAllSchedules) && (
        <div
          style={{
            textAlign: 'center',
            position: 'relative',
            width: '100%',
            marginBottom: '40px',
          }}
        >
          <p style={{ margin: 0, fontSize: '20px', fontWeight: 700 }}>
            {showAllConvocations && 'Convocatórias'}
            {showAllSchedules && 'Agendamentos'}
          </p>
          <Button
            onClick={() => {
              setShowAllConvocations(false);
              setShowAllSchedules(false);
            }}
            sx={{
              position: 'absolute',
              top: '40px',
              left: '50%',
              transform: 'translateX(-50%)',
              height: '22px',
              fontSize: '13px',
              color: '#212121',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <img src={arrowLeft} />
            {'VOLTAR'}
          </Button>
        </div>
      )}

      {objConvocation?.convocacoes?.length > 0 && !showAllSchedules && (
        <ListConvocations
          showAllConvocations={showAllConvocations}
          setShowAllConvocations={setShowAllConvocations}
          convocations={objConvocation?.convocacoes.sort((a, b) => {
            if (a.ordem < b.ordem) {
              return 1;
            }

            if (a.ordem > b.ordem) {
              return -1;
            }

            return 0;
          })}
          objTotalDataConvocation={objConvocation}
        />
      )}

      {objConvocation.agendamentos.length > 0 && !showAllConvocations && (
        <ListSchedules
          objConvocation={objConvocation}
          schedules={objConvocation.agendamentos}
          showAllSchedules={showAllSchedules}
          setShowAllSchedules={setShowAllSchedules}
        />
      )}
      {!showAllConvocations && !showAllSchedules && (
        <>
          <ComponentHpv schedules={schedules} objConvocation={objConvocation} />
        </>
      )}
    </Paper>
  );
};

export default DetailScheduled;
