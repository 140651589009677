export const mockNotifications = {
  data: {
    page: 1,
    count: 25,
    totalItems: 9,
    data: [
      {
        id: 16703,
        created_at: '2024-09-02T18:08:55.384Z',
        updated_at: '2024-09-02T18:08:55.384Z',
        ref_id: 16177,
        resolved_at: null,
        need_resolve: false,
        ref_type_id: 1,
        resolved_by: {
          id: null,
          pessoaId: null,
          full_name: null,
        },
        user_notify_id: 207103,
        is_read: false,
        is_resolved: false,
        type: {
          id: 1,
          name: 'AVAILABLE_HPV_RESULT',
          table_name: 'notificacao_ref_hpv_result_disponivel',
          context: 'HPV',
          label: 'Resultado Disponível do Teste de HPV',
        },
        user_notify: {
          id: 207103,
          created_at: '2024-09-02T18:08:55.843Z',
          updated_at: '2024-09-02T18:08:55.843Z',
          viewed_at: null,
          usuario_perfil_id: 425,
          notificacao_id: 16703,
        },
        notify_data: {
          id: 16177,
          pessoa_id: 173142,
          hpv_id: 8269,
          pessoa: {
            id: 173142,
            nome: 'MARIA CRISTIANE DA SILVA',
          },
        },
      },
      {
        id: 16702,
        created_at: '2024-09-02T18:07:19.627Z',
        updated_at: '2024-09-02T18:07:19.627Z',
        ref_id: 16176,
        resolved_at: '2024-09-03T10:15:30.000Z',
        need_resolve: true,
        ref_type_id: 2,
        resolved_by: {
          id: 42,
          pessoaId: 55,
          full_name: 'Cícero Lino',
        },
        user_notify_id: 207087,
        is_read: true,
        is_resolved: true,
        type: {
          id: 2,
          name: 'DID_NOT_RESPOND_TO_THE_CALL',
          table_name: 'notificacao_ref_nao_respondeu_convocacao',
          context: 'Convocatória',
          label: 'Paciente não Respondeu a Convocatória',
        },
        user_notify: {
          id: 207087,
          created_at: '2024-09-02T18:07:20.124Z',
          updated_at: '2024-09-02T18:07:20.124Z',
          viewed_at: '2024-09-03T08:30:00.000Z',
          usuario_perfil_id: 425,
          notificacao_id: 16702,
        },
        notify_data: {
          id: 16176,
          pessoa_id: 83578,
          hpv_id: null,
          pessoa: {
            id: 83578,
            nome: 'MARIA JOSE ALVES DA SILVA',
          },
        },
      },
      {
        id: 16701,
        created_at: '2024-09-02T16:05:35.438Z',
        updated_at: '2024-09-02T16:05:35.438Z',
        ref_id: 16175,
        resolved_at: null,
        need_resolve: true,
        ref_type_id: 3,
        resolved_by: {
          id: null,
          pessoaId: null,
          full_name: null,
        },
        user_notify_id: 207071,
        is_read: true,
        is_resolved: false,
        type: {
          id: 3,
          name: 'REQUESTING_A_VISIT',
          table_name: 'notificacao_ref_solicitando_visita',
          context: 'Visita',
          label: 'Paciente Solicitando a sua visita',
        },
        user_notify: {
          id: 207071,
          created_at: '2024-09-02T16:05:36.054Z',
          updated_at: '2024-09-02T16:05:36.054Z',
          viewed_at: '2024-09-02T17:20:00.000Z',
          usuario_perfil_id: 425,
          notificacao_id: 16701,
        },
        notify_data: {
          id: 16175,
          pessoa_id: 130880,
          hpv_id: null,
          pessoa: {
            id: 130880,
            nome: 'ESTEFANIA OLIVEIRA LIMA SILVA',
          },
        },
      },
      {
        id: 16712,
        created_at: '2024-09-03T16:14:22.987Z',
        updated_at: '2024-09-03T16:14:22.987Z',
        ref_id: 16182,
        resolved_at: null,
        need_resolve: true,
        ref_type_id: 6,
        resolved_by: {
          id: null,
          pessoaId: null,
          full_name: null,
        },
        user_notify_id: 207152,
        is_read: false,
        is_resolved: false,
        type: {
          id: 6,
          name: 'MISSING_RESPONSE',
          table_name: 'notificacao_ref_resposta_ausente',
          context: 'Convocatória',
          label: 'Resposta Ausente',
        },
        user_notify: {
          id: 207152,
          created_at: '2024-09-03T16:14:23.123Z',
          updated_at: '2024-09-03T16:14:23.123Z',
          viewed_at: null,
          usuario_perfil_id: 425,
          notificacao_id: 16712,
        },
        notify_data: {
          id: 16182,
          pessoa_id: 132456,
          hpv_id: null,
          pessoa: {
            id: 132456,
            nome: 'SANDRA RIBEIRO COSTA',
          },
        },
      },
      {
        id: 16713,
        created_at: '2024-09-03T10:14:22.987Z',
        updated_at: '2024-09-03T10:14:22.987Z',
        ref_id: 16183,
        resolved_at: null,
        need_resolve: true,
        ref_type_id: 7,
        resolved_by: {
          id: null,
          pessoaId: null,
          full_name: null,
        },
        user_notify_id: 207153,
        is_read: false,
        is_resolved: false,
        type: {
          id: 7,
          name: 'MISMATCH_HPV_COLETA',
          table_name: 'notificacao_ref_divergencia_hpv_coleta',
          context: 'HPV',
          label: 'Divergência de Dados do Teste de HPV',
        },
        user_notify: {
          id: 207153,
          created_at: '2024-09-03T10:14:23.123Z',
          updated_at: '2024-09-03T10:14:23.123Z',
          viewed_at: null,
          usuario_perfil_id: 425,
          notificacao_id: 16713,
        },
        notify_data: {
          id: 16183,
          pessoa_id: 132457,
          hpv_id: null,
          pessoa: {
            id: 132457,
            nome: 'ANA CAROLINA ALMEIDA',
          },
        },
      },
      {
        id: 16714,
        created_at: '2024-09-03T09:20:22.987Z',
        updated_at: '2024-09-03T09:20:22.987Z',
        ref_id: 16184,
        resolved_at: null,
        need_resolve: true,
        ref_type_id: 8,
        resolved_by: {
          id: null,
          pessoaId: null,
          full_name: null,
        },
        user_notify_id: 207154,
        is_read: false,
        is_resolved: false,
        type: {
          id: 8,
          name: 'MISSING_HPV_EXAM',
          table_name: 'notificacao_ref_inexistencia_hpv_exame',
          context: 'HPV',
          label: 'Inexistência de Dados do Teste de HPV',
        },
        user_notify: {
          id: 207154,
          created_at: '2024-09-03T09:20:23.123Z',
          updated_at: '2024-09-03T09:20:23.123Z',
          viewed_at: null,
          usuario_perfil_id: 425,
          notificacao_id: 16714,
        },
        notify_data: {
          id: 16184,
          pessoa_id: 132458,
          hpv_id: null,
          pessoa: {
            id: 132458,
            nome: 'JULIANA PEREIRA SANTOS',
          },
        },
      },
      {
        id: 16715,
        created_at: '2024-09-03T08:30:22.987Z',
        updated_at: '2024-09-03T08:30:22.987Z',
        ref_id: 16185,
        resolved_at: null,
        need_resolve: true,
        ref_type_id: 9,
        resolved_by: {
          id: null,
          pessoaId: null,
          full_name: null,
        },
        user_notify_id: 207155,
        is_read: false,
        is_resolved: false,
        type: {
          id: 9,
          name: 'DID_NOT_RESPOND_TO_THE_WAITING_LIST',
          table_name: 'notificacao_ref_nao_respondeu_lista_espera',
          context: 'Agendamento',
          label: 'Paciente não Respondeu a Lista de Espera',
        },
        user_notify: {
          id: 207155,
          created_at: '2024-09-03T08:30:23.123Z',
          updated_at: '2024-09-03T08:30:23.123Z',
          viewed_at: null,
          usuario_perfil_id: 425,
          notificacao_id: 16715,
        },
        notify_data: {
          id: 16185,
          pessoa_id: 132459,
          hpv_id: null,
          pessoa: {
            id: 132459,
            nome: 'PATRICIA SOUSA LIMA',
          },
        },
      },
      {
        id: 16716,
        created_at: '2024-09-03T07:45:22.987Z',
        updated_at: '2024-09-03T07:45:22.987Z',
        ref_id: 16186,
        resolved_at: null,
        need_resolve: true,
        ref_type_id: 10,
        resolved_by: {
          id: null,
          pessoaId: null,
          full_name: null,
        },
        user_notify_id: 207156,
        is_read: false,
        is_resolved: false,
        type: {
          id: 10,
          name: 'CANCELLED_THE_APPOINTMENT',
          table_name: 'notificacao_ref_cancelou_agendamento',
          context: 'Agendamento',
          label: 'Paciente Cancelou o Agendamento',
        },
        user_notify: {
          id: 207156,
          created_at: '2024-09-03T07:45:23.123Z',
          updated_at: '2024-09-03T07:45:23.123Z',
          viewed_at: null,
          usuario_perfil_id: 425,
          notificacao_id: 16716,
        },
        notify_data: {
          id: 16186,
          pessoa_id: 132460,
          hpv_id: null,
          pessoa: {
            id: 132460,
            nome: 'FERNANDA OLIVEIRA COSTA',
          },
        },
      },
      {
        id: 16717,
        created_at: '2024-09-03T07:15:22.987Z',
        updated_at: '2024-09-03T07:15:22.987Z',
        ref_id: 16187,
        resolved_at: null,
        need_resolve: true,
        ref_type_id: 11,
        resolved_by: {
          id: null,
          pessoaId: null,
          full_name: null,
        },
        user_notify_id: 207157,
        is_read: false,
        is_resolved: false,
        type: {
          id: 11,
          name: 'DID_NOT_WANT_TO_SCHEDULE',
          table_name: 'notificacao_ref_nao_quis_agendar',
          context: 'Agendamento',
          label: 'Paciente não Quis Agendar',
        },
        user_notify: {
          id: 207157,
          created_at: '2024-09-03T07:15:23.123Z',
          updated_at: '2024-09-03T07:15:23.123Z',
          viewed_at: null,
          usuario_perfil_id: 425,
          notificacao_id: 16717,
        },
        notify_data: {
          id: 16187,
          pessoa_id: 132461,
          hpv_id: null,
          pessoa: {
            id: 132461,
            nome: 'CAMILA RODRIGUES SANTOS',
          },
        },
      },
      {
        id: 16718,
        created_at: '2024-09-03T06:45:22.987Z',
        updated_at: '2024-09-03T06:45:22.987Z',
        ref_id: 16188,
        resolved_at: null,
        need_resolve: true,
        ref_type_id: 12,
        resolved_by: {
          id: null,
          pessoaId: null,
          full_name: null,
        },
        user_notify_id: 207158,
        is_read: false,
        is_resolved: false,
        type: {
          id: 12,
          name: 'PERSISTING_WITH_WRONG_NUMBER',
          table_name: 'notificacao_ref_persistindo_telefone_errado',
          context: 'Contato',
          label: 'Paciente persistindo com Telefone Errado',
        },
        user_notify: {
          id: 207158,
          created_at: '2024-09-03T06:45:23.123Z',
          updated_at: '2024-09-03T06:45:23.123Z',
          viewed_at: null,
          usuario_perfil_id: 425,
          notificacao_id: 16718,
        },
        notify_data: {
          id: 16188,
          pessoa_id: 132462,
          hpv_id: null,
          pessoa: {
            id: 132462,
            nome: 'BEATRIZ FERREIRA ALVES',
          },
        },
      },
      {
        id: 16719,
        created_at: '2024-09-03T06:15:22.987Z',
        updated_at: '2024-09-03T06:15:22.987Z',
        ref_id: 16189,
        resolved_at: null,
        need_resolve: true,
        ref_type_id: 13,
        resolved_by: {
          id: null,
          pessoaId: null,
          full_name: null,
        },
        user_notify_id: 207159,
        is_read: false,
        is_resolved: false,
        type: {
          id: 13,
          name: 'WRONG_NUMBER',
          table_name: 'notificacao_ref_numero_errado',
          context: 'Contato',
          label: 'Número de Telefone Inválido',
        },
        user_notify: {
          id: 207159,
          created_at: '2024-09-03T06:15:23.123Z',
          updated_at: '2024-09-03T06:15:23.123Z',
          viewed_at: null,
          usuario_perfil_id: 425,
          notificacao_id: 16719,
        },
        notify_data: {
          id: 16189,
          pessoa_id: 16735,
          hpv_id: null,
          pessoa: {
            id: 16735,
            nome: 'REBECCA LUCENA THEOPHILO',
          },
        },
      },
    ],
  },
};
