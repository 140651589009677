import { useEffect, useState } from 'react';

import { Box, Paper, Typography } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { structureChartBarAcsSelected } from './mockStrutureChartBar';
import { structurePieBar } from './mockStrutucturePieBar';

const mockcategoriesChartBarChart = [['Outras', 'Convocações']];

const mockPie = [18202, 2457];

export const TypeConvocationChart = ({ data }) => {
  const dataPie = mockPie;
  const categoriesChartBar = mockcategoriesChartBarChart;
  const [chartBarData, setchartBarData] = useState<any>();
  const [chartPieData, setChartPieData] = useState<any>();

  useEffect(() => {
    // if (data && categoriesChartBar.length > 0) {
    // setchartBarData(
    //   structureChartBarAcsSelected(data?.totais?.outras_convocatorias)
    // );
    // }
  }, [categoriesChartBar]);

  useEffect(() => {
    // setChartPieData(structurePieBar({ setchartBarData, data }));
  }, [dataPie]);

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          position: 'relative',
          padding: '14px',
          marginTop: '24px',
          borderColor: '#BBC5CA',
        }}
      >
        <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>
          Tipo de Convocações
        </Typography>
        <Typography sx={{ fontSize: '12px', color: '#00000061' }}>
          Todos os Períodos
        </Typography>
        {chartPieData && (
          <div style={{ position: 'absolute' }}>
            <ReactApexChart
              options={chartPieData}
              series={chartPieData?.series}
              type="pie"
              // height={350}

              width="500px"
            />
          </div>
        )}

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {chartBarData && !!categoriesChartBar.length ? (
            <>
              <ReactApexChart
                options={chartBarData}
                series={chartBarData?.series}
                type={chartBarData?.chart?.type || 'area'}
                height={310}
                width="450px"
              />
            </>
          ) : undefined}
        </Box>
        <p
          style={{
            marginBottom: '16px',
            marginRight: '16px',
            bottom: '0',
            right: '0',
            position: 'absolute',
            fontSize: '10px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          Fonte dos Dados: JORDANA
        </p>
      </Paper>
    </>
  );
};
