import { download } from '../../../lib/getCidadaoAgenda';
import { AgendaHeader, ContentContainer, Label, Value } from '../styles';

import { Typography, Grid, Button } from '@mui/material';

const ScheduleNotRealized = ({
  citizenObj,
  objNotSchedule,
  handleDownload,
}) => {
  return (
    <>
      <ContentContainer>
        <Grid
          container
          spacing={1}
          sx={{
            '@media (min-width: 965px)': {
              boxShadow: '0px 0px 14px -8px black',
              width: '350px',
              padding: '25px',
            },
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',

                gap: '6px',
              }}
            >
              <Typography color="#3B873E" fontWeight={700} fontSize="20px">
                Agenda Não Realizada
              </Typography>
            </div>
          </div>
          <Grid item xs={12}>
            <Label>Nome</Label>
            <Value>{citizenObj.nome}</Value>
          </Grid>
          <Grid item xs={6}>
            <Label>CPF</Label>
            <Value>{citizenObj.cpf}</Value>
          </Grid>
          <Grid item xs={6}>
            <Label>Data de Nascimento</Label>
            <Value>{citizenObj.dtNascimento}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Ação</Label>
            <Value style={{ fontWeight: 'bold' }}>NÃO COMPARECIMENTO</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Motivo</Label>
            <Value style={{ fontWeight: 'bold' }}>
              {objNotSchedule.motivo}
            </Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Local do Procedimento</Label>
            <Value style={{ fontWeight: '400' }}>
              Upinha Dom Helder Câmara
            </Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Endereço</Label>
            <Value>
              R. Grijalva Costa, 09 - Vasco da Gama, Recife - PE, 52081-265
            </Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Procedimento</Label>
            <Value>Teste de HPV</Value>
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ width: '100%', marginTop: '8px' }}
              variant="contained"
              type="button"
              onClick={handleDownload}
            >
              BAIXAR COMPROVANTE
            </Button>
          </Grid>
        </Grid>
      </ContentContainer>
    </>
  );
};

export default ScheduleNotRealized;
