import { useContext } from 'react';
import { LoadingContext } from '../../../../../context/LoadingContext';
import { getReportsHistopatologia } from '../../../../../lib/Reports/getReportsHistopatologia';

const useRequestFilter = ({ setTableData, setLoadingTable }) => {
  const { setLoading } = useContext(LoadingContext);

  const loadReportsHistopatologia = async ({ payload }) => {
    try {
      setLoadingTable(true);
      setLoading(true);

      const response = await getReportsHistopatologia({ payload });

      if (response) {
        setTableData(response);
      } else {
        console.error('No response from API');
      }
    } catch (error) {
      console.error('Error loading reports:', error);
    } finally {
      setLoadingTable(false);
      setLoading(false);
    }
  };

  return {
    loadReportsHistopatologia,
  };
};

export default useRequestFilter;
