import moment from 'moment';
import dot from '../../../../../../../../assets/imgs/Convocation/dot.svg';
import connector from '../../../../../../../../assets/imgs/Convocation/connector.svg';
import {
  CalendarPlus,
  DisabledCalendarPlus,
} from '../../../../../../../../assets/imgs/Convocation/calendarPlus';
import { DisableHpvIcon } from '../../../../../../../../assets/imgs/Convocation/hpvIcon';

const ComponentHpv = ({ schedules, objConvocation, setRedIcon }: any) => {
  return (
    <>
      {!setRedIcon ? (
        <>
          {objConvocation.status_convocacao?.includes('Agendada') ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '114px',
              }}
            >
              <DisableHpvIcon />
              <p
                style={{
                  fontSize: '16px',

                  fontWeight: 700,
                }}
              >
                Teste de HPV
              </p>
              <div style={{ display: 'flex', width: '100%' }}>
                <img style={{ width: '12px' }} src={dot} />
                <p
                  style={{
                    fontWeight: 500,

                    fontSize: '14px',
                    margin: '0 0 0px 14px',
                  }}
                >
                  Compareceu?
                </p>
              </div>
              <div style={{ display: 'flex', width: '100%' }}>
                <img
                  style={{ width: '12px', height: '35px' }}
                  src={connector}
                />
                <p
                  style={{
                    fontWeight: 400,

                    fontSize: '14px',
                    margin: '0 0 0 14px',
                    paddingTop: '7px',
                    width: '196px',
                  }}
                >
                  Em aguardo
                </p>
              </div>
            </div>
          ) : (
            <>
              {schedules?.map((item, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: schedules.length > 0 ? '114px' : '',
                    }}
                  >
                    <CalendarPlus />
                    <p
                      style={{
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Agendamento
                    </p>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <img style={{ width: '12px' }} src={dot} />
                      <p
                        style={{
                          fontWeight: 500,

                          fontSize: '14px',
                          margin: '0 0 0px 14px',
                        }}
                      >
                        Agendado para:
                      </p>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <img
                        style={{ width: '12px', height: '35px' }}
                        src={connector}
                      />
                      <p
                        style={{
                          fontWeight: 400,

                          fontSize: '14px',
                          margin: '0 0 0 14px',
                          paddingTop: '7px',
                          width: '196px',
                        }}
                      >
                        {item.data
                          ? moment(item.data).format('DD/MM/YYYY')
                          : ''}
                      </p>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <img style={{ width: '12px' }} src={dot} />
                      <p
                        style={{
                          fontWeight: 500,

                          fontSize: '14px',
                          margin: '0 0 0px 14px',
                        }}
                      >
                        Horário
                      </p>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <img
                        style={{ width: '12px', height: '35px' }}
                        src={connector}
                      />
                      <p
                        style={{
                          fontWeight: 400,

                          fontSize: '14px',
                          margin: '0 0 0 14px',
                          paddingTop: '7px',
                          width: '196px',
                        }}
                      >
                        {item.horario
                          ? `${item.horario.split(':')[0]}h${
                              item.horario.split(':')[1]
                            }`
                          : ''}
                      </p>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <img style={{ width: '12px' }} src={dot} />
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: '14px',
                          margin: '0 0 0px 14px',
                        }}
                      >
                        Confirmou a presença?
                      </p>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <img
                        style={{ width: '12px', height: '35px' }}
                        src={connector}
                      />
                      <p
                        style={{
                          fontWeight: 400,

                          fontSize: '14px',
                          margin: '0 0 0 14px',
                          paddingTop: '7px',
                        }}
                      >
                        {objConvocation.confirmacao_presenca !== null
                          ? ''
                          : 'Confirmação ainda não enviada'}
                        <br />
                      </p>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default ComponentHpv;
