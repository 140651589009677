import { PerfilContext } from '../../../../../context/PerfilContext';
import { useContext, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import CustomizedButton from '../../../../../components/CustomizedButton';

import { formatCpf } from '../../../../../utils/formatters';
import moment from 'moment';

import AgendadaModal from '../modais/AgendadaModal';
import NaoAtendida from '../modais/NaoAtendidaModal';
import PendenteModal from '../modais/PendenteModal';
import NaoElegivel from '../modais/NaoElegivel';
import {
  getConvocations,
  getConvocationsList,
} from '../../../../../lib/convocation';
import HeadersTableConvocation from './Headers';
import RowsTableConvocation, {
  getColorAndLabelStatus,
  ModalType,
  StatusEnum,
} from './Rows';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddIcon from '@mui/icons-material/Add';
import FixedColumnConvocation from './FixedColumn';

const TableConvocation = ({
  tableData,
  setTableData,

  currentPage,
  setCurrentPage,
  stateToSaveFilter,
  showTableData,
  watch,
  setValue,
}) => {
  const [modalAberto, setModalAberto] = useState<string | null>(null);
  const [registroSelecionado, setRegistroSelecionado] = useState(null);

  const { hasPermission } = useContext(PerfilContext);

  const abrirModal = (status, registro) => {
    const modalTypes = Object.values(ModalType);

    if (modalTypes.includes(status)) {
      const modalType = ModalType[status];
      setModalAberto(modalType);
      setRegistroSelecionado(registro);
    } else {
    }
  };

  const fecharModal = () => {
    setModalAberto(null);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTableData({ ...tableData, items: [] });
    setValue('items', Number(event.target.value));

    try {
      const response: any = await getConvocationsList({
        data: {
          ...stateToSaveFilter,
          limit: event.target.value,
          page: String(currentPage),
        },
      });

      setTableData({ ...response });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePage = async (newPage) => {
    setTableData({ ...tableData, items: [] });
    setCurrentPage(newPage + 1);
    try {
      const response: any = await getConvocationsList({
        data: {
          ...stateToSaveFilter,
          page: newPage + 1,
          limit: String(watch('items')),
        },
      });
      setTableData({ ...response });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <TableContainer sx={{ position: 'relative' }}>
        <Table sx={{ minWidth: 3108 }} aria-label="simple table">
          {showTableData && <HeadersTableConvocation />}

          <TableBody>
            {showTableData ? (
              <>
                {tableData?.items?.length === 0 ? (
                  <>
                    {tableData?.total === 0 ? (
                      <p>Não há dados</p>
                    ) : (
                      <p>Carregando dados...</p>
                    )}
                  </>
                ) : (
                  <>
                    <RowsTableConvocation
                      setModalAberto={setModalAberto}
                      setRegistroSelecionado={setRegistroSelecionado}
                      tableData={tableData}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <p>Selecione um filtro</p>
              </>
            )}

            {modalAberto === 'Agendada' && registroSelecionado && (
              <AgendadaModal
                open={modalAberto === 'Agendada'}
                onClose={fecharModal}
                registro={registroSelecionado}
              />
            )}
            {modalAberto === 'NaoAtendida' && registroSelecionado && (
              <NaoAtendida
                open={modalAberto === 'NaoAtendida'}
                onClose={fecharModal}
                registro={registroSelecionado}
              />
            )}
            {modalAberto === 'Pendente' && registroSelecionado && (
              <PendenteModal
                open={modalAberto === 'Pendente'}
                onClose={fecharModal}
                registro={registroSelecionado}
              />
            )}
            {modalAberto === 'NaoElegivel' && registroSelecionado && (
              <NaoElegivel
                open={modalAberto === 'NaoElegivel'}
                onClose={fecharModal}
                registro={registroSelecionado}
              />
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={tableData?.total ?? 0}
          rowsPerPage={watch('items')}
          page={tableData?.page - 1 || 0}
          onPageChange={(e, newPage) => handleChangePage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from ?? 0}–${to ?? 0} de ${
              count !== -1 ? count : `mais do que ${to}`
            }`;
          }}
        />
      </TableContainer>
      {tableData?.items?.length > 0 && (
        <FixedColumnConvocation
          tableData={tableData}
          setModalAberto={setModalAberto}
          setRegistroSelecionado={setRegistroSelecionado}
        />
      )}
    </div>
  );
};

export default TableConvocation;
