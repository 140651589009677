import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import EmptyData from '../../../../../components/Charts/emptyData';

interface AreaChartProps {
  data: number[];
  categories: string[];
  height?: number;
  payload: any;
  total?: number;
  onClick?: () => void;
  needsRequiredFilters: boolean;
}

export function AreaChart({
  data,
  categories,
  height,
  payload,
  total,
  onClick,
  needsRequiredFilters,
}: AreaChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();
  const emptyData = !data.find((item) => item > 0);

  const handleBarClick = (event, chartContext, config) => {
    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    if (data && categories.length > 0) {
      setChartData({
        series: [
          {
            name: 'Testes Realizados',
            data,
          },
        ],
        dataLabels: {
          enabled: false,
        },
        chart: {
          height: 700,
          type: 'area',
          events: {
            dataPointSelection: function (event, chartContext, config) {
              if (onClick && config.dataPointIndex >= 0) {
                onClick();
              }
            },
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              reset: true,
            },
          },
          sparkline: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories,
          labels: {
            style: {
              fontSize: '12px',
            },
            minHeight: 40,
          },
        },
        colors: ['#76AB78'],
        grid: {
          show: true,
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 10,
          },
        },
        yaxis: {
          tickAmount: 5,
          min: 0,
          labels: {
            show: true,
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        fill: {
          type: 'solid',
          opacity: 0.4,
        },
      });
    }
  }, [categories, data, height, onClick]);

  return (
    <>
      {chartData && !!categories.length ? (
        <div
          style={{
            padding: '0 16px',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          {emptyData && (
            <div style={{ position: 'absolute', left: '40%', zIndex: '1' }}>
              <EmptyData needsRequiredFilters={needsRequiredFilters} />
            </div>
          )}
          <p style={{ display: 'none' }} data-testid="totalTestsRealized">
            {total?.toLocaleString('pt-BR')}
          </p>
          <Chart
            options={chartData}
            series={chartData?.series}
            type={chartData?.chart?.type || 'area'}
            height={310}
          />
        </div>
      ) : undefined}
    </>
  );
}
