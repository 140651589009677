import { Box, Divider, Paper, Typography } from '@mui/material';
import FiltersConvocationGeneralView from './FiltersConvocationGeneralView';
import { useForm } from 'react-hook-form';
import DashboardConvocationGeneralView from './DashboardConvocationGeneralView';
import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from '../../../../context/LoadingContext';
import useSetPayload from './FiltersConvocationGeneralView/hooks';
import {
  getCardsTotais,
  getChartsMonth,
  getChartsNumberPatients,
  getChartsTypeConvocation,
} from '../../../../lib/convocation';

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimentoId', value: '' },
  { inputName: 'status', value: '' },
  { inputName: 'search', value: '' },
];
type StateListFilter = { inputName: string; value: string };
const GeneralView = ({
  principalFilters,
  setPrincipalFilters,
  optionsMunicipios,
  setOptionsMunicipios,
  optionsEstabelecimentos,
  setOptionsEstabelecimentos,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      estado: principalFilters.estado.selected,
      municipio: principalFilters.municipio.selected,
      estabelecimentoId: principalFilters.estabelecimento.selected,
      acsId: [],
      idadeInicio: '',
      idadeFim: '',
      periodoMeses: '',
      pesquisa: '',
    },
  });

  const [dataTotais, setDataTotais] = useState<any>();
  const [dataMonths, setDataMonths] = useState<any>();
  const [dataTypeConvocations, setDataTypeConvocations] = useState<any>();
  const [dataNumberPatients, setDataNumberPatients] = useState<any>();

  const [listFilter, setListFilter] = useState<StateListFilter[]>(
    initialStateListFilter
  );

  const [listFilterShow, setListFilterShow] = useState([]);

  const [pageIndexAnswers, setPageIndexAnswers] = useState(1);
  const [pageMediumTimeAnswers, setPageMediumTimeAnswers] = useState(1);

  const { setLoading } = useContext(LoadingContext);

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected: watch('estado'),
    municipiosSelected: watch('municipio'),
    estabelecimentosSelected: watch('estabelecimentoId'),
  });

  const loadTotais = async (payload) => {
    const response = await getCardsTotais({ data: payload });
    return response;
  };
  const loadMonths = async (payload) => {
    const response = await getChartsMonth({ data: payload });
    return response;
  };
  const loadTypeConvocations = async (payload) => {
    const response = await getChartsTypeConvocation({ data: payload });
    return response;
  };
  const loadNumberPatients = async (payload) => {
    const response = await getChartsNumberPatients({ data: payload });
    return response;
  };

  const loadCharts = async ({ clear, listParamsToRemove }: any) => {
    setLoading(true);
    setPayload().then(async (payload: any) => {
      if (clear) {
        listParamsToRemove.forEach((param) => {
          delete payload[param];
        });
      }
      await Promise.all([
        loadTotais(payload),
        loadMonths(payload),
        loadTypeConvocations(payload),
        loadNumberPatients(payload),
      ]).then((res) => {
        setDataTotais(res[0]);
        setDataMonths(res[1]);
        setDataTypeConvocations(res[2]);
        setDataNumberPatients(res[3]);

        setLoading(false);
      });
    });
  };

  useEffect(() => {
    loadCharts({});
  }, []);

  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const handleSetSelectedOptionsInDisplay = ({
    selectedOptions,
    description,
    fieldName,
  }) => {
    const optionLabel = () => {
      if (selectedOptions.length === 0) {
        return '';
      }
      if (selectedOptions.length === 1) {
        return `${description}: ${selectedOptions[0].label}`;
      }
      if (selectedOptions.length > 1) {
        return `${description}: Vários`;
      }
    };

    handleSetFilter(optionLabel(), fieldName);
  };

  return (
    <Box mt={5}>
      <FiltersConvocationGeneralView
        loadGeneralView={loadCharts}
        handleSetSelectedOptionsInDisplay={handleSetSelectedOptionsInDisplay}
        optionsEstabelecimentos={optionsEstabelecimentos}
        optionsMunicipios={optionsMunicipios}
        setOptionsEstabelecimentos={setOptionsEstabelecimentos}
        setOptionsMunicipios={setOptionsMunicipios}
        setPrincipalFilters={setPrincipalFilters}
        setValue={setValue}
        handleSubmit={handleSubmit}
        control={control}
        clearErrors={clearErrors}
        watch={watch}
        setError={setError}
        errors={errors}
        initialStateListFilter={null}
        listFilter={null}
        setListFilter={null}
        setListFilterShow={null}
        listFilterShow={null}
        setShowTableData={null}
        showTableData={null}
        handleSetFilter={handleSetFilter}
      />
      <DashboardConvocationGeneralView
        dataTotais={dataTotais}
        dataMonths={dataMonths}
        dataTypeConvocations={dataTypeConvocations}
        dataNumberPatients={dataNumberPatients}
      />
    </Box>
  );
};

export default GeneralView;
