import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface FunnelChartProps {
  data: Array<{ x: string; y: number }>;
  onClick?: (item: string) => void;
}

export function FunnelChart({ data, onClick }: FunnelChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();

  const handleClick = (
    _event?: any,
    _chartContext?: any,
    config?: { dataPointIndex?: number }
  ) => {
    if (
      onClick &&
      config?.dataPointIndex !== undefined &&
      config.dataPointIndex >= 0
    ) {
      onClick(data[config.dataPointIndex].x);
    }
  };

  useEffect(() => {
    if (data.length === 0 || data.every((item) => item.y === 0)) {
      setChartData(undefined);
      return;
    }

    const sortedData = [...data].sort((a, b) => b.y - a.y);
    const maxValue = Math.max(...sortedData.map((item) => item.y));

    setChartData({
      series: [
        {
          name: 'Quantidade de lesões ',
          data: sortedData.map((item) => item.y),
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: function (event, chartContext, config) {
            if (onClick && config.dataPointIndex >= 0 && data) {
              onClick(data[config.dataPointIndex].x);
            }
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          barHeight: '62%',
          isFunnel: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          const label = sortedData[opt.dataPointIndex].x;
          const value = Number(val);
          const percentage = (value / maxValue) * 100;

          // Função para truncar o texto
          const truncateText = (text: string, maxLength: number) => {
            if (text.length <= maxLength) return text;
            const truncated = text.substr(0, maxLength - 3) + '...';
            return truncated;
          };

          // Estima o número de caracteres que cabem na barra
          const estimatedCharsFit = Math.floor(percentage * 0.8);

          // Decide se deve truncar o texto
          let finalLabel;
          if (estimatedCharsFit < label.length) {
            finalLabel = truncateText(label, Math.max(3, estimatedCharsFit));
          } else {
            finalLabel = label;
          }

          return `${finalLabel} (${value})`;
        },
        dropShadow: {
          enabled: false,
        },
        style: {
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 'bold',
          fontSize: '16px',
          colors: ['#FFFFFF'],
        },
      },
      fill: {
        colors: ['#283371'],
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#7986CB'],
      },
      xaxis: {
        categories: sortedData.map((item) => item.x),
      },
      grid: {
        padding: {
          left: 16,
          right: 16,
        },
      },
      legend: {
        show: false,
      },
    });
  }, [data, onClick]);

  if (!chartData) {
    return <div style={{ height: 500 }} />;
  }

  return (
    <div style={{ padding: '0 16px 22px 16px' }}>
      <ReactApexChart
        options={chartData}
        series={chartData.series}
        type="bar"
        width="100%"
        height={500}
      />
    </div>
  );
}
