export const DisableHpvIcon = () => {
  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.333984"
        width="64"
        height="64"
        rx="32"
        fill="black"
        fill-opacity="0.26"
      />
      <path
        d="M28.5849 17V26.6563C28.5834 26.881 28.5242 27.1016 28.413 27.2969L18.4755 43.8594C18.3627 44.0483 18.3018 44.2637 18.2989 44.4837C18.2961 44.7037 18.3513 44.9206 18.4591 45.1124C18.5669 45.3043 18.7235 45.4642 18.9129 45.5762C19.1023 45.6881 19.318 45.7481 19.538 45.75H45.1317C45.3518 45.7481 45.5674 45.6881 45.7569 45.5762C45.9463 45.4642 46.1028 45.3043 46.2106 45.1124C46.3184 44.9206 46.3737 44.7037 46.3708 44.4837C46.3679 44.2637 46.307 44.0483 46.1943 43.8594L36.2567 27.2969C36.1456 27.1016 36.0864 26.881 36.0849 26.6563V17"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.084 17H38.584"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.1152 37.7814C23.959 36.422 27.1309 35.6564 32.334 38.2501C37.9121 41.047 41.1621 39.9376 42.9434 38.422"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
