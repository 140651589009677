import { useRef, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import CheckIcon from '@mui/icons-material/Check';

import { Label } from '../../../../../components/Typo';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { forwardRef, useCallback, useContext, useState } from 'react';
import { useStyles } from '../styles';
import api from '../../../../../api';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
import { toast } from 'react-toastify';
import { getUserToken } from '../../../../../lib/auth';
import useDebounce from '../../../../../utils/hooks/useDebounce';
import InputRequestScroll from '../../../../../components/InputRequestScroll';
import { LoadingContext } from '../../../../../context/LoadingContext';
import moment from 'moment';

const RealizeSchedule = ({
  open,
  appointmentTime,
  appointmentDate,
  patientName,
  profissionalName,
  handleClose,
  profissionalId,
  examName,
  optionsPatients,
  listTimes,
  estabelecimentoId,
  estabelecimentoEndereco,
  nomeFantasiaEstabelecimento,
  objToEditPatient,
  handleClickSearchProfessionalSchedule,
  disableAutomaticFill,
  estabelecimentoProfessionalId,
}) => {
  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [lastRequestIsFromInputTyped, setLastRequestIsFromInputTyped] =
    useState(false);

  const [valueInputType, setValueInputType] = useState<any>('');

  const [patient, setPatient] = useState<any>(patientName);
  const [time, setTime] = useState(appointmentTime);
  const [isOpen, setIsOpen] = useState(false);

  const [loadingOptions, setLoadingOptions] = useState(false);

  const [errorOnSubmit, setErrorOnSubmit] = useState<any>();
  const [peopleData, setPeopleData] = useState<any>({});
  const [needUpdatePatient, setNeedUpdatePatient] = useState<any>(false);

  const navigate = useNavigate();

  const styles = useStyles();
  const handleSetPatient = (patient) => setPatient(patient);
  const handleSetTime = (time: any) => {
    setTime(time.hour);
  };

  const { currentEstablishment } = useContext(EstablishmentContext);

  const { setLoading } = useContext(LoadingContext);

  const handleSubmitSchedule = async () => {
    setLoading(true);
    await api
      .get(`/pacientes/${valueInputType?.id}`)
      .then(async (res) => {
        const response = await api.post('/cidadao_agenda/agendamento', {
          id_paciente: valueInputType.id,
          nome: valueInputType.nome,
          cns: res.data.cns,
          cpf: res.data.cpf,
          dt_nascimento: res.data.dt_nascimento,
          nacionalidade: res.data.nacionalidade ?? '',
          estabelecimentoId: estabelecimentoId,
          nome_fantasia: nomeFantasiaEstabelecimento,
          endereco_local: estabelecimentoEndereco,
          procedimento: 'Teste de HPV',
          isagendamento: true,
          nomeProfissional: profissionalName,
          dt_agendamento: appointmentDate,
          idProfissional: profissionalId,
          horario_atendimento: time,
          status: 'Agendado',
          motivo_cancelamento: '',
          motivo_outro: '',
        });
        if (response.status === 201) {
          handleClose();

          toast.success('Agendamento realizado com Sucesso.', {
            position: 'bottom-right',
          });

          handleClickSearchProfessionalSchedule();
        }
      })
      .catch((error) => {
        setErrorOnSubmit(error?.error?.message);
        toast.error(
          error?.error?.message ??
            'Ocorreu um erro. Tente novamente ou contate o suporte.',
          {
            position: 'bottom-right',
          }
        );
      });

    setLoading(false);
  };

  const userToken = getUserToken();
  const getPacientes = async (nome, page, requestScroll, estabelecimentoId) => {
    setLoadingOptions(true);

    const response = await api
      .get('/pacientes/nomes', {
        params: {
          estabelecimentoId: estabelecimentoId,
          nome,
          page: requestScroll ? page : 0,
        },
      })
      .then((response) => response)
      .catch((error) => error);

    setItems(
      requestScroll
        ? (prev) => [...prev, ...response.data.pessoas]
        : response.data.pessoas
    );

    setLoadingOptions(false);
  };
  const debounceChange = useDebounce(async () => {
    if (valueInputType && valueInputType.length > 2) {
      setIsOpen(true);
      await getPacientes(
        valueInputType,
        0,
        false,
        estabelecimentoProfessionalId
      );
    } else {
      setLoadingOptions(false);
    }
    setLastRequestIsFromInputTyped(true);
  }, 500);

  useEffect(() => {
    if (valueInputType.length === 0) {
      setLastRequestIsFromInputTyped(false);
      setNeedUpdatePatient(false);
    }
  }, [valueInputType]);

  const location = useLocation();

  useEffect(() => {
    if (!!location.state?.objAllAgenda && !disableAutomaticFill) {
      const locationParsed =
        JSON.parse(JSON.parse(location.state?.objAllAgenda)) ?? {};

      setTime(locationParsed.time);
      setItems(locationParsed.items);
      setValueInputType(locationParsed.valueInputType);
    }
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.header}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={styles.h6}
                fontWeight="700"
              >
                Agendamento
              </Typography>

              <button className={styles.closeIcon} onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </button>
            </div>

            <div className={styles.body}>
              <Typography
                variant="h6"
                component="h2"
                className={styles.h6}
                fontWeight="700"
              >
                Dados da convocação
              </Typography>

              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
                sx={{ mt: 2 }}
              >
                <EventAvailableOutlinedIcon sx={{ color: 'rgba(0,0,0,0.8)' }} />
                <Typography variant="body1">
                  {moment(appointmentDate, 'YYYY-MM-DD').format('DD/MM/YYYY') ||
                    ''}
                </Typography>
              </Box>

              <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
                Local do Procedimento
              </Label>
              <Typography variant="body1">
                {nomeFantasiaEstabelecimento || ''}
              </Typography>

              <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
                Endereço
              </Label>
              <Typography variant="body1">
                {estabelecimentoEndereco || ''}
              </Typography>

              <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
                Profissional
              </Label>
              <Typography variant="body1">{profissionalName || ''}</Typography>

              <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
                Procedimento
              </Label>
              <Typography variant="body1">{examName || ''}</Typography>

              <Box mt={2}>
                <div style={{ position: 'relative', height: '40px' }}>
                  <InputRequestScroll
                    estabelecimentoProfessionalId={
                      estabelecimentoProfessionalId
                    }
                    setNeedUpdatePatient={setNeedUpdatePatient}
                    setPeopleData={setPeopleData}
                    getPacientes={getPacientes}
                    setLoadingOptions={setLoadingOptions}
                    lastRequestIsFromInputTyped={lastRequestIsFromInputTyped}
                    items={items}
                    setItems={setItems}
                    page={page}
                    setPage={setPage}
                    fnRequest={debounceChange}
                    loadingOptions={loadingOptions}
                    valueInputType={valueInputType}
                    setValueInputType={setValueInputType}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                  />
                </div>
                {needUpdatePatient && (
                  <p style={{ color: '#CC0000' }}>
                    Atualize o CPF ou data de nascimento da paciente
                    <br />
                    para prosseguir.
                  </p>
                )}
              </Box>

              <Label variant="body1" mt={2} labelcolor="rgba(0, 0, 0, 0.87)">
                Paciente ainda não cadastrada?{' '}
                <Link
                  to="/cadastrar-paciente"
                  state={{
                    fromAgenda: true,
                    objAgenda: JSON.stringify({
                      ...objToEditPatient,
                      time,
                      listTimes,
                      items,
                      valueInputType,
                    }),
                  }}
                  className={styles.newPatientLink}
                >
                  Cadastrar Novo Paciente
                </Link>
              </Label>

              <Box mt={2}>
                <Autocomplete
                  options={listTimes}
                  onChange={(_, value) => handleSetTime(value ?? '')}
                  value={time}
                  renderOption={(props, option) => (
                    <li {...props}>{option.hour}</li>
                  )}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField {...params} label="Horário" size="small" />
                  )}
                />
              </Box>
            </div>

            <div className={styles.footer}>
              <Button
                disabled={!valueInputType || !time}
                variant="contained"
                endIcon={<CheckIcon />}
                onClick={() => {
                  if (needUpdatePatient) {
                    const state = {
                      fromAgenda: true,
                      id: valueInputType.id,
                      cns: peopleData?.cns,
                      nome: peopleData?.nome,
                      apelido: peopleData?.apelido,
                      nome_mae: peopleData?.nome_mae,
                      cpf: peopleData?.cpf,
                      nacionalidade: peopleData?.nacionalidade,
                      raca: peopleData?.raca,
                      escolaridade: peopleData?.escolaridade,
                      endereco: peopleData?.endereco,
                      objAgenda: JSON.stringify({
                        ...objToEditPatient,
                        time,
                        listTimes,
                        items,
                        valueInputType,
                      }),
                    };
                    navigate(`/pacientes/${valueInputType?.id}/edit`, {
                      state,
                    });
                  } else {
                    handleSubmitSchedule();
                  }
                }}
              >
                {needUpdatePatient ? 'ATUALIZAR DADOS' : 'Agendar'}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RealizeSchedule;
