import CloseIcon from '@mui/icons-material/CloseRounded';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { Label } from '../../../../components/Typo';
import { useStyles } from './styles';

interface ModalProps {
  onClose: () => void;
  open: boolean;
  footer?: ReactNode;
  children?: ReactNode;
  title: string;
  appointmentTime?: string;
  appointmentDate?: string;
  appointmentStatus?: string;
  patientName?: string;
  profissionalName?: string;
  procedureLocation?: string;
  address?: string | null;
  examName?: string;
  ref?: null;
}

export const ModalBase = ({
  onClose,
  open = false,
  footer,
  children,
  title,
  appointmentTime,
  appointmentDate,
  appointmentStatus,
  patientName,
  profissionalName,
  procedureLocation,
  address,
  examName,
  ref,
}: ModalProps) => {
  const styles = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={styles.h6}
              fontWeight="700"
            >
              {title}
            </Typography>

            <button className={styles.closeIcon} onClick={onClose}>
              <CloseIcon fontSize="small" />
            </button>
          </div>

          <div className={styles.body} ref={ref}>
            <Typography
              variant="h6"
              component="h2"
              className={styles.h6}
              fontWeight="700"
            >
              Dados da convocação
            </Typography>

            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
              sx={{ mt: 2 }}
            >
              <EventAvailableOutlinedIcon sx={{ color: 'rgba(0,0,0,0.8)' }} />
              <Typography variant="body1">{appointmentDate || ''}</Typography>
            </Box>

            <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
              Local do Procedimento
            </Label>
            <Typography variant="body1">{procedureLocation || ''}</Typography>

            <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
              Endereço
            </Label>
            <Typography variant="body1">{address || ''}</Typography>

            <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
              Profissional
            </Label>
            <Typography variant="body1">{profissionalName || ''}</Typography>

            <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
              Procedimento
            </Label>
            <Typography variant="body1">{examName || ''}</Typography>

            {children}
          </div>

          {footer}
        </div>
      </div>
    </Modal>
  );
};
