import { Box, Paper } from '@mui/material';
import { FiltersHistoric } from './FiltersHistoric';
import { useForm } from 'react-hook-form';
import { CardDetailPatientConvocation } from './CardDetailPatientConvocation';
import { ListHistoric } from './ListHistoric';
import { useContext, useEffect, useState } from 'react';
import useSetPayload from './FiltersHistoric/hooks/useSetPayload';
import { getConvocationsHistoric } from '../../../../lib/convocation';
import useSetFilterByPerfil from '../../../../utils/hooks/useSetFilterByPerfilV2';
import { LoadingContext } from '../../../../context/LoadingContext';

type StateListFilter = { inputName: string; value: string };

interface Inputs {
  estado: any[];
  municipio: any[];
  estabelecimentoId: any[];
  status: any[];
  page: number;
  limit: number;
}

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimentoId', value: '' },
  { inputName: 'status', value: '' },
  { inputName: 'search', value: '' },
];

export const Historic = ({
  tableDataHistoric,
  setTableDataHistoric,
  principalFilters,
  setPrincipalFilters,
  optionsMunicipios,
  setOptionsMunicipios,
  optionsEstabelecimentos,
  setOptionsEstabelecimentos,
}) => {
  const [listFilter, setListFilter] = useState<StateListFilter[]>(
    initialStateListFilter
  );

  const [listFilterShow, setListFilterShow] = useState([]);
  const [estadosOptions, setEstadosOptions] = useState<any[]>([]);

  const [loadingList, setLoadingList] = useState<any>(false);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      estado: principalFilters.estado.selected,
      municipio: principalFilters.municipio.selected,
      estabelecimentoId: principalFilters.estabelecimento.selected,
      status: [],
      page: 1,
      limit: 25,
    },
  });

  const { setLoading } = useContext(LoadingContext);

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected: watch('estado'),
    municipiosSelected: watch('municipio'),
    estabelecimentosSelected: watch('estabelecimentoId'),

    // estadosSelected: watch('estado'),
  });

  const loadHistoric = async ({ clear, listParamsToRemove }: any) => {
    setLoading(true);
    setTableDataHistoric(undefined);
    setPayload().then(async (payload: any) => {
      if (clear) {
        listParamsToRemove.forEach((param) => {
          delete payload[param];
        });
      }
      const response = await getConvocationsHistoric({ data: payload });

      setTableDataHistoric(response);
      setLoadingList(false);
      setLoading(false);
    });
  };

  const { loadFiltersByPerfilV2 } = useSetFilterByPerfil({
    loadReports: loadHistoric,
    setListFilter: setListFilter,
    setListFilterShow: setListFilterShow,
    setShowTableData: () => {
      return;
    },
    setOptionsMunicipios,
    setEstadosOptions,
    setOptionsEstabelecimentos,

    setValue,
  });

  useEffect(() => {
    loadFiltersByPerfilV2();
  }, []);

  useEffect(() => {
    loadHistoric({});
  }, []);

  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const handleSetSelectedOptionsInDisplay = ({
    selectedOptions,
    description,
    fieldName,
  }) => {
    const optionLabel = () => {
      if (selectedOptions.length === 0) {
        return '';
      }
      if (selectedOptions.length === 1) {
        return `${description}: ${selectedOptions[0].label}`;
      }
      if (selectedOptions.length > 1) {
        return `${description}: Vários`;
      }
    };

    handleSetFilter(optionLabel(), fieldName);
  };

  return (
    <Box mt={5}>
      <FiltersHistoric
        loadHistoric={loadHistoric}
        setTableData={setTableDataHistoric}
        setPayload={setPayload}
        setValue={setValue}
        handleSubmit={handleSubmit}
        control={control}
        clearErrors={clearErrors}
        watch={watch}
        setError={setError}
        errors={errors}
        initialStateListFilter={initialStateListFilter}
        listFilter={listFilter}
        setListFilter={setListFilter}
        setListFilterShow={setListFilterShow}
        listFilterShow={listFilterShow}
        optionsMunicipios={optionsMunicipios}
        setOptionsMunicipios={setOptionsMunicipios}
        estadosOptions={estadosOptions}
        setEstadosOptions={setEstadosOptions}
        optionsEstabelecimentos={optionsEstabelecimentos}
        setOptionsEstabelecimentos={setOptionsEstabelecimentos}
        setLoadingList={setLoadingList}
        setPrincipalFilters={setPrincipalFilters}
        handleSetFilter={handleSetFilter}
        handleSetSelectedOptionsInDisplay={handleSetSelectedOptionsInDisplay}
      />
      <Paper
        variant="outlined"
        sx={{
          paddingTop: '32px',
          paddingRight: '32px',
          marginTop: '24px',
          borderRadius: '10px',
          borderColor: 'rgba(0, 0, 0, 0.23)',
        }}
      >
        <ListHistoric
          tableData={tableDataHistoric}
          watch={watch}
          setTableData={setTableDataHistoric}
          setValue={setValue}
          loadingList={loadingList}
          setLoadingList={setLoadingList}
        />
      </Paper>
    </Box>
  );
};
