import { toast } from 'react-toastify';
import api from '../api';

interface getConvocationsParams {
  getParams: {
    profissionalId?: { id: string | number };
    nasc_min?: string;
    nasc_max?: string;
    ultimo_Procedimento?: string;
    n_convocacao?: string;
    status?: { value: number };
    nome?: string;
    page?: string;
    size?: string;
  };
}
export async function getConvocations({ getParams }: getConvocationsParams) {
  return await api
    .get('/convocacao', {
      params: {
        profissionalId: getParams.profissionalId?.id,
        nasc_min: getParams.nasc_min,
        nasc_max: getParams.nasc_max,
        ultimo_Procedimento: getParams.ultimo_Procedimento,
        n_convocacao: getParams.n_convocacao,
        status: getParams.status?.value,
        nome: getParams.nome,
        page: getParams.page,
        size: getParams.size,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e: any) => {
      return { success: false, error: e.response.data };
    });
}

export async function getConvocationsList({ data }: any) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get('/convocatoria/listagem', {
        params: {
          ...data,
        },
      });

      resolve(response.data);
    } catch (err) {
      resolve(err);
    }
  });
}

export async function getConvocationsHistoric({ data }: any) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get('/convocatoria/historico', {
        params: {
          ...data,
        },
      });

      resolve(response.data);
    } catch (err) {
      resolve(err);
    }
  });
}

export async function getCardsTotais({ data }: any) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get('/convocatoria/dashboard/totais', {
        params: {
          ...data,
        },
      });

      resolve(response.data);
    } catch (err) {
      resolve(err);
    }
  });
}

export async function getChartsMonth({ data }: any) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get('/convocatoria/dashboard/mensal', {
        params: {
          ...data,
        },
      });

      resolve(response.data);
    } catch (err) {
      resolve(err);
    }
  });
}

export async function getChartsTypeConvocation({ data }: any) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get(
        '/convocatoria/dashboard/tipos-convocatoria',
        {
          params: {
            ...data,
          },
        }
      );

      resolve(response.data);
    } catch (err) {
      resolve(err);
    }
  });
}

export async function getChartsNumberPatients({ data }: any) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get(
        '/convocatoria/dashboard/numero-paciente',
        {
          params: {
            ...data,
          },
        }
      );

      resolve(response.data);
    } catch (err) {
      resolve(err);
    }
  });
}

export const getCidadesConvocatoria = async (sigla) => {
  try {
    const response = await api.get(`/municipios?sigla=${sigla}`, {
      params: { isConvocatoria: true },
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    toast.error(error.message, { position: 'bottom-right' });
  }
};

export const getEstabelecimentosConvocatoria = async ({ municipio }) => {
  try {
    const response = await api.get('/estabelecimentos-saude/unpaged', {
      params: {
        municipio,
        isConvocatoria: true,
      },
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    toast.error(error.message, { position: 'bottom-right' });
  }
};
