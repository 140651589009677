import moment from 'moment';
import { getLabelFormatedLastConvocation } from '../../../Utils/getLabelFormatedLastConvocation';
import DynamicHeaders from '../../Details/Components/DynamicHeaders';
import DynamicRows from '../../Details/Components/DynamicRows';

const ResumeConcluded = ({
  objConvocation,
  opened,
  patientName,
  cpf,
  status,
  setOpenedCard,
  index,
}) => {
  const hasAgendaConvocacao = objConvocation.agendamentos.length > 0;
  const hasHpvWithResult = !!objConvocation.testes_hpv.find(
    (hpv) => hpv.dt_recebimento_resultado !== null
  );

  const noRealizedAtendimento =
    objConvocation.testes_hpv.length > 0 &&
    !!objConvocation.testes_hpv.find(
      (hpv) => hpv.atendimento_status !== 'REALIZADO'
    );

  const valueThirdColumn = () => {
    let value = '';
    if (noRealizedAtendimento) {
      value = 'Não';
    }

    if (!noRealizedAtendimento && hasHpvWithResult) {
      value = moment(
        objConvocation.testes_hpv?.[0]?.dt_recebimento_resultado
      ).format('DD/MM/YYYY');
    }
    return value;
  };

  return (
    <>
      <table className="table-detail-patient">
        <thead>
          <tr>
            <DynamicHeaders
              opened={opened}
              HeadersWhenHided={() => (
                <>
                  <>
                    <th>Convocação</th>
                    <th>Data do Agendamento</th>
                    <th>
                      {noRealizedAtendimento
                        ? 'Realizou o procedimento'
                        : 'Data do Resultado'}
                    </th>
                  </>
                </>
              )}
            />
          </tr>
        </thead>
        <tbody>
          <tr>
            <DynamicRows
              status={status}
              setOpenedCard={setOpenedCard}
              objConvocation={objConvocation}
              patientName={patientName}
              cpf={cpf}
              index={index}
              opened={opened}
              firstRowConfig={{
                text: getLabelFormatedLastConvocation(
                  objConvocation.tipo_ultima_convocatoria
                ).text,
                tooltip: getLabelFormatedLastConvocation(
                  objConvocation.tipo_ultima_convocatoria
                ).tooltip,
              }}
              secondRowConfig={{
                text: moment(
                  objConvocation.agendamentos?.find(
                    (agendamento) => agendamento.status === 1
                  )?.data
                ).format('DD/MM/YYYY'),
              }}
              thirdRowConfig={{ text: valueThirdColumn() }}
            />
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ResumeConcluded;
