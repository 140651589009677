import { useEffect } from 'react';
import useStatesFields from '../../components/MultipleFilters/hooks/useStatesFields';
import { getUserToken } from '../../lib/auth';
import api from '../../api';

const useSetFilterByPerfil = ({
  setListFilter,
  setSelectedOptionsLaboratorio,
  setListFilterShow,
  setShowTableData,
  loadReports,
  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  setOptionsMunicipios,
  setOptionsProfissional,
  setEstadosOptions,
  setOptionsEstabelecimentos,
  setOptionsLaboratorios,
  setOptionsProfisisonaisLaboratorio,
  setValue,
  fieldProfissionalResultado,
  fieldLaboratorioResultado,
  selectedLaboratorio,
  setSelectedLaboratorio,
}: Partial<any>) => {
  const handleSetFilter = (newValue: any, inputName: string, origin?: any) => {
    setListFilter((prev) => {
      return prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      );
    });
  };
  const handleSetFilterShow = (newValue: any, inputName: string) => {
    setListFilterShow((prev) => {
      const exists = prev.some((item) => item.inputName === inputName);
      return exists
        ? prev
        : [...prev, { inputName: inputName, value: newValue }];
    });
  };

  const userToken = getUserToken();
  const {
    permissao_atual: {
      contexto: contextoPermissao,
      id: idPermissao,
      nome: nomePermissao,
    },
    estabelecimentoId: estabelecimentoIdUser,
    estabelecimento_saude: {
      nome_fantasia: estabelecimentoNameUser,
      endereco: {
        municipio: {
          id: municipioUserId,
          nome: municipioUserName,
          estadoSigla: estadoUserSigla,
        },
      },
    },
    pessoa: { nome: userName },
  } = userToken;

  const setDefaultEstado = ({ sigla }) => {
    setEstadosOptions([
      {
        label: sigla,
        value: sigla,
      },
    ]);
    handleSetFilter(`Estado: ${sigla}`, 'estado');

    setValue('estado', [{ label: sigla, value: sigla }]);

    // setEstadosSelected([sigla]);
  };

  const setDefaultMunicipio = ({ municipioId, municipioNome }) => {
    setOptionsMunicipios([
      {
        value: municipioId,
        label: municipioNome,
      },
    ]);
    handleSetFilter(`Municipio: ${municipioNome}`, 'municipio');
    setValue('municipio', [
      {
        value: municipioId,
        label: municipioNome,
      },
    ]);
    // setMunicipiosSelected([municipioId]);
  };

  const setDefaultEstabelecimento = ({}) => {
    handleSetFilter(
      `Estabelecimento de Saúde: ${estabelecimentoNameUser}`,
      'estabelecimento_saude'
    );

    setValue('estabelecimentoId', [
      { label: estabelecimentoNameUser, value: estabelecimentoIdUser },
    ]);
  };

  const setDefaultProfissional = ({ profissionalId, profissionalNome }) => {
    setOptionsProfissional([
      {
        value: profissionalId,
        label: profissionalNome,
      },
    ]);
    // handleSetFilter(`Municipio: ${profissionalNome}`, 'municipio');
    setValue('acsId', [
      {
        value: profissionalId,
        label: profissionalNome,
      },
    ]);
    // setMunicipiosSelected([municipioId]);
  };

  const setDefaultLaboratorio = ({ laboratorioId, laboratorioName }) => {
    const objLab = [
      {
        label: laboratorioName,
        value: laboratorioId,
      },
    ];
    setOptionsLaboratorios(objLab);
    setSelectedOptionsLaboratorio(objLab);
    setSelectedLaboratorio(objLab);

    handleSetFilter(
      `Laboratório Responsável pelo Laudo: ${laboratorioName}`,
      fieldLaboratorioResultado
    );
  };

  const isSuperAdmin = nomePermissao === 'SuperAdmin';
  const isEstadual = nomePermissao === 'Admin Estadual';
  const isMunicipal = nomePermissao === 'Admin Municipal';
  const isAcsEstabelecimento = nomePermissao === 'ACS';
  const isAdminLaboratorio = nomePermissao === 'Admin Estabelecimento';
  const isPadraoEstabelecimento = contextoPermissao === 'UNIDADE DE SAÚDE';
  const isMedicoLaboratorio = idPermissao === 7;
  const isOutrosLaboratorio = idPermissao === 8;

  const actionsWhenUserIsEstadual = () => {
    setDefaultEstado({
      sigla: estadoUserSigla,
    });
  };

  const actionsWhenUserIsMunicipal = () => {
    setDefaultEstado({
      sigla: estadoUserSigla,
    });
    setDefaultMunicipio({
      municipioId: String(municipioUserId),
      municipioNome: municipioUserName,
    });

    handleSetFilterShow(`Estados: ${estadoUserSigla}`, 'estados');
    handleSetFilterShow(`Município: ${municipioUserName}`, 'municipio');

    loadReports();

    setShowTableData(true);
  };

  const actionsWhenUserIsAdminLaboratorio = () => {
    setDefaultEstado({
      sigla: userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
    });

    setDefaultLaboratorio({
      laboratorioId: userToken.estabelecimentoId,
      laboratorioName: userToken.estabelecimento_saude?.nome_fantasia,
    });
  };

  const actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento = () => {
    actionsWhenUserIsMunicipal();

    setDefaultEstabelecimento({
      estabelecimentoId: estabelecimentoIdUser,
      estabelecimentoNome: estabelecimentoNameUser,
    });

    handleSetFilterShow(`Estados: ${estadoUserSigla}`, 'estados');
    handleSetFilterShow(`Município: ${municipioUserName}`, 'municipio');
    handleSetFilterShow(
      `Estabelecimento de Saúde: ${estabelecimentoNameUser}`,
      'estabelecimento_saude'
    );
  };

  const actionsWhenUserIsACS = async () => {
    actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento();

    const objProfissional = await api.get(
      `/profissionais?pessoa_id=${userToken.pessoa?.id}`
    );

    setDefaultProfissional({
      profissionalNome: userToken.pessoa.nome,
      profissionalId: objProfissional.data[0]?.id,
    });

    loadReports();
  };

  const actionsWhenUserIsMedicoLaboratorio = async () => {
    actionsWhenUserIsAdminLaboratorio();

    setShowTableData(false);

    const profissional = await api.get('/profissionais', {
      params: {
        cpf: userToken.pessoa.cpf,
      },
    });

    const profissionalId = profissional?.data?.data?.[0]?.id;

    // loadReports({
    //   payload: {
    //     estados: estadoUserSigla,

    //     laboratorioLaudoId: estabelecimentoIdUser,
    //     profissionalResultadoId: profissionalId,
    //     page: 1,
    //     size: 25,
    //   },
    // });

    setDefaultProfissional({
      profissionalId: profissionalId,
      profissionalNome: userToken.pessoa.nome,
    });

    handleSetFilter(
      `Profissional Responsável Pelo Resultado: ${userName}`,
      fieldProfissionalResultado
    );

    // handleSetFilterShow(`Estados: ${estadoUserSigla}`, 'estados');
    // handleSetFilterShow(
    //   `Laboratório Responsável Pelo Laudo: ${estabelecimentoNameUser}`,
    //   'laboratorioId'
    // );
    // handleSetFilterShow(
    //   `Profissional Responsável Pelo Resultado: ${userName}`,
    //   'profissionalLaudoId'
    // );
  };

  const loadFiltersByPerfilV2 = () => {
    if (isSuperAdmin) {
      return;
    }

    if (isEstadual) {
      actionsWhenUserIsEstadual();
    }

    if (isMunicipal) {
      actionsWhenUserIsMunicipal();
    }

    if (isAcsEstabelecimento) {
      actionsWhenUserIsACS();
    }
    if (isAdminLaboratorio || isOutrosLaboratorio) {
      actionsWhenUserIsAdminLaboratorio();
    }

    if (isPadraoEstabelecimento) {
      actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento();
    }

    if (isMedicoLaboratorio) {
      actionsWhenUserIsMedicoLaboratorio();
    }
  };

  return {
    loadFiltersByPerfilV2,
  };
};

export default useSetFilterByPerfil;
