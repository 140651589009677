import CheckIcon from '@mui/icons-material/Check';
import { Button, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { maskCpf } from '../../../../components/maskCpf';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { maskDate } from '../../../../utils/maskDate';

// Componente TextMask para o campo de data
function DateMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'_'}
      showMask={false} // Changed to false to prevent mask showing on empty field
      guide={false} // Changed to false to prevent guide showing with the label
    />
  );
}

const TypePersonalData = ({
  control,
  setStep,
  watch,
  setError,
  errors,
  clearErrors,
  errorMessageServer,
}) => {
  return (
    <>
      <Controller
        control={control}
        name="dataNascimento"
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              error={!!errors?.dataNascimento}
              helperText={errors?.dataNascimento?.message}
              value={value}
              onChange={(e) => {
                onChange(maskDate(e.target.value));
                clearErrors('birth');
              }}
              size="small"
              label="Data de Nascimento"
              inputMode="numeric"
              fullWidth
              sx={{ marginTop: '25.32px' }}
              placeholder="DD/MM/AAAA"
              InputProps={{
                inputComponent: DateMask,
              }}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="identificador"
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              error={!!errors?.identificador}
              helperText={errors?.identificador?.message}
              value={value}
              onChange={(e) => {
                onChange(e);
                clearErrors('cpf');
              }}
              size="small"
              label="CPF, CNS ou Nome da Mãe"
              fullWidth
              sx={{ marginTop: '25.32px' }}
            />
          );
        }}
      />
      {errorMessageServer && (
        <p style={{ color: '#CC0000' }}>{errorMessageServer}</p>
      )}
      <Button
        variant="contained"
        type="submit"
        sx={{ marginTop: '25.32px', marginBottom: '32.48px' }}
        endIcon={<CheckIcon />}
      >
        ACESSAR
      </Button>
    </>
  );
};

export default TypePersonalData;