import { Button, Collapse, Paper } from '@mui/material';
import { useState } from 'react';
import ScheduleItens from '../Components/ScheduleItens/ScheduleItens';
import ComponentHpv from '../Components/ComponentHpv';
import ListConvocations from '../Components/ListConvocations';

import arrowLeft from '../../../../../../../assets/ArrowLeft.svg';
import ListHpvs from '../Components/ListHpvs';
import moment from 'moment';
import ListSchedules from '../Components/ListSchedules';

const DetailOutConvocation = ({ convocationsList, objConvocation }) => {
  const [showAllHpvs, setShowAllHpvs] = useState(false);
  const [showAllConvocations, setShowAllConvocations] = useState(false);
  const [showAllSchedules, setShowAllSchedules] = useState(false);

  const schedules = objConvocation.agendamentos ?? [];
  const hpvs = objConvocation.testes_hpv;

  const initialDateConvocation = moment(objConvocation?.convocacao_data_inicio);

  const hasTestHpvBeforeConvocation =
    hpvs?.filter((hpv) => {
      const dateHpv = moment(hpv.created_at);
      return dateHpv.isBefore(initialDateConvocation);
    })?.length > 0;

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        gap:
          showAllHpvs || showAllConvocations || showAllSchedules ? '12px' : '',
        flexDirection:
          showAllHpvs || showAllConvocations || showAllSchedules
            ? 'column'
            : 'row',
        borderRadius: '10px',
        padding: '32px',
        position: 'relative',
      }}
    >
      {(showAllHpvs || showAllConvocations || showAllSchedules) && (
        <div
          style={{
            textAlign: 'center',
            position: 'relative',
            width: '100%',
            marginBottom: '40px',
          }}
        >
          <p style={{ margin: 0, fontSize: '20px', fontWeight: 700 }}>
            {showAllHpvs && 'Testes de HPV'}
            {showAllConvocations && 'Convocatórias'}
            {showAllSchedules && 'Agendamentos'}
          </p>
          <Button
            onClick={() => {
              setShowAllHpvs(false);
              setShowAllConvocations(false);
              setShowAllSchedules(false);
            }}
            sx={{
              position: 'absolute',
              top: '40px',
              left: '50%',
              transform: 'translateX(-50%)',
              height: '22px',
              fontSize: '13px',
              color: '#212121',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <img src={arrowLeft} />
            {'VOLTAR'}
          </Button>
        </div>
      )}

      {objConvocation?.convocacoes?.length > 0 &&
        !showAllHpvs &&
        !showAllSchedules && (
          <ListConvocations
            conditiionToDisableImg={
              schedules.length === 0 && objConvocation?.testes_hpv.length > 0
            }
            showAllConvocations={showAllConvocations}
            setShowAllConvocations={setShowAllConvocations}
            convocations={objConvocation?.convocacoes.sort((a, b) => {
              if (a.ordem < b.ordem) {
                return 1;
              }

              if (a.ordem > b.ordem) {
                return -1;
              }

              return 0;
            })}
            objTotalDataConvocation={objConvocation}
          />
        )}
      {!showAllHpvs && !showAllConvocations && (
        <>
          {objConvocation.agendamentos.length > 0 &&
            !showAllConvocations &&
            !showAllHpvs && (
              <ListSchedules
                objConvocation={objConvocation}
                schedules={objConvocation.agendamentos}
                showAllSchedules={showAllSchedules}
                setShowAllSchedules={setShowAllSchedules}
              />
            )}
        </>
      )}
      {(!showAllConvocations || !showAllSchedules) && (
        <>
          {hasTestHpvBeforeConvocation && (
            <ComponentHpv
              objConvocation={{}}
              schedules={[]}
              setRedIcon={true}
            />
          )}
          <ListHpvs
            hpvs={hpvs}
            showAllHpvs={showAllHpvs}
            setShowAllHpvs={setShowAllHpvs}
            objConvocation={objConvocation}
          />
        </>
      )}
    </Paper>
  );
};

export default DetailOutConvocation;
