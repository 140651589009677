import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';
import api from '../../api';

import './style.css';
import HelpIcon from '@mui/icons-material/Help';
import { LoadingContext } from '../../context/LoadingContext';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';

type NotificationBoxProps = {
  id: number;
  tipo?: string;
  nomeDoPaciente?: string;
  setClicked: (clicked: boolean) => void;
  setModalOpen: (modalOpen: boolean) => void;
  setAlertId: (alertId: number) => void;
  foiChecado?: boolean;
  resultadoHPV?: any;
  id_pessoa?: number;
  id_exame?: number;
  typeNotification: string;
  idPessoa: string;
  idExam: number;
  alertId: number;
};

export const NotificationBox = (props: NotificationBoxProps) => {
  const navigate = useNavigate();

  const tooltipText = () => {
    const { typeNotification, nomeDoPaciente } = props;

    const messages = {
      // HPV

      MISMATCH_HPV_COLETA: `Um resultado do Teste de HPV para a paciente ${nomeDoPaciente} foi identificado no GAL, porém não há dados de Anamnese e Exame Clínico registrados no Jordana.`,
      MISSING_HPV_EXAM: `Um resultado do Teste de HPV para a paciente ${nomeDoPaciente} foi identificado no GAL, porém não há dados de Anamnese e Exame Clínico registrados no Jordana.`,
      PACIENTE_NAO_CADASTRADO: `As informações do GAL não correspondem aos dados necessários para associar o resultado a uma paciente no Jordana.`,
      AVAILABLE_HPV_RESULT: `O resultado do Teste de HPV da paciente ${nomeDoPaciente} está disponível. Selecione aqui para visualizá-lo.`,

      // Convocatória

      REQUESTING_A_VISIT: `A paciente ${nomeDoPaciente} foi convocada por WhatsApp para agendar seu teste, mas sinalizou que deseja receber auxílio. Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
      DID_NOT_RESPOND_TO_THE_CALL: `A paciente ${nomeDoPaciente} foi convocada por WhatsApp para agendar seu teste, mas não tivemos resposta. Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
      DID_NOT_RESPOND_TO_THE_WAITING_LIST: `A paciente ${nomeDoPaciente}, que estava na nossa Lista de Espera, foi avisada de novas vagas disponíveis, mas ainda não agendou. Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
      CANCELLED_THE_APPOINTMENT: `A paciente ${nomeDoPaciente} tinha agendado seu Teste de HPV, mas cancelou. Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
      DID_NOT_WANT_TO_SCHEDULE: `A paciente ${nomeDoPaciente} foi convocada por WhatsApp para agendar seu teste, mas sinalizou que não quer agendar. Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
      PERSISTING_WITH_WRONG_NUMBER: `O telefone da paciente ${nomeDoPaciente} foi corrigido recentemente, mas ainda está incorreto. Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
      MISSING_RESPONSE: `A paciente ${nomeDoPaciente} foi convocada por WhatsApp para agendar seu teste, mas não tivemos resposta da mesma. Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
      WRONG_NUMBER: `O telefone da paciente ${nomeDoPaciente} está incorreto. Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
      CONV_NOT_ANSWERED: `A paciente ${nomeDoPaciente} foi convocada por WhatsApp para agendar seu teste, mas não tivemos resposta. Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
      CONV_CANCELED_SCHEDULE: `A paciente ${nomeDoPaciente} tinha agendado seu Teste de HPV, mas cancelou. Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
      CONV_NOT_SCHEDULED: `A paciente ${nomeDoPaciente} foi convocada por WhatsApp para agendar seu teste, mas sinalizou que não quer agendar. Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
      CONV_WRONG_NUMBER: `O telefone da paciente ${nomeDoPaciente} foi corrigido recentemente, mas ainda está incorreto. Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
    };

    return messages[typeNotification] || 'Notificação desconhecida.';
  };

  const text = (() => {
    const messages = {
      // HPV
      MISMATCH_HPV_COLETA: `Um resultado do Teste de HPV para a paciente ${props.nomeDoPaciente} apresenta divergência de dados.`,
      MISSING_HPV_EXAM: `Não há registro de Teste de HPV para a paciente ${props.nomeDoPaciente}.`,
      PACIENTE_NAO_CADASTRADO: `As informações do GAL não correspondem aos dados necessários para associar o resultado à paciente no Jordana.`,
      AVAILABLE_HPV_RESULT: `O resultado do Teste de HPV da paciente ${props.nomeDoPaciente} está disponível.`,

      // Convocatória
      REQUESTING_A_VISIT: `A paciente ${props.nomeDoPaciente} solicitou uma visita para agendar seu teste.`,
      DID_NOT_RESPOND_TO_THE_CALL: `A paciente ${props.nomeDoPaciente} foi convocada por WhatsApp, mas não respondeu.`,
      DID_NOT_RESPOND_TO_THE_WAITING_LIST: `A paciente ${props.nomeDoPaciente}, que estava na Lista de Espera, foi avisada, mas não respondeu.`,
      CANCELLED_THE_APPOINTMENT: `A paciente ${props.nomeDoPaciente} cancelou seu agendamento para o Teste de HPV.`,
      DID_NOT_WANT_TO_SCHEDULE: `A paciente ${props.nomeDoPaciente} foi convocada para agendamento, mas optou por não marcar.`,
      PERSISTING_WITH_WRONG_NUMBER: `O telefone da paciente ${props.nomeDoPaciente} foi corrigido recentemente, mas o erro persiste.`,
      MISSING_RESPONSE: `A paciente ${props.nomeDoPaciente} foi convocada, mas ainda não respondeu.`,
      WRONG_NUMBER: `O telefone da paciente ${props.nomeDoPaciente} está incorreto.`,
      CONV_NOT_ANSWERED: `A paciente ${props.nomeDoPaciente} foi convocada, mas não respondeu.`,
      CONV_CANCELED_SCHEDULE: `A paciente ${props.nomeDoPaciente} cancelou seu agendamento.`,
      CONV_NOT_SCHEDULED: `A paciente ${props.nomeDoPaciente} foi convocada para agendamento, mas não marcou.`,
      CONV_WRONG_NUMBER: `A paciente ${props.nomeDoPaciente} foi convocada, mas o número informado está incorreto.`,
    };

    return (
      messages[props.typeNotification] || 'Notificação sem mensagem definida.'
    );
  })();

  const title = (() => {
    const notificationTitles = {
      // HPV

      MISMATCH_HPV_COLETA: 'Divergência de Dados do Teste de HPV',
      MISSING_HPV_EXAM: 'Inexistência de Dados do Teste de HPV',
      PACIENTE_NAO_CADASTRADO:
        'Falha de vinculação do Resultado do Teste de HPV por divergência de Dados Cadastrais',
      AVAILABLE_HPV_RESULT: 'Resultado disponível',

      // Convocatória

      REQUESTING_A_VISIT: 'Paciente Solicitando a sua visita',
      DID_NOT_RESPOND_TO_THE_CALL: 'Paciente não Respondeu à Convocatória',
      DID_NOT_RESPOND_TO_THE_WAITING_LIST:
        'Paciente não Respondeu à Lista de Espera',
      CANCELLED_THE_APPOINTMENT: 'Paciente Cancelou o Agendamento',
      DID_NOT_WANT_TO_SCHEDULE: 'Paciente não Quis Agendar',
      PERSISTING_WITH_WRONG_NUMBER: 'Paciente persistindo com Telefone Errado',
      MISSING_RESPONSE: 'Paciente não Respondeu à Convocatória',
      WRONG_NUMBER: 'Número de Telefone Errado',
      CONV_NOT_ANSWERED: 'Paciente não Respondeu à Convocatória',
      CONV_CANCELED_SCHEDULE: 'Paciente Cancelou o Agendamento',
      CONV_NOT_SCHEDULED: 'Paciente não Quis Agendar',
      CONV_WRONG_NUMBER: 'Paciente com Número Errado',
    };

    return (
      notificationTitles[props.typeNotification] || 'Notificação desconhecida'
    );
  })();

  const iconMap = {
    AVAILABLE_HPV_RESULT: <CheckCircle color="success" className="icon" />,
    MISSING_HPV_EXAM: <CancelIcon color="error" className="icon" />,
    PACIENTE_NAO_CADASTRADO: <HelpIcon color="error" className="icon" />,
    MISMATCH_HPV_COLETA: (
      <ErrorIcon color="warning" className="icon" sx={{ color: '#FFB547' }} />
    ),

    // Convocatória

    CONV_CANCELED_SCHEDULE: <CancelIcon color="error" className="icon" />,
    CONV_NOT_SCHEDULED: <CancelIcon color="error" className="icon" />,
    CONV_WRONG_NUMBER: <CancelIcon color="error" className="icon" />,
    CONV_NOT_ANSWERED: (
      <ErrorIcon color="warning" className="icon" sx={{ color: '#FFB547' }} />
    ),
  };

  const icon = iconMap[props.typeNotification] || null;

  const { setLoading } = React.useContext(LoadingContext);

  // const { setAlertList } = React.useContext(AlertListContext);

  const handleClick = async (id: number) => {
    try {
      const response = await api.post(`/notificacao/view/${id}`);
      if (response?.status === 201) {
        if (props.typeNotification === 'AVAILABLE_HPV_RESULT') {
          navigate(`/pacientes/${props.id_pessoa}/result/${props.idExam}`, {
            state: { view: 'visualizar' },
          });
          setLoading(false);

          return;
        } else {
          props.setModalOpen(true);
          props.setAlertId(props.alertId);
          props.setClicked(true);
          setLoading(false);
        }
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.error?.message, { position: 'bottom-right' });
    }
  };
  return (
    <div className="notificationBox">
      {icon}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span
          className="titleNotificationBox"
          onClick={() => handleClick(props.id)}
        >
          {title}
        </span>
        <div
          className="notificationBoxBody"
          onClick={() => handleClick(props.id)}
        >
          <Tooltip title={String(tooltipText())} placement="top">
            <p className="notificationBoxText">{text}</p>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
