import { useContext, useEffect } from 'react';
import { PerfilContext } from '../../context/PerfilContext';
import { getCidades } from '../../lib/getCidadesEstabelecimentos';

const useActionsControlInputs = ({
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,

  setDisabledEstadoInput,
  setDisabledMunicipioInput,
  setDisabledEstabelecimentoInput,
  setDisabledAcsInput,

  handleSetFilter,

  setValue,
  loadMunicipiosOptions,
  loadEstabelecimentosOptions,
  loadAcsOptions,
}) => {
  const {
    isSuperAdmin,
    isEstadual,
    isMunicipal,
    isAdminLaboratorio,
    isPadraoEstabelecimento,
    isMedicoLaboratorio,
    isOutrosLaboratorio,
    estabelecimentoIdUser,
    estabelecimentoNameUser,
    municipioUserId,
    municipioUserName,
    estadoUserSigla,
    userName,
    userCpf,
  } = useContext(PerfilContext);

  const structureOffActions = ({
    arrSelected,
    listOffSetStatesThatDisabled,
    listOffInputsNamesThatCleared,
    fnThatPopuledOptionsNextInput,
  }) => {
    if (arrSelected.length === 0) {
      listOffSetStatesThatDisabled.forEach((setState) => {
        setState(true);
      });

      listOffInputsNamesThatCleared.forEach((inputName) => {
        handleSetFilter('', inputName);
        setValue(inputName, []);
      });
    }

    if (arrSelected.length === 1) {
      fnThatPopuledOptionsNextInput({ param: arrSelected[0].value });

      listOffSetStatesThatDisabled.length > 0 &&
        listOffSetStatesThatDisabled[0](false);
    }

    if (arrSelected.length > 1) {
      listOffSetStatesThatDisabled[0](true);
    }
  };

  const actionsEstadoSelected = async () => {
    structureOffActions({
      arrSelected: estadosSelected,
      listOffSetStatesThatDisabled: [
        setDisabledMunicipioInput,
        setDisabledEstabelecimentoInput,
      ],
      listOffInputsNamesThatCleared: ['municipio', 'estabelecimentoId'],
      fnThatPopuledOptionsNextInput: loadMunicipiosOptions,
    });
  };
  const actionsMunicipioSelected = async () => {
    structureOffActions({
      arrSelected: municipiosSelected,
      listOffSetStatesThatDisabled: [setDisabledEstabelecimentoInput],
      listOffInputsNamesThatCleared: ['estabelecimentoId'],
      fnThatPopuledOptionsNextInput: loadEstabelecimentosOptions,
    });
  };
  const actionsEstabelecimentoSelected = async () => {
    structureOffActions({
      arrSelected: estabelecimentosSelected,
      listOffSetStatesThatDisabled: [setDisabledAcsInput],
      listOffInputsNamesThatCleared: ['acsId'],
      fnThatPopuledOptionsNextInput: loadAcsOptions,
    });
  };

  return {
    actionsEstadoSelected,
    actionsMunicipioSelected,
    actionsEstabelecimentoSelected,
  };
};

export default useActionsControlInputs;
