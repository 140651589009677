import './styles.scss';
import mismatchIcon from '../../assets/imgs/CentralNotification/mismatch.svg';
import missingIcon from '../../assets/imgs/CentralNotification/missing.svg';
import missingDisabledIcon from '../../assets/imgs/CentralNotification/missing-disabled.svg';
import availableIcon from '../../assets/imgs/CentralNotification/available.svg';
import moment from 'moment';
import api from '../../api';
import { useContext } from 'react';
import { LoadingContext } from '../../context/LoadingContext';
import { useNavigate } from 'react-router-dom';
import mismatchDisabledIcon from '../../assets/imgs/CentralNotification/mismatch-disabled.svg';

const Chip = ({ text, color }) => {
  return (
    <div className={`chip chip-${color}`}>
      <p style={{ fontSize: '13px', fontWeight: 400 }}>{text}</p>
    </div>
  );
};

const CardCentralNotification = ({
  description,
  status,
  statusLida,
  data,
  namePatient,
  id,
  viewed_at,
  setAlertId,
  setModalIsOpen,
  alertId,
  isResolved,
  idPessoa,
  idExam,
  resolved_at,
  notificationResolved,
}: any) => {
  const icon = {
    // HPV
    MISMATCH_HPV_COLETA: notificationResolved
      ? mismatchDisabledIcon
      : mismatchIcon,
    MISSING_HPV_EXAM: notificationResolved ? missingDisabledIcon : missingIcon,
    AVAILABLE_HPV_RESULT: availableIcon,

    // Convocatória
    DID_NOT_RESPOND_TO_THE_CALL: notificationResolved
      ? missingDisabledIcon
      : missingIcon,
    REQUESTING_A_VISIT: notificationResolved
      ? missingDisabledIcon
      : missingIcon,
    MISSING_RESPONSE: notificationResolved ? missingDisabledIcon : missingIcon,
    WRONG_NUMBER: notificationResolved ? missingDisabledIcon : missingIcon,
    DID_NOT_RESPOND_TO_THE_WAITING_LIST: notificationResolved
      ? missingDisabledIcon
      : missingIcon,
    CANCELLED_THE_APPOINTMENT: notificationResolved
      ? missingDisabledIcon
      : missingIcon,
    DID_NOT_WANT_TO_SCHEDULE: notificationResolved
      ? missingDisabledIcon
      : missingIcon,
    PERSISTING_WITH_WRONG_NUMBER: notificationResolved
      ? missingDisabledIcon
      : missingIcon,
  };

  const colorSide = {
    // HPV
    MISMATCH_HPV_COLETA: 'yellow',
    MISSING_HPV_EXAM: 'red',
    AVAILABLE_HPV_RESULT: 'green',

    // Convocatória
    DID_NOT_RESPOND_TO_THE_CALL: 'yellow',
    REQUESTING_A_VISIT: 'yellow',
    MISSING_RESPONSE: 'yellow',
    WRONG_NUMBER: 'red',
    DID_NOT_RESPOND_TO_THE_WAITING_LIST: 'yellow',
    CANCELLED_THE_APPOINTMENT: 'red',
    DID_NOT_WANT_TO_SCHEDULE: 'red',
    PERSISTING_WITH_WRONG_NUMBER: 'red',
  };

  const text = {
    // HPV
    MISMATCH_HPV_COLETA: {
      title: 'Divergência de Dados do Teste de HPV',
      description: `Um resultado do Teste de HPV para a paciente ${namePatient} foi 
      identificado no GAL, porém não há dados de Anamnese e Exame Clínico registrados no Jordana.`,
    },
    MISSING_HPV_EXAM: {
      title: 'Inexistência de Dados do Teste de HPV',
      description: `Um resultado do Teste de HPV para a paciente ${namePatient} foi
         identificado no GAL, porém não há dados de Anamnese e Exame Clínico registrados no Jordana.`,
    },
    AVAILABLE_HPV_RESULT: {
      title: 'Resultado Disponível do Teste de HPV',
      description: `O resultado do Teste de HPV da paciente 
        ${namePatient} está disponível, selecione aqui para visualizá-lo`,
    },

    // Convocatória
    REQUESTING_A_VISIT: {
      title: 'Paciente Solicitando a sua visita',
      description: `A paciente ${namePatient} foi convocada por WhatsApp para agendar seu teste, 
      mas a mesma sinalizou que deseja receber o seu auxílio. Faça uma visita domiciliar para verificar 
      se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
    },
    DID_NOT_RESPOND_TO_THE_CALL: {
      title: 'Paciente não Respondeu a Convocatória',
      description: `A paciente ${namePatient} foi convocada por WhatsApp para agendar seu teste, 
      mas não tivemos resposta da mesma. Faça uma visita domiciliar para verificar se está tudo 
      bem com ela e registre o resultado da sua Busca Ativa.`,
    },
    DID_NOT_RESPOND_TO_THE_WAITING_LIST: {
      title: 'Paciente não Respondeu a Lista de Espera',
      description: `A paciente ${namePatient} que estava na nossa Lista de Espera foi avisada 
      de novas vagas disponíveis, mas ela ainda não agendou. Faça uma visita domiciliar para verificar
       se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
    },
    CANCELLED_THE_APPOINTMENT: {
      title: 'Paciente Cancelou o Agendamento',
      description: `A paciente ${namePatient} tinha agendado seu Teste de HPV mas cancelou. 
      Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
    },
    DID_NOT_WANT_TO_SCHEDULE: {
      title: 'Paciente não Quis Agendar',
      description: `A paciente ${namePatient} foi convocada por WhatsApp para agendar seu teste, 
      mas a mesma sinalizou que não quer agendar. Faça uma visita domiciliar para verificar se está 
      tudo bem com ela e registre o resultado da sua Busca Ativa.`,
    },
    PERSISTING_WITH_WRONG_NUMBER: {
      title: 'Paciente persistindo com Telefone Errado',
      description: `A paciente ${namePatient} teve seu telefone corrigido recentemente, 
      porém o telefone continua não sendo da mesma.  Faça uma visita domiciliar para verificar 
      se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
    },
    MISSING_RESPONSE: {
      title: 'Paciente não Respondeu a Convocatória',
      description: `A paciente ${namePatient} foi convocada por WhatsApp para agendar seu teste, 
      mas não tivemos resposta da mesma. 
          Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
    },
    WRONG_NUMBER: {
      title: 'Telefone Errado',
      description: `O telefone da paciente ${namePatient} está incorreto.
          Faça uma visita domiciliar para verificar se está tudo bem com ela e registre o resultado da sua Busca Ativa.`,
    },
  };

  const { setLoading } = useContext(LoadingContext);

  const navigate = useNavigate();

  // const handleViewNotification = async () => {
  //   if (notificationResolved) {
  //     return;
  //   }

  //   setLoading(true);
  //   const response = await api.post(`/notificacao/view/${id}`);

  //   if (response?.status === 201) {
  //     if (status === 'AVAILABLE_HPV_RESULT') {
  //       try {
  //         navigate(`/pacientes/${idPessoa}/result/${idExam}`, {
  //           state: { view: 'visualizar' },
  //         });
  //         setLoading(false);
  //       } catch (error) {
  //         setLoading(false);

  //         console.error(error);
  //       }

  //       return;
  //     }
  //     setAlertId(alertId);
  //     setModalIsOpen(true);
  //     setLoading(false);
  //   }
  // };
  const handleViewNotification = async () => {
    const disabledStatuses = [
      'DID_NOT_RESPOND_TO_THE_CALL',
      'DID_NOT_WANT_TO_SCHEDULE',
    ];

    setLoading(true);
    const response = await api.post(`/notificacao/view/${id}`);

    if (
      (notificationResolved || disabledStatuses.includes(status)) &&
      status !== 'AVAILABLE_HPV_RESULT'
    ) {
      setLoading(false);
      return;
    }

    if (response?.status === 201) {
      if (status === 'DID_NOT_RESPOND_TO_THE_CALL') {
        setLoading(false);
        return;
      }

      if (status === 'AVAILABLE_HPV_RESULT') {
        try {
          navigate(`/pacientes/${idPessoa}/result/${idExam}`, {
            state: { view: 'visualizar', origin: 'central-notification' },
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);

          console.error(error);
        }

        return;
      }
      if (status === 'WRONG_NUMBER') {
        // try {
        //   navigate(`/pacientes/${idPessoa}/`, {
        //     state: { view: 'edit' },
        //   });
        //   setLoading(false);
        // } catch (error) {
        //   setLoading(false);

        //   console.error(error);
        // }
        setLoading(false);

        return;
      }
      setAlertId(alertId);
      setModalIsOpen(true);
      setLoading(false);
    }
  };

  const notRead = viewed_at === null;

  const dateResolved = `${moment(resolved_at).format('DD/MM/YYYY')}`;
  const timeResolved = `${moment(resolved_at).utcOffset(-3).format('HH:mm')}`;

  return (
    <div
      className={`card-item ${
        notificationResolved && status !== 'AVAILABLE_HPV_RESULT'
          ? 'disabled'
          : ''
      }`}
      onClick={handleViewNotification}
    >
      <div
        className={`side side-${
          notificationResolved && status !== 'AVAILABLE_HPV_RESULT'
            ? 'gray'
            : colorSide[status]
        }`}
      />
      <div className="card-item__content">
        <img src={icon[status]} alt="" />
        <div>
          <p className="card-item__content__date">
            Recebida em {data ? moment(data).format('DD/MM/YYYY') : ''}
            {resolved_at && (
              <span>
                , resolvido por {isResolved.full_name} em {dateResolved} às{' '}
                {timeResolved}
              </span>
            )}
          </p>
          <p>{text[status]?.title}</p>
          <span>{text[status]?.description}</span>

          <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
            <Chip
              text={notRead ? 'Não Lida' : 'Lida'}
              color={notRead ? 'blue' : 'gray'}
            />

            {status !== 'AVAILABLE_HPV_RESULT' && (
              <Chip
                text={notificationResolved ? 'Resolvida' : 'Não Resolvida'}
                color={notificationResolved ? 'gray' : 'red'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCentralNotification;
