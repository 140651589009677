import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const TableHeaders = () => {
  const headers = [
    { id: 'prioridade', label: 'Prioridade' },
    { id: 'faixa', label: 'Faixa' },
    { id: 'idade', label: 'Idade' },
    { id: 'dataColeta', label: 'Data de Coleta do\nCitopatológico' },
    { id: 'testeHPV', label: 'Teste de HPV' },
    { id: 'nome', label: 'Nome' },
    { id: 'apelido', label: 'Apelido' },
    { id: 'cpf', label: 'CPF' },
    { id: 'cartaoSUS', label: 'Nº Cartão do SUS' },
    { id: 'dataNascimento', label: 'Data de\nNascimento' },
    { id: 'contatoPreferencial', label: 'Contato\nPreferencial' },
    { id: 'logradouro', label: 'Logradouro' },
    { id: 'numero', label: 'Número' },
    { id: 'bairro', label: 'Bairro' },
    { id: 'complemento', label: 'Complemento' },
    { id: 'referencia', label: 'Referência' },
    { id: 'cep', label: 'CEP' },
    { id: 'municipio', label: 'Município' },
    { id: 'estado', label: 'Estado' },
    { id: 'whatsapp', label: 'WhatsApp' },
    { id: 'estabelecimento', label: 'Estabelecimento\nde Saúde' },
    { id: 'agenteSaude', label: 'Agente\nComunitário de\nSaúde' },
    { id: 'procedimento', label: 'Procedimento\nRealizado' },
    { id: 'meioRastreamento', label: 'Meio de\nRastreamento' },
  ];

  const cellStyle = { borderBottom: 'none' };

  const textStyle = {
    fontSize: '14px',
    width: '160px',
    fontWeight: 'bold',
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map((header) => (
          <TableCell key={header.id} align="left" sx={cellStyle}>
            <Typography sx={textStyle}>{header.label}</Typography>
          </TableCell>
        ))}
        <TableCell align="right" sx={cellStyle}></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;
