import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import { PieChart } from '../pieChart';
import EmptyData from '../../../../../components/Charts/emptyData';

interface ReasonsNotProcedureChartProps {
  data: number[];
  categories?: String[];
  height?: number;
  payload: any;
  needsRequiredFilters: boolean;
}

export function ReasonsNotProcedureChart({
  data,
  payload,
  needsRequiredFilters,
}: ReasonsNotProcedureChartProps) {
  const emptyData = !data.find((item) => item > 0);

  const [chartData, setChartData] = useState<ApexOptions>();

  useEffect(() => {
    setChartData({
      series: data,
      chart: {
        width: 284,
        type: 'pie',
        events: {
          dataPointSelection: function (event, chartContext, config) {
            if (config.dataPointIndex > -1) {
              localStorage.removeItem('result');
              localStorage.removeItem('typeVirus');

              const values = {
                0: 'NAO_REALIZOU',
                1: 'MENSTRUADA',
                2: 'NAO_FAIXA_ETARIA',
                3: 'HISTERECTOMIZADA',
                4: 'OUTRO',
              };

              localStorage.setItem('chartName', 'reasonNotProcedure');
              localStorage.setItem(
                'itemClicked',
                values[config.dataPointIndex]
              );

              localStorage.setItem(
                'reasonNotProcedure',
                values[config.dataPointIndex]
              );
              localStorage.setItem('requestFromTable', 'true');
              localStorage.setItem('payload', JSON.stringify(payload));
              window.location.href = '/relatorios/hpv';
            }
          },
        },
      },
      labels: [
        `Compareceu mas não realizou o teste (${data[0]})`,
        `Estava Menstruada (${data[1]})`,
        `Não está na faixa etária(${data[2]})`,
        `Histerectomizada por patologia benigna (${data[3]})`,
        `Outros (${data[4]})`,
      ],
      colors: ['#64b6f7', '#2196F3', '#0B79D0', '#1769ab', '#ACB7B8'],
      yaxis: {
        min: 0,
      },
      xaxis: {
        min: 0,
      },
      legend: {
        position: 'right',
        width: 200,
        offsetY: 40,
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              position: 'bottom',
              horizontalAlign: 'left',
              width: 300,
              offsetY: 0,
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
      },
      plotOptions: {
        pie: {
          startAngle: -135,
          endAngle: 225,
        },
      },
    });
  }, [data]);

  return (
    <>
      {chartData ? (
        <>
          <div style={{ display: 'none' }}>
            <ul>
              {data.map((value, index) => {
                const total = data[0] + data[1] + data[2] + data[3] + data[4];
                const percentNaoRealizou = `${Number(
                  (data[0] / total) * 100
                ).toFixed(1)}%`;
                const percentMenstruada = `${Number(
                  (data[1] / total) * 100
                ).toFixed(1)}%`;
                const percentNotAge = `${Number(
                  (data[2] / total) * 100
                ).toFixed(1)}%`;
                const percentHisterectomizada = `${Number(
                  (data[3] / total) * 100
                ).toFixed(1)}%`;
                const percentOthers = `${Number(
                  (data[4] / total) * 100
                ).toFixed(1)}%`;
                return (
                  <>
                    {index === 0 && (
                      <li>
                        <p data-testid="labelAndValueNaoRealizou">
                          Compareceu mas não realizou o teste ({value})
                        </p>
                        <p data-testid="percentNaoRealizou">
                          percentNaoRealizou: {percentNaoRealizou}
                        </p>
                      </li>
                    )}
                    {index === 1 && (
                      <li>
                        <p data-testid="labelAndValueMenstruada">
                          Estava Menstruada ({value})
                        </p>
                        <p data-testid="percentMenstruada">
                          percentMenstruada: {percentMenstruada}
                        </p>
                      </li>
                    )}
                    {index === 2 && (
                      <li>
                        <p data-testid="labelAndValueNotAge">
                          Não está na faixa etária ({value})
                        </p>
                        <p data-testid="percentNotAge">
                          percentNotAge: {percentNotAge}
                        </p>
                      </li>
                    )}
                    {index === 3 && (
                      <li>
                        <p data-testid="labelAndValueHisterectomizada">
                          Histerctomizada por patologia benigna ({value})
                        </p>
                        <p data-testid="percentHisterectomizada">
                          percentHisterectomizada: {percentHisterectomizada}
                        </p>
                      </li>
                    )}
                    {index === 4 && (
                      <li>
                        <p data-testid="labelAndValueOthers">
                          Outros ({value})
                        </p>
                        <p data-testid="percentOthers">
                          percentOthers: {percentOthers}
                        </p>
                      </li>
                    )}
                  </>
                );
              })}
            </ul>
          </div>

          <div style={{ position: 'relative' }}>
            <PieChart chartData={chartData} />
            {emptyData && (
              <div
                style={{
                  marginTop: '-49px',
                  marginBottom: '179px',
                  position: 'absolute',
                  left: '10%',
                  top: '20%',
                }}
              >
                <EmptyData needsRequiredFilters={needsRequiredFilters} />
              </div>
            )}
          </div>
        </>
      ) : undefined}
    </>
  );
}
