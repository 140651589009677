import moment from 'moment';
import blueIcon from '../../../../../../../../assets/imgs/Convocation/tube.svg';
import greenIcon from '../../../../../../../../assets/imgs/Convocation/greenTube.svg';
import redIcon from '../../../../../../../../assets/imgs/Convocation/redHpvTube.svg';
import dot from '../../../../../../../../assets/imgs/Convocation/dot.svg';
import connector from '../../../../../../../../assets/imgs/Convocation/connector.svg';
import { Grid } from '@mui/material';
import { valuesMotivoNaoAtendida } from '../../../../../../../../components/TableReports/TableHpv/variablesRowsValues';

// type Props = {
//   testHpv: Schedule[];
// };

// type Schedule = {
//   wantSchedule: { is: boolean; when: string };
//   confirmed: boolean;
// };
const ComponentHpv = ({ hpv, objConvocation, setRedIcon }: any) => {
  const dtHpv = moment(hpv?.created_at);
  const initialConvocation = moment(objConvocation?.convocacao_data_inicio);
  const isOutConvocation = dtHpv?.isBefore(initialConvocation);
  const realizedTestWithSuccess = hpv?.atendimento_status === 'REALIZADO';

  const icon = () => {
    if (isOutConvocation) {
      return blueIcon;
    }

    if (setRedIcon) {
      return redIcon;
    }
    return greenIcon;
  };

  return (
    <>
      {!setRedIcon ? (
        <>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '246px',
              marginLeft: '80px',
            }}
          >
            <img src={icon()} style={{ width: '64px' }} />
            <p
              style={{
                fontSize: '16px',

                fontWeight: 700,
                marginBottom: '8px',
              }}
            >
              Teste de HPV
            </p>
            {isOutConvocation && (
              <span style={{ fontSize: '12px', marginBottom: '8px' }}>
                Fora da Convocatória
              </span>
            )}

            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px' }} src={dot} />
              <p
                style={{
                  fontWeight: 500,

                  fontSize: '14px',
                  margin: '0 0 0px 14px',
                }}
              >
                Realizou o procedimento?
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px', height: '35px' }} src={connector} />
              <p
                style={{
                  fontWeight: 400,

                  fontSize: '14px',
                  margin: '0 0 0 14px',
                  paddingTop: '7px',
                  width: '196px',
                }}
              >
                {hpv?.atendimento_status === 'REALIZADO' ? 'Sim' : 'Não'}
                {!isOutConvocation && !realizedTestWithSuccess && (
                  <>
                    <br />
                    <span
                      style={{
                        fontSize: '12px',
                        marginBottom: '8px',
                        fontStyle: 'italic',
                      }}
                    >
                      {valuesMotivoNaoAtendida[hpv?.atendimento_status]}
                    </span>
                  </>
                )}
              </p>
            </div>
            {hpv?.dt_coleta && isOutConvocation && (
              <>
                <div style={{ display: 'flex', width: '100%' }}>
                  <img style={{ width: '12px' }} src={dot} />
                  <p
                    style={{
                      fontWeight: 500,

                      fontSize: '14px',
                      margin: '0 0 0px 14px',
                    }}
                  >
                    Compareceu em?
                  </p>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                  <img
                    style={{ width: '12px', height: '35px' }}
                    src={connector}
                  />
                  <p
                    style={{
                      fontWeight: 400,

                      fontSize: '14px',
                      margin: '0 0 0 14px',
                      paddingTop: '7px',
                      width: '196px',
                    }}
                  >
                    {moment(hpv?.dt_coleta).format('DD/MM/YYYY')}
                  </p>
                </div>
              </>
            )}

            {hpv?.dt_recebimento_resultado && (
              <>
                <div style={{ display: 'flex', width: '100%' }}>
                  <img style={{ width: '12px' }} src={dot} />
                  <p
                    style={{
                      fontWeight: 500,

                      fontSize: '14px',
                      margin: '0 0 0px 14px',
                    }}
                  >
                    Recebeu o resultado em:
                  </p>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                  <img
                    style={{ width: '12px', height: '35px' }}
                    src={connector}
                  />
                  <p
                    style={{
                      fontWeight: 400,

                      fontSize: '14px',
                      margin: '0 0 0 14px',
                      paddingTop: '7px',
                      width: '196px',
                    }}
                  >
                    {moment(hpv?.dt_recebimento_resultado).format('DD/MM/YYYY')}
                  </p>
                </div>
              </>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '246px',
              marginLeft: '80px',
            }}
          >
            <img src={icon()} style={{ width: '64px' }} />
            <p
              style={{
                fontSize: '16px',

                fontWeight: 700,
                marginBottom: '8px',
              }}
            >
              Teste de HPV
            </p>
            {isOutConvocation && (
              <span style={{ fontSize: '12px', marginBottom: '8px' }}>
                Fora da Convocatória
              </span>
            )}

            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px' }} src={dot} />
              <p
                style={{
                  fontWeight: 500,

                  fontSize: '14px',
                  margin: '0 0 0px 14px',
                }}
              >
                Realizou o procedimento?
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px', height: '35px' }} src={connector} />
              <p
                style={{
                  fontWeight: 400,

                  fontSize: '14px',
                  margin: '0 0 0 14px',
                  paddingTop: '7px',
                  width: '196px',
                }}
              >
                {hpv?.atendimento_status === 'REALIZADO' ? 'Sim' : 'Não'}
              </p>
            </div>
            {hpv?.dt_coleta && isOutConvocation && (
              <>
                <div style={{ display: 'flex', width: '100%' }}>
                  <img style={{ width: '12px' }} src={dot} />
                  <p
                    style={{
                      fontWeight: 500,

                      fontSize: '14px',
                      margin: '0 0 0px 14px',
                    }}
                  >
                    Compareceu em?
                  </p>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                  <img
                    style={{ width: '12px', height: '35px' }}
                    src={connector}
                  />
                  <p
                    style={{
                      fontWeight: 400,

                      fontSize: '14px',
                      margin: '0 0 0 14px',
                      paddingTop: '7px',
                      width: '196px',
                    }}
                  >
                    {moment(hpv?.dt_coleta).format('DD/MM/YYYY')}
                  </p>
                </div>
              </>
            )}

            {hpv?.dt_recebimento_resultado && (
              <>
                <div style={{ display: 'flex', width: '100%' }}>
                  <img style={{ width: '12px' }} src={dot} />
                  <p
                    style={{
                      fontWeight: 500,

                      fontSize: '14px',
                      margin: '0 0 0px 14px',
                    }}
                  >
                    Recebeu o resultado em:
                  </p>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                  <img
                    style={{ width: '12px', height: '35px' }}
                    src={connector}
                  />
                  <p
                    style={{
                      fontWeight: 400,

                      fontSize: '14px',
                      margin: '0 0 0 14px',
                      paddingTop: '7px',
                      width: '196px',
                    }}
                  >
                    {moment(hpv?.dt_recebimento_resultado).format('DD/MM/YYYY')}
                  </p>
                </div>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
export default ComponentHpv;
