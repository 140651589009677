import { Chip, Tooltip } from '@mui/material';
import { formatCpf } from '../../../../../../../../utils/formatters';
import { ExpandIconWrapper } from '../../../../../../../../components/CardExam/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DynamicRows = ({
  opened,
  objConvocation,
  arrTextRowsWhenHided,
  firstRowConfig,
  secondRowConfig,
  thirdRowConfig,
  patientName,
  cpf,
  status,
  setOpenedCard,
  index,
}: any) => {
  const colorChip = {
    Concluída: '#7BC67E',
    'Teste Realizado': '#64B6F7',
    'Em Andamento': '#F6B681',
    Cancelada: '#F88078',
    'Fora da Convocatória': '#7986CB',
    Agendada: '#e188b9',
  };
  return (
    <>
      <td style={{ width: '124px' }}>
        <Tooltip
          title={patientName}
          placement="top"
          sx={{
            position: 'relative',
          }}
        >
          <span>
            {patientName?.length > 10
              ? patientName?.slice(0, 10) + '...'
              : `${patientName}`}
          </span>
        </Tooltip>
      </td>

      <td style={{ width: '149px' }}>{cpf ? formatCpf(cpf) : ''}</td>
      {opened ? (
        <>
          <td style={{ width: '149px' }}>{objConvocation?.estado}</td>
          {objConvocation?.municipio.length > 12 ? (
            <td style={{ width: '149px' }}>
              <Tooltip title={objConvocation.municipio}>
                <span>{objConvocation?.municipio.slice(0, 12)}...</span>
              </Tooltip>
            </td>
          ) : (
            <td style={{ width: '149px' }}>{objConvocation?.municipio}</td>
          )}

          <td style={{ width: '149px' }}>
            <Tooltip
              title={objConvocation?.estabelecimento}
              placement="top"
              sx={{
                position: 'relative',
              }}
            >
              <span>{objConvocation?.estabelecimento?.slice(0, 12)}...</span>
            </Tooltip>
          </td>
        </>
      ) : (
        <>
          {firstRowConfig?.text?.length > 15 ? (
            <Tooltip title={firstRowConfig?.tooltip} placement="top">
              <td style={{ width: '149px' }}>
                {firstRowConfig?.text?.slice(0, 14)}...
              </td>
            </Tooltip>
          ) : (
            <td style={{ width: '149px' }}>{firstRowConfig?.text}</td>
          )}
          {secondRowConfig?.text?.length > 14 ? (
            <Tooltip title={secondRowConfig?.tooltip} placement="top">
              <td style={{ width: '149px' }}>
                {secondRowConfig?.text?.slice(0, 14)}...
              </td>
            </Tooltip>
          ) : (
            <td style={{ width: '149px' }}>{secondRowConfig?.text}</td>
          )}
          {thirdRowConfig?.text?.length > 14 ? (
            <Tooltip title={thirdRowConfig?.tooltip} placement="top">
              <td>{thirdRowConfig?.text?.slice(0, 14)}...</td>
            </Tooltip>
          ) : (
            <td style={{ width: '149px' }}>{thirdRowConfig?.text}</td>
          )}
        </>
      )}
      <td
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '35px',
          height: '32px',
          alignItems: 'center',
          marginTop: '-10px',
        }}
      >
        <div style={{ width: '149px' }}>
          {status.includes('Fora') ? (
            <Tooltip title={status}>
              <Chip
                label={status}
                sx={{
                  fontWeight: '700',
                  color: `${'black'}`,
                  backgroundColor: colorChip[status],
                }}
              />
            </Tooltip>
          ) : (
            <Chip
              label={status}
              sx={{
                fontWeight: '700',
                color: `${'black'}`,
                backgroundColor: colorChip[status],
              }}
            />
          )}
        </div>
        <div style={{ width: '40px', marginLeft: '16px' }}>
          <ExpandIconWrapper
            onClick={() => {
              setOpenedCard(opened ? null : index);
            }}
          >
            <ExpandMoreIcon
              fontSize="medium"
              sx={{
                color: 'white',
                transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </ExpandIconWrapper>
        </div>
      </td>
    </>
  );
};

export default DynamicRows;
