import { useState } from 'react';
import { AgendaHeader, ContentContainer, Label, Value } from './styles';
import CheckCircle from '../../assets/imgs/check_circle.svg';
import CalendarCheck from '../../assets/imgs/Calendar-Check.svg';
import Clock from '../../assets/imgs/clock.svg';

import { Typography, Grid, Button } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

const Rescheduling = () => {
  const [statusCancel, setStatusCancel] = useState({
    text: 'Agendamento confirmado',
    color: '#3b873e',
    showImage: true,
    showReagendar: false,
    showButton: true,
  });

  const { id } = useParams();

  const urlPage = window.location.href;

  const urlSplited = urlPage.split('/');

  const handleImprime = async () => {
    const urlFormated = `${urlSplited[0]}//${urlSplited[2]}/${urlSplited[3]}`;

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cidadao_agenda/imprime`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ url: urlFormated }),
      }
    );

    const pdfBlob = await response.blob();

    const blobURL = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = 'comprovante-agendamento.pdf'; // Nome do arquivo para download
    link.click();

    URL.revokeObjectURL(blobURL);
  };

  const handleCancel = async () => {
    setStatusCancel({
      text: 'Agendamento Cancelado',
      color: '#F88078',
      showImage: false,
      showReagendar: true,
      showButton: false,
    });
    try {
      const data = {
        status: 'Agendamento Cancelado', // Altere para um dos valores permitidos
        isAgendamento: true,
        motivo_depois_agendado: true,
      };
      fetch(
        `${process.env.REACT_APP_API_URL}/cidadao_agenda/agendamento/${idAgendamento}/atualizar-status`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      ).then((res) => res.json().then((response) => {}));
    } catch (error) {
      console.error(error);
    }
  };

  const location = useLocation();
  const agendamentoData = location.state as any;

  const nome = agendamentoData?.agendamentoData.nome;
  const cartaoSus = agendamentoData?.agendamentoData.cns;
  const cpf = agendamentoData?.agendamentoData.cpf;
  moment.locale('pt-br');
  const formmatedDate = moment(
    agendamentoData?.agendamentoData.dt_agendamento
  ).format('dddd, DD/MM/YYYY');
  const horario = agendamentoData?.agendamentoData.horario_atendimento;
  const local = agendamentoData?.agendamentoData.nome_fantasia;
  const medico = agendamentoData?.agendamentoData.nomeProfissional;
  const endereco = agendamentoData?.agendamentoData.endereco_local;
  const idAgendamento = agendamentoData?.idAgendamento;

  const maskedCPF = `***${cpf?.substring(3, cpf.length - 3)}***`;

  return (
    <>
      <AgendaHeader>
        <Typography
          sx={{ fontSize: '20px', color: 'white', fontWeight: '700' }}
        >
          Agendamento
        </Typography>
      </AgendaHeader>
      <ContentContainer>
        <Grid
          container
          spacing={1}
          sx={{
            height: 'fit-content',
            '@media (min-width: 965px)': {
              boxShadow: '0px 0px 14px -8px black',
              width: '350px',
              padding: '25px',
            },
          }}
        >
          <Grid
            item
            xs={12}
            style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
            color={statusCancel.color}
          >
            <Typography
              fontWeight="bold"
              fontSize="20px"
              color={statusCancel.color}
            >
              {statusCancel.text}
            </Typography>
            {statusCancel.showImage && (
              <img src={CheckCircle} alt="check circle" />
            )}
          </Grid>
          <Grid item xs={12}>
            <Label>Nome</Label>
            <Value>{nome}</Value>
          </Grid>
          <Grid item xs={6}>
            <Label>Nº do Cartão do SUS</Label>
            <Value>{cartaoSus}</Value>
          </Grid>
          <Grid item xs={6}>
            <Label>CPF</Label>
            <Value>{maskedCPF}</Value>
          </Grid>
          <Grid
            item
            xs={7}
            style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
            marginTop={'8px'}
          >
            <img src={CalendarCheck} alt="check circle" />
            <Value style={{ fontSize: '15px', textTransform: 'capitalize' }}>
              {formmatedDate}
            </Value>
          </Grid>
          <Grid
            item
            xs={5}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              flex: '1',
            }}
            marginTop={'8px'}
          >
            <img src={Clock} alt="" />
            <Value>{horario}h</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Local do Procedimento</Label>
            <Value>{local}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Endereço</Label>
            <Value>{endereco}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Procedimento</Label>
            <Value>Teste de HPV</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Profissional</Label>
            <Value>{medico}</Value>
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ width: '100%', marginTop: '15px' }}
              variant="contained"
              type="button"
              onClick={handleImprime}
            >
              BAIXAR COMPROVANTE
            </Button>
          </Grid>
          {/* <Grid item xs={12} style={{ textAlign: 'center', marginTop: '8px' }}>
            <Label>Caso necessário, você pode</Label>
          </Grid>
          {statusCancel.showButton && (
            <>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Grid item xs={5}>
                  <Button
                    href={`/agenda/${id}`}
                    sx={{ width: '100%', marginTop: '15px' }}
                    variant="outlined"
                    type="submit"
                  >
                    REAGENDAR
                  </Button>
                </Grid>
                <Grid item xs={2} textAlign={'center'}>
                  <Label>ou</Label>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    sx={{ width: '100%', marginTop: '15px' }}
                    variant="outlined"
                    type="submit"
                    color="error"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          {statusCancel.showReagendar && (
            <>
              <Grid item xs={12}>
                <Button
                  href={`/agenda/${id}`}
                  sx={{ width: '100%', marginTop: '15px' }}
                  variant="contained"
                  type="submit"
                >
                  Reagendar
                </Button>
              </Grid>
            </>
          )} */}
        </Grid>
      </ContentContainer>
    </>
  );
};

export default Rescheduling;
