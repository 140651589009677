import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Menu, MenuItem, TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { StyledAsteriskTextField } from '../StyledAsteriskTextField/styles';

export interface Option {
  [key: string | number]: any;
  label?: string;
  value?: string | number;
  inputName?: string;
}

interface ModalSelectProps {
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  inputName: string;
  handleSetFilter: (value: string, inputName: string, label: string) => void;

  options: Option[];
  allOptions: Option[];
  selectedValues: string | string[];
  displayKey: string;
  valueKey: string;

  searchValue: string;
  anchorEl: HTMLElement | null;
  loading?: boolean;

  errors?: string[];
  errorMessage?: string;

  setAnchorEl: (anchor: HTMLElement | null) => void;
  setSearchValue: (value: string) => void;
  setOptions: (options: Option[]) => void;
  setAllOptions: (options: Option[]) => void;
  setSelectedValues: (
    values: string | string[] | ((prev: string[] | string) => string[] | string)
  ) => void;
  onChange?: (value: string | string[] | null) => void;
  onSearch?: (searchTerm: string) => void;

  menuWidth?: string | number;
  inputWidth?: string | number;
}

const ModalSelect: React.FC<ModalSelectProps> = ({
  label,
  placeholder = `Pesquise por ${label}`,
  required = false,
  disabled = false,
  inputName,
  handleSetFilter,

  options,
  allOptions,
  selectedValues,
  displayKey,
  valueKey,

  searchValue,
  anchorEl,
  loading = false,

  errors = [],
  errorMessage = 'Campo Obrigatório',

  setAnchorEl,
  setSearchValue,
  setOptions,
  setAllOptions,
  setSelectedValues,
  onChange,
  onSearch,

  menuWidth = '289px',
  inputWidth = '100%',
}) => {
  const [localLoading, setLocalLoading] = useState(loading);
  const [filterText, setFilterText] = useState<string>('');

  const hasError = !!errors.length;

  const selectedValuesArray = Array.isArray(selectedValues)
    ? selectedValues
    : selectedValues
    ? [selectedValues]
    : [];

  useEffect(() => {
    if (options.length > 0 && localLoading) {
      setLocalLoading(false);
    }
  }, [options, localLoading]);

  // const handleSearch = () => {
  //   if (onSearch) {
  //     onSearch(searchValue);
  //   } else if (!searchValue) {
  //     setOptions(allOptions);
  //   } else {
  //     const filteredOptions = allOptions.filter((option) =>
  //       option[displayKey]?.toLowerCase().includes(searchValue.toLowerCase())
  //     );
  //     setOptions(filteredOptions);
  //   }
  // };

  const handleSelectAll = () => {
    const allValues = options.map((option) => option[valueKey]);
    setSelectedValues(allValues);

    handleSetFilter('Vários', inputName, label);

    if (onChange) {
      onChange(allValues);
    }
  };

  const handleClear = () => {
    setSelectedValues([]);

    handleSetFilter('', inputName, label);

    if (onChange) {
      onChange(null);
    }
  };

  const handleSelect = (option: Option) => {
    const value = option[valueKey];
    if (!value) return;

    let newSelectedValues: string[];

    if (Array.isArray(selectedValues)) {
      const isSelected = selectedValues.includes(value);
      newSelectedValues = isSelected
        ? selectedValues.filter((v) => v !== value)
        : [...selectedValues, value];
    } else {
      newSelectedValues = selectedValues ? [selectedValues, value] : [value];
    }

    setSelectedValues(newSelectedValues);

    const displayValue =
      newSelectedValues.length > 1 ? 'Vários' : option[displayKey];

    handleSetFilter(displayValue, inputName, label);

    if (onChange) {
      onChange(newSelectedValues);
    }
  };

  const filteredOptions = options?.filter((option) =>
    option?.label?.toLowerCase().includes(filterText.toLowerCase())
  );

  const getDisplayValue = () => {
    if (!selectedValuesArray?.length) return '';

    if (selectedValuesArray.length > 1) {
      return 'Vários';
    }

    if (selectedValuesArray.length === 1) {
      const selectedOption = options.find(
        (opt) => opt[valueKey] === selectedValuesArray[0]
      );
      return selectedOption ? selectedOption[displayKey] : '';
    }
    return '';
  };

  return (
    <>
      <StyledAsteriskTextField
        required={required}
        size="small"
        error={hasError}
        disabled={disabled}
        helperText={hasError ? errorMessage : undefined}
        label={label}
        value={getDisplayValue()}
        sx={{
          padding: 0,
          width: inputWidth,
          '.MuiOutlinedInput-root': {
            paddingRight: '9px',
          },
        }}
        InputProps={{
          endAdornment: (
            <Button
              sx={{
                padding: 0,
                minWidth: '24px !important',
              }}
              disabled={disabled}
              onClick={(event) => {
                window.scrollTo(0, 0);
                setAnchorEl(event.currentTarget);
              }}
            >
              <ArrowDropDownIcon
                sx={{
                  cursor: 'pointer',
                  color: disabled ? '#BDBDBD' : 'gray',
                }}
              />
            </Button>
          ),
        }}
      />

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setFilterText('');
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          position: 'absolute',
          width: '100%',
          marginTop: '10px',
          left: '10px',
          '.MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '24px 32px',
            flexDirection: 'column',
          }}
        >
          <TextField
            size="small"
            placeholder={placeholder}
            onChange={(e) => setFilterText(e.target.value)}
            value={filterText}
            sx={{
              width: menuWidth,
              '.MuiOutlinedInput-root': {
                paddingRight: '9px',
              },
            }}
            // InputProps={{
            //   endAdornment: (
            //     <Button
            //       sx={{
            //         padding: 0,
            //         minWidth: '24px !important',
            //       }}
            //       onClick={handleSearch}
            //     >
            //       <SearchIcon
            //         sx={{
            //           cursor: 'pointer',
            //           color: 'gray',
            //         }}
            //       />
            //     </Button>
            //   ),
            // }}
          />

          <div>
            <Button
              variant="text"
              sx={{ padding: '0' }}
              onClick={handleSelectAll}
            >
              SELECIONAR TODOS
            </Button>
            <Button
              variant="text"
              sx={{ padding: '0', marginLeft: '10px' }}
              onClick={handleClear}
            >
              LIMPAR
            </Button>
          </div>

          {localLoading ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <span
                className="loader"
                style={{ width: '40px', height: '40px' }}
              ></span>
            </div>
          ) : (
            <>
              {options.length === 0 ? (
                <p>Não há dados</p>
              ) : (
                <>
                  {filteredOptions.map((option, index) => (
                    <div
                      key={index}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Checkbox
                        onChange={() => handleSelect(option)}
                        checked={selectedValuesArray.includes(option[valueKey])}
                      />
                      <MenuItem
                        sx={{
                          padding: '0 8px',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={() => handleSelect(option)}
                      >
                        {option[displayKey]}
                      </MenuItem>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </Menu>
    </>
  );
};

export default ModalSelect;
