export function maskDate(input) {
  let value = input?.replace(/\D/g, '');

  if (value.length > 2) {
    value = value.replace(/^(\d{2})/, '$1/');
  }
  if (value.length > 5) {
    value = value.replace(/^(\d{2})\/(\d{2})/, '$1/$2/');
  }
  if (value.length > 10) {
    value = value.slice(0, 10);
  }

  return value;
}
