import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import MultipleFilters from '../../../../../components/MultipleFilters';
import { SelectWithSearchV2 } from '../../../../../components/SelectWithSearchV2';
import { DisplayFilter, ItemsDisplayFilter } from '../styles';
import { Controller, useForm } from 'react-hook-form';
import PeriodoInput from '../../../../../components/Inputs/PeriodoInput';
import SearchByNameInput from '../../../../../components/Inputs/SearchByNameInput';
import { validateSearch } from '../../../../../utils/validateSearchInput';
import { useContext, useEffect, useState } from 'react';
import useSetFilterByPerfilV2 from '../../../../../utils/hooks/useSetFilterByPerfilV2';
import { getUserToken } from '../../../../../lib/auth';
import useActionsControlInputs from '../../../../../utils/hooks/useActionsControlInputs';
import { PerfilContext } from '../../../../../context/PerfilContext';
import { getCidades } from '../../../../../lib/getCidadesEstabelecimentos';
import { getEstabelecimentos } from '../../../../../lib/getEstabelecimentos';
import { getAcs } from '../../../../../lib/profissionais';
import {
  getCidadesConvocatoria,
  getConvocationsList,
  getEstabelecimentosConvocatoria,
} from '../../../../../lib/convocation';
import { LoadingContext } from '../../../../../context/LoadingContext';
// import { PerfilContext } from '../../../../../context/PerfilContext';
const optionsEstado = [
  { label: 'PE', value: 'PE' },
  { label: 'PR', value: 'PR' },
];

const FiltersConvocation = ({
  handleSubmit,
  control,
  clearErrors,
  setError,
  watch,
  errors,
  setValue,
  initialStateListFilter,
  listFilter,
  setListFilter,
  setListFilterShow,
  listFilterShow,
  setShowTableData,
  showTableData,
  optionsMunicipios,
  setOptionsMunicipios,
  estadosOptions,
  setEstadosOptions,
  optionsEstabelecimentos,
  setOptionsEstabelecimentos,
  optionsProfissional,
  setOptionsProfissional,
  setPayload,
  setTableData,
  setPrincipalFilters,
  principalFilters,
  loadConvocationList,
  handleSetSelectedOptionsInDisplay,
  handleSetFilter,
}) => {
  const [disabledEstadoInput, setDisabledEstadoInput] = useState(false);
  const [disabledMunicipioInput, setDisabledMunicipioInput] = useState(false);
  const [disabledAcsInput, setDisabledAcsInput] = useState(false);
  const [disabledEstabelecimentoInput, setDisabledEstabelecimentoInput] =
    useState(false);

  const [loadingMunicipios, setLoadingMunicipios] = useState(false);
  const [loadingEstabelecimentos, setLoadingEstabelecimentos] = useState(false);
  const [loadingAcs, setLoadingAcs] = useState(false);

  const { setLoading } = useContext(LoadingContext);

  const loadMunicipiosOptions = async ({ param }) => {
    setLoadingMunicipios(true);
    const optionsCidadesData = await getCidadesConvocatoria(param);

    setOptionsMunicipios(
      optionsCidadesData.map((cidade) => {
        return {
          label: cidade.nome,
          value: cidade.id,
        };
      })
    );
    setLoadingMunicipios(false);
  };

  const loadEstabelecimentosOptions = async ({ param }) => {
    setLoadingEstabelecimentos(true);
    const valueParam = optionsMunicipios.find(
      (municipio) => municipio.value == param
    )?.label;

    const optionsEstabelecimentosData = await getEstabelecimentosConvocatoria({
      municipio: valueParam,
    });

    setOptionsEstabelecimentos(
      optionsEstabelecimentosData.data
        .map((estabelecimento) => {
          return {
            label: estabelecimento.nome_fantasia,
            value: estabelecimento.id,
          };
        })
        .slice(0, 200)
    );

    setLoadingEstabelecimentos(false);
  };

  const loadAcsOptions = async ({ param }) => {
    setLoadingAcs(true);
    const optionsAcs = await getAcs({ estabelecimentoId: param });

    setOptionsProfissional(
      optionsAcs.data.map((acs) => {
        return {
          label: acs.pessoa.nome,
          value: acs.id,
        };
      })
    );

    setLoadingAcs(false);
  };

  const {
    isSuperAdmin,
    isMunicipal,
    isPadraoEstabelecimento,
    isEstadual,
    isAcsEstabelecimento,
  } = useContext(PerfilContext);

  const {
    actionsEstadoSelected,
    actionsMunicipioSelected,
    actionsEstabelecimentoSelected,
  } = useActionsControlInputs({
    estadosSelected: watch('estado'),
    municipiosSelected: watch('municipio'),
    estabelecimentosSelected: watch('estabelecimentoId'),

    setValue,
    handleSetFilter,

    setDisabledEstadoInput,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,
    setDisabledAcsInput,

    loadMunicipiosOptions,
    loadEstabelecimentosOptions,
    loadAcsOptions,
  });

  const handleClearFilters = () => {
    setListFilter(initialStateListFilter);
    setListFilterShow(initialStateListFilter);
    setValue('acsId', []);
    setValue('search', null);
    setValue('idadeInicio', null);
    setValue('idadeFim', null);
    setValue('status', []);
    setValue('convocacao', []);
    if (isSuperAdmin) {
      setValue('estado', []);
      loadConvocationList({
        clear: true,
        listParamsToRemove: [
          'estado',
          'municipio',
          'estabelecimentoId',
          'acsId',
          'idadeInicio',
          'idadeFim',
          'pesquisa',
          'status',
        ],
      });
    }

    if (isEstadual) {
      setValue('municipio', []);
      loadConvocationList({
        clear: true,
        listParamsToRemove: [
          'municipio',
          'estabelecimentoId',
          'acsId',
          'idadeInicio',
          'idadeFim',
          'pesquisa',
          'status',
        ],
      });
    }

    if (isMunicipal) {
      setValue('estabelecimentoId', []);
      loadConvocationList({
        clear: true,
        listParamsToRemove: [
          'estabelecimentoId',
          'acsId',
          'idadeInicio',
          'idadeFim',
          'pesquisa',
          'status',
        ],
      });
    }

    setTableData({});
    // setLoadingList(true);
    setLoading(true);
  };

  const submitForm = () => {
    const searchNameCpfCnsIsValid = validateSearch({
      clearErrors,
      setError,
      watch,
    });

    if (searchNameCpfCnsIsValid) {
      if (searchNameCpfCnsIsValid) {
        setLoading(true);
        setTableData(undefined);
        setListFilterShow(listFilter);
        loadConvocationList({});
      }
      // setLoading(true);
      // // setDataCentral([]);
      // setPayload().then(async (payload) => {
      //   const dataTable = await getConvocationsList({ data: payload });

      //   setTableData(dataTable);
      //   setShowTableData(true);
      //   setLoading(false);
      // });

      // setListFilterShow(listFilter);
    }
  };

  useEffect(() => {
    actionsEstadoSelected();
    setPrincipalFilters((prev) => {
      return {
        ...prev,
        estado: { options: optionsEstado, selected: watch('estado') },
      };
    });
  }, [watch('estado'), isSuperAdmin, isMunicipal, isPadraoEstabelecimento]);

  useEffect(() => {
    actionsMunicipioSelected();
    setPrincipalFilters((prev) => {
      return {
        ...prev,
        municipio: { options: optionsMunicipios, selected: watch('municipio') },
      };
    });
  }, [watch('municipio'), isSuperAdmin, isMunicipal, isPadraoEstabelecimento]);

  useEffect(() => {
    if (principalFilters.estado.selected.length > 0) {
      submitForm();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={3} mt="2px">
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={[
              { label: 'PE', value: 'PE' },
              { label: 'PR', value: 'PR' },
            ]}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Estado"
            required
            disabled={isEstadual || isMunicipal || isAcsEstabelecimento}
            setValue={setValue}
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('estado'),
                description: 'Estado',
                fieldName: 'estado',
              });
            }}
            inputName="estado"
            selectedOptions={watch('estado')}
            // setSelected
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch

            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={optionsMunicipios}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Município"
            required
            setValue={setValue}
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('municipio'),
                description: 'Município',
                fieldName: 'municipio',
              });
            }}
            loading={loadingMunicipios}
            inputName="municipio"
            selectedOptions={watch('municipio')}
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            disabled={
              disabledMunicipioInput || isMunicipal || isAcsEstabelecimento
            }
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={optionsEstabelecimentos}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Estabelecimento"
            required
            inputName="estabelecimentoId"
            setValue={setValue}
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('estabelecimentoId'),
                description: 'Estabelecimento',
                fieldName: 'estabelecimentoId',
              });
            }}
            selectedOptions={watch('estabelecimentoId')}
            loading={loadingEstabelecimentos}
            // setSelected
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            disabled={disabledEstabelecimentoInput || isAcsEstabelecimento}
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={optionsProfissional}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Agente Comunitário de Saúde (ACS)"
            inputName="acsId"
            required
            selectedOptions={watch('acsId')}
            setValue={setValue}
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('acsId'),
                description: 'Agente Comunitário de Saúde',
                fieldName: 'acsId',
              });
            }}
            loading={loadingAcs}
            disabled={disabledAcsInput || isAcsEstabelecimento}
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <Controller
            name="idadeInicio"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  options={[
                    '25 Anos',
                    '26 Anos',
                    '27 Anos',
                    'Fora da Faixa Etária',
                  ]}
                  value={value}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    handleSetFilter(
                      `Idade Início: ${newValue}`,
                      'idade_inicio'
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Idade Início" size="small" />
                  )}
                />
              );
            }}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <Controller
            name="idadeFim"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  options={['61 Anos', '62 Anos', '63 Anos']}
                  value={value}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    handleSetFilter(`Idade Fim: ${newValue}`, 'idade_fim');
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Idade Final" size="small" />
                  )}
                />
              );
            }}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={[
              { label: 'Pendentes', value: 'Pendentes' },
              { label: 'Não Atendidas', value: 'Não Atendidas' },
              { label: 'Agendada', value: 'Agendada' },
              { label: 'Não Elegíveis', value: 'Não Elegíveis' },
            ]}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Status"
            required
            inputName="status"
            setValue={setValue}
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('status'),
                description: 'Status',
                fieldName: 'status',
              });
            }}
            selectedOptions={watch('status')}
            // status
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            // disabled
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={[
              { label: '1º Convocação', value: '1º Convocação' },
              { label: '2º Convocação', value: '2º Convocação' },
              { label: '3º Convocação', value: '3º Convocação' },
              { label: 'Outras Convocações', value: 'Outras Convocações' },
            ]}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Convocação"
            required
            inputName="convocacao"
            setValue={setValue}
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('convocacao'),
                description: 'Convocação',
                fieldName: 'estado',
              });
            }}
            selectedOptions={watch('convocacao')}
            // setSelected
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            // disabled
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PeriodoInput control={control} handleSetFilter={handleSetFilter} />
        </Grid>
        <Grid xs={12} md={9.4} item>
          <SearchByNameInput
            control={control}
            // disableOtherInputs={disableOtherInputs}
            errors={errors}
            handleSetFilter={handleSetFilter}
          />
        </Grid>
        <Grid item xs={12} md={1.3}>
          <Button
            sx={{ padding: '6px 22px' }}
            variant="contained"
            type="submit"
            data-testid="submitFilterReportsHpv"
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} md={1.3}>
          <Button
            sx={{ padding: '6px 22px' }}
            variant="outlined"
            color="error"
            onClick={handleClearFilters}
            data-testid="clearFilterReportsHpv"
          >
            Limpar
          </Button>
        </Grid>
      </Grid>

      <DisplayFilter style={{ marginTop: '24px', marginBottom: '24px' }}>
        {!listFilterShow?.find((item) => item.value !== '') && (
          <p style={{ margin: 0, width: '413px', color: '#838383' }}>
            Os filtros selecionados aparecerão aqui.
          </p>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            gap: '10px',
            flexWrap: 'wrap',
          }}
        >
          {listFilterShow.map((item) => (
            <>
              {item.value ? (
                <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
              ) : undefined}
            </>
          ))}
        </div>
      </DisplayFilter>
    </form>
  );
};

export default FiltersConvocation;
