import {
  CalendarPlus,
  DisabledCalendarPlus,
} from '../../../../../../../../assets/imgs/Convocation/calendarPlus';

import moment from 'moment';
import dot from '../../../../../../../../assets/imgs/Convocation/dot.svg';
import connector from '../../../../../../../../assets/imgs/Convocation/connector.svg';

const ComponentSchedules = ({ schedule, objConvocation }) => {
  const isOtherConvocation =
    objConvocation?.tipo_ultima_convocatoria?.includes('Busca');
  const isScheduled = objConvocation?.status === 'Agendada';

  return (
    <>
      {schedule.confirmacao_presenca ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CalendarPlus />
          <p
            style={{
              fontSize: '16px',
              fontWeight: 700,
            }}
          >
            Agendamento
          </p>
          <div style={{ display: 'flex', width: '100%' }}>
            <img style={{ width: '12px' }} src={dot} />
            <p
              style={{
                fontWeight: 500,

                fontSize: '14px',
                margin: '0 0 0px 14px',
              }}
            >
              {isOtherConvocation || isScheduled
                ? 'Agendado para:'
                : 'Quis agendar?'}
            </p>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <img style={{ width: '12px', height: '35px' }} src={connector} />
            <p
              style={{
                fontWeight: 400,

                fontSize: '14px',
                margin: '0 0 0 14px',
                paddingTop: '7px',
                width: '196px',
              }}
            >
              {isOtherConvocation || isScheduled ? (
                <>
                  {' '}
                  {schedule.data
                    ? moment(schedule.data).format('DD/MM/YYYY')
                    : ''}
                </>
              ) : (
                <>
                  Sim, para o dia{' '}
                  {schedule.data
                    ? moment(schedule.data).format('DD/MM/YYYY')
                    : ''}{' '}
                  às
                  <br />
                  {moment(schedule.data).format('HH[h]mm')}
                </>
              )}
            </p>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <img style={{ width: '12px' }} src={dot} />
            <p
              style={{
                fontWeight: 500,

                fontSize: '14px',
                margin: '0 0 0px 14px',
              }}
            >
              Horário
            </p>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <img style={{ width: '12px', height: '35px' }} src={connector} />
            <p
              style={{
                fontWeight: 400,

                fontSize: '14px',
                margin: '0 0 0 14px',
                paddingTop: '7px',
                width: '196px',
              }}
            >
              {schedule.horario
                ? `${schedule.horario.split(':')[0]}h${
                    schedule.horario.split(':')[1]
                  }`
                : ''}
            </p>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <img style={{ width: '12px' }} src={dot} />
            <p
              style={{
                fontWeight: 400,
                fontSize: '14px',
                margin: '0 0 0px 14px',
              }}
            >
              Confirmou a presença?
            </p>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <img style={{ width: '12px', height: '35px' }} src={connector} />
            <p
              style={{
                fontWeight: 400,

                fontSize: '14px',
                margin: '0 0 0 14px',
                paddingTop: '7px',
              }}
            >
              {schedule.confirmacao_presenca !== null
                ? schedule.confirmacao_presenca === true
                  ? 'Sim'
                  : 'Não'
                : 'Confirmação ainda não enviada'}
              <br />
            </p>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '114px',
          }}
        >
          <DisabledCalendarPlus />
          <p
            style={{
              fontSize: '16px',

              fontWeight: 700,
            }}
          >
            Agendamento
          </p>
          <div style={{ display: 'flex', width: '100%' }}>
            <img style={{ width: '12px' }} src={dot} />
            <p
              style={{
                fontWeight: 500,

                fontSize: '14px',
                margin: '0 0 0px 14px',
              }}
            >
              {isOtherConvocation ? 'Agendado para:' : 'Quis agendar?'}
            </p>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <img style={{ width: '12px', height: '35px' }} src={connector} />
            <p
              style={{
                fontWeight: 400,

                fontSize: '14px',
                margin: '0 0 0 14px',
                paddingTop: '7px',
                width: '196px',
              }}
            >
              Em aguardo
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ComponentSchedules;
