import { toast } from 'react-toastify';
import api from '../../api';

export const getReportsHistopatologia = async ({ payload }) => {
  try {
    console.log('Making API request with params:', payload);
    const response = await api.get('/reports/visualizar/histopatologia', {
      params: payload,
    });

    if (response.status === 200) {
      console.log('API response:', response.data);
      return response.data;
    }

    return null;
  } catch (error: any) {
    console.error('API error:', error);
    toast.error(error.message, {
      position: 'bottom-right',
    });
    throw error;
  }
};
