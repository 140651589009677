import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';

interface PieChartProps {
  data: number[];
  categories?: string[] | string[][];
  height?: number;
  width?: number;
  colors: string[];
  labels?: string[];
  legendWidth?: number;
  startAngle?: number;
  endAngle?: number;
}

export function PieChart2({
  data,
  width,
  height,
  labels,
  colors,
  legendWidth,
  startAngle = -135,
  endAngle = 225,
}: PieChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();

  useEffect(() => {
    setChartData({
      series: data,
      chart: {
        width: width || 284,
        height,
        type: 'pie',
      },
      labels,
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              position: 'right',
              offsetY: 100,
              width: legendWidth || 140,
              labels: {
                colors: '#000000',
                useSeriesColors: false,
                style: {
                  fontSize: '14px',
                  fontFamily: 'Roboto, sans-serif',
                  fontWeight: 400,
                },
              },
            },
          },
        },
      ],
      colors,
      yaxis: {
        min: 0,
      },
      legend: {
        position: 'right',
        offsetY: 100,
        width: legendWidth || 140,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 1,
            minAngleToShowLabel: 0,
          },
          startAngle,
          endAngle,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
        formatter: function (val, options) {
          if (typeof val === 'number') {
            return `${val.toFixed()}%`;
          }
          return String(val);
        },
      },
    });
  }, [data, colors, labels, height, legendWidth, width, endAngle, startAngle]);

  return (
    <>
      {chartData ? (
        <ReactApexChart
          options={chartData}
          series={chartData.series}
          type={'pie'}
          height={height ?? 350}
        />
      ) : undefined}
    </>
  );
}

interface HistopathologyOriginProps {
  data: number[];
  labels: string[];
  colors?: string[];
  onChartClick?: (label: string) => void;
  estadosSelected?: string[];
  municipiosSelected?: string[];
  estabelecimentosSelected?: string[];
  period?: {
    periodo_inicio?: string;
    periodo_fim?: string;
  };
}

export function HistopathologyOrigin({
  data,
  labels,
  colors = ['#0079AD', '#01AEFA'],
  onChartClick,
}: HistopathologyOriginProps) {
  const [chartData, setChartData] = useState<ApexOptions>();

  useEffect(() => {
    setChartData({
      series: data,
      chart: {
        type: 'pie',
        height: 284,
        width: 284,
        events: {
          dataPointSelection: function (event, chartContext, config) {
            if (onChartClick && config.dataPointIndex >= 0 && labels) {
              // Extrai o texto antes do parênteses para remover o total
              const label = labels[config.dataPointIndex].split(' (')[0];
              console.log('HistopathologyOrigin clicked:', label);
              onChartClick(label);
            }
          },
        },
      },
      labels,
      colors,
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              position: 'right',
              offsetY: 100,
              width: 140,
              labels: {
                colors: '#000000',
                useSeriesColors: false,
                style: {
                  fontSize: '14px',
                  fontFamily: 'Roboto, sans-serif',
                  fontWeight: 400,
                },
              },
            },
          },
        },
      ],
      legend: {
        position: 'right',
        offsetY: 100,
        width: 140,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 1,
            minAngleToShowLabel: 0,
          },
          startAngle: -135,
          endAngle: 225,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
        formatter: function (val, options) {
          if (typeof val === 'number') {
            return `${val.toFixed()}%`;
          }
          return String(val);
        },
      },
    });
  }, [data, colors, labels, onChartClick]);

  return (
    <>
      {chartData ? (
        <ReactApexChart
          options={chartData}
          series={chartData.series}
          type="pie"
          height={284}
        />
      ) : null}
    </>
  );
}
