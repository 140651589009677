import { Button, Grid, TextField } from '@mui/material';
import { validateSearch } from '../../../../../utils/validateSearchInput';
import { SelectWithSearch } from '../../../../../components/SelectWithSearch';
import PeriodoInput from '../../../../../components/Inputs/PeriodoInput';
import SearchByNameInput from '../../../../../components/Inputs/SearchByNameInput';
import { DisplayFilter } from '../../List/styles';
import { useContext, useEffect, useState } from 'react';
import useActionsControlInputs from '../../../../../utils/hooks/useActionsControlInputs';
import { getCidades } from '../../../../../lib/getCidadesEstabelecimentos';
import { getEstabelecimentos } from '../../../../../lib/getEstabelecimentos';
import { PerfilContext } from '../../../../../context/PerfilContext';
import { SelectWithSearchV2 } from '../../../../../components/SelectWithSearchV2';
import {
  getCidadesConvocatoria,
  getEstabelecimentosConvocatoria,
} from '../../../../../lib/convocation';
import { LoadingContext } from '../../../../../context/LoadingContext';
const optionsEstado = [
  { label: 'PE', value: 'PE' },
  { label: 'PR', value: 'PR' },
];
const FiltersConvocationGeneralView = ({
  handleSubmit,
  control,
  clearErrors,
  setError,
  watch,
  errors,
  setValue,
  initialStateListFilter,
  listFilter,
  setListFilter,
  setListFilterShow,
  listFilterShow,
  setShowTableData,
  showTableData,
  handleSetFilter,
  optionsMunicipios,
  setOptionsMunicipios,

  optionsEstabelecimentos,
  setOptionsEstabelecimentos,
  setPrincipalFilters,
  handleSetSelectedOptionsInDisplay,
  loadGeneralView,
}) => {
  const [disabledEstadoInput, setDisabledEstadoInput] = useState(false);
  const [disabledMunicipioInput, setDisabledMunicipioInput] = useState(false);
  const [disabledEstabelecimentoInput, setDisabledEstabelecimentoInput] =
    useState(false);

  const [loadingMunicipios, setLoadingMunicipios] = useState(false);
  const [loadingEstabelecimentos, setLoadingEstabelecimentos] = useState(false);

  const submitForm = () => {
    const searchNameCpfCnsIsValid = validateSearch({
      clearErrors,
      setError,
      watch,
    });

    if (searchNameCpfCnsIsValid) {
      // setLoading(true);
      // setDataCentral([]);
      // setPayload().then((payload) => loadDataCentralNotification(payload));
      // setListFilterShow(listFilter);
    }
  };

  const {
    isSuperAdmin,
    isMunicipal,
    isPadraoEstabelecimento,
    isEstadual,
    isAcsEstabelecimento,
  } = useContext(PerfilContext);

  const { setLoading } = useContext(LoadingContext);

  const loadMunicipiosOptions = async ({ param }) => {
    setLoadingMunicipios(true);
    const optionsCidadesData = await getCidadesConvocatoria(param);

    setOptionsMunicipios(
      optionsCidadesData.map((cidade) => {
        return {
          label: cidade.nome,
          value: cidade.id,
        };
      })
    );
    setLoadingMunicipios(false);
  };

  const loadEstabelecimentosOptions = async ({ param }) => {
    setLoadingEstabelecimentos(true);
    const valueParam = optionsMunicipios.find(
      (municipio) => municipio.value == param
    )?.label;

    const optionsEstabelecimentosData = await getEstabelecimentosConvocatoria({
      municipio: valueParam,
    });

    setOptionsEstabelecimentos(
      optionsEstabelecimentosData.data
        .map((estabelecimento) => {
          return {
            label: estabelecimento.nome_fantasia,
            value: estabelecimento.id,
          };
        })
        .slice(0, 200)
    );

    setLoadingEstabelecimentos(false);
  };

  const {
    actionsEstadoSelected,
    actionsMunicipioSelected,
    actionsEstabelecimentoSelected,
  } = useActionsControlInputs({
    estadosSelected: watch('estado'),
    municipiosSelected: watch('municipio'),
    estabelecimentosSelected: watch('estabelecimentoId'),

    setValue,
    handleSetFilter,

    setDisabledEstadoInput,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,
    setDisabledAcsInput: () => {},

    loadMunicipiosOptions,
    loadEstabelecimentosOptions,
    loadAcsOptions: () => {},
  });

  const handleClearFilters = () => {
    setLoading(true);

    setListFilter(initialStateListFilter);
    setListFilterShow(initialStateListFilter);
    setValue('status', []);
    setValue('pesquisa', null);
    if (isSuperAdmin) {
      setValue('estado', []);
      loadGeneralView({
        clear: true,
        listParamsToRemove: ['estado', 'municipio', 'estabelecimentoId'],
      });
    }

    if (isEstadual) {
      setValue('municipio', []);
      loadGeneralView({
        clear: true,
        listParamsToRemove: ['municipio', 'estabelecimentoId'],
      });
    }

    if (isMunicipal) {
      setValue('estabelecimentoId', []);
      loadGeneralView({
        clear: true,
        listParamsToRemove: ['estabelecimentoId'],
      });
    }
  };

  const handleSetSelectedOptions =
    ({ setSelected, fieldName }) =>
    (options) => {
      setSelected(options);
      const optionLabel = () => {
        if (options.length === 0) {
          return '';
        }

        if (options.length === 1) {
          return `${fieldName}: ${options[0].label}`;
        }

        if (options.length > 1) {
          return `${fieldName}: Vários`;
        }
      };
      handleSetFilter(optionLabel(), 'resultado');
    };

  useEffect(() => {
    actionsEstadoSelected();
    setPrincipalFilters((prev) => {
      return {
        ...prev,
        estado: { options: optionsEstado, selected: watch('estado') },
      };
    });
  }, [watch('estado'), isSuperAdmin, isMunicipal, isPadraoEstabelecimento]);

  useEffect(() => {
    actionsMunicipioSelected();
    setPrincipalFilters((prev) => {
      return {
        ...prev,
        municipio: { options: optionsMunicipios, selected: watch('municipio') },
      };
    });
  }, [watch('municipio'), isSuperAdmin, isMunicipal, isPadraoEstabelecimento]);

  useEffect(() => {
    actionsEstabelecimentoSelected();
    setPrincipalFilters((prev) => {
      return {
        ...prev,
        estabelecimento: {
          options: optionsEstabelecimentos,
          selected: watch('estabelecimentoId'),
        },
      };
    });
  }, [
    watch('estabelecimentoId'),
    isSuperAdmin,
    isMunicipal,
    isPadraoEstabelecimento,
  ]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={3} mt="2px">
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={optionsEstado}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Estado"
            required
            disabled={isEstadual || isMunicipal || isAcsEstabelecimento}
            setValue={setValue}
            inputName="estado"
            selectedOptions={watch('estado')}
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('estado'),
                description: 'Estado',
                fieldName: 'estado',
              });
            }}
            // setSelected
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch

            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>

        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={optionsMunicipios}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Município"
            required
            setValue={setValue}
            loading={loadingMunicipios}
            inputName="municipio"
            selectedOptions={watch('municipio')}
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('municipio'),
                description: 'Município',
                fieldName: 'municipio',
              });
            }}
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            disabled={
              disabledMunicipioInput || isMunicipal || isAcsEstabelecimento
            }
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>

        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={optionsEstabelecimentos}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Estabelecimento"
            required
            inputName="estabelecimentoId"
            setValue={setValue}
            selectedOptions={watch('estabelecimentoId')}
            loading={loadingEstabelecimentos}
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('estabelecimentoId'),
                description: 'Estabelecimento',
                fieldName: 'estabelecimentoId',
              });
            }}
            // setSelected
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            disabled={disabledEstabelecimentoInput || isAcsEstabelecimento}
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>

        <Grid xs={12} md={4} item>
          <TextField label="Idade Início" select fullWidth size="small" />
        </Grid>

        <Grid xs={12} md={4} item>
          <TextField label="Idade Final" select fullWidth size="small" />
        </Grid>

        <Grid xs={12} md={4} item>
          <PeriodoInput control={control} handleSetFilter={handleSetFilter} />
        </Grid>

        <Grid xs={12} item>
          <SelectWithSearch
            // requestOptionsBySearch={(text) => {
            //   getProfissionaisList({ bySearchInput: false, nome: text });
            // }}
            loading={false}
            options={[]}
            isLaboratorio
            label="Agente Comunitário de Saúde (ACS)"
            disabled={false}
            selectedOptions={[]}
            setSelectedOptions={() => null}
            setSelected={handleSetSelectedOptions({
              setSelected: () => null,
              fieldName: 'Profissional Responsável pela Coleta',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
            // emptyCondition={estabelecimentosSelected.length !== 1}
            // emptyMessage="Selecione um Estabelecimento de Saúde"
          />
        </Grid>
        <Grid item xs={10}>
          <SearchByNameInput
            control={control}
            errors={errors}
            handleSetFilter={() => null}
          />
        </Grid>
        <Grid item xs={1}>
          <Button type="submit" variant="contained">
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button variant="outlined" color="error" onClick={handleClearFilters}>
            Limpar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DisplayFilter />
        </Grid>
      </Grid>
    </form>
  );
};

export default FiltersConvocationGeneralView;
