import { Box, Container, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useStyles } from './RegisterExam.style';
import { useLocation, Link } from 'react-router-dom';
import BoxInfoPatient from '../../components/BoxInfoPatient/BoxInfoPatient';
import { SideMenu } from '../../components/SideMenu';
import { Citologia } from './Exams/Citologia';
import { HpvForm } from './Exams/Hpv';
import { useEffect, useState } from 'react';
import Colposcopia from './Exams/Colposcopia';
import { Histopatologia } from './Exams/Histopatologia';
import CitologiaReflexo from './Exams/CitologiaReflexo';

interface StateProps {
  examType: string;
  patientData: {
    id: number;
  };
}

export const Exams = () => {
  const local = localStorage.getItem('stateHpv');
  const parsedLocalStorage = JSON.parse(String(local));

  const styles = useStyles();
  const location = useLocation();
  const state =
    parsedLocalStorage?.origin === 'notification'
      ? parsedLocalStorage
      : (location.state as StateProps);
  // const navigate = useNavigate();
  const [headerTitle, setHeaderTitle] = useState('');

  const renderExamForm = () => {
    switch (state.examType) {
      case 'citologia-rastreio':
        return <Citologia data={state} />;
      case 'citologia-reflexo':
        return (
          <CitologiaReflexo location={location} data={state} view={false} />
        );
      case 'hpv':
        return (
          <HpvForm
            data={state}
            patientId={state.patientData?.id}
            state={state}
          />
        );
      case 'colposcopia':
        return <Colposcopia />;
      case 'histopatologia':
        return <Histopatologia data={state} />;
      default:
        return <div>Exame não encontrado</div>;
    }
  };

  useEffect(() => {
    if (state.examType === 'citologia-rastreio') {
      setHeaderTitle('Citologia - Como Rastreio');
    } else if (state.examType === 'citologia-reflexo') {
      setHeaderTitle('Citologia - Reflexo (após teste de HPV Positivo)');
    } else if (state.examType === 'hpv') {
      setHeaderTitle('Teste de HPV');
    } else if (state.examType === 'colposcopia') {
      setHeaderTitle('Colposcopia');
    } else if (state.examType === 'histopatologia') {
      setHeaderTitle('Histopatologia');
    }
  }, [state?.examType]);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <Box
            mb={3}
            justifyContent="space-between"
            display="flex"
            flexWrap="wrap"
          >
            <div>
              <Typography
                variant="h4"
                fontWeight="bold"
                style={{ marginBottom: '5px' }}
                id="header-title"
              >
                Exame: {headerTitle}
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
              >
                <Link key="1" color="inherit" to="/pacientes">
                  Listagem de Pacientes
                </Link>
                <Link
                  className={styles.link}
                  key="1"
                  color="inherit"
                  to={`/pacientes/${state.patientData?.id ?? ''}`}
                >
                  Ficha Individual da Paciente
                </Link>
                <Typography key="3" color="text.primary">
                  Exame: {headerTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </Box>

          {state.examType !== 'citologia-reflexo' && (
            <Box
              mb={3}
              sx={{
                '@media screen and (min-width: 1366px)': {
                  width: state.examType === 'hpv' ? '1200px' : '100%',
                },
              }}
            >
              <BoxInfoPatient
                data={state.patientData}
                viewButton={false}
              ></BoxInfoPatient>
            </Box>
          )}

          {renderExamForm()}
        </Box>
      </Container>
    </SideMenu>
  );
};
