import { createContext, useEffect } from 'react';
import { getUserToken } from '../lib/auth';

interface PerfilContextValue {
  getPermissao: () => string;
  hasPermission: (p: string | string[]) => boolean;
  isSuperAdmin?: boolean;
  isEstadual?: boolean;
  isMunicipal?: boolean;
  isAdminLaboratorio?: boolean;
  isPadraoEstabelecimento?: boolean;
  isAcsEstabelecimento?: boolean;
  isMedicoLaboratorio?: boolean;
  isOutrosLaboratorio?: boolean;
  estabelecimentoIdUser?: string;
  estabelecimentoNameUser?: string;
  municipioUserId?: string;
  municipioUserName?: string;
  estadoUserSigla?: string;
  userName?: string;
  userCpf?: string;
}

export const PerfilContext = createContext<PerfilContextValue>({
  getPermissao: () => {
    return '';
  },
  hasPermission: (p) => false,
});

function PerfilProvider({ children }) {
  const userToken = getUserToken() as any;
  const {
    permissao_atual: {
      contexto: contextoPermissao,
      id: idPermissao,
      nome: nomePermissao,
    },
    estabelecimentoId: estabelecimentoIdUser,
    estabelecimento_saude: {
      nome_fantasia: estabelecimentoNameUser,
      endereco: {
        municipio: {
          id: municipioUserId,
          nome: municipioUserName,
          estadoSigla: estadoUserSigla,
        },
      },
    },
    pessoa: { nome: userName, cpf: userCpf },
  } = userToken ?? {
    permissao_atual: {},
    pessoa: {},
    estabelecimento_saude: { endereco: { municipio: {} } },
  };

  const isSuperAdmin = nomePermissao === 'SuperAdmin';
  const isEstadual = nomePermissao === 'Admin Estadual';
  const isMunicipal = nomePermissao === 'Admin Municipal';
  const isAdminLaboratorio = nomePermissao === 'Admin Estabelecimento';
  const isPadraoEstabelecimento = contextoPermissao === 'UNIDADE DE SAÚDE';
  const isAcsEstabelecimento = nomePermissao === 'ACS';

  const isMedicoLaboratorio = idPermissao === 7;
  const isOutrosLaboratorio = idPermissao === 8;

  // const [perfilState, setPerfilState] = useState();
  // const perfil = getUserToken().permissao_atual?.nome;

  const getPermissao = () => {
    let permissao;

    if (getUserToken().permissoes?.includes('SUPER_ADMIN')) {
      permissao = 'isSuperAdmin';
    }

    if (getUserToken().permissao_atual?.nome === 'Padrão') {
      permissao = 'isPadrao';
    }

    if (
      getUserToken().permissoes?.includes('ZONE_MUNICIPIO') &&
      getUserToken().permissao_atual?.nome !== 'Padrão' &&
      !getUserToken().permissoes?.includes('SUPER_ADMIN')
    ) {
      permissao = 'isMunicipal';
    }

    if (
      getUserToken().permissoes?.includes('ZONE_ESTADO') &&
      !getUserToken().permissoes?.includes('SUPER_ADMIN')
    ) {
      permissao = 'isEstadual';
    }

    return permissao;
    // const isSuperAdmin = getUserToken().permissoes??.includes('SUPER_ADMIN');

    // const isPadrao = perfil === 'Padrão';

    // const isMunicipial =
    //   getUserToken()?.permissoes??.includes('ZONE_MUNICIPIO') &&
    //   !isPadrao &&
    //   !isSuperAdmin;

    // const isEstadual =
    //   getUserToken()?.permissoes??.includes('ZONE_ESTADO') && !isSuperAdmin;
  };
  const hasPermission = (permission: string | string[]) => {
    if (getUserToken().permissoes?.includes('SUPER_ADMIN')) {
      return true;
    }
    if (typeof permission === 'object') {
      return !!permission?.find((p) => getUserToken().permissoes?.includes(p));
    }

    return getUserToken().permissoes?.includes(permission);
  };

  useEffect(() => {}, [userToken]);

  return (
    <PerfilContext.Provider
      value={{
        getPermissao,
        hasPermission,
        isSuperAdmin,
        isEstadual,
        isMunicipal,
        isAdminLaboratorio,
        isPadraoEstabelecimento,
        isMedicoLaboratorio,
        isAcsEstabelecimento,
        isOutrosLaboratorio,
        estabelecimentoIdUser,
        estabelecimentoNameUser,
        municipioUserId,
        municipioUserName,
        estadoUserSigla,
        userName,
        userCpf,
      }}
    >
      {children}
    </PerfilContext.Provider>
  );
}

export default PerfilProvider;
