import { Button, Chip, Collapse, Paper, Tooltip } from '@mui/material';
import { CardDetailPatient } from './CardDetailPatientConvocationStyle';

import './CardDetailPatientConvocationStyles.scss';
import { ExpandIconWrapper } from '../../../../../components/CardExam/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';

import DetailTestRealized from './Details/DetailTestRealized';
import DetailConcluded from './Details/DetailConcluded';
import DetailInProgress from './Details/DetailInProgress';
import DetailCanceled from './Details/DetailCanceled';
import moment from 'moment';
import DetailOutConvocation from './Details/DetailOutConvocation';
import { formatCpf } from '../../../../../utils/formatters';
import DetailScheduled from './Details/DetailScheduled';
import DynamicHeaders from './Details/Components/DynamicHeaders';
import DynamicRows from './Details/Components/DynamicRows';
import ResumeOutConvocation from './Resumes/ResumeOutConvocation';
import { getLabelFormatedLastConvocation } from '../Utils/getLabelFormatedLastConvocation';
import ResumeInProgress from './Resumes/ResumeInProgress';
import ResumeScheduled from './Resumes/ResumeScheduled';
import ResumeConcluded from './Resumes/ResumeConcluded';
import ResumeTestRealized from './Resumes/ResumeTestRealized';
import ResumeCanceled from './Resumes/ResumeCanceled';

export const CardDetailPatientConvocation = ({
  patientName,
  cpf,
  convocation,
  dtAgendamento,
  dtResult,
  status,
  index,
  opened,
  setOpenedCard,
  attendedAt,
  resultConvocation,
  confirmPresent,
  estado,
  municipio,
  estabelecimento,
  objConvocation,
}: any) => {
  // console.log(objConvocation);
  const colorChip = {
    Concluída: '#7BC67E',
    'Teste Realizado': '#64B6F7',
    'Em Andamento': '#F6B681',
    Cancelada: '#F88078',
    'Fora da Convocatória': '#7986CB',
    Agendada: '#e188b9',
  };

  const labelChip = {
    1: 'Concluída',
    2: 'Teste Realizado',
    3: 'Em Andamento',
    4: 'Cancelada',
  };

  const getLabelChip = (label) => {
    if (label === 'Colposcopia' || label === 'Histopatologia') {
      return 'Disponivel';
    }

    if (label === 'Intraepitelial LIEAG' && status === 'COLETADO') {
      return 'Resultado Disponível';
    }

    if (label === 'Intraepitelial LIEAG' && status === 'FALHA') {
      return 'Excluir Registro';
    }
    // return statusJson[status || 'FALHA'].label;
  };

  return (
    <CardDetailPatient borderLeftColor="#238884">
      <>
        {status === 'Cancelada' && (
          <ResumeCanceled
            objConvocation={objConvocation}
            opened={opened}
            cpf={cpf}
            patientName={patientName}
            status={status}
            setOpenedCard={setOpenedCard}
            index={index}
          />
        )}
        {status === 'Em Andamento' && (
          <ResumeInProgress
            objConvocation={objConvocation}
            opened={opened}
            cpf={cpf}
            patientName={patientName}
            status={status}
            setOpenedCard={setOpenedCard}
            index={index}
          />
        )}
        {status === 'Fora da Convocatória' && (
          <ResumeOutConvocation
            objConvocation={objConvocation}
            opened={opened}
            cpf={cpf}
            patientName={patientName}
            status={status}
            setOpenedCard={setOpenedCard}
            index={index}
          />
        )}

        {status === 'Agendada' && (
          <ResumeScheduled
            objConvocation={objConvocation}
            opened={opened}
            cpf={cpf}
            patientName={patientName}
            status={status}
            setOpenedCard={setOpenedCard}
            index={index}
          />
        )}
        {status === 'Concluída' && (
          <ResumeConcluded
            objConvocation={objConvocation}
            opened={opened}
            cpf={cpf}
            patientName={patientName}
            status={status}
            setOpenedCard={setOpenedCard}
            index={index}
          />
        )}
        {status === 'Teste Realizado' && (
          <ResumeTestRealized
            objConvocation={objConvocation}
            opened={opened}
            cpf={cpf}
            patientName={patientName}
            status={status}
            setOpenedCard={setOpenedCard}
            index={index}
          />
        )}
      </>

      <Collapse
        in={opened && status === 'Concluída'}
        sx={{ width: '100%' }}
        timeout="auto"
        unmountOnExit
      >
        <DetailConcluded
          convocationsList={convocation}
          objConvocation={objConvocation}
        />
      </Collapse>

      <Collapse
        in={opened && status === 'Teste Realizado'}
        sx={{ width: '100%' }}
        timeout="auto"
        unmountOnExit
      >
        <DetailTestRealized
          convocationsList={convocation}
          objConvocation={objConvocation}
        />
      </Collapse>
      <Collapse
        in={opened && status === 'Em Andamento'}
        sx={{ width: '100%' }}
        timeout="auto"
        unmountOnExit
      >
        <DetailInProgress
          convocationsList={convocation}
          objConvocation={objConvocation}
        />
      </Collapse>

      <Collapse
        in={opened && status === 'Agendada'}
        sx={{ width: '100%' }}
        timeout="auto"
        unmountOnExit
      >
        <DetailScheduled
          convocationsList={convocation}
          objConvocation={objConvocation}
        />
      </Collapse>

      <Collapse
        in={opened && status === 'Fora da Convocatória'}
        sx={{ width: '100%' }}
        timeout="auto"
        unmountOnExit
      >
        <DetailOutConvocation
          convocationsList={convocation}
          objConvocation={objConvocation}
        />
      </Collapse>
      <Collapse
        in={opened && status === 'Cancelada'}
        sx={{ width: '100%' }}
        timeout="auto"
        unmountOnExit
      >
        <DetailCanceled
          convocationsList={convocation}
          objConvocation={objConvocation}
        />
      </Collapse>
    </CardDetailPatient>
  );
};
