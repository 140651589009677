import moment from 'moment';

export const formatedMultiples = (arraySelecteds) => {
  if (Array.isArray(arraySelecteds)) {
    return arraySelecteds.length > 0
      ? arraySelecteds
          .map((item) => {
            if (item.value) {
              return item.value;
            } else {
              return item;
            }
          })
          .join(', ')
      : undefined;
  }
};

export const formatDate = (date: any) => {
  return date ? moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD') : undefined;
};
