/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { Star } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Badge,
  Box,
  ButtonBase,
  Collapse,
  ListItemButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/imgs/logo.png';
import atendimentoIcon from '../../assets/imgs/Atendimento.svg';
import administracaoIcon from '../../assets/imgs/AdminPanelSettings.svg';
import documentoIcon from '../../assets/imgs/TextSnippet.svg';
import homeIcon from '../../assets/imgs/Home.svg';
import paineisIcon from '../../assets/imgs/Paineis.svg';
import PhoneIphoneIcon from '../../assets/imgs/PhoneIphone.svg';
import relatorioIcon from '../../assets/imgs/FileCopy.svg';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import chatTeardropDotsIcon from '../../assets/imgs/Icon-ChatTeardropDots.svg';
import './styles.scss';
import useLogout from './hooks/useLogout';
import SelectEstablishment from '../SelectEstablishment';
import { PerfilContext } from '../../context/PerfilContext';
import { AlertListContext } from '../../context/AlertListContext';
import { NotificationBox } from '../NotificationBox';
import NotificationModal from '../NotificationModal';
import { getUserToken } from '../../lib/auth';
import { useTheme } from '@emotion/react';
import { HelperBox } from '../HelperBox';
import useRequestFilter from '../../pages/CentralNotification/hooks/useRequestFilter';
import {
  formatedMultiples,
  formatDate,
} from '../../utils/requestUtils/formatMultiples';
import { FiltersCentralNotificationContext } from '../../context/FiltersCentralNotificationContext';
import Template from '../Template';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface Props {
  children: React.ReactChild;
}

export const SideMenu = ({ children }: Props) => {
  const timeoutLogoutRef = useRef<number>(null);
  const [open, setOpen] = React.useState(false);
  const [painelMenuOpen, setPainelMenuOpen] = React.useState(false);

  const [menuOpenAdministration, setMenuOpenAdministration] =
    React.useState(false);
  const [menuRelatoriosOpen, setMenuRelatoriosOpen] = React.useState(false);
  const [innerWidth, setInnerWidth] = React.useState<number>(0);
  const [showHelperModal, setShowHelperModal] = React.useState(false);
  const [menuOpenAtendimento, setMenuOpenAtendimento] = React.useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [alertId, setAlertId] = React.useState(0);
  const [clickOnNotification, setClickOnNotification] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const idUser = getUserToken()?.permissao_atual?.id;

  const userIsLaboratorioOutros = idUser === 8;

  const {
    alertList,
    setPage,
    showNotificationList,
    setShowNotificationList,
    loadingNotifications,
    alertListQuantity,
    reloadAlertList,
    setDataCentral,
  } = React.useContext(AlertListContext);

  const { hasPermission } = React.useContext(PerfilContext);

  const notificationListRef = useRef<any>(null);

  const navigate = useNavigate();

  const { automaticLogoutFunction } = useLogout({ timeoutLogoutRef, navigate });

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleResize = () => {
    setInnerWidth(window.innerWidth);
  };

  const { permissao_atual } = getUserToken();

  const profilesThatViewDashboards = [2, 3, 4, 5, 6];
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, [innerWidth]);
  useEffect(() => {
    automaticLogoutFunction({ isMountComponent: true });
    return () => {
      automaticLogoutFunction({ isMountComponent: false });
    };
  }, []);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleNavigate = (url: string) => {
    navigate(url);
  };

  const isLogged = !!localStorage.getItem('isLogged');

  const location = useLocation();

  const { clearFiltersCentralNotification } = React.useContext(
    FiltersCentralNotificationContext
  );

  useEffect(() => {
    if (location.pathname !== '/central-notificacao') {
      clearFiltersCentralNotification();
    }
  }, [location.pathname]);

  const UpArrow = () => (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8.99316L6 14.9932L7.41 16.4032L12 11.8232L16.59 16.4032L18 14.9932L12 8.99316Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
  const DownArrow = () => (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'rotate(180deg)' }}
    >
      <path
        d="M12 8.99316L6 14.9932L7.41 16.4032L12 11.8232L16.59 16.4032L18 14.9932L12 8.99316Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );

  function notificationsLabel(count: number) {
    if (count === 0) {
      return 'Sem notificações';
    }
    if (count > 99) {
      return 'Mais de 99 notificações';
    }
    return `${count} notificações`;
  }

  const listRef = useRef(null);
  const sentinelRef = useRef(null);

  useEffect(() => {
    const element = listRef.current;
    const sentinel = sentinelRef.current;

    if (element && sentinel) {
      const iObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setPage((prev) => prev + 1);
            }
          });
        },
        {
          root: element,
          rootMargin: '0px',
          threshold: 0.1,
        }
      );

      iObserver.observe(sentinel);

      return () => {
        iObserver.disconnect();
      };
    }
  }, [showNotificationList]);

  const handleNotificationList = () => {
    if (alertList?.length > 0) {
      // console.log(alertList)
      return (
        <div
          id="element"
          ref={listRef}
          style={{
            height: '300px',
            overflowY: 'scroll',
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <ul style={{ padding: 0 }}>
            {alertList.map((alert) => {
              return (
                <NotificationBox
                  key={alert.id}
                  alertId={alert.id}
                  id={alert.user_notify.id}
                  idExam={alert?.notify_data?.hpv_id ?? null}
                  idPessoa={alert?.notify_data?.pessoa?.nome}
                  nomeDoPaciente={alert?.notify_data?.pessoa?.nome}
                  setClicked={setClickOnNotification}
                  setModalOpen={setModalIsOpen}
                  setAlertId={setAlertId}
                  id_exame={alert?.resultadoHPV?.hpv?.id}
                  id_pessoa={alert?.notify_data?.pessoa_id}
                  typeNotification={alert?.type?.name}
                />
              );
            })}
          </ul>

          <div
            ref={sentinelRef}
            style={{ height: '20px', backgroundColor: 'transparent' }}
          />
        </div>
      );
    }
    return;
  };

  const handleNotificationIconClick = () => {
    setShowNotificationList(!showNotificationList);
  };

  const handleHelperIconClick = () => {
    setShowHelperModal((prev) => !prev);
  };

  useEffect(() => {
    if (showNotificationList) {
      setShowNotificationList(false);
      setClickOnNotification(false);
    }
  }, [clickOnNotification]);

  useEffect(() => {
    if (showNotificationList) {
      setShowNotificationList(false);
      setClickOnNotification(false);
    }
  }, [alertList]);

  const handleClickOutside = (event) => {
    if (
      notificationListRef.current &&
      !notificationListRef.current.contains(event.target) &&
      !event.target.closest('#element')
    ) {
      setTimeout(() => {
        setShowNotificationList(false);
      }, 300);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isLogged) {
      navigate('/');
      return;
    }
    handleResize();
  }, []);

  const { loadDataCentralNotification } = useRequestFilter({
    setDataCentral,
  });

  const {
    dataNotificacaoFinal,
    dataNotificacaoIni,
    selectedOptionsStatus,
    selectedOptionsNotificationType,
    selectedOptionsStatusLidas,
  } = React.useContext(FiltersCentralNotificationContext);

  useEffect(() => {
    if (!modalIsOpen) {
      reloadAlertList();

      loadDataCentralNotification({
        status: formatedMultiples(selectedOptionsStatus),
        type_ids: formatedMultiples(selectedOptionsNotificationType),
        date_start: formatDate(dataNotificacaoIni),
        date_end: formatDate(dataNotificacaoFinal),
        page: 1,
        size: 25,
      });
    }
  }, [modalIsOpen]);

  return (
    <Template>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{
            backgroundColor: '#238884',
            padding: '0 16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              // height: '100px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!open ? (
                <IconButton
                  data-testid="hamburguerMenu"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerClose}
                  edge="start"
                  sx={{ mr: 2 }}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <SelectEstablishment />
            </Box>
            <span>
              <ButtonBase
                data-testid="helperCtxBtn"
                aria-label={'central de ajuda'}
                className="helper"
                color="secondary"
                sx={{
                  backgroundColor: '#fff',
                  padding: '16px',
                  borderRadius: '500px',
                  height: '50px',
                  minWidth: '50px',
                  gap: '8px',
                  ':hover': { opacity: 0.8 },
                }}
                onMouseDown={() => handleHelperIconClick()}
              >
                <Box sx={{ display: innerWidth < 760 ? 'none' : 'inline' }}>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: '#238884',
                    }}
                  >
                    Central de ajuda
                  </p>
                </Box>
                <img
                  src={chatTeardropDotsIcon}
                  alt="Home"
                  style={{ color: '#238884', width: '24px', height: '24px' }}
                />
              </ButtonBase>
              <HelperBox
                enabled={showHelperModal}
                setEnabled={setShowHelperModal}
              />
              <IconButton
                ref={notificationListRef}
                aria-label={notificationsLabel(alertList?.length)}
                className="notification"
                onMouseDown={() => handleNotificationIconClick()}
              >
                <Badge
                  badgeContent={alertListQuantity}
                  color="error"
                  sx={{
                    '.MuiBadge-anchorOriginTopRightRectangular': {
                      top: '10px',
                      right: '5px',
                    },
                  }}
                >
                  <NotificationsIcon
                    sx={{ color: 'white', width: '35px', height: '35px' }}
                  />
                </Badge>
              </IconButton>
              <Button
                variant="text"
                data-testid="logoutButton"
                endIcon={<LogoutIcon />}
                onClick={() => {
                  handleNavigate('/');
                  localStorage.removeItem('@jornada-ccu/auth');
                  localStorage.removeItem('isLogged');
                  localStorage.removeItem('bG9naW4=');
                }}
                style={{ color: '#fff' }}
              >
                Sair
              </Button>
            </span>
            {showNotificationList && (
              <div className="notificationList">
                <h1
                  style={{
                    color: 'black',
                    fontSize: '20px',
                    textAlign: 'start',
                    margin: 0,
                  }}
                >
                  Notificações
                </h1>
                {handleNotificationList()}
                <div className="centralNotificationButton">
                  <button
                    type="button"
                    onClick={() => navigate('/central-notificacao')}
                  >
                    Central de notificação
                  </button>
                </div>
              </div>
            )}
          </Box>

          <NotificationModal
            isOpen={modalIsOpen}
            closeModal={setModalIsOpen}
            alertId={alertId}
            fromCentral={false}
          />
        </AppBar>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 2,
              }}
            >
              <img
                src={logo}
                alt="Log"
                width="70%"
                style={{ borderRadius: 4 }}
              />
            </Box>
          </DrawerHeader>
          <List></List>
          <List>
            <Link
              data-testid="homeSideButton"
              to="/home"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItemButton onClick={() => handleNavigate('/home')}>
                <ListItemIcon>
                  <img
                    src={homeIcon}
                    alt="Home"
                    style={{ width: '24px', height: '24px' }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    marginLeft: '-16px',
                  }}
                  primary="Home"
                />
              </ListItemButton>
              <Divider />
            </Link>

            <ListItemButton onClick={() => handleNavigate('/convocatoria')}>
              <ListItemIcon>
                <img
                  src={PhoneIphoneIcon}
                  alt="Convocatória"
                  style={{ width: '24px', height: '24px' }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  paddingTop: '12px',
                  paddingBottom: '12px',
                  marginLeft: '-16px',
                }}
                primary="Convocatória"
              />
            </ListItemButton>
            <Divider />

            <ListItemButton
              onClick={() => setMenuOpenAtendimento(!menuOpenAtendimento)}
              data-testid="atendimentoButton"
            >
              <ListItemIcon>
                <img
                  src={atendimentoIcon}
                  alt="Atendimento"
                  style={{ width: '24px', height: '24px' }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  paddingTop: '12px',
                  paddingBottom: '12px',
                  marginLeft: '-16px',
                }}
                primary="Atendimento"
              />
              {menuOpenAtendimento ? <UpArrow /> : <DownArrow />}
            </ListItemButton>
            <Divider />

            <Collapse in={menuOpenAtendimento} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {hasPermission('VIEW_AGENDA') && (
                  <>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleNavigate('/agenda-digital')}
                    >
                      <ListItemIcon>
                        <Star sx={{ color: 'transparent' }} />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          paddingTop: '12px',
                          paddingBottom: '12px',
                          marginLeft: '-56px',
                        }}
                        primary="Agendas"
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}

                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleNavigate('/pacientes')}
                  data-testid="atendimentoPacienteButton"
                >
                  <ListItemIcon>
                    <Star sx={{ color: 'transparent' }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      paddingTop: '12px',
                      paddingBottom: '12px',
                      marginLeft: '-56px',
                    }}
                    primary="Pacientes"
                  />
                </ListItemButton>
                <Divider />
              </List>
            </Collapse>

            {hasPermission('VIEW_DASHBOARD') &&
              profilesThatViewDashboards.includes(permissao_atual?.id) && (
                <>
                  <ListItemButton
                    data-testid="paineisButton"
                    onClick={() => setPainelMenuOpen(!painelMenuOpen)}
                  >
                    <ListItemIcon>
                      <img
                        src={paineisIcon}
                        alt="Paineis"
                        style={{ width: '24px', height: '24px' }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        marginLeft: '-16px',
                      }}
                      primary="Paineis"
                    />
                    {painelMenuOpen ? <UpArrow /> : <DownArrow />}
                  </ListItemButton>
                  <Divider />
                </>
              )}

            <Collapse in={painelMenuOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* {hasPermission('VIEW_CONVOCACAO') && (
                <>
                  <ListItemButton
                    onClick={() => handleNavigate('/rastreamento/hpv')}
                    sx={{ pl: 4 }}
                    data-testid="painelRastreamentoButton"
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      style={{
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        marginLeft: '-56px',
                      }}
                      primary="Painel de Rastreamento para Testes de HPV"
                    />
                  </ListItemButton>
                  <Divider />
                </>
              )} */}

                {hasPermission('VIEW_DASHBOARD_HPV') && (
                  <>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      data-testid="painelTesteButton"
                      onClick={() => handleNavigate('/paineis/teste-hpv')}
                    >
                      <ListItemIcon>
                        <Star sx={{ color: 'transparent' }} />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          paddingTop: '12px',
                          paddingBottom: '12px',
                          marginLeft: '-56px',
                        }}
                        primary="Painel do Teste de HPV"
                      />
                    </ListItemButton>

                    <Divider />
                  </>
                )}

                {hasPermission('VIEW_DASHBOARD_CITOLOGIA') && (
                  <>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() =>
                        handleNavigate('/paineis/teste-citologia-reflexo')
                      }
                    >
                      <ListItemIcon>
                        <Star sx={{ color: 'transparent' }} />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          paddingTop: '12px',
                          paddingBottom: '12px',
                          marginLeft: '-56px',
                        }}
                        primary="Painel de Citologia"
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}

                {hasPermission('VIEW_DASHBOARD_COLPOSCOPIA') && (
                  <>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() =>
                        handleNavigate('/paineis/teste-colposcopia')
                      }
                    >
                      <ListItemIcon>
                        <Star sx={{ color: 'transparent' }} />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          paddingTop: '12px',
                          paddingBottom: '12px',
                          marginLeft: '-56px',
                        }}
                        primary="Painel de Colposcopia"
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}

                {hasPermission('VIEW_DASHBOARD_HISTOPATOLOGIA') && (
                  <>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() =>
                        handleNavigate('/paineis/teste-histopatologia')
                      }
                    >
                      <ListItemIcon>
                        <Star sx={{ color: 'transparent' }} />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          paddingTop: '12px',
                          paddingBottom: '12px',
                          marginLeft: '-56px',
                        }}
                        primary="Painel de Histopatologia"
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}
              </List>
            </Collapse>

            {hasPermission([
              // MENU PAINEIS E RELATORIOS
              'VIEW_DASHBOARD',
              'VIEW_RELATORIO_HPV',
              'VIEW_RELATORIO_CONVOCATORIA',
              'CREATE_EXAME_CITOLOGIA',
              'VIEW_RELATORIO_CITOLOGIA',
              'VIEW_RELATORIO_COLPOSCOPIA',
              'VIEW_RELATORIO_HISTOPATOLOGIA',
              'VIEW_RELATORIO_CONSOLIDADO',
            ]) &&
              !userIsLaboratorioOutros && (
                <>
                  <ListItemButton
                    data-testid="relatóriosButton"
                    onClick={() => setMenuRelatoriosOpen(!menuRelatoriosOpen)}
                  >
                    <ListItemIcon>
                      <img
                        src={relatorioIcon}
                        alt="Relatórios"
                        style={{ width: '24px', height: '24px' }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        marginLeft: '-16px',
                      }}
                      primary="Relatórios"
                    />
                    {menuOpen ? <UpArrow /> : <DownArrow />}
                  </ListItemButton>

                  <Collapse
                    in={menuRelatoriosOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {hasPermission('VIEW_RELATORIO_HPV') && (
                        <>
                          <ListItemButton
                            data-testid="relatorioGeralButton"
                            onClick={() => {
                              handleNavigate('/relatorios/hpv');
                              localStorage.removeItem('requestFromTable');
                            }}
                            // sx={{ pl: 4 }}
                            // onClick={() => handleNavigate('/panels')}
                          >
                            <ListItemText
                              style={{
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                marginLeft: '40px',
                              }}
                              primary="Relatório Geral dos Testes de HPV"
                            />
                          </ListItemButton>
                        </>
                      )}

                      {/* {getUserToken().permissao_atual?.contexto !==
                      'LABORATÓRIO' && (
                        <ListItemButton
                          data-testid="relatorioRastreamentoButton"
                          onClick={() => {
                            localStorage.removeItem('requestFromTable');
                            handleNavigate('/relatorios/rastreamento-hpv');
                          }}
                        // sx={{ pl: 4 }}
                        // onClick={() => handleNavigate('/panels')}
                        >
                          <ListItemText
                            style={{
                              paddingTop: '12px',
                              paddingBottom: '12px',
                              marginLeft: '40px',
                            }}
                            primary="Relatório Geral dos Testes de HPV"
                          />
                        </ListItemButton>

                      )} */}

                      <List component="div" disablePadding>
                        <ListItemButton
                          data-testid="relatorioRastreamentoButton"
                          onClick={() => {
                            localStorage.removeItem('requestFromTable');
                            handleNavigate('/relatorios/rastreamento-hpv');
                          }}
                          // sx={{ pl: 4 }}
                          // onClick={() => handleNavigate('/panels')}
                        >
                          <ListItemText
                            style={{
                              paddingTop: '12px',
                              paddingBottom: '12px',
                              marginLeft: '40px',
                            }}
                            primary="Relatório de Rastreamento para Testes de HPV"
                          />
                        </ListItemButton>

                        <ListItemButton
                          data-testid="relatorioRastreamentoButton"
                          onClick={() => {
                            localStorage.removeItem('requestFromTable');
                            handleNavigate('/relatorios/consolidado');
                          }}
                          // sx={{ pl: 4 }}
                          // onClick={() => handleNavigate('/panels')}
                        >
                          <ListItemText
                            style={{
                              paddingTop: '12px',
                              paddingBottom: '12px',
                              marginLeft: '40px',
                            }}
                            primary="Relatório Consolidado"
                          />
                        </ListItemButton>
                        {hasPermission('VIEW_RELATORIO_HPV') && (
                          <ListItemButton
                            data-testid="relatorioRastreamentoButton"
                            onClick={() => {
                              localStorage.removeItem('requestFromTable');
                              handleNavigate('/relatorios/consolidado');
                            }}
                            // sx={{ pl: 4 }}
                            // onClick={() => handleNavigate('/panels')}
                          >
                            <ListItemText
                              style={{
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                marginLeft: '40px',
                              }}
                              primary="Relatório Consolidado"
                            />
                          </ListItemButton>
                        )}

                        {hasPermission('VIEW_RELATORIO_CITOLOGIA') && (
                          <ListItemButton
                            onClick={() => {
                              localStorage.removeItem('requestFromTable');
                              handleNavigate('/relatorios/citologia-reflexo');
                            }}
                          >
                            <ListItemText
                              style={{
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                marginLeft: '40px',
                              }}
                              primary="Relatório Geral de Exames de Citologia - Reflexo"
                            />
                          </ListItemButton>
                        )}

                        {hasPermission('VIEW_RELATORIO_HISTOPATOLOGIA') && (
                          <ListItemButton
                            onClick={() => {
                              localStorage.removeItem('requestFromTable');
                              handleNavigate('/relatorios/histopatologia');
                            }}
                          >
                            <ListItemText
                              style={{
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                marginLeft: '40px',
                              }}
                              primary="Relatório Geral de Histopatologia"
                            />
                          </ListItemButton>
                        )}

                        {hasPermission('VIEW_RELATORIO_COLPOSCOPIA') && (
                          <ListItemButton
                            onClick={() => {
                              localStorage.removeItem('requestFromTable');
                              handleNavigate('/relatorios/colposcopia');
                            }}
                          >
                            <ListItemText
                              style={{
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                marginLeft: '40px',
                              }}
                              primary="Relatório Geral de Colposcopia"
                            />
                          </ListItemButton>
                        )}

                        {hasPermission('VIEW_RELATORIO_INTRAEPITELIAL ') && (
                          <ListItemButton
                            onClick={() => {
                              localStorage.removeItem('requestFromTable');
                              handleNavigate(
                                '/relatorios/lesoes-intraepiteliais'
                              );
                            }}
                          >
                            <ListItemText
                              style={{
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                marginLeft: '40px',
                              }}
                              primary="Relatório de Tratamento das Lesões Intraepiteliais"
                            />
                          </ListItemButton>
                        )}

                        {/* {hasPermission('VIEW_RELATORIO_CONVOCATORIA') && (
                    <>
                      <ListItemButton
                        onClick={() =>
                          handleNavigate('/relatorios/convocatoria')
                        }
                      >
                        <ListItemText
                          style={{
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            marginLeft: '40px',
                          }}
                          primary="Relatório de Convocatória"
                        />
                      </ListItemButton>
                    </>
                  )} */}
                      </List>
                    </List>
                  </Collapse>
                </>
              )}

            {/* <ListItemButton onClick={() => {}}>
            <ListItemIcon>
              <img
                src={documentoIcon}
                alt="Ajuda"
                style={{ width: '24px', height: '24px' }}
              />
            </ListItemIcon>
            <ListItemText
              style={{
                paddingTop: '12px',
                paddingBottom: '12px',
                marginLeft: '-16px',
              }}
              primary="Ajuda"
            />
            <DownArrow />
          </ListItemButton> */}
            <Divider />

            <Collapse in={false} timeout="auto" unmountOnExit>
              <List component="div" disablePadding></List>
            </Collapse>

            <ListItemButton
              onClick={() => setMenuOpenAdministration(!menuOpenAdministration)}
            >
              <ListItemIcon>
                <img
                  src={administracaoIcon}
                  alt="Administração"
                  style={{ width: '24px', height: '24px' }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  paddingTop: '12px',
                  paddingBottom: '12px',
                  marginLeft: '-16px',
                }}
                primary="Administração"
              />
              {menuOpenAdministration ? <UpArrow /> : <DownArrow />}
            </ListItemButton>
            <Collapse in={menuOpenAdministration} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {hasPermission('VIEW_ESTABELECIMENTO') && (
                  <>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() =>
                        handleNavigate('/gerenciar-estabelecimentos')
                      }
                    >
                      <ListItemIcon>
                        <Star sx={{ color: 'transparent' }} />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          paddingTop: '12px',
                          paddingBottom: '12px',
                          marginLeft: '-56px',
                        }}
                        primary="Gerenciar Estabelecimentos de Saúde"
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}

                {hasPermission('CREATE_PROFISSIONAL') && (
                  <>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleNavigate('/profissionais')}
                    >
                      <ListItemIcon>
                        <Star sx={{ color: 'transparent' }} />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          paddingTop: '12px',
                          paddingBottom: '12px',
                          marginLeft: '-56px',
                        }}
                        primary="Cadastrar Profissional"
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}

                {hasPermission('VIEW_USUARIO') && (
                  <>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleNavigate('/usuarios')}
                    >
                      <ListItemIcon>
                        <Star sx={{ color: 'transparent' }} />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          paddingTop: '12px',
                          paddingBottom: '12px',
                          marginLeft: '-56px',
                        }}
                        primary="Gerenciamento de Usuários"
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}

                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleNavigate('/gerenciar-conta')}
                >
                  <ListItemIcon>
                    <Star sx={{ color: 'transparent' }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      paddingTop: '12px',
                      paddingBottom: '12px',
                      marginLeft: '-56px',
                    }}
                    primary="Gerenciar Conta"
                  />
                </ListItemButton>
                <Divider />
              </List>
            </Collapse>

            <Divider />
            <Collapse in={menuOpenAdministration} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {hasPermission('VIEW_ESTABELECIMENTO') && (
                  <>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() =>
                        handleNavigate('/gerenciar-estabelecimentos')
                      }
                    >
                      <ListItemIcon>
                        <Star sx={{ color: 'transparent' }} />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          paddingTop: '12px',
                          paddingBottom: '12px',
                          marginLeft: '-56px',
                        }}
                        primary="Gerenciar Estabelecimentos de Saúde"
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}

                {hasPermission('CREATE_PROFISSIONAL') && (
                  <>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleNavigate('/profissionais')}
                    >
                      <ListItemIcon>
                        <Star sx={{ color: 'transparent' }} />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          paddingTop: '12px',
                          paddingBottom: '12px',
                          marginLeft: '-56px',
                        }}
                        primary="Cadastrar Profissional"
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}

                {hasPermission('VIEW_USUARIO') && (
                  <>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleNavigate('/usuarios')}
                    >
                      <ListItemIcon>
                        <Star sx={{ color: 'transparent' }} />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          paddingTop: '12px',
                          paddingBottom: '12px',
                          marginLeft: '-56px',
                        }}
                        primary="Gerenciamento de Usuários"
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}

                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleNavigate('/gerenciar-conta')}
                >
                  <ListItemIcon>
                    <Star sx={{ color: 'transparent' }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      paddingTop: '12px',
                      paddingBottom: '12px',
                      marginLeft: '-56px',
                    }}
                    primary="Gerenciar Conta"
                  />
                </ListItemButton>
                <Divider />
              </List>
            </Collapse>

            <ListItemButton onClick={() => setMenuOpen(!menuOpen)}>
              <ListItemIcon>
                <img
                  src={documentoIcon}
                  alt="Documentação"
                  style={{ width: '24px', height: '24px' }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  paddingTop: '12px',
                  paddingBottom: '12px',
                  marginLeft: '-16px',
                }}
                primary="Documentação"
              />
              {menuOpen ? <UpArrow /> : <DownArrow />}
            </ListItemButton>
            <Divider />

            <Collapse in={menuOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <Star sx={{ color: 'transparent' }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      paddingTop: '12px',
                      paddingBottom: '12px',
                      marginLeft: '-56px',
                    }}
                    primary="Sobre o Jordana"
                  />
                </ListItemButton>
                <Divider />

                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleNavigate('/termos-de-uso')}
                >
                  <ListItemIcon>
                    <Star sx={{ color: 'transparent' }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      paddingTop: '12px',
                      paddingBottom: '12px',
                      marginLeft: '-56px',
                    }}
                    primary="Termos de Uso"
                  />
                </ListItemButton>
                <Divider />

                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleNavigate('/politica-de-privacidade')}
                >
                  <ListItemIcon>
                    <Star sx={{ color: 'transparent' }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      paddingTop: '12px',
                      paddingBottom: '12px',
                      marginLeft: '-56px',
                    }}
                    primary="Política de Privacidade"
                  />
                </ListItemButton>
                <Divider />
              </List>
            </Collapse>
          </List>
        </Drawer>
        <Main open={open} sx={{ overflowX: 'hidden' }}>
          <DrawerHeader />
          {children}
        </Main>
      </Box>
    </Template>
  );
};
