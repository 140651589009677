import { Button, Grid, Paper } from '@mui/material';
import { SelectWithSearch } from '../../../../../components/SelectWithSearch';
import SearchByNameInput from '../../../../../components/Inputs/SearchByNameInput';
import { DisplayFilter, ItemsDisplayFilter } from '../../List/styles';
import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from '../../../../../context/LoadingContext';
import { PerfilContext } from '../../../../../context/PerfilContext';
import useActionsControlInputs from '../../../../../utils/hooks/useActionsControlInputs';
import { validateSearch } from '../../../../../utils/validateSearchInput';
import {
  getCidadesConvocatoria,
  getConvocationsHistoric,
  getEstabelecimentosConvocatoria,
} from '../../../../../lib/convocation';
import { SelectWithSearchV2 } from '../../../../../components/SelectWithSearchV2';

const optionsEstado = [
  { label: 'PE', value: 'PE' },
  { label: 'PR', value: 'PR' },
];

const optionsStatus = [
  { label: 'Agendada', value: '7' },
  { label: 'Cancelada', value: '5' },
  { label: 'Concluída', value: '1' },
  { label: 'Em Andamento', value: '3, 4' },
  { label: 'Fora da Convocatória', value: '6' },
  { label: 'Teste Realizado', value: '2' },
];
export const FiltersHistoric = ({
  setPayload,
  setValue,
  handleSubmit,
  control,
  clearErrors,
  watch,
  setError,
  errors,
  initialStateListFilter,
  listFilter,
  setListFilter,
  setListFilterShow,
  listFilterShow,
  optionsMunicipios,
  setOptionsMunicipios,
  estadosOptions,
  setEstadosOptions,
  optionsEstabelecimentos,
  setOptionsEstabelecimentos,
  setTableData,
  setPrincipalFilters,
  setLoadingList,
  handleSetFilter,
  handleSetSelectedOptionsInDisplay,
  loadHistoric,
}) => {
  const [disabledEstadoInput, setDisabledEstadoInput] = useState(false);
  const [disabledMunicipioInput, setDisabledMunicipioInput] = useState(false);
  const [disabledEstabelecimentoInput, setDisabledEstabelecimentoInput] =
    useState(false);

  const [loadingMunicipios, setLoadingMunicipios] = useState(false);
  const [loadingEstabelecimentos, setLoadingEstabelecimentos] = useState(false);

  const { setLoading } = useContext(LoadingContext);
  const {
    isSuperAdmin,
    isMunicipal,
    isPadraoEstabelecimento,
    isEstadual,
    isAcsEstabelecimento,
  } = useContext(PerfilContext);

  const loadMunicipiosOptions = async ({ param }) => {
    setLoadingMunicipios(true);
    const optionsCidadesData = await getCidadesConvocatoria(param);

    setOptionsMunicipios(
      optionsCidadesData.map((cidade) => {
        return {
          label: cidade.nome,
          value: cidade.id,
        };
      })
    );
    setLoadingMunicipios(false);
  };

  const loadEstabelecimentosOptions = async ({ param }) => {
    setLoadingEstabelecimentos(true);
    const valueParam = optionsMunicipios.find(
      (municipio) => municipio.value == param
    )?.label;

    const optionsEstabelecimentosData = await getEstabelecimentosConvocatoria({
      municipio: valueParam,
    });

    setOptionsEstabelecimentos(
      optionsEstabelecimentosData.data
        .map((estabelecimento) => {
          return {
            label: estabelecimento.nome_fantasia,
            value: estabelecimento.id,
          };
        })
        .slice(0, 200)
    );

    setLoadingEstabelecimentos(false);
  };

  const handleClearFilters = () => {
    setTableData({});
    setLoadingList(true);
    setLoading(true);

    setListFilter(initialStateListFilter);
    setListFilterShow(initialStateListFilter);
    setValue('status', []);
    setValue('pesquisa', null);
    if (isSuperAdmin) {
      setValue('estado', []);
      loadHistoric({
        clear: true,
        listParamsToRemove: [
          'estado',
          'municipio',
          'estabelecimentoId',
          'status',
          'pesquisa',
        ],
      });
    }

    if (isEstadual) {
      setValue('municipio', []);
      loadHistoric({
        clear: true,
        listParamsToRemove: [
          'municipio',
          'estabelecimentoId',
          'status',
          'pesquisa',
        ],
      });
    }

    if (isMunicipal) {
      setValue('estabelecimentoId', []);
      loadHistoric({
        clear: true,
        listParamsToRemove: ['estabelecimentoId', 'status', 'pesquisa'],
      });
    }
  };

  const {
    actionsEstadoSelected,
    actionsMunicipioSelected,
    actionsEstabelecimentoSelected,
  } = useActionsControlInputs({
    estadosSelected: watch('estado'),
    municipiosSelected: watch('municipio'),
    estabelecimentosSelected: watch('estabelecimentoId'),

    setValue,
    handleSetFilter,

    setDisabledEstadoInput,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,
    setDisabledAcsInput: () => {},

    loadMunicipiosOptions,
    loadEstabelecimentosOptions,
    loadAcsOptions: () => {},
  });

  const submitForm = () => {
    setTableData(undefined);
    setLoadingList(true);
    const searchNameCpfCnsIsValid = validateSearch({
      clearErrors,
      setError,
      watch,
    });

    if (searchNameCpfCnsIsValid) {
      setLoading(true);
      setTableData(undefined);
      setListFilterShow(listFilter);
      loadHistoric({});
    }
  };

  useEffect(() => {
    actionsEstadoSelected();
    setPrincipalFilters((prev) => {
      return {
        ...prev,
        estado: { options: optionsEstado, selected: watch('estado') },
      };
    });
  }, [watch('estado'), isSuperAdmin, isMunicipal, isPadraoEstabelecimento]);

  useEffect(() => {
    actionsMunicipioSelected();
    setPrincipalFilters((prev) => {
      return {
        ...prev,
        municipio: { options: optionsMunicipios, selected: watch('municipio') },
      };
    });
  }, [watch('municipio'), isSuperAdmin, isMunicipal, isPadraoEstabelecimento]);

  useEffect(() => {
    actionsEstabelecimentoSelected();
    setPrincipalFilters((prev) => {
      return {
        ...prev,
        estabelecimento: {
          options: optionsEstabelecimentos,
          selected: watch('estabelecimentoId'),
        },
      };
    });
  }, [
    watch('estabelecimentoId'),
    isSuperAdmin,
    isMunicipal,
    isPadraoEstabelecimento,
  ]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={3}>
          <SelectWithSearchV2
            options={optionsEstado}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Estado"
            required
            disabled={isEstadual || isMunicipal || isAcsEstabelecimento}
            setValue={setValue}
            inputName="estado"
            selectedOptions={watch('estado')}
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('estado'),
                description: 'Estado',
                fieldName: 'estado',
              });
            }}
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            customPadding="20px"
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectWithSearchV2
            options={optionsMunicipios}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Município"
            required
            setValue={setValue}
            loading={loadingMunicipios}
            inputName="municipio"
            selectedOptions={watch('municipio')}
            customPadding="20px"
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('municipio'),
                description: 'Município',
                fieldName: 'municipio',
              });
            }}
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            disabled={
              disabledMunicipioInput || isMunicipal || isAcsEstabelecimento
            }
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectWithSearchV2
            options={optionsEstabelecimentos}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Estabelecimento"
            required
            inputName="estabelecimentoId"
            setValue={setValue}
            selectedOptions={watch('estabelecimentoId')}
            customPadding="20px"
            loading={loadingEstabelecimentos}
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('estabelecimentoId'),
                description: 'Estabelecimento',
                fieldName: 'estabelecimentoId',
              });
            }}
            // setSelected
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            disabled={disabledEstabelecimentoInput || isAcsEstabelecimento}
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectWithSearchV2
            options={optionsStatus}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Status"
            required
            // disabled={isEstadual || isMunicipal || isAcsEstabelecimento}
            customPadding="20px"
            setValue={setValue}
            inputName="status"
            selectedOptions={watch('status')}
            setDisplayFilter={() => {
              handleSetSelectedOptionsInDisplay({
                selectedOptions: watch('status'),
                description: 'Status',
                fieldName: 'status',
              });
            }}
            // setSelected
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch

            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid item xs={12} md={9.4}>
          <SearchByNameInput
            control={control}
            errors={{}}
            handleSetFilter={handleSetFilter}
          />
        </Grid>
        <Grid item xs={12} md={1.3}>
          <Button variant="contained" type="submit">
            FILTRAR
          </Button>
        </Grid>
        <Grid item xs={12} md={1.3}>
          <Button onClick={handleClearFilters} variant="outlined" color="error">
            LIMPAR
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DisplayFilter style={{ marginTop: '24px', marginBottom: '24px' }}>
            {!listFilterShow?.find((item) => item.value !== '') && (
              <p style={{ margin: 0, width: '413px', color: '#838383' }}>
                Os filtros selecionados aparecerão aqui.
              </p>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                gap: '10px',
                flexWrap: 'wrap',
              }}
            >
              {listFilterShow.map((item) => (
                <>
                  {item.value ? (
                    <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                  ) : undefined}
                </>
              ))}
            </div>
          </DisplayFilter>
        </Grid>
      </Grid>
    </form>
  );
};
