import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { useContext, useState } from 'react';
import { AlertInfo } from '../../../../components/AlertInfo';
import { Label } from '../../../../components/Typo';
import { PeriodList, Periods } from '../periodList';
import { ProfessionalSchedule } from '../..';
import { Controller, useForm } from 'react-hook-form';
import { postProfessionalSchedule } from '../../../../lib/professionalSchedule';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { getUserToken } from '../../../../lib/auth';
import { LoadingContext } from '../../../../context/LoadingContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';

interface CreateNewAgendaProps {
  handleClickCancel: ({ origin }) => void;
  selectedProfessional: any;
  handleSetProfessionalSchedule: (professionalSchedule) => void;
  savedProfessionalSchedule: ProfessionalSchedule[];
  professionalSchedule: any;
  setNoHasSchedule: (value) => void;
}

const interval = [
  '20 min',
  '30 min',
  '40 min',
  '60 min',
  '80 min',
  '100 min',
  '120 min',
];

export const CreateNewAgenda = ({
  handleClickCancel,
  selectedProfessional,
  handleSetProfessionalSchedule,
  setNoHasSchedule,
}: CreateNewAgendaProps) => {
  const [, setIntervalBetweenCalls] = useState('');
  const [periods, setPeriods] = useState<Periods>({
    seg_hours: [],
    ter_hours: [],
    qua_hours: [],
    qui_hours: [],
    sex_hours: [],
  });

  const {
    handleSubmit: submitForm,
    control,
    watch,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  const handleIntervalBetweenCallsChange = (event: SelectChangeEvent) => {
    setIntervalBetweenCalls(event.target.value as string);
  };

  const { setLoading } = useContext(LoadingContext);

  const currentEstablishmentId = localStorage.getItem('id_last_establishment');

  const perfilProfissional = getUserToken().permissao_atual?.nome;

  const validateIfHasPeriodSetted = () => {
    let hasPeriodsSetted = false;
    for (const period in periods) {
      if (periods[period].length > 0) {
        hasPeriodsSetted = true;
      }
    }

    const noContainPeriodsSetted =
      !!Object.keys(periods)
        .map((period) => periods[period])
        .find((item) => item.length > 0) === false;

    if (hasPeriodsSetted === false || noContainPeriodsSetted) {
      setError('periods', {
        type: 'manual',
        message: 'Campo Obrigatório.',
      });
    }
  };

  const handleSubmit = async (data) => {
    const hasHourInvalid = !!Array.from(
      document.getElementsByClassName('Mui-error')
    )?.find((element) => element.textContent === 'Horário inválido');

    if (hasHourInvalid) {
      return;
    }
    setLoading(true);
    const intervalFormated = data.intervalo?.replace(/[a-zA-Z\s]/g, '');

    try {
      const response = await postProfessionalSchedule({
        profissionalId: selectedProfessional.idProfissional,
        estabelecimentoSaudeId:
          selectedProfessional?.estabelecimentoProfissional,
        procedimento: 'Teste de HPV',
        n_dias: data.n_dias,
        intervalo: intervalFormated,
        idade_min: data.idade_min,
        idade_max: data.idade_max,
        sexo: data.sexo,
        periods,
        ativo: true,
        start_at: data.start_at || new Date(),
      });

      if (response.status !== 201) {
        toast.error(response.data.message, {
          position: 'bottom-right',
        });
        setNoHasSchedule(false);
      } else {
        toast.success('Agenda cadastrada!', {
          position: 'bottom-right',
        });
        handleClickCancel({ origin: 'submit' });
      }

      handleSetProfessionalSchedule({
        profissionalId: selectedProfessional.idProfissional,
        estabelecimentoSaudeId: currentEstablishmentId,
        procedimento: 'Teste de HPV',
        n_dias: data.n_dias,
        intervalo: data.intervalo,
        idade_min: data.idade_min,
        idade_max: data.idade_max,
        sexo: data.sexo,
        periods,
        ativo: true,
        start_at: data.start_at || new Date(),
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (savedProfessionalSchedule.length) {
  //     setPeriods(savedProfessionalSchedule[0].periods);
  //   }
  // }, [savedProfessionalSchedule]);

  return (
    <form onSubmit={submitForm(handleSubmit)} noValidate>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <Label labelcolor="rgba(0, 0, 0, 0.87)">Profissional</Label>
          <Typography variant="body1" mt={1}>
            {selectedProfessional?.nomeProfissional}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Label labelcolor="rgba(0, 0, 0, 0.87)">Procedimento</Label>
          <Typography variant="body1" mt={1}>
            Teste de HPV
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Controller
              control={control}
              name="intervalo"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  options={interval}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                  }}
                  value={value}
                  renderInput={(params) => (
                    <StyledAsteriskTextField
                      {...params}
                      required
                      error={!!errors.intervalo}
                      helperText={errors?.intervalo?.message}
                      size="small"
                      fullWidth
                      label="Tempo de atendimento para cada paciente"
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          display={'flex'}
          alignItems={'center'}
          gap={2}
        >
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <Controller
                control={control}
                name="start_at"
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <DatePicker
                      mask="__/__/____"
                      onChange={onChange}
                      value={value ? value : null}
                      renderInput={(params) => (
                        <StyledAsteriskTextField
                          {...params}
                          required
                          error={!!errors?.start_at}
                          helperText={errors?.start_at?.message}
                          style={{ minWidth: 220 }}
                          label="Data de início"
                          size="small"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </div>
          <Controller
            control={control}
            name="n_dias"
            render={({ field: { onChange, value } }) => (
              <StyledAsteriskTextField
                required
                label="Quantidade de dias futuros pela liberação da agenda"
                size="small"
                variant="outlined"
                fullWidth
                placeholder="ex: 15"
                onChange={onChange}
                inputMode="numeric"
                type="number"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="sexo"
            render={({ field: { onChange, value } }) => (
              <FormControl onChange={onChange} error={!!errors?.sexo}>
                <Label
                  mb={1}
                  labelcolor={
                    !!errors?.sexo ? '#cc0000' : 'rgba(0, 0, 0, 0.87)'
                  }
                >
                  Restrição por sexo <span style={{ color: '#cc0000' }}>*</span>
                </Label>
                <RadioGroup
                  row
                  aria-labelledby="restriction-by-gender-label"
                  name="restriction-by-gender"
                >
                  <FormControlLabel
                    value="N"
                    control={<Radio />}
                    label="Não restringir"
                  />
                  <FormControlLabel
                    value="F"
                    control={<Radio />}
                    label="Apenas feminino"
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio />}
                    label="Apenas Masculino"
                  />
                </RadioGroup>
                <FormHelperText>{errors?.sexo?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Label
            mb={1}
            labelcolor={
              !!errors?.idade_min || !!errors?.idade_max
                ? '#cc0000'
                : 'rgba(0, 0, 0, 0.87)'
            }
          >
            Restrição por idade <span style={{ color: '#cc0000' }}>*</span>
          </Label>

          <Box
            display="flex"
            justifyContent="space-evenly"
            width="220px"
            gap={2}
          >
            <Controller
              control={control}
              name="idade_min"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Entre:"
                  size="small"
                  variant="outlined"
                  placeholder="ex: 12"
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="idade_max"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="E:"
                  size="small"
                  variant="outlined"
                  placeholder="ex: 60"
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <FormHelperText sx={{ color: '#D32F2F' }}>
            {errors?.idade_min?.message || errors?.idade_max?.message}
          </FormHelperText>
        </Grid>
      </Grid>

      <Box mt={3} gap={3} display="flex" alignItems="center" flexWrap="wrap">
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          color={!!errors?.periods ? '#cc0000' : ''}
        >
          Configuração de Horários <span style={{ color: '#cc0000' }}>*</span>
        </Typography>

        <AlertInfo text="Recomendamos que os períodos inseridos sejam múltiplos do tempo de atendimento para cada paciente" />
      </Box>
      <FormHelperText sx={{ color: '#D32F2F' }}>
        {errors?.periods?.message}
      </FormHelperText>

      <PeriodList
        periods={periods}
        setPeriods={setPeriods}
        setValue={setValue}
        clearErrors={clearErrors}
        interval={getValues().intervalo?.replace(/[a-zA-Z\s]/g, '')}
      />

      <Box mt={3} display="flex" justifyContent="space-between">
        <Button
          type="submit"
          variant="contained"
          startIcon={<CheckIcon />}
          onClick={() => validateIfHasPeriodSetted()}
        >
          Salvar agenda
        </Button>
        <Button
          onClick={() => handleClickCancel({ origin: 'close' })}
          variant="outlined"
          color="error"
          startIcon={<CloseIcon />}
        >
          Cancelar
        </Button>
      </Box>
    </form>
  );
};
