import { Button, Grid } from '@mui/material';
import ComponentHpv from '../ComponentHpvs';
import moment from 'moment';

const ListHpvs = ({
  hpvs,
  showAllHpvs,
  setShowAllHpvs,
  // conditionToDisableImg,
  objConvocation,
}) => {
  const hasSchedules = objConvocation?.agendamentos?.length > 0;

  const initialDateConvocation = moment(objConvocation?.convocacao_data_inicio);

  const hasTestHpvBeforeConvocation =
    hpvs?.filter((hpv) => {
      const dateHpv = moment(hpv.created_at);

      return dateHpv.isBefore(initialDateConvocation);
    })?.length > 0;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '246px',
        justifyContent: 'flex-start',
      }}
    >
      <Grid container>
        <ComponentHpv hpv={hpvs[0]} objConvocation={objConvocation} />
        {hpvs.length > 1 && (
          <>
            {showAllHpvs && (
              <>
                {hpvs.map((hpv, index) => {
                  if (index > 0) {
                    return (
                      <ComponentHpv hpv={hpv} objConvocation={objConvocation} />
                    );
                  }
                })}
              </>
            )}
          </>
        )}

        {!showAllHpvs && (
          <>
            {hpvs.length > 1 && (
              <Button
                onClick={() => setShowAllHpvs(true)}
                sx={{
                  height: '22px',
                  fontSize: '13px',
                  width: '100%',
                  marginTop: '12px',
                  color: '#212121',
                }}
              >
                + VER TODOS 'TESTES' ({hpvs.length})
              </Button>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default ListHpvs;
