import {
  formatDate,
  formatedMultiples,
} from '../../../../../../utils/requestUtils/formatMultiples';

const useSetPayload = ({
  watch,
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  acsId,
}) => {
  const setPayload = () => {
    let payload: any;
    return new Promise((resolve, reject) => {
      payload = {
        estado: formatedMultiples(estadosSelected),
        municipio: formatedMultiples(municipiosSelected),
        estabelecimentoId: formatedMultiples(estabelecimentosSelected),
        acsId: formatedMultiples(acsId),
        idadeInicio: watch('idadeInicio')?.split(' ')[0],
        idadeFim: watch('idadeFim')?.split(' ')[0],
        page: 1,
        limit: watch('items'),
        pesquisa: watch('search'),
      };

      resolve(payload);
    });
  };

  return {
    setPayload,
  };
};

export default useSetPayload;
