import { getLabelFormatedLastConvocation } from '../../../Utils/getLabelFormatedLastConvocation';
import DynamicHeaders from '../../Details/Components/DynamicHeaders';
import DynamicRows from '../../Details/Components/DynamicRows';

const ResumeInProgress = ({
  objConvocation,
  opened,
  patientName,
  cpf,
  status,
  setOpenedCard,
  index,
}) => {
  const hasAgendaConvocacao = objConvocation.agendamentos.length > 0;
  return (
    <>
      <table className="table-detail-patient">
        <thead>
          <tr>
            <DynamicHeaders
              opened={opened}
              HeadersWhenHided={() => (
                <>
                  <>
                    <th>Convocação</th>
                    <th>Número estava correto?</th>
                    <th>Quis agendar?</th>
                  </>
                </>
              )}
            />
          </tr>
        </thead>
        <tbody>
          <tr>
            <DynamicRows
              status={status}
              setOpenedCard={setOpenedCard}
              inde={index}
              objConvocation={objConvocation}
              patientName={patientName}
              cpf={cpf}
              index={index}
              opened={opened}
              firstRowConfig={{
                text: getLabelFormatedLastConvocation(
                  objConvocation.tipo_ultima_convocatoria
                ).text,
                tooltip: getLabelFormatedLastConvocation(
                  objConvocation.tipo_ultima_convocatoria
                ).tooltip,
              }}
              secondRowConfig={{
                text: objConvocation.numero_estava_correto ? 'Sim' : 'Não',
              }}
              thirdRowConfig={{
                text: 'Em aguardo',
              }}
            />
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ResumeInProgress;
