import api from '../api';

type TMedico = {
  estabelecimentoId?: string;
};
// Orientações de rotas anteriores para o caminho de 'Agendamento':
// - Baseado nas rotas de 'Agenda Cidadão':
//   1. Pegar os médicos da unidade em: /cidadao_agenda/estabelecimento/{id}
//   2. Pegar os horários disponíveis para o médico em: /cidadao_agenda/horarios-disponiveis
// Depois destes passos, seguir esta rota para atualizar com novo Agendamento.

const optionsFetch = (method, token) => {
  return {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
};

export async function getEstabelecimento({ estabelecimentoId }: TMedico) {
  return await api
    .get(`/cidadao_agenda/estabelecimento/${estabelecimentoId}`)
    .then((res) => {
      return res;
    })
    .catch((e: any) => {
      return e;
    });
}

export async function getHoursAvaliable() {
  const token = localStorage.getItem('token_paciente');

  return await fetch(
    `${process.env.REACT_APP_API_URL}/agenda-convocacao/dados-agenda`,
    optionsFetch('GET', token)
  )
    .then((res) => {
      return res.json();
    })
    .catch((e: any) => {
      return e;
    });
}

export async function getDataCitizen() {
  const token = localStorage.getItem('token_paciente');

  return await fetch(
    `${process.env.REACT_APP_API_URL}/agenda-convocacao/dados-paciente`,
    optionsFetch('GET', token)
  )
    .then((res) => {
      return res.json();
    })
    .catch((e: any) => {
      return e;
    });
}

// Defina um tipo para a resposta da verificação do token
interface TokenVerificationResponse {
  valido: boolean;
  mensagem?: string;
}

export async function ValidateCode(code: string): Promise<TokenVerificationResponse> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth-externo/verificar/${code}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      
      // Tenta converter a resposta para JSON
      const data = await response.json().catch(() => {
        // Se não for possível obter o JSON, usa o status HTTP para determinar a validade
        if (response.status === 200) {
          return { valido: true } as TokenVerificationResponse;
        } else {
          return { 
            valido: false,
            mensagem: 'Seu link não existe ou está inválido. Em caso de dúvidas Procure o Agente Comunitário de Saúde ou o Posto de Saúde.'
          } as TokenVerificationResponse;
        }
      });
      
      // Retorna os dados com o tipo adequado
      resolve(data as TokenVerificationResponse);
    } catch (error) {
      // Em caso de erro na requisição, retorna um objeto com valido: false
      resolve({
        valido: false,
        mensagem: 'Seu link não existe ou está inválido. Em caso de dúvidas Procure o Agente Comunitário de Saúde ou o Posto de Saúde.'
      });
    }
  });
}

export async function LoginAgenda({ token, dataNascimento, identificador }) {
  return new Promise(async (resolve, reject) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/auth-externo`, {
        body: JSON.stringify({ token, dataNascimento, identificador }),
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then((res) => resolve(res.json()));
    } catch (error) {
      resolve(error);
    }
  });
}

export const postAgendamento = async ({
  medicoId,
  data,
  horario,
  statusAgendamento,
}) => {
  try {
    const token = localStorage.getItem('token_paciente');
    return await fetch(
      `${process.env.REACT_APP_API_URL}/agenda-convocacao/agendamento`,
      {
        ...optionsFetch('POST', token),
        body: JSON.stringify({
          medicoId,
          data,
          horario,
          statusAgendamento,
        }),
      }
    )
      .then((res) => {
        return res;
      })
      .catch((e: any) => {
        return e;
      });
  } catch (error) {}
};

export const notAcceptSubmit = async ({ motivo, observacao, filaEspera }) => {
  try {
    const token = localStorage.getItem('token_paciente');
    return await fetch(
      `${process.env.REACT_APP_API_URL}/agenda-convocacao/nao-realizar`,
      {
        ...optionsFetch('POST', token),
        body: JSON.stringify({
          motivo,
          observacao,
          filaEspera,
        }),
      }
    )
      .then((res) => {
        return res.status !== 201 ? res.json() : res;
      })
      .catch((e: any) => {
        console.log('hey');
        return e;
      });
  } catch (error) {}
};

export const download = async () => {
  try {
    const token = localStorage.getItem('token_paciente');
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/agenda-convocacao/gerar-comprovante`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/pdf',
        },
      }
    );

    if (!response.ok) {
      throw new Error('Erro ao baixar o arquivo');
    }

    // Converte a resposta para um Blob
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Erro ao baixar o arquivo:', error);
    throw error;
  }
};
