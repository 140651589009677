import { useContext, useEffect, useState } from 'react';
import { ScreenBackgroundLogin } from '../../SignIn/style';
import { BackgroundContext } from '../../../context/BackgroundContext';
import logoJordana from '../../../assets/imgs/logo.png';
import { Box, Typography } from '@mui/material';
import { FormContainer } from './style';
import { useForm } from 'react-hook-form';
import TypePersonalData from './TypePessoalData';
import { LoginAgenda, ValidateCode } from '../../../lib/getCidadaoAgenda';
import { LoadingContext } from '../../../context/LoadingContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const LoginAgendaCitizen = () => {
  const [step, setStep] = useState('personal_data');
  const [codeIsValid, setCodeIsValid] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMessageServer, setErrorMessageServer] = useState<any>();
  const {
    control,
    watch,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { backgroundImage } = useContext(BackgroundContext);
  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

// Fragmento da função validateCode com tipagem adequada
const validateCode = async () => {
  setLoading(true);
  try {
    const response = await ValidateCode(code || '');
    
    // Agora o TypeScript sabe que response tem a propriedade valido
    if (response.valido) {
      setCodeIsValid(true);
    } else {
      setCodeIsValid(false);
      setErrorMessage(response.mensagem || 'Seu link não existe ou está inválido. Em caso de dúvidas Procure o Agente Comunitário de Saúde ou o Posto de Saúde.');
    }
  } catch (error: any) {
    setCodeIsValid(false);
    setErrorMessage('Seu link não existe ou está inválido. Em caso de dúvidas Procure o Agente Comunitário de Saúde ou o Posto de Saúde.');
    console.error('Erro ao validar código:', error);
  } finally {
    setLoading(false);
  }
};
  const onSubmit = async (data) => {
    setErrorMessageServer('');
    setLoading(true);

    try {
      const response: any = await LoginAgenda({
        token: code,
        dataNascimento: data.dataNascimento,
        identificador: data.identificador,
      });

      if (response.token) {
        localStorage.setItem('token_paciente', response.token);
        setTimeout(() => {
          navigate('/agenda-cidadao');
        }, 500);
      } else if (response.error) {
        setErrorMessageServer(response.error.message);
      } else if (response.message) {
        setErrorMessageServer(response.message);
      } else if (response.statusCode && response.statusCode >= 400) {
        setErrorMessageServer('Houve um erro na autenticação. Por favor, verifique seus dados.');
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessageServer(error.response.data.message);
      } else if (error.message) {
        setErrorMessageServer(error.message);
      } else {
        setErrorMessageServer('Ocorreu um erro ao fazer login. Por favor, tente novamente.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validateCode();
  }, []);

  return (
    <ScreenBackgroundLogin
      bgcolor="lightblue"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <FormContainer>
        <img src={logoJordana} alt="logo jornada" width="181" />
        <Box>
          <Typography variant="h5" fontWeight="bold">
            Boas Vindas!
          </Typography>
          <Typography
            variant="body2"
            sx={{ marginTop: '25.32px', fontSize: '12px', color: '#00000099' }}
          >
            Confirme seus dados para ter acesso ao seu
            <br /> agendamento
          </Typography>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            {codeIsValid && (
              <TypePersonalData
                clearErrors={clearErrors}
                control={control}
                setStep={setStep}
                watch={watch}
                setError={setError}
                errors={errors}
                errorMessageServer={errorMessageServer}
              />
            )}
            {codeIsValid === false && (
              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  border: '1px solid #f44336',
                  borderRadius: '4px',
                  backgroundColor: '#ffebee',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <ErrorOutlineIcon color="error" sx={{ fontSize: 48, mb: 1 }} />
                <Typography 
                  variant="subtitle1" 
                  color="error" 
                  fontWeight="medium"
                  sx={{ mb: 1 }}
                >
                  Não foi possível acessar
                </Typography>
                <Typography variant="body2" color="error.dark">
                  {errorMessage}
                </Typography>
              </Box>
            )}
          </form>
        </Box>
      </FormContainer>
    </ScreenBackgroundLogin>
  );
};

export default LoginAgendaCitizen;