import { TextField } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import useDebounce from '../../utils/hooks/useDebounce';

import './InputRequestScrollStyles.scss';
import { getPeopleById } from '../../lib/getPeople';
import { LoadingContext } from '../../context/LoadingContext';
import { validarCPF } from '../../utils/validateCPF';

const InputRequestScroll = ({
  fnRequest,
  setPage,
  page,
  items,
  setItems,
  loadingOptions,
  setLoadingOptions,
  valueInputType,
  setValueInputType,
  isOpen,
  setIsOpen,
  lastRequestIsFromInputTyped,
  getPacientes,
  setPeopleData,
  setNeedUpdatePatient,
  estabelecimentoProfessionalId,
}) => {
  const [shrinkInput, setShrinkInput] = useState(false);

  const { setLoading } = useContext(LoadingContext);

  const listRef: any = useRef();

  const handleOpen = () => {
    if (
      !valueInputType.nome &&
      valueInputType.length === 0 &&
      !lastRequestIsFromInputTyped
    ) {
      setItems([]);
      setLoadingOptions(true);
      getPacientes('', 1, true, estabelecimentoProfessionalId);
    }
    setIsOpen(true);
    setShrinkInput(true);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
    if (!valueInputType) {
      setShrinkInput(false);
      setNeedUpdatePatient(false);
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight;

    if (isAtBottom) {
      if (!lastRequestIsFromInputTyped) {
        getPacientes('', page + 1, true, estabelecimentoProfessionalId);
        setPage(page + 1);
      }
    }
  };

  const loadDataPatient = async (id) => {
    setLoading(true);
    try {
      const response = await getPeopleById(id);

      setNeedUpdatePatient(!validarCPF(response.cpf));
      setPeopleData(response);
    } catch (error) {}
    setLoading(false);
  };

  const CustomListbox = (props, ref) => (
    <div
      id="element"
      ref={listRef}
      style={{
        maxHeight: '300px',
      }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <ul style={{ padding: 0 }}>
        {items.map((item) => {
          return (
            <p
              onClick={() => {
                setNeedUpdatePatient(false);
                setValueInputType(item);
                loadDataPatient(item.id);
                setIsOpen(false);
              }}
              className="inputOption"
              style={{ paddingLeft: '5px' }}
            >
              {item.nome}
            </p>
          );
        })}

        {loadingOptions && (
          <p
            style={{
              paddingLeft: '5px',
              paddingTop: '10px',
              paddingBottom: '10px',
              margin: 0,
            }}
          >
            Carregando...
          </p>
        )}
      </ul>
    </div>
  );

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listRef.current) {
        listRef?.current?.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, isOpen]);

  return (
    <>
      <TextField
        label="Paciente"
        placeholder="Busque nome ou cpf do paciente"
        sx={{
          width: '90%',
          position: 'absolute',
          zIndex: 1,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiInputLabel-root': {
            display: 'none',
          },
        }}
        value={valueInputType?.nome}
        onChange={(e) => {
          setValueInputType(e.target.value);
          if (e.target.value) {
            fnRequest();
          }
          setItems([]);
        }}
        onFocus={() => setShrinkInput(true)}
        onBlur={() => {
          if (!valueInputType) {
            setShrinkInput(false);
          }
        }}
        size="small"
        InputLabelProps={{ shrink: shrinkInput }}
      />
      <TextField
        select
        label="Paciente"
        size="small"
        placeholder="Busque nome ou cpf do paciente"
        fullWidth
        sx={{ position: 'absolute' }}
        InputLabelProps={{ shrink: shrinkInput }}
        SelectProps={{
          open: isOpen,
          onOpen: handleOpen,
          onClose: handleCloseDropdown,
          MenuProps: {
            PaperProps: {
              ref: listRef,
              onScroll: handleScroll,
              style: { maxHeight: 200 }, // Altura máxima do dropdown
            },
          },
        }}
      >
        <CustomListbox />
      </TextField>
    </>
  );
};

export default InputRequestScroll;
