import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import useStatesFields from '../../../components/MultipleFilters/hooks/useStatesFields';
import ReturnButton from '../../../components/ReturnButton';
import { SideMenu } from '../../../components/SideMenu';
import { TableReportsColposcopia } from '../../../components/TableReports/TableColposcopia/TableReportsColposcopia';
import { Option } from '../../../types/Option';
import FiltersReportsColposcopia from './FiltersReportsColposcopia';
import { DisplayFilter, ItemsDisplayFilter } from '../../Convocation/styles';
import { getUserToken } from '../../../lib/auth';
import useRequestFilter from './FiltersReportsColposcopia/hooks/useRequestFilter';
import useSetFilterByPerfil from '../../../utils/hooks/useSetFilterByPerfil';

// const RequiredText = () => {
//   return (
//     <div style={{ margin: '3px 0 0', fontSize: '12px' }}>
//       <p style={{ margin: 0, fontStyle: 'italic', color: 'rgba(0,0,0,0.6)' }}>
//         <span style={{ color: 'red' }}>* </span>Obrigatório
//       </p>
//     </div>
//   );
// };

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'data_ini_colposcopia', value: '' },
  { inputName: 'data_fin_colposcopia', value: '' },
  { inputName: 'macroscopico', value: '' },
  { inputName: 'aval_geral', value: '' },
  { inputName: 'visibilidade', value: '' },
  { inputName: 'zt', value: '' },
  { inputName: 'achados', value: '' },
  { inputName: 'lugol', value: '' },
  { inputName: 'schiller', value: '' },
  { inputName: 'biopsia', value: '' },
  { inputName: 'laboratorio_responsavel_laudo', value: '' },
  { inputName: 'profissional_responsavel_laudo', value: '' },
  { inputName: 'search', value: '' },
];

const alteracoes: Option[] = [
  {
    label: 'Com Alterações',
    value: 'COM_ALTERACOES',
  },
  {
    label: 'Sem Alterações',
    value: 'SEM_ALTERACOES',
  },
];

const avaliacao: Option[] = [
  {
    label: 'Adequada',
    value: 'ADEQUADA',
  },
  {
    label: 'Inadequada',
    value: 'INADEQUADA',
  },
];

const visibilidadeEscamocolunar: Option[] = [
  { label: 'Completamente Visivel', value: 'COMPLETAMENTE_VISIVEL' },
  { label: 'Nao Visivel', value: 'NAO_VISIVEL' },
  { label: 'Parcialmente Visivel', value: 'PARCIALMENTE_VISIVEL' },
];

const testeSchiller: Option[] = [
  { label: 'Negativo', value: 'NEGATIVO' },
  { label: 'Positivo', value: 'POSITIVO' },
];

const realizarBiopsia: Option[] = [
  { label: 'Sim', value: 'SIM' },
  { label: 'Não', value: 'NAO' },
];

export const ReportsColposcopia = () => {
  const [tableData, setTableData] = useState({
    data: [],
    page: 1,
    count: 25,
    totalPages: 1,
    totalItems: 0,
  });
  const [profissional, setProfissional] = useState('');
  const [disableOtherInputs, setDisableOtherInputs] = useState('');
  const [, setEstadosSelecionados] = useState<Option[]>([]);
  const [, setMunicipiosSelecionados] = useState<Option[]>([]);
  const [, setLaboratoriosSelecionados] = useState<Option[]>([]);
  const [period, setTipePeriod] = useState('date');

  const [periodDtColposcopia, setPeriodDtColposcopia] = useState('period');
  const [periodDtResultado, setPeriodDtResultado] = useState('period');

  const [showTableData, setShowTableData] = useState(false);
  const [listFilterShow, setListFilterShow] = useState<any[]>([]);
  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);
  const [selectedResultado, setSelectedResultado] = useState();
  const [dtColposcopiaIni, setDtColposcopiaIni] = useState<any>(null);
  const [dtColposcopiaFinal, setDtColposcopiaFinal] = useState<any>(null);
  const [dtResultadoIni, setDataResultadoIni] = useState<any>(null);
  const [dtResultadoFinal, setDataResultadoFinal] = useState<any>(null);

  const [selectedOrigem, setSelectedOrigem] = useState([]);
  const [selectedCarater, setSelectedCarater] = useState([]);
  const [selectedEscamosa, setSelectedEscamosa] = useState([]);
  const [selectedGlandular, setSelectedGlandular] = useState([]);

  const [selectedOptionsAvaliacao, setSelectedOptionsAvaliacao] = useState([]);
  const [selectedOptionsVisibilidade, setSelectedOptionsVisibilidade] =
    useState([]);
  const [selectedOptionsZt, setSelectedOptionsZt] = useState([]);
  const [selectedOptionsTiposAchados, setSelectedOptionsTiposAchados] =
    useState([]);
  const [selectedOptionsLugol, setSelectedOptionsLugol] = useState([]);
  const [selectedOptionsSchiller, setSelectedOptionsSchiller] = useState([]);
  const [selectedOptionsBiopsia, setSelectedOptionsBiopsia] = useState([]);
  const [selectedOptionsAlteracoes, setSelectedOptionsAlteracoes] = useState(
    []
  );

  const [selectedMacroscopico, setSelectedMacroscopico] = useState([]);
  const [selectedOptionsLaboratorio, setSelectedOptionsLaboratorio] = useState<
    any[]
  >([]);

  const [selectedLaboratorio, setSelectedLaboratorio] = useState<any>();

  const [loadingTable, setLoadingTable] = useState(true);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      page: 1,
      size: 25,
    },
  });
  const { loadReportsColposcopia } = useRequestFilter({
    setTableData,
    setLoadingTable,
  });

  const location = useLocation();
  const origin = location?.state?.origin;
  const fromTable =
    origin === 'tables' || !!localStorage.getItem('requestFromTable');

  // Add chart filter handling
  const chartNameSavedInStorage = localStorage.getItem('chartName');
  const itemClicked = localStorage.getItem('itemClicked');
  const payloadFromPanelCharts = JSON.parse(
    String(localStorage.getItem('payload'))
  );

  // Add user permission checks
  const userToken = getUserToken();
  const isSuperAdmin = userToken.permissao_atual.nome === 'SuperAdmin';
  const isEstadual = userToken.permissao_atual.nome === 'Admin Estadual';
  const isMunicipal = userToken.permissao_atual.nome === 'Admin Municipal';
  const isAdminLaboratorio =
    userToken.permissao_atual.nome === 'Admin Estabelecimento';
  const isPadraoEstabelecimento =
    userToken.permissao_atual.contexto === 'UNIDADE DE SAÚDE';

  // Add filter handling function
  const handleSetFilter = (
    newValue: any,
    inputName: string,
    setListFilter: any
  ) => {
    setListFilter((prev: any[]) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue ?? '' } : item
      )
    );
  };

  // Add payload handling function
  const setPayload = async (formValues: any) => {
    return {
      ...formValues,
      page: 1,
      size: 25,
    };
  };

  const getDataByChartClicked = async () => {
    const isGestao =
      isSuperAdmin || isAdminLaboratorio || isEstadual || isMunicipal;

    // Format estados to remove quotes and brackets
    const formattedEstados = payloadFromPanelCharts.params.estados?.replace(
      /[\[\]"]/g,
      ''
    );
    const estadosArray = formattedEstados?.split(',') || [];

    console.log('4. Estados formatados:', formattedEstados);
    console.log('4.1 Array de estados:', estadosArray);

    if (isGestao) {
      setEstadosSelecionados([formattedEstados]);
      setMunicipiosSelecionados([payloadFromPanelCharts.params.municipios]);

      // Convert estabelecimentos to correct format
      const estabelecimentos = payloadFromPanelCharts.params.estabelecimentos
        ? [
            {
              value: String(payloadFromPanelCharts.params.estabelecimentos),
              label: String(payloadFromPanelCharts.params.estabelecimentos),
            },
          ]
        : [];

      console.log('5. Estabelecimentos convertidos:', estabelecimentos);

      setLaboratoriosSelecionados(estabelecimentos);

      // Update filter display with handling for multiple values
      const newFilters = [
        {
          inputName: 'estado',
          value: `Estado${estadosArray.length > 1 ? 's' : ''}: ${
            estadosArray.length > 1
              ? 'Vários'
              : formattedEstados || 'Não selecionado'
          }`,
        },
        payloadFromPanelCharts.params.municipios && {
          inputName: 'municipio',
          value: `Município${
            payloadFromPanelCharts.params.municipios?.includes(',') ? 's' : ''
          }: ${
            payloadFromPanelCharts.params.municipios?.includes(',')
              ? 'Vários'
              : payloadFromPanelCharts.params.municipios
          }`,
        },
        estabelecimentos.length > 0 && {
          inputName: 'estabelecimento_saude',
          value: `Estabelecimento${estabelecimentos.length > 1 ? 's' : ''}: ${
            estabelecimentos.length > 1 ? 'Vários' : estabelecimentos[0].label
          }`,
        },
      ].filter(Boolean);

      console.log('6. Novos filtros a serem exibidos:', newFilters);

      setListFilterShow(newFilters);
    }

    setShowTableData(true);

    // Set filters based on chart clicked
    if (chartNameSavedInStorage === 'avaliacaoGeral' && itemClicked) {
      console.log('7. Setando filtro de avaliação geral:', itemClicked);
      setValue('avaliacao_geral', itemClicked);
      setListFilterShow((prev) => {
        const newFilters = prev.filter(
          (item) =>
            item.value && item.value !== 'undefined' && item.value !== 'null'
        );
        return [
          ...newFilters,
          {
            inputName: 'avaliacao_geral',
            value: `Avaliação Geral: ${itemClicked}`,
          },
        ];
      });
    } else if (chartNameSavedInStorage === 'tipoAchados' && itemClicked) {
      const [type, id] = itemClicked.split('_');
      console.log('7. Setando filtro de tipo de achados:', { type, id });
      setValue('tipo_achados', id);
      setListFilterShow((prev) => {
        const newFilters = prev.filter(
          (item) =>
            item.value && item.value !== 'undefined' && item.value !== 'null'
        );
        return [
          ...newFilters,
          { inputName: 'tipo_achados', value: `Tipo de Achados: ${type}` },
        ];
      });
    } else if (chartNameSavedInStorage === 'realizarBiopsia' && itemClicked) {
      console.log('7. Setando filtro de realizar biópsia:', itemClicked);
      setValue('realizar_biopsia', itemClicked);
      setListFilterShow((prev) => {
        const newFilters = prev.filter(
          (item) =>
            item.value && item.value !== 'undefined' && item.value !== 'null'
        );
        return [
          ...newFilters,
          {
            inputName: 'realizar_biopsia',
            value: `Realizar Biópsia: ${itemClicked === 'sim' ? 'Sim' : 'Não'}`,
          },
        ];
      });
    }

    // Construct the final payload
    const requestPayload = {
      payload: {
        page: 1,
        size: 25,
        skip: 0,
        take: 25,
        estados: formattedEstados || null,
        municipios: payloadFromPanelCharts.params.municipios || null,
        estabelecimentoSaudeId:
          payloadFromPanelCharts.params.estabelecimentos || null,
        dataInicial: payloadFromPanelCharts.params.dataInicial || null,
        dataFinal: payloadFromPanelCharts.params.dataFinal || null,
        avaliacao_geral:
          chartNameSavedInStorage === 'avaliacaoGeral' ? itemClicked : null,
        tipo_achados:
          chartNameSavedInStorage === 'tipoAchados'
            ? itemClicked?.split('_')[1]
            : null,
        realizar_biopsia:
          chartNameSavedInStorage === 'realizarBiopsia'
            ? itemClicked === 'sim'
            : null,
      },
    };

    // Ensure all pagination parameters are numbers
    requestPayload.payload.page = Number(requestPayload.payload.page);
    requestPayload.payload.size = Number(requestPayload.payload.size);
    requestPayload.payload.skip = Number(requestPayload.payload.skip);
    requestPayload.payload.take = Number(requestPayload.payload.take);

    // Remove null fields from the payload
    Object.keys(requestPayload.payload).forEach(
      (key) =>
        requestPayload.payload[key] === null &&
        delete requestPayload.payload[key]
    );

    console.log('9. Payload final enviado para API:', requestPayload);

    // Call API with the wrapped payload
    await loadReportsColposcopia(requestPayload);

    // Clear localStorage after successful API call
    localStorage.removeItem('payload');
    localStorage.removeItem('chartName');
    localStorage.removeItem('itemClicked');
    localStorage.removeItem('requestFromTable');
  };

  useEffect(() => {
    console.log('=== Debug useEffect Colposcopia ===');
    console.log('fromTable:', fromTable);
    console.log('Location state:', location?.state);
    console.log('Payload do localStorage:', localStorage.getItem('payload'));

    if (fromTable) {
      getDataByChartClicked();
    }
  }, [fromTable]);

  const clearValuesInputs = () => {
    // setEstabelecimentosSelected([]);
    setLaboratoriosSelecionados([]);

    setDataResultadoIni('');
    setDataResultadoFinal('');
    setDtColposcopiaFinal('');
    setDtColposcopiaIni('');
    setSelectedOrigem([]);
    setSelectedCarater([]);
    setSelectedEscamosa([]);
    setSelectedGlandular([]);
    setSelectedOptionsAvaliacao([]);
    setSelectedOptionsVisibilidade([]);
    setSelectedOptionsZt([]);
    setSelectedOptionsTiposAchados([]);
    setSelectedOptionsLugol([]);
    setSelectedOptionsSchiller([]);
    setSelectedOptionsBiopsia([]);
    setSelectedOptionsAlteracoes([]);

    setListFilter(initialStateListFilter);

    setValue('profissionalId', '');
  };

  const {
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    disableInputEstado,
    disableMunicipioInput,
    disableEstabelecimentoInput,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    setDisableInputEstado,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,
    setOptionsMunicipios,
    setEstadosOptions,
    setOptionsEstabelecimentos,
    setOptionsLaboratorios,
    setOptionsProfisisonaisLaboratorio,
  } = useStatesFields();

  const handleChange = (event) => {
    setProfissional(event.target.value);
  };

  const handleSetSelectedOptions = (setter) => (options) => {
    setter(options);
  };

  const handleSetPeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTipePeriod((event.target as HTMLInputElement).value);
  };

  // const userToken = getUserToken();

  // const handleSetFilter = (newValue: any, inputName: string) => {
  //   setListFilter((prev) =>
  //     prev.map((item) =>
  //       item.inputName === inputName ? { ...item, value: newValue ?? '' } : item
  //     )
  //   );
  // };

  // const {
  //   estabelecimentoId: estabelecimentoIdUser,
  //   estabelecimento_saude: {
  //     nome_fantasia: estabelecimentoNameUser,
  //     endereco: {
  //       municipio: {
  //         id: municipioUserId,
  //         nome: municipioUserName,
  //         estadoSigla: estadoUserSigla,
  //       },
  //     },
  //   },
  //   // endereco: {
  //   //   municipio_id: municipioUserId,
  //   //   municipio_nome: municipioUserName,
  //   // },
  // } = userToken;

  // const setDefaultEstado = ({ sigla }) => {
  //   setEstadosOptions([
  //     {
  //       sigla: sigla,
  //     },
  //   ]);
  //   handleSetFilter(`Estado: ${sigla}`, 'estado');

  //   setEstadosSelected([sigla]);
  // };

  // const setDefaultMunicipio = ({ municipioId, municipioNome }) => {
  //   setOptionsMunicipios({
  //     id: municipioId,
  //     nome: municipioNome,
  //   });
  //   handleSetFilter(`Municipio: ${municipioNome}`, 'municipio');
  //   setMunicipiosSelected([municipioId]);
  // };

  // const setDefaultEstabelecimento = ({
  //   estabelecimentoId,
  //   estabelecimentoNome,
  // }) => {
  //   setOptionsEstabelecimentos({
  //     id: estabelecimentoIdUser,
  //     municipio: municipioUserName,
  //     municipio_id: municipioUserId,
  //     nome: estabelecimentoNameUser,
  //     label: estabelecimentoNameUser,
  //   });

  //   setEstabelecimentosSelected([estabelecimentoIdUser]);
  // };

  // const setDefaultLaboratorio = ({ laboratorioId, laboratorioName }) => {
  //   setOptionsLaboratorios([
  //     {
  //       label: laboratorioName,
  //       value: laboratorioId,
  //     },
  //   ]);
  //   setSelectedOptionsLaboratorio([
  //     {
  //       label: laboratorioName,
  //       value: laboratorioId,
  //     },
  //   ]);

  //   handleSetFilter(
  //     `Laboratório Responsável pelo Laudo: ${laboratorioName}`,
  //     'estabelecimentoLaudoId'
  //   );
  // };

  // const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
  // const isEstadual = userToken.permissao_atual?.nome === 'Admin Estadual';
  // const isMunicipal = userToken.permissao_atual?.nome === 'Admin Municipal';
  // const isAdminLaboratorio =
  //   userToken.permissao_atual?.nome === 'Admin Estabelecimento';
  // const isPadraoEstabelecimento =
  //   userToken.permissao_atual?.contexto === 'UNIDADE DE SAÚDE';

  // const actionsWhenUserIsEstadual = () => {
  //   setDefaultEstado({
  //     sigla: estadoUserSigla,
  //   });
  // };

  // const actionsWhenUserIsMunicipal = () => {
  //   setDefaultEstado({
  //     sigla: estadoUserSigla,
  //   });
  //   setDefaultMunicipio({
  //     municipioId: String(municipioUserId),
  //     municipioNome: municipioUserName,
  //   });

  //   setListFilterShow((prev) => [
  //     ...prev,
  //     { inputName: 'estados', value: `Estados: ${estadoUserSigla}` },
  //     { inputName: 'municipio', value: `Município: ${municipioUserName}` },
  //   ]);

  //   setShowTableData(true);
  // };

  // const actionsWhenUserIsAdminLaboratorio = () => {
  //   setDefaultEstado({
  //     sigla: userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
  //   });

  //   setDefaultLaboratorio({
  //     laboratorioId: userToken.estabelecimentoId,
  //     laboratorioName: userToken.estabelecimento_saude?.nome_fantasia,
  //   });
  // };

  // const actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento = () => {
  //   actionsWhenUserIsMunicipal();

  //   setDefaultEstabelecimento({
  //     estabelecimentoId: estabelecimentoIdUser,
  //     estabelecimentoNome: estabelecimentoNameUser,
  //   });

  //   setListFilterShow((prev) => [
  //     ...prev,
  //     { inputName: 'estados', value: `Estados: ${estadoUserSigla}` },
  //     { inputName: 'municipio', value: `Município: ${municipioUserName}` },
  //     {
  //       inputName: 'estabelecimento_saude',
  //       value: `Estabelecimento de Saúde: ${estabelecimentoNameUser}`,
  //     },
  //   ]);
  // };

  // useEffect(() => {
  //   if (isSuperAdmin) {
  //     return;
  //   }

  //   if (isEstadual) {
  //     actionsWhenUserIsEstadual();
  //   }

  //   if (isMunicipal) {
  //     actionsWhenUserIsMunicipal();

  //     loadReportsColposcopia({
  //       estados: estadoUserSigla,
  //       municipios: municipioUserId,
  //       page: 1,
  //       size: 25,
  //     });
  //   }

  //   if (isAdminLaboratorio) {
  //     actionsWhenUserIsAdminLaboratorio();
  //   }

  //   if (isPadraoEstabelecimento) {
  //     actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento();

  //     loadReportsColposcopia({
  //       estados: estadoUserSigla,
  //       municipios: municipioUserId,
  //       estabelecimentoSaudeId: estabelecimentoIdUser,
  //       page: 1,
  //       size: 25,
  //     });
  //   }
  // }, []);

  const { loadFiltersByPerfil } = useSetFilterByPerfil({
    loadReports: loadReportsColposcopia,
    setListFilter: setListFilter,
    setListFilterShow: setListFilterShow,
    setSelectedOptionsLaboratorio: setSelectedOptionsLaboratorio,
    setShowTableData: setShowTableData,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    setOptionsMunicipios,
    setEstadosOptions,
    setOptionsEstabelecimentos,
    setOptionsLaboratorios,
    setSelectedLaboratorio,
    selectedLaboratorio,
    fieldProfissionalResultado: 'profissional_responsavel_laudo',
    fieldLaboratorioResultado: 'laboratorio_responsavel_laudo',

    setOptionsProfisisonaisLaboratorio,
    setValue,
  });

  useEffect(() => {
    loadFiltersByPerfil();
  }, []);

  // const nameMunicipioFromGestaoChart = optionsMunicipios?.find(
  //   (mncp) => mncp.id === payloadFromPanelCharts.params.municipios[0]
  // );

  // console.log(nameMunicipioFromGestaoChart);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton
            backPage={fromTable ? '/paineis/teste-colposcopia' : '/home'}
          />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h4" fontWeight="bold">
                Relatório Geral de Exames de Colposcopia
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
                sx={{ marginBottom: '24px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>
                {fromTable ? (
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to={'/paineis/teste-colposcopia'}
                  >
                    {'Painel do Teste de Colposcopia'}
                  </Link>
                ) : (
                  <Typography color="text.primary">{'Relatórios'}</Typography>
                )}

                <Typography key="3" color="text.primary">
                  Relatório Geral de Exames de Colposcopia
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Filtros
            </Typography>
            <Divider />
            <p
              style={{
                fontSize: '12px',
                textAlign: 'right',
                color: '#d32f2f',
                margin: '0',
                marginTop: '20px',
                // marginBottom: '-20px',
              }}
            >
              <i>* Campo obrigatório</i>
            </p>

            <DisplayFilter style={{ marginTop: '24px' }}>
              {!listFilterShow?.some(
                (item) =>
                  item.value &&
                  item.value !== 'undefined' &&
                  item.value !== 'null'
              ) && (
                <p style={{ margin: 0, width: '413px', color: '#838383' }}>
                  Os filtros selecionados aparecerão aqui.
                </p>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {listFilterShow
                  .filter(
                    (item) =>
                      item &&
                      item.value &&
                      item.value !== 'undefined' &&
                      item.value !== 'null' &&
                      item.value.trim() !== ''
                  )
                  .map((item) => (
                    <ItemsDisplayFilter key={item.inputName}>
                      {item.value}
                    </ItemsDisplayFilter>
                  ))}
              </div>
            </DisplayFilter>

            <FiltersReportsColposcopia
              initialStateListFilter={initialStateListFilter}
              selectedOptionsLaboratorio={selectedOptionsLaboratorio}
              setSelectedOptionsLaboratorio={setSelectedOptionsLaboratorio}
              selectedLaboratorio={selectedLaboratorio}
              setSelectedLaboratorio={setSelectedLaboratorio}
              setLoadingTable={setLoadingTable}
              clearErrors={clearErrors}
              errors={errors}
              setSelectedMacroscopico={setSelectedMacroscopico}
              selectedMacroscopico={selectedMacroscopico}
              disableMunicipioInput={disableMunicipioInput}
              disableEstabelecimentoInput={disableEstabelecimentoInput}
              disableInputEstado={disableInputEstado}
              clearValuesInputs={clearValuesInputs}
              selectedGlandular={selectedGlandular}
              selectedEscamosa={selectedEscamosa}
              selectedCarater={selectedCarater}
              selectedOrigem={selectedOrigem}
              setSelectedCarater={setSelectedCarater}
              setSelectedEscamosa={setSelectedEscamosa}
              setSelectedGlandular={setSelectedGlandular}
              setSelectedOrigem={setSelectedOrigem}
              dtResultadoIni={dtResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              setDataResultadoIni={setDataResultadoIni}
              setDataResultadoFinal={setDataResultadoFinal}
              estadosSelected={estadosSelected}
              setEstadosSelected={setEstadosSelected}
              setTableData={setTableData}
              setShowTableData={setShowTableData}
              municipiosSelected={municipiosSelected}
              setMunicipiosSelected={setMunicipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              setEstabelecimentosSelected={setEstabelecimentosSelected}
              periodDtResultado={periodDtResultado}
              control={control}
              handleSubmit={handleSubmit}
              setValue={setValue}
              setSelectedResultado={setSelectedResultado}
              setPeriodDtResultado={setPeriodDtResultado}
              selectedResultado={selectedResultado}
              watch={watch}
              setError={setError}
              showTableData={showTableData}
              listFilter={listFilter}
              setListFilter={setListFilter}
              setListFilterShow={setListFilterShow}
              // ---------------------------------------
              setDisableInputEstado={setDisableInputEstado}
              setDisabledMunicipioInput={setDisabledMunicipioInput}
              setSelectedOptionsAvaliacao={setSelectedOptionsAvaliacao}
              setSelectedOptionsVisibilidade={setSelectedOptionsVisibilidade}
              setSelectedOptionsZt={setSelectedOptionsZt}
              setSelectedOptionsTiposAchados={setSelectedOptionsTiposAchados}
              setSelectedOptionsLugol={setSelectedOptionsLugol}
              setSelectedOptionsSchiller={setSelectedOptionsSchiller}
              setSelectedOptionsBiopsia={setSelectedOptionsBiopsia}
              selectedOptionsAvaliacao={selectedOptionsAvaliacao}
              selectedOptionsVisibilidade={selectedOptionsVisibilidade}
              selectedOptionsZt={selectedOptionsZt}
              selectedOptionsTiposAchados={selectedOptionsTiposAchados}
              selectedOptionsLugol={selectedOptionsLugol}
              selectedOptionsSchiller={selectedOptionsSchiller}
              selectedOptionsBiopsia={selectedOptionsBiopsia}
              periodDtColposcopia={periodDtColposcopia}
              setDtColposcopiaFinal={setDtColposcopiaFinal}
              setDtColposcopiaIni={setDtColposcopiaIni}
              dtColposcopiaIni={dtColposcopiaIni}
              dtColposcopiaFinal={dtColposcopiaFinal}
              setPeriodDtColposcopia={setPeriodDtColposcopia}
              selectedOptionsAlteracoes={selectedOptionsAlteracoes}
              setSelectedOptionsAlteracoes={setSelectedOptionsAlteracoes}
            />

            <TableReportsColposcopia
              loadingTable={loadingTable}
              setLoadingTable={setLoadingTable}
              // selectedResultado={selectedResultado}
              // dtColetaFinal={dtColetaFinal}
              // dtColetaIni={dtColetaIni}
              selectedOptionsAlteracoes={selectedOptionsAlteracoes}
              dtResultadoIni={dtResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              showTableData={showTableData}
              tableData={tableData}
              setValue={setValue}
              watch={watch}
              setTableData={setTableData}
              estadosSelected={estadosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              equipesSelected={equipesSelected}
              dtColposcopiaIni={dtColposcopiaIni}
              dtColposcopiaFinal={dtColposcopiaFinal}
              selectedOptionsAvaliacao={selectedOptionsAvaliacao}
              selectedOptionsVisibilidade={selectedOptionsVisibilidade}
              selectedOptionsZt={selectedOptionsZt}
              selectedOptionsTiposAchados={selectedOptionsTiposAchados}
              selectedOptionsLugol={selectedOptionsLugol}
              selectedOptionsSchiller={selectedOptionsSchiller}
              selectedOptionsBiopsia={selectedOptionsBiopsia}
            />
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};
