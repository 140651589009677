export const getLabelFormatedLastConvocation = (lastConvocation) => {
  if (lastConvocation?.includes('Busca')) {
    return { tooltip: lastConvocation, text: 'Outras Convocações' };
  } else {
    return {
      tooltip: '',
      text: lastConvocation
        ? `${lastConvocation?.slice(0, 1)}º Convocatória`
        : '',
    };
  }
};
