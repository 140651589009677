import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';

import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';
import MultipleFilters from '../../../../components/MultipleFilters';
import useStatesFields from '../../../../components/MultipleFilters/hooks/useStatesFields';
import { SelectWithSearch } from '../../../../components/SelectWithSearch';
import { resultadoCitologia } from '../../../../mocks/resultado-citologia-mock';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import useActions from '../../../../components/MultipleFilters/hooks/useActions';
import { useContext, useEffect, useState } from 'react';
import useSetPayload from './hooks/useSetPayload';
import useRequestFilter from './hooks/useRequestFilter';
import api from '../../../../api';
import useClearValues from './hooks/useClearValues';
import { PerfilContext } from '../../../../context/PerfilContext';
import useHandleChangeFilters from './hooks/useHandleChangesFilters';
import { getUserToken } from '../../../../lib/auth';
import ModalSelect, { Option } from '../../../../components/ModalSelect';
import {
  optionsAtipiaGlandular,
  optionsAtipicasEscamosas,
  optionsAtipicasGlandulares,
  optionsAtipiaOutros,
  optionsAtipiaEscamosa,
} from '../../../../utils/variables';

const FiltersReportsCitologiaReflexo = ({
  estadosSelected,
  setEstadosSelected,
  setTableData,
  setShowTableData,
  municipiosSelected,
  setMunicipiosSelected,
  estabelecimentosSelected,
  setEstabelecimentosSelected,
  period,
  control,
  handleSubmit,
  setValue,
  disableInputEstado,
  setSelectedResultado,
  setPeriod,
  setDataColetaFinal,
  setDataColetaIni,
  dtColetaIni,
  dtColetaFinal,
  selectedResultado,
  watch,
  setError,
  showTableData,
  listFilter,
  disableMunicipioInput,
  disableEstabelecimentoInput,
  selectedLaboratorio,
  setSelectedLaboratorio,
  selectedOptionsLaboratorio,
  setSelectedOptionsLaboratorio,
  disabledLaboratorioResultado,
  disabledProfissionalResultado,
  clearErrors,
  errors,
  setLoadingTable,
  initialStateListFilter,
  setListFilter,
  setListFilterShow,
}) => {
  const [disableOtherInputs, setDisableOtherInputs] = useState(true);

  const [atipicasGlandularesOptions, setAtipicasGlandularesOptions] = useState<
    Option[]
  >(optionsAtipicasGlandulares);
  const [atipicasEscamosasOptions, setAtipicasEscamosasOptions] = useState<
    Option[]
  >(optionsAtipicasEscamosas);
  const [atipicasOrigemIndefinidaOptions, setAtipicasOrigemIndefinidaOptions] =
    useState<Option[]>(optionsAtipiaOutros);
  const [atipiasGlandularesOptions, setAtipiasGlandularesOptions] = useState<
    Option[]
  >(optionsAtipiaGlandular);
  const [atipiasEscamosasOptions, setAtipiasEscamosasOptions] = useState<
    Option[]
  >(optionsAtipiaEscamosa);

  const [atipicasGlandularesSearch, setAtipicasGlandularesSearch] =
    useState('');
  const [atipicasEscamosasSearch, setAtipicasEscamosasSearch] = useState('');
  const [atipicasOrigemIndefinidaSearch, setAtipicasOrigemIndefinidaSearch] =
    useState('');
  const [atipiasGlandularesSearch, setAtipiasGlandularesSearch] = useState('');
  const [atipiasEscamosasSearch, setAtipiasEscamosasSearch] = useState('');

  const [atipicaEscamosasAnchor, setAtipicaEscamosasAnchor] =
    useState<null | HTMLElement>(null);
  const [atipicasGlandularesAnchor, setAtipicasGlandularesAnchor] =
    useState<null | HTMLElement>(null);
  const [celulasAtipicasAnchor, setCelulasAtipicasAnchor] =
    useState<null | HTMLElement>(null);
  const [atipiaEscamosasAnchor, setAtipiaEscamosasAnchor] =
    useState<null | HTMLElement>(null);
  const [atipiasGlandularesAnchor, setAtipiasGlandularesAnchor] =
    useState<null | HTMLElement>(null);

  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [errorInputDate, setErrorInputDate] = useState<any>();

  const [, setFirstLoadPage] = useState(true);

  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,

    setAnchorElAcs,
    setArrayAcs,
    setSearchAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,

    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,

    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,
    setLoadingEstados,

    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,

    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,
    setDisabledMunicipioInput,

    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,

    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setDisabledEstabelecimentoInput,

    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    disableEquipeInput,
    errorsFields,

    setAllEquipesOptions,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setDisableEquipeInput,
    setErrorsFields,

    setOptionsLaboratorios,
    optionsLaboratorios,
    setAllLaboratoriosOptions,
    optionsProfissionaisLaboratorio,
    setOptionsProfisisonaisLaboratorio,
  } = useStatesFields();

  const { validationSubmit } = useActions();

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    dtColetaFinal,
    dtColetaIni,
    selectedResultado,
    selectedLaboratorio,
  });

  const { loadReportsCitologiaReflexo } = useRequestFilter({
    setTableData,
    setLoadingTable,
  });
  const { getPermissao } = useContext(PerfilContext);
  const { handleSetFilter } = useHandleChangeFilters({ setListFilter });
  const permissao = getPermissao();

  const isLaboratorio =
    getUserToken().permissao_atual?.contexto === 'LABORATÓRIO';

  const isMedicoLaboratorio =
    isLaboratorio && getUserToken().permissao_atual?.id === 7;

  const { clearValues } = useClearValues({
    permissao,
    setValue,
    handleSetFilter,
    watch,
    loadReportsCitologiaReflexo,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    showTableData,
    setShowTableData,
    setTableData,
    setErrorsFields,
    setListFilterShow,
    setSelectedResultado,
    setDataColetaFinal,
    setDataColetaIni,
    setSelectedLaboratorio,
    initialStateListFilter,
    setSelectedOptions,
    setSelectedOptionsLaboratorio,
  });

  const fnWhenFormIsValid = async () => {
    if (!errorInputDate) {
      setTableData({ data: [] });

      setListFilterShow([
        ...listFilter.filter((item) => item.value && item.value.trim() !== ''),
      ]);

      setShowTableData(true);

      await setPayload().then((payload) => {
        try {
          loadReportsCitologiaReflexo({ payload });
        } catch (error) {}
      });
    }
  };

  const handleSetSelectedOptions =
    ({ setSelected, description, fieldName }) =>
    (options) => {
      setSelected(options);
      const optionLabel = () => {
        if (options.length === 0) {
          return '';
        }

        if (options.length === 1) {
          return `${description}: ${options[0].label}`;
        }

        if (options.length > 1) {
          return `${description}: Vários`;
        }
      };
      handleSetFilter(optionLabel(), fieldName);
    };

  const onSubmit = async () => {
    validationSubmit({
      fnWhenFormIsValid,
      estadosSelected,
      municipiosSelected,
      estabelecimentosSelected,
      setErrorsFields,
      setDisableOtherInputs,
      watch,
      setError,
      clearErrors,
    });
  };

  const handleSetPeriod = (e) => {
    setDataColetaFinal(null);
    setDataColetaIni(null);
  };

  useEffect(() => {
    // const state: any = location.state;
    async function loadResponsaveis() {
      const arrayLaboratorios = selectedLaboratorio?.map((lab) => lab.value);
      const response = await api.get(
        '/dashboard/exames/citologia/panel/profissionais-resultado',
        {
          params: {
            estados:
              estadosSelected.length > 0
                ? estadosSelected.join(', ')
                : undefined,
            municipios:
              municipiosSelected.length > 0
                ? municipiosSelected.join(', ')
                : undefined,
            laboratorioId:
              arrayLaboratorios.length > 0
                ? arrayLaboratorios.join(', ')
                : undefined,
            periodo_inicio: dtColetaIni
              ? moment(dtColetaIni, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : undefined,
            periodo_fim: dtColetaFinal
              ? moment(dtColetaFinal, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : undefined,

            tipo: 'Reflexo',
          },
        }
      );

      const profissionais = response.data.data.map((profissional) => {
        return {
          idprofissional: profissional.prof_id,
          nomeprofissional: `${profissional.pessoa.nome}`,
          label: `${profissional.pessoa.nome}`,
        };
      });

      setOptionsProfisisonaisLaboratorio(profissionais);
    }

    if (selectedLaboratorio?.length === 1) {
      loadResponsaveis();
    }

    if (selectedLaboratorio?.length > 1 || selectedLaboratorio?.length === 0) {
      setOptionsProfisisonaisLaboratorio([]);
    }
  }, [selectedLaboratorio]);

  useEffect(() => {
    if (
      (optionsMunicipios?.length > 0 &&
        municipiosSelected?.length === optionsMunicipios?.length) ||
      municipiosSelected.length > 0
    ) {
      setDisableOtherInputs(false);
    }
  }, [optionsMunicipios, municipiosSelected]);

  const verifyingIfAllCharactersInputSearchIsNumber = (str) => {
    const isAllNumbers = /^[0-9]+$/.test(str);
    return isAllNumbers;
  };

  const inputValueWithoutEspecialChar = watch('search')?.replace(
    /[^a-zA-Z0-9]/g,
    ''
  );

  useEffect(() => {
    const dtIni = moment(dtColetaIni, 'DD-MM-YYYY');
    const dtFin = moment(dtColetaFinal, 'DD-MM-YYYY');

    if (dtIni.isAfter(dtFin)) {
      setErrorInputDate({
        ini: 'Data final deve ser maior que a data inicial',
        fin: '',
      });
    } else {
      setErrorInputDate('');
    }
  }, [dtColetaFinal, dtColetaIni]);
  useEffect(() => {
    if (
      selectedLaboratorio?.length === 0 ||
      selectedOptionsLaboratorio?.length === 0
    ) {
      setValue('profissionalResultadoId', []);
      setOptionsProfisisonaisLaboratorio([]);
    }
  }, [selectedLaboratorio, selectedOptionsLaboratorio]);

  const searchInput = watch('search');

  useEffect(() => {
    if (!searchInput) {
      clearErrors('search');
    }
  }, [searchInput]);

  useEffect(() => {
    const loadEstados = async () => {
      // setLoadingEstados(true);
      try {
        const response = await api.get('/estados/citologia');
        const estados = response.data;
        setEstadosOptions(estados);
        setAllEstadosOptions(estados);
        setLoadingEstados(false);
      } catch (error) {
        setLoadingEstados(false);

        console.error('Erro ao carregar estados:', error);
        // setEstadosLoadError(true);
      }
    };

    loadEstados();
  }, []);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <MultipleFilters
          requiredMunicipio={estadosSelected.length > 1 ? false : true}
          setFirstLoadPage={setFirstLoadPage}
          setOptionsLaboratorios={setOptionsLaboratorios}
          setAllLaboratoriosOptions={setAllLaboratoriosOptions}
          routeGetEstabelecimentos="/dashboard/exames/citologia/estabelecimentos"
          setDisableOtherInputs={setDisableOtherInputs}
          disableInputEstado={disableInputEstado}
          handleSetFilter={handleSetFilter}
          // laboratorioField
          mdEstado={4}
          mdMunicipio={4}
          mdEstabelecimento={4}
          mdEquipe={3}
          acsSelected={acsSelected}
          arrayAcs={arrayAcs}
          searchAcs={searchAcs}
          allAcsOptions={allAcsOptions}
          setAllAcsOptions={setAllAcsOptions}
          anchorElAcs={anchorElAcs}
          disableAcsInput={disableAcsInput}
          setAnchorElAcs={setAnchorElAcs}
          setArrayAcs={setArrayAcs}
          setSearchAcs={setArrayAcs}
          setAcsSelected={setAcsSelected}
          anchorElEstados={anchorElEstados}
          searchEstado={searchEstado}
          estadosOptions={estadosOptions}
          allEstadosOptions={allEstadosOptions}
          estadosSelected={estadosSelected}
          setAnchorElEstados={setAnchorElEstados}
          setSearchEstado={setSearchEstado}
          setEstadosOptions={setEstadosOptions}
          setAllEstadosOptions={setAllEstadosOptions}
          setEstadosSelected={setEstadosSelected}
          allMunicipiosOptions={allMunicipiosOptions}
          searchMunicipio={searchMunicipio}
          anchorElMunicipios={anchorElMunicipios}
          municipiosSelected={municipiosSelected}
          optionsMunicipios={optionsMunicipios}
          disableMunicipioInput={disableMunicipioInput}
          setAllMunicipiosOptions={setAllMunicipiosOptions}
          setSearchMunicipio={setSearchMunicipio}
          setAnchorElMunicipios={setAnchorElMunicipios}
          setMunicipiosSelected={setMunicipiosSelected}
          setOptionsMunicipios={setOptionsMunicipios}
          optionsEstabelecimentos={optionsEstabelecimentos}
          allEstabelecimentosOptions={allEstabelecimentosOptions}
          searchEstabelecimentos={searchEstabelecimentos}
          anchorElEstabelecimentos={anchorElEstabelecimentos}
          estabelecimentosSelected={estabelecimentosSelected}
          disableEstabelecimentoInput={disableEstabelecimentoInput}
          setOptionsEstabelecimentos={setOptionsEstabelecimentos}
          setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
          setSearchEstabelecimentos={setSearchEstabelecimentos}
          setAnchorEstabelecimentos={setAnchorEstabelecimentos}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          allEquipesOptions={allEquipesOptions}
          searchEquipes={searchEquipes}
          anchorElEquipe={anchorElEquipe}
          arrayEquipes={arrayEquipes}
          equipesSelected={equipesSelected}
          disableEquipeInput={disableEquipeInput}
          setAllEquipesOptions={setAllEquipesOptions}
          setSearchEquipes={setSearchEquipes}
          setAnchorElEquipe={setAnchorElEquipe}
          setArrayEquipes={setArrayEquipes}
          setEquipesSelected={setEquipesSelected}
          setDisabledMunicipioInput={setDisabledMunicipioInput}
          setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
          setDisableEquipeInput={setDisableEquipeInput}
          setDisableAcsInput={setDisableAcsInput}
          errorsFields={errorsFields}
          setErrorsFields={setErrorsFields}
        />
        <Grid item xs={12} md={4}>
          <SelectWithSearch
            options={resultadoCitologia}
            label="Resultado da Citologia"
            disabled={disableOtherInputs}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedResultado,
              description: 'Resultado da Citologia',
              fieldName: 'resultado',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Controller
            name="celulasAtipicasEscamosas"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Células Atípicas... Escamosa"
                placeholder="Células Atípicas... Escamosa"
                inputName="celulasAtipicasEscamosas"
                handleSetFilter={handleSetFilter}
                options={atipicasEscamosasOptions}
                allOptions={optionsAtipicasEscamosas}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={atipicasEscamosasSearch}
                anchorEl={atipicaEscamosasAnchor}
                setAnchorEl={setAtipicaEscamosasAnchor}
                setSearchValue={setAtipicasEscamosasSearch}
                setOptions={setAtipicasEscamosasOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        optionsAtipicasEscamosas.find(
                          (opt) => String(opt.value) === String(val)
                        )?.label
                    )

                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'celulasAtipicasEscamosas');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="celulasAtipicasGlandulares"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Células Atípicas... Glandulares"
                placeholder="Pesquisar por Células Atípicas... Glandulares"
                inputName="celulasAtipicasGlandulares"
                handleSetFilter={handleSetFilter}
                options={atipicasGlandularesOptions}
                allOptions={optionsAtipicasGlandulares}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={atipicasGlandularesSearch}
                anchorEl={atipicasGlandularesAnchor}
                setAnchorEl={setAtipicasGlandularesAnchor}
                setSearchValue={setAtipicasGlandularesSearch}
                setOptions={setAtipicasGlandularesOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        optionsAtipicasGlandulares.find(
                          (opt) => String(opt.value) === String(val)
                        )?.label
                    )
                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'celulasAtipicasGlandulares');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="celulasAtipicasOrigemIndefinida"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Células Atípicas... de Origem Indefinida"
                placeholder="Pesquisar por Células Atípicas... de Origem Indefinida"
                inputName="celulasAtipicasOrigemIndefinida"
                handleSetFilter={handleSetFilter}
                options={atipicasOrigemIndefinidaOptions}
                allOptions={optionsAtipiaOutros}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={atipicasOrigemIndefinidaSearch}
                anchorEl={celulasAtipicasAnchor}
                setAnchorEl={setCelulasAtipicasAnchor}
                setSearchValue={setAtipicasOrigemIndefinidaSearch}
                setOptions={setAtipicasOrigemIndefinidaOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        optionsAtipiaOutros.find(
                          (opt) => String(opt.value) === String(val)
                        )?.label
                    )
                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'celulasAtipicasOrigemIndefinida');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="atipiaCelulasGlandulares"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Atipias em Células Glandulares"
                placeholder="Pesquisar por cAtipias em Células Glandulares"
                inputName="atipiaCelulasGlandulares"
                handleSetFilter={handleSetFilter}
                options={atipiasGlandularesOptions}
                allOptions={optionsAtipiaGlandular}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={atipiasGlandularesSearch}
                anchorEl={atipiasGlandularesAnchor}
                setAnchorEl={setAtipiasGlandularesAnchor}
                setSearchValue={setAtipiasGlandularesSearch}
                setOptions={setAtipiasGlandularesOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        optionsAtipiaGlandular.find(
                          (opt) => String(opt.value) === String(val)
                        )?.label
                    )
                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'atipiaCelulasGlandulares');
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Controller
            name="atipiaCelulasEscamosas"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ModalSelect
                label="Atipias em Células Escamosas"
                placeholder="Pesquisar por Atipias em Células Escamosas"
                inputName="atipiaCelulasEscamosas"
                handleSetFilter={handleSetFilter}
                options={atipiasEscamosasOptions}
                allOptions={optionsAtipiaEscamosa}
                selectedValues={
                  Array.isArray(value) ? value : value ? [value] : []
                }
                displayKey="label"
                valueKey="value"
                disabled={disableOtherInputs}
                searchValue={atipiasEscamosasSearch}
                anchorEl={atipiaEscamosasAnchor}
                setAnchorEl={setAtipiaEscamosasAnchor}
                setSearchValue={setAtipiasEscamosasSearch}
                setOptions={setAtipiasEscamosasOptions}
                setAllOptions={() => {}}
                setSelectedValues={(newValue) => {
                  onChange(newValue);
                  const selectedValues = Array.isArray(newValue)
                    ? newValue
                    : [newValue];
                  const labels = selectedValues
                    .map(
                      (val) =>
                        optionsAtipiaEscamosa.find(
                          (opt) => String(opt.value) === String(val)
                        )?.label
                    )
                    .filter(Boolean)
                    .join(', ');
                  handleSetFilter(labels, 'atipiaCelulasEscamosas');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectWithSearch
            options={optionsLaboratorios}
            isLaboratorio
            label="Laboratório Responsável pelo Laudo"
            disabled={
              isLaboratorio
                ? true
                : disableOtherInputs || disabledLaboratorioResultado
            }
            selectedOptions={selectedOptionsLaboratorio}
            setSelectedOptions={setSelectedOptionsLaboratorio}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedLaboratorio,
              description: 'Laboratório Responsável pelo Laudo',
              fieldName: 'estabelecimentoLaudoId',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="profissionalResultadoId"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  disableClearable
                  data-testid="inputProfissionalResultadoId"
                  disabled={
                    isMedicoLaboratorio
                      ? true
                      : disableOtherInputs || disabledProfissionalResultado
                  }
                  options={optionsProfissionaisLaboratorio}
                  noOptionsText={
                    selectedLaboratorio?.length > 0
                      ? 'Não há opções'
                      : 'Selecione ao menos um laboratório para visualizar a lista de profissionais'
                  }
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    handleSetFilter(
                      `Profissional Responsável pelo Resultado: ${newValue?.nomeprofissional}`,
                      'profissionalResultadoId'
                    );
                  }}
                  value={value ? value : ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      data-testid="profissionalLaudo"
                      label="Profissional Responsável pelo Resultado"
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            gap={3}
          >
            <Typography fontWeight="bold">Data do Resultado:</Typography>

            <FormControl>
              <RadioGroup
                aria-labelledby="handleSetPeriod"
                name="handleSetPeriod"
                value={period}
                onChange={handleSetPeriod}
                row
              >
                <FormControlLabel
                  value="date"
                  control={<Radio />}
                  data-testid="filterPerDate"
                  label="Filtrar por Data"
                />
                <FormControlLabel
                  value="period"
                  data-testid="filterPerPeriod"
                  control={<Radio />}
                  label="Filtrar por período"
                />
              </RadioGroup>
            </FormControl>

            {period === 'period' && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                flex="1"
                rowGap={1}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    data-testid="periodDatepickerInitialData"
                    onChange={(date) => {
                      handleSetFilter(
                        'Início da Data do Resultado: ' +
                          moment(date).format('DD/MM/YYYY'),
                        'data_ini'
                      );
                      setDataColetaIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtColetaIni && moment(dtColetaIni, 'DD-MM-YYYY').isValid()
                        ? moment(dtColetaIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        error={!!errorInputDate?.ini}
                        label="Início da Data do Resultado"
                        size="small"
                        helperText={errorInputDate?.ini}
                        variant="outlined"
                        sx={{
                          minWidth: '200px',
                          marginBottom: !!errorInputDate?.ini ? '-22px' : '',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    data-testid="periodDatepickerEndData"
                    onChange={(date) => {
                      handleSetFilter(
                        'Fim da Data do Resultado: ' +
                          moment(date).format('DD/MM/YYYY'),
                        'data_fin'
                      );
                      setDataColetaFinal(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtColetaFinal &&
                      moment(dtColetaFinal, 'DD-MM-YYYY').isValid()
                        ? moment(dtColetaFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Fim da Data do Resultado"
                        size="small"
                        variant="outlined"
                        error={!!errorInputDate?.fin}
                        helperText={errorInputDate?.fin}
                        sx={{ minWidth: '200px' }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            )}

            {period === 'date' && (
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    data-testid="datePicker"
                    onChange={(date) => {
                      handleSetFilter(
                        'Data do Resultado: ' +
                          moment(date).format('DD/MM/YYYY'),
                        'data_fin'
                      );
                      setDataColetaIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      setDataColetaFinal(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtColetaIni && moment(dtColetaIni, 'DD-MM-YYYY').isValid()
                        ? moment(dtColetaIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Data do Resultado"
                        size="small"
                        variant="outlined"
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" gap={2} my={2}>
            <Controller
              name="search"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    disabled={disableOtherInputs}
                    data-testid="inputSearchByNameCpf"
                    label="Pesquisar"
                    error={!!errors.search}
                    helperText={errors?.search?.message}
                    size="small"
                    placeholder="Pesquise por Nome, Nº do Cartão SUS ou CPF da paciente"
                    sx={{
                      '.MuiOutlinedInput-root': { paddingRight: '9px' },
                    }}
                    value={value ? value : ''}
                    onChange={(e) => {
                      onChange(e.target.value);
                      handleSetFilter(e.target.value, 'search');
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <Button sx={{ padding: 0, minWidth: '24px' }}>
                          <SearchIcon
                            sx={{
                              cursor: 'pointer',
                              color: 'gray',
                            }}
                          />
                        </Button>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                );
              }}
            />

            <Button
              sx={{ padding: '6px 22px' }}
              variant="contained"
              type="submit"
              data-testid="submitFilterReportsCitologiaReflexo"
            >
              Filtrar
            </Button>

            <Button
              sx={{ padding: '6px 22px' }}
              variant="outlined"
              color="error"
              onClick={clearValues}
              data-testid="clearFilterReportsCitologiaReflexo"
            >
              Limpar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default FiltersReportsCitologiaReflexo;
