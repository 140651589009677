const DynamicHeaders = ({ opened, HeadersWhenHided }) => {
  return (
    <>
      <th>Paciente</th>
      <th>CPF</th>
      {opened ? (
        <>
          <th>Estado</th>
          <th>Município</th>
          <th>Estabelecimento</th>
        </>
      ) : (
        <HeadersWhenHided />
      )}
      <th></th>
    </>
  );
};

export default DynamicHeaders;
