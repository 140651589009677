import moment from 'moment';
import smartphone from '../../../../../../../../assets/imgs/Convocation/smartphone.svg';
import smartphoneDisabled from '../../../../../../../../assets/imgs/Convocation/smartphoneDisabled.svg';
import redSmartphone from '../../../../../../../../assets/imgs/Convocation/redSmartphone.svg';
import dot from '../../../../../../../../assets/imgs/Convocation/dot.svg';
import connector from '../../../../../../../../assets/imgs/Convocation/connector.svg';
import { Grid } from '@mui/material';

const ComponentConvocations = ({
  tipo,
  data_realizacao,
  numero_estava_correto,
  data_resposta,
  conditiionToDisableImg,
  acsResponsavelNome,
  statusConvocacao,
  motivoCancelamento,
  status,
}: any) => {
  const typeIsAutomaticConvocation = tipo.includes('convocatoria');

  return (
    <Grid
      item
      xs={12}
      md={4}
      style={{ display: 'flex', flexDirection: 'column', minWidth: '246px' }}
    >
      {statusConvocacao !== 'Cancelada' ? (
        <img
          src={conditiionToDisableImg ? smartphoneDisabled : smartphone}
          style={{ width: '64px' }}
        />
      ) : (
        <img src={redSmartphone} style={{ width: '64px' }} />
      )}

      <p
        style={{
          fontSize: '16px',
          fontWeight: 700,
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        {typeIsAutomaticConvocation && `${tipo?.slice(0, 1)}º Convocatória`}
        {!typeIsAutomaticConvocation && (
          <>
            Outras Convocações{' '}
            <p style={{ fontSize: '12px', fontWeight: 400, margin: 0 }}>
              {tipo}
            </p>
          </>
        )}
      </p>
      <div style={{ display: 'flex', width: '100%' }}>
        <img style={{ width: '12px' }} src={dot} />
        <p
          style={{
            fontWeight: 500,

            fontSize: '14px',
            margin: '0 0 0px 14px',
          }}
        >
          {typeIsAutomaticConvocation ? 'Recebida em:' : 'ACS Responsável'}
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          borderLeft: '2px #bdbdbd solid',
          marginLeft: '5px',
          paddingLeft: '5px',
          marginTop: '10px',
        }}
      >
        <p
          style={{
            fontWeight: 400,

            fontSize: '14px',
            margin: '0 0 0 14px',
            paddingTop: '7px',
          }}
        >
          {typeIsAutomaticConvocation
            ? moment(data_realizacao).format('DD/MM/YYYY [às] HH[h]mm')
            : acsResponsavelNome}
        </p>
      </div>

      <div style={{ display: 'flex', width: '100%', marginTop: '10px' }}>
        <img style={{ width: '12px' }} src={dot} />
        <p
          style={{
            fontWeight: 500,

            fontSize: '14px',
            margin: '0px 0 0px 14px',
          }}
        >
          {typeIsAutomaticConvocation
            ? 'Número estava correto?'
            : 'Resultado da Convocação'}
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          borderLeft: '2px #bdbdbd solid',
          marginLeft: '5px',
          paddingLeft: '5px',
        }}
      >
        <p
          style={{
            fontWeight: 400,

            fontSize: '14px',
            margin: '0 0 0 14px',
            // paddingTop: '7px',
          }}
        >
          {typeIsAutomaticConvocation ? (
            <>
              {numero_estava_correto}
              {numero_estava_correto === 'Sim' && data_resposta ? (
                <p
                  style={{
                    fontSize: '12px',
                    fontStyle: 'italic',
                    margin: 0,
                    width: '175px',
                    marginTop: '-17px',
                  }}
                >
                  <br />
                  Respondido em{' '}
                  {moment(data_resposta).format(`DD/MM/YYYY [às] HH[h]mm`)}
                </p>
              ) : undefined}
            </>
          ) : (
            <>
              {' '}
              <p style={{ margin: 0, marginTop: '5px' }}>{status}</p>
              <span
                style={{
                  fontSize: '12px',
                  margin: 0,
                  marginTop: '5px',
                  fontStyle: 'italic',
                }}
              >
                Busca Ativa registrada em{' '}
                {moment(data_realizacao).format(`DD/MM/YYYY`)}
              </span>
            </>
          )}
        </p>
      </div>

      {statusConvocacao === 'Cancelada' && (
        <>
          <div style={{ display: 'flex', width: '100%', marginTop: '10px' }}>
            <img style={{ width: '12px' }} src={dot} />
            <p
              style={{
                fontWeight: 500,

                fontSize: '14px',
                margin: '0px 0 0px 14px',
              }}
            >
              Motivo
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              borderLeft: '2px #bdbdbd solid',
              marginLeft: '5px',
              paddingLeft: '5px',
            }}
          >
            <p
              style={{
                fontWeight: 400,

                fontSize: '14px',
                margin: '0 0 0 14px',
                // paddingTop: '7px',
              }}
            >
              {motivoCancelamento}
            </p>
          </div>
        </>
      )}
    </Grid>
  );
};

export default ComponentConvocations;
