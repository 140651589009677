import { Button, Grid } from '@mui/material';
import ComponentHpv from '../ComponentHpvs';
import moment from 'moment';
import ComponentSchedules from '../ComponentSchedules';

const ListSchedules = ({
  schedules,
  showAllSchedules,
  setShowAllSchedules,
  objConvocation,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '246px',
        justifyContent: 'flex-start',
        marginLeft: showAllSchedules ? '0px' : '82px',
      }}
    >
      <Grid container>
        <ComponentSchedules
          schedule={schedules[0]}
          objConvocation={objConvocation}
        />
        {schedules.length > 1 && (
          <>
            {showAllSchedules && (
              <>
                {schedules.map((schedule, index) => {
                  if (index > 0) {
                    return (
                      <ComponentSchedules
                        schedule={schedule}
                        objConvocation={objConvocation}
                      />
                    );
                  }
                })}
              </>
            )}
          </>
        )}

        {!showAllSchedules && (
          <>
            {schedules.length > 1 && (
              <Button
                onClick={() => setShowAllSchedules(true)}
                sx={{
                  height: '22px',
                  fontSize: '13px',
                  width: '100%',
                  marginTop: '12px',
                  color: '#212121',
                }}
              >
                + VER TODOS 'AGENDAMENTOS' ({schedules.length})
              </Button>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default ListSchedules;
