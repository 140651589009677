import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Chart from 'react-apexcharts';
import { Box, Grid, Typography } from '@mui/material';

import api from '../../../../api';
import { getUserToken } from '../../../../lib/auth';
import { LoadingContext } from '../../../../context/LoadingContext';

import styles from './styles.module.css';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';

import { BarChart } from '../components/barChart';
import ItemGraphic from '../components/RealizationProcedurePosNotRealized/ItemGraphic';
import { PieChart2 } from '../components/pieChart2';
import { TableResults } from '../components/tableResults';
import FiltersVisaoGeral from '../components/filters/FilterVisaoGeral';
import EmptyData from '../../../../components/Charts/emptyData';
import { AreaChart } from '../components/areaChart';

import { DataSource } from '../../hpv/components/dataSource';
import { ChartCard } from '../../hpv/components/chartCard';
import useStatesFields from '../../../../components/MultipleFilters/hooks/useStatesFields';

interface FilterItem {
  inputName: string;
  value: string;
}

interface ExamResultsData {
  negativo: number[];
  alteracao: number[];
  insatisfatorio: number[];
}

interface ExamResultItem {
  count_negativo: string;
  count_alteracao: string;
  count_insatisfatorio: string;
  month: string;
  month_n: number;
  year: number;
}

interface ProcessedExamResults {
  series: {
    negativo: number[];
    alteracao: number[];
    insatisfatorio: number[];
  };
  categories: (string | string[])[];
}

interface QuantityMonthData {
  year: number;
  month: string;
  month_n: number;
  count: string;
}

interface AtypicalCellsData {
  escamosa: { count_neoplasica: number; count_lesao: number };
  glandular: { count_neoplasica: number; count_lesao: number };
  indefinida: { count_neoplasica: number; count_lesao: number };
}

interface GlandularAdenomaData {
  label: string;
  count: number;
}

interface SquamousCellAtypiaData {
  tipo: string;
  count: number;
}

interface DataRow {
  label: string;
  hpv_outros_count: number;
  citologia_realizar: number;
  citologia_count: number;
  citologia_count_total: number;
}

interface Payload {
  params: {
    estados?: string | string[];
    municipios?: string | string[];
    estabelecimentos?: string | string[];
    periodo_inicio?: string;
    periodo_fim?: string;
    tipo?: string;
    page?: number;
    size?: number;
    [key: string]: any;
  };
}

const initialStateListFilter: FilterItem[] = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'periodo', value: '' },
];

export function VisaoGeral() {
  const [listFilter, setListFilter] = useState<FilterItem[]>(
    initialStateListFilter
  );
  const [showListFilter, setShowListFilter] = useState<FilterItem[]>([]);
  const [estadosSelected, setEstadosSelected] = useState<any[]>([]);
  const [municipiosSelected, setMunicipiosSelected] = useState<any>([]);
  const [estabelecimentosSelected, setEstabelecimentosSelected] = useState<any>(
    []
  );
  const [totalTestsPerMonth, setTotalTestsPerMonth] = useState<number[]>([]);
  const [categories, setCategories] = useState<string[]>(['NÃO HÁ DADOS']);
  const [payload, setPayload] = useState<Payload>({
    params: {
      page: 1,
      size: 10,
      tipo: 'Reflexo',
    },
  });
  const [tableTotal, setTableTotal] = useState({
    hpv: 0,
    citologia: 0,
    realizar: 0,
    citologia_total: 0,
  });
  const [countLoading, setCountLoading] = useState(0);
  const [tableData, setTableData] = useState<DataRow[]>([]);
  const [totalTableItems, setTotalTableItems] = useState(0);
  const [period, setPeriod] = useState<any>({});

  const [valuePeriodoModal, setValuePeriodoModal] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [periodToDisplay, setPeriodToDisplay] = useState('Todos os períodos');
  const [valueInputPeriod, setValueInputPeriod] = useState<any>();
  const [shouldApplyFilters, setShouldApplyFilters] = useState(false);
  const [defaultState, setDefaultState] = useState('');
  const [total, setTotal] = useState<any>();
  const [needsRequiredFilters, setNeedsRequiredFilters] = useState(true);
  const [disableGraphicsOnHover, setDisableGraphicsOnHover] = useState(0);
  const [examResultsData, setExamResultsData] = useState<ExamResultsData>({
    negativo: [],
    alteracao: [],
    insatisfatorio: [],
  });

  const [examResultsCategories, setExamResultsCategories] = useState<
    (string | string[])[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [atypicalCells, setAtypicalCells] = useState<AtypicalCellsData>({
    escamosa: { count_neoplasica: 0, count_lesao: 0 },
    glandular: { count_neoplasica: 0, count_lesao: 0 },
    indefinida: { count_neoplasica: 0, count_lesao: 0 },
  });

  const [glandularAdenoma, setGlandularAdenoma] = useState<
    GlandularAdenomaData[]
  >([]);
  const [squamousCellAtypia, setSquamousCellAtypia] = useState<
    SquamousCellAtypiaData[]
  >([]);
  const [filtersData, setFiltersData] = useState<any>();

  const refRealized = useRef<HTMLSpanElement>(null);
  const refNotRealized = useRef<HTMLSpanElement>(null);
  const { control } = useForm();
  const userToken = getUserToken();

  const { setEstadosOptions, setOptionsMunicipios } = useStatesFields();

  const navigate = useNavigate();

  const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';

  const isAdminLaboratorio = userToken.permissao_atual?.id === 6;
  const isMunicipal = userToken.permissao_atual?.id === 3;
  const isEstadual = userToken.permissao_atual?.id === 4;
  const isLaboratorio = userToken.permissao_atual?.contexto === 'LABORATÓRIO';

  const { setLoading } = useContext(LoadingContext);

  const determineListType = () => {
    if (estabelecimentosSelected.length > 0) {
      return 'estabelecimentos';
    } else if (municipiosSelected.length > 0) {
      return 'municipios';
    } else {
      return 'estados';
    }
  };

  useEffect(() => {
    if (isAdminLaboratorio) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
    }

    if (isMunicipal) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
      setMunicipiosSelected(
        filtersData?.municipios ? [filtersData?.municipios] : []
      );
    }

    if (isEstadual) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
    }
  }, [filtersData]);

  useEffect(() => {
    if (totalTestsPerMonth.length > 0) {
      setTotal(totalTestsPerMonth.reduce((prev, curr) => prev + curr, 0));
    } else {
      setTotal(null);
    }
  }, [totalTestsPerMonth]);

  const setDefaultEstado = ({ sigla }) => {
    setEstadosOptions([
      {
        sigla: sigla,
      },
    ]);
    setEstadosSelected([sigla]);
  };

  const setDefaultMunicipio = ({ municipioId, municipioNome }) => {
    setOptionsMunicipios({
      id: municipioId,
      nome: municipioNome,
    });
    setMunicipiosSelected([municipioId]);
  };

  useEffect(() => {
    if (isSuperAdmin) {
      setCountLoading(9);
      return;
    }
    if (isEstadual) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude?.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        { inputName: 'estabelecimento_saude', value: '' },
        { inputName: 'equipe', value: '' },
        { inputName: 'periodo_inicio', value: 'Período: Todos os períodos' },
        { inputName: 'periodo_fim', value: '' },
      ];

      setListFilter(listFilter);
      setDefaultEstado({
        sigla: userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
      });

      setCountLoading(9);
    }

    if (isAdminLaboratorio) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude?.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        { inputName: 'estabelecimento_saude', value: '' },
        { inputName: 'equipe', value: '' },
        { inputName: 'periodo_inicio', value: 'Período: Todos os períodos' },
        { inputName: 'periodo_fim', value: '' },
      ];

      setListFilter(listFilter);
      setDefaultEstado({
        sigla: userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
      });
    }

    if (isMunicipal) {
      setDefaultEstado({
        sigla: userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
      });
      setDefaultMunicipio({
        municipioId: String(
          userToken.estabelecimento_saude?.endereco.municipio.id
        ),
        municipioNome: userToken.estabelecimento_saude?.endereco.municipio.nome,
      });
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude?.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: `Municípios: ${userToken.estabelecimento_saude?.endereco.municipio.nome}`,
        },
        {
          inputName: 'estabelecimento_saude',
          value: 'Estabelecimento de Saúde: Vários',
        },
        { inputName: 'equipe', value: '' },
        { inputName: 'periodo_inicio', value: 'Período: Todos os períodos' },
        { inputName: 'periodo_fim', value: '' },
      ];

      setListFilter(listFilter);
      setShowListFilter(listFilter);
      getAllCharts({
        cleared: false,
        payload: {
          params: {
            ...payload?.params,
          },
        },
      });

      setValueInputPeriod('Todos');
      setValuePeriodoModal({
        label: 'Todos',
        value: 'all',
      });
      setPeriod({ periodo_inicio: '2022-01-01' });
    }
  }, []);

  const handleMouseMove = (event: React.MouseEvent) => {
    const element = document.elementFromPoint(event.clientX, event.clientY);
    setDisableGraphicsOnHover(
      element === refRealized.current
        ? 1
        : element === refNotRealized.current
        ? 2
        : 0
    );
  };

  const calculateSum = (data: number[]) =>
    data.reduce((sum, value) => sum + value, 0);

  const getTestesPerMonth = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/quantity-month',
        {
          params: { ...payload.params, tipo: 'Reflexo' },
        }
      );

      if (response.data.data.length === 0) {
        setCategories(['NÃO HÁ DADOS']);
      } else {
        setCategories(
          response.data?.data.map((item, index) => {
            if (index === 0 || item?.month === 'Jan') {
              return [
                `${String(item?.month)?.toUpperCase()}`,
                `${String(item?.year)}`,
              ];
            } else {
              return String(item?.month).toUpperCase();
            }
          })
        );
      }

      setTotalTestsPerMonth(
        response.data?.data.map((item) => Number(item?.count))
      );

      setFiltersData(response.data.filters);
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      console.error('Error fetching tests per month:', error);
    }
  };

  const getExamResults = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/resultados',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );

      const data = response.data?.data || [];

      const categories = data.map((item, index) => {
        if (index === 0 || item.month === 'Jan') {
          return [item.month.toUpperCase(), String(item.year)];
        }
        return item.month.toUpperCase();
      });

      const series = {
        negativo: data.map((item) => item.count_negativo),
        alteracao: data.map((item) => item.count_alteracao),
        insatisfatorio: data.map((item) => item.count_insatisfatorio),
      };

      setExamResultsCategories(categories);
      setExamResultsData(series);
    } catch (error) {
      console.error('Error fetching exam results:', error);
      setExamResultsCategories([]);
      setExamResultsData({
        negativo: [],
        alteracao: [],
        insatisfatorio: [],
      });
    }
  };

  const getAtypicalCells = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/celulas-atipicas',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );
      if (response.data?.data) {
        setAtypicalCells({
          escamosa: {
            count_neoplasica: parseInt(
              response.data.data.escamosa.count_neoplasica ?? 0
            ),
            count_lesao: parseInt(response.data.data.escamosa.count_lesao ?? 0),
          },
          glandular: {
            count_neoplasica: parseInt(
              response.data.data.glandular.count_neoplasica ?? 0
            ),
            count_lesao: parseInt(
              response.data.data.glandular.count_lesao ?? 0
            ),
          },
          indefinida: {
            count_neoplasica: parseInt(
              response.data.data.indefinida.count_neoplasica ?? 0
            ),
            count_lesao: parseInt(
              response.data.data.indefinida.count_lesao ?? 0
            ),
          },
        });
      }
    } catch (error) {
      console.error('Error fetching atypical cells data:', error);
      setAtypicalCells({
        escamosa: { count_neoplasica: 0, count_lesao: 0 },
        glandular: { count_neoplasica: 0, count_lesao: 0 },
        indefinida: { count_neoplasica: 0, count_lesao: 0 },
      });
    }
  };

  const getGlandularAdenoma = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/celulas-glandulares-adeno',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );
      const data = response.data?.data || [];

      const cleanLabel = (label: string): string[] => {
        const cleanedLabel = label.replace(/Adenocarcinoma\s*/g, '').trim();

        if (cleanedLabel.toLowerCase().includes('invasor')) {
          if (cleanedLabel.toLowerCase().includes('em outras especificações')) {
            return ['Invasor', 'em outras', 'especificações'];
          } else {
            const parts = cleanedLabel.split(/\s+/);
            const invasorIndex = parts.findIndex(
              (part) => part.toLowerCase() === 'invasor'
            );
            if (invasorIndex !== -1) {
              parts[invasorIndex] = 'Invasor';
              return [
                parts.slice(0, invasorIndex + 1).join(' '),
                ...parts.slice(invasorIndex + 1),
              ];
            }
          }
        } else if (cleanedLabel.toLowerCase().includes('in situ')) {
          return ['In situ'];
        }

        return [cleanedLabel];
      };

      setGlandularAdenoma(
        data.map((item) => ({
          label: cleanLabel(item.label),
          count: parseInt(item.count),
        }))
      );
    } catch (error) {
      console.error('Error fetching glandular adenoma data:', error);
    }
  };

  const getSquamousCellAtypia = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/celulas-escamosas',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );
      const data = response.data?.data || [];
      setSquamousCellAtypia(
        data.map((item) => ({
          tipo: item.tipo,
          count: parseInt(item.count),
        }))
      );
    } catch (error) {
      console.error('Error fetching squamous cell atypia data:', error);
    }
  };

  const fetchTableData = async (currentPayload: Payload) => {
    try {
      let params = {
        ...currentPayload.params,
        page: currentPayload.params.page,
        size: currentPayload.params.size,
      };

      if (shouldApplyFilters) {
        if (!estadosSelected.length) {
          params = { ...params, estados: JSON.stringify([defaultState]) };
        } else {
          params = {
            ...params,
            estados: estadosSelected,
            municipios: municipiosSelected.join(', '),
            estabelecimentos: estabelecimentosSelected.join(', '),
          };
        }
      }

      const response = await api.get(
        '/dashboard/exames/citologia/panel/quantity-citologia-outros',
        { params }
      );
      setTableData(response.data.data.data);
      setTotalTableItems(response.data.data.totalItems);
      setTableTotal(response.data.data.total);
    } catch (error) {
      console.error('Error fetching table data:', error);
    }
  };

  useEffect(() => {
    if (
      !userToken ||
      !userToken.permissoes ||
      !userToken.permissoes.includes('VIEW_DASHBOARD')
    ) {
      navigate('/home');
    }
  }, [userToken, navigate]);

  useEffect(() => {
    if (payload && payload.params) {
      fetchTableData(payload);
    }
  }, [payload]);

  useEffect(() => {
    setShouldApplyFilters(false);
  }, [estadosSelected, municipiosSelected, estabelecimentosSelected]);

  const handleTablePageChange = (newPage: number) => {
    setPayload((prevPayload) => {
      const newPayload = {
        ...prevPayload,
        params: { ...prevPayload.params, page: newPage + 1 },
      };
      return newPayload;
    });
  };

  const handleChartClick = (chartName: string, itemClicked: string) => {
    console.log('=== Debug Citologia Reflexo Click ===');
    console.log('1. Chart clicked:', { chartName, itemClicked });

    localStorage.removeItem('result');
    localStorage.removeItem('typeVirus');
    localStorage.removeItem('reasonNotProcedure');
    localStorage.removeItem('collectionOrigin');
    localStorage.removeItem('chartName');
    localStorage.removeItem('itemClicked');
    localStorage.removeItem('requestFromTable');

    localStorage.setItem('chartName', chartName);
    localStorage.setItem('itemClicked', itemClicked);
    localStorage.setItem('requestFromTable', 'true');
    localStorage.setItem('payload', JSON.stringify(payload));

    navigate('/relatorios/citologia-reflexo');
  };

  const handleGlandularAdenomaClick = (
    event: any,
    chartContext: any,
    config: any
  ) => {
    handleChartClick('glandularAdenoma', config.selectedLabel);
  };

  const handleSquamousCellAtypiaClick = (
    event: any,
    chartContext: any,
    config: any
  ) => {
    handleChartClick('squamousCellAtypia', config.selectedLabel);
  };

  const redirectToReports = () => {
    localStorage.removeItem('result');
    localStorage.removeItem('typeVirus');
    localStorage.removeItem('reasonNotProcedure');
    localStorage.removeItem('collectionOrigin');
    localStorage.removeItem('chartName');
    localStorage.removeItem('itemClicked');
    localStorage.removeItem('requestFromTable');

    navigate('/relatorios/citologia-reflexo', {
      state: {
        origin: 'tables',
        payload: payload,
        panelClicked: 'totalTests',
      },
    });
  };

  const handleTableRowsPerPageChange = (newRowsPerPage: number) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      params: { ...prevPayload.params, size: newRowsPerPage, page: 1 },
    }));
  };

  const getAllCharts = async ({ cleared, payload: newPayload }) => {
    setNeedsRequiredFilters(false);
    setCountLoading(0);
    localStorage.setItem('coleta_inicio', newPayload?.periodo_inicio);
    localStorage.setItem('coleta_fim', newPayload?.periodo_fim);
    setIsLoading(true);
    setLoading(true);

    try {
      const updatedPayload = cleared
        ? newPayload
        : {
            ...newPayload,
            params: {
              ...newPayload.params,
              estados: estadosSelected,
              municipios: municipiosSelected.join(', '),
              estabelecimentos: estabelecimentosSelected.join(', '),
            },
          };
      setPayload(updatedPayload);
      setShouldApplyFilters(true);

      const commonParams = {
        ...updatedPayload.params,
        tipo: 'Reflexo',
      };

      await Promise.all([
        getTestesPerMonth(cleared, { params: commonParams }),
        getExamResults(cleared, { params: commonParams }),
        getAtypicalCells(cleared, { params: commonParams }),
        getGlandularAdenoma(cleared, { params: commonParams }),
        getSquamousCellAtypia(cleared, { params: commonParams }),
        fetchTableData({ params: commonParams }),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };

  const emptyDataChartExamResult = examResultsCategories.length === 0;

  const emptyDataChartCelulaAtipicaIndeterminado =
    Object.keys(atypicalCells).filter((valueName) => {
      return (
        atypicalCells[valueName].count_neoplasica > 0 ||
        atypicalCells[valueName].count_lesao > 0
      );
    }).length === 0;

  const chartOptions = {
    chart: {
      height: 350,
      zoom: { enabled: false },
      toolbar: { show: false },
      type: 'line' as const,
      events: {
        dataPointSelection: function (event, chartContext, config) {
          if (config.dataPointIndex > -1) {
            const series = ['alteracao', 'negativo', 'insatisfatorio'];
            const clickedSeries = series[config.seriesIndex];
            handleChartClick('resultadoExame', clickedSeries);
          }
        },
      },
    },
    stroke: {
      width: 3,
      curve: 'straight' as const,
      dashArray: [],
    },
    dataLabels: { enabled: false },
    markers: { size: 0 },
    xaxis: {
      categories: examResultsCategories,
      labels: {
        style: {
          fontSize: '12px',
        },
        minHeight: 40,
        formatter: function (value) {
          if (Array.isArray(value)) {
            return value;
          }
          return value;
        },
      },
    },
    colors: ['#f44336', '#238884', '#9e9e9e'],
    tooltip: {
      y: { formatter: (val: number) => `${val} exames` },
    },
    legend: {
      show: true,
      position: 'top' as const,
      itemMargin: {
        horizontal: 25,
        vertical: 5,
      },
      formatter: (seriesName, opts) => {
        const dataSum = calculateSum(opts.w.globals.series[opts.seriesIndex]);
        return `${seriesName} (${dataSum})`;
      },
    },
    fill: {
      type: 'solid',
      opacity: 0.4,
    },
  };

  return (
    <Box mt="31px">
      <Box sx={{ marginBottom: '42px' }}>
        <FiltersVisaoGeral
          period={period}
          setPeriod={setPeriod}
          valuePeriodoModal={valuePeriodoModal}
          setValuePeriodoModal={setValuePeriodoModal}
          setNeedsRequiredFilters={setNeedsRequiredFilters}
          setTotalTableItems={setTotalTableItems}
          setTableTotal={setTableTotal}
          setTotalTestsPerMonth={setTotalTestsPerMonth}
          setTotal={setTotal}
          setExamResultsData={setExamResultsData}
          setAtypicalCells={setAtypicalCells}
          setGlandularAdenoma={setGlandularAdenoma}
          setSquamousCellAtypia={setSquamousCellAtypia}
          setTableData={setTableData}
          setPeriodToDisplay={setPeriodToDisplay}
          valueInputPeriod={valueInputPeriod}
          setValueInputPeriod={setValueInputPeriod}
          payload={payload}
          setPayload={setPayload}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          setEstadosSelected={setEstadosSelected}
          setMunicipiosSelected={setMunicipiosSelected}
          municipiosSelected={municipiosSelected}
          estadosSelected={estadosSelected}
          estabelecimentosSelected={estabelecimentosSelected}
          control={control}
          setShowListFilter={setShowListFilter}
          getAllCharts={getAllCharts}
          setListFilter={setListFilter}
          initialStateListFilter={initialStateListFilter}
          listFilter={listFilter}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="2px">
        {/* <Typography variant="h5" fontWeight="bold" fontSize="24px">
          Testes de Citologia - Reflexo - Visão Geral
        </Typography>
        <Typography variant="h6" fontWeight="bold" fontSize="20px">
          {periodToDisplay}
        </Typography> */}

        <DisplayFilter style={{ marginTop: '17px', marginBottom: '37px' }}>
          <p style={{ margin: 0, width: '138px' }}>Filtros aplicados:</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {showListFilter.map((item) => (
              <>
                {item.value ? (
                  <>
                    <ItemsDisplayFilter>
                      {item.value}
                      {payload?.params?.dash && ' - dash'}
                    </ItemsDisplayFilter>
                  </>
                ) : undefined}
              </>
            ))}
          </div>
        </DisplayFilter>
      </Box>

      <div className={styles.testsDoneCard}>
        <div className={styles.testsDoneCardTitle}>
          <Typography variant="h5" fontWeight="bold">
            Exames de Citologia - Reflexo
            <br />
            Total de Exames Realizados
          </Typography>

          <p style={{ margin: 0, fontSize: '12px', color: '#9E9E9E' }}>
            {valueInputPeriod === 'Todos' || !valueInputPeriod
              ? 'Todos os períodos'
              : valueInputPeriod}
          </p>

          <Typography
            variant="h5"
            fontWeight="bold"
            style={{ cursor: 'pointer' }}
            onClick={redirectToReports}
          >
            {total ? total?.toLocaleString('pt-BR') : ''}
          </Typography>
        </div>
        <AreaChart
          data={totalTestsPerMonth}
          categories={categories}
          payload={payload}
          total={total}
          onClick={redirectToReports}
          needsRequiredFilters={needsRequiredFilters}
        />
        <DataSource />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Resultado do Exame"
            valueInputPeriod={valueInputPeriod}
          >
            <div className="chartOnCenter" style={{ position: 'relative' }}>
              {emptyDataChartExamResult ? (
                <div style={{ position: 'absolute', left: '40%', zIndex: '1' }}>
                  <EmptyData needsRequiredFilters={needsRequiredFilters} />
                </div>
              ) : undefined}
              <Chart
                options={{
                  ...chartOptions,
                  chart: {
                    ...chartOptions.chart,
                    events: {
                      click(e, chart, options) {
                        if (options.dataPointIndex > -1) {
                          const series = [
                            'alteracao',
                            'negativo',
                            'insatisfatorio',
                          ];
                          const clickedSeries = series[options.seriesIndex];
                          handleChartClick('resultadoExame', clickedSeries);
                        }
                      },
                      // dataPointSelection: function (
                      //   event,
                      //   chartContext,
                      //   config
                      // ) {
                      //   if (config.dataPointIndex > -1) {
                      //     const series = [
                      //       'alteracao',
                      //       'negativo',
                      //       'insatisfatorio',
                      //     ];
                      //     const clickedSeries = series[config.seriesIndex];
                      //     handleChartClick('resultadoExame', clickedSeries);
                      //   }
                      // },
                    },
                  },
                }}
                series={[
                  {
                    name: 'Com Alterações Epiteliais',
                    data: examResultsData.alteracao,
                  },
                  { name: 'Negativo', data: examResultsData.negativo },
                  {
                    name: 'Insatisfatório',
                    data: examResultsData.insatisfatorio,
                  },
                ]}
                type="line"
                height={350}
              />
            </div>
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Células Atípicas de Significado Indeterminado"
            valueInputPeriod={valueInputPeriod}
          >
            {emptyDataChartCelulaAtipicaIndeterminado ? (
              <EmptyData needsRequiredFilters={needsRequiredFilters} />
            ) : (
              <>
                <div className="boxData" onMouseMove={handleMouseMove}>
                  <div
                    className="boxDatalegends"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      marginTop: '60px',
                      marginBottom: '16px',
                      fontSize: '14px',
                    }}
                  >
                    <div
                      className="legendItem"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <div
                        className="boxDatalegendscolorizedLegend"
                        style={{
                          backgroundColor: '#7b61ff',
                          width: '12px',
                          height: '12px',
                          borderRadius: '2px',
                          display: 'inline-block',
                          marginRight: '8px',
                          flexShrink: 0,
                          marginTop: '4px',
                        }}
                      />
                      <span ref={refRealized} style={{ lineHeight: '1.2' }}>
                        Possívelmente não neoplásticas (ASC-US)
                      </span>
                    </div>
                    <div
                      className="legendItem"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <div
                        className="boxDatalegendscolorizedLegend"
                        style={{
                          backgroundColor: '#ed6c02',
                          width: '12px',
                          height: '12px',
                          borderRadius: '2px',
                          display: 'inline-block',
                          marginRight: '8px',
                          flexShrink: 0,
                          marginTop: '4px',
                        }}
                      />
                      <span ref={refNotRealized} style={{ lineHeight: '1.2' }}>
                        Não se pode afastar lesão de alto grau (ASC-H)
                      </span>
                    </div>
                  </div>
                  <div
                    className="boxDatacontent"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '220px',
                      width: '100%',
                      padding: '10px 20px',
                      boxSizing: 'border-box',
                    }}
                  >
                    {Object.entries(atypicalCells).map(
                      ([key, value], index) => (
                        <ItemGraphic
                          key={index}
                          value1={value.count_neoplasica}
                          value2={value.count_lesao}
                          label={
                            key === 'indefinida'
                              ? 'De origem indefinida'
                              : key.charAt(0).toUpperCase() + key.slice(1)
                          }
                          disableGraphicsOnHover={disableGraphicsOnHover}
                          onClick={() =>
                            handleChartClick('celulasAtipicas', key)
                          }
                        />
                      )
                    )}
                  </div>
                </div>
              </>
            )}
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Atipias em Células Glandulares Adenocarcinoma"
            valueInputPeriod={valueInputPeriod}
          >
            <BarChart
              data={glandularAdenoma.map((item) => item.count)}
              categories={glandularAdenoma.map((item) => item.label)}
              colors={['#2dc4cc']}
              needsRequiredFilters={needsRequiredFilters}
              onClick={handleGlandularAdenomaClick}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Atipias em Células Escamosas"
            valueInputPeriod={valueInputPeriod}
          >
            <PieChart2
              needsRequiredFilters={needsRequiredFilters}
              data={squamousCellAtypia.map((item) => item.count)}
              height={284}
              labels={squamousCellAtypia.map((item) => item.tipo)}
              colors={['#bdbdbd', '#2196f3', '#ffb547', '#f44336']}
              legendWidth={500}
              customLegend={(seriesName, opts) => {
                const index = opts.seriesIndex;
                const count = squamousCellAtypia[index]?.count || 0;
                return `${seriesName} (${count})`;
              }}
              onClick={handleSquamousCellAtypiaClick}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12}>
          <Box mt="2px">
            <TableResults
              data={tableData}
              valueInputPeriod={valueInputPeriod}
              totalItems={totalTableItems}
              onPageChange={handleTablePageChange}
              onRowsPerPageChange={handleTableRowsPerPageChange}
              page={(payload.params.page || 1) - 1}
              rowsPerPage={payload.params.size || 10}
              total={tableTotal}
              listType={determineListType()}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
