import { LocalizationProvider, StaticDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CancelScheduleModal } from '../modals/cancelScheduleModal';
import { ModalScheduling } from '../modals/modalScheduling';
import { ScheduleListItem } from '../scheduleListItem';
import { useStyles } from './styles';
import EmptySchedule from '../.../../../../../assets/imgs/empty_schedule.png';
import { Link, useLocation } from 'react-router-dom';
import { getUsersAgenda } from '../../../../lib/profissionais';
import { getProfessionalSchedule } from '../../../../lib/professionalSchedule';
import moment from 'moment';

import api from '../../../../api';
import RealizeSchedule from '../modals/realizeSchedule';
import { EstablishmentContext } from '../../../../context/EstablishmentContext';
import { toast } from 'react-toastify';
import { PerfilContext } from '../../../../context/PerfilContext';
import { getUserToken } from '../../../../lib/auth';
import { LoadingContext } from '../../../../context/LoadingContext';

interface PatientData {
  time: string;
  appointmentStatus: string;
  patient: string;
  exam: string;
  scheduleId: string | null;
  scheduleData: any;
  estabelecimentoId: string | null;
  estabelecimentoEndereco: string | null;
}

export const ProfessionalsSchedule = ({
  setDisableAutomaticFill,
  disableAutomaticFill,
  listProfessionals,
  setListProfessionals,
  selectedProfessional,
  setSelectedProfessional,
}) => {
  const { control, setValue: setValueInForm } = useForm();
  const [value, setValue] = useState<any>(null);
  const styles = useStyles();
  const [emptySchedule, setEmptySchedule] = useState(true);
  const [realizeSchedule, setRealizeSchedule] = useState(false);
  const [cancelSchedule, setCancelSchedule] = useState(false);
  const [notScheduled, setNotScheduled] = useState(false);
  const [patientSelected, setPatientSelected] = useState('');
  const [patientData, setPatientData] = useState<PatientData>({
    time: '',
    appointmentStatus: '',
    patient: '',
    exam: '',
    scheduleId: null,
    scheduleData: {},
    estabelecimentoId: '',
    estabelecimentoEndereco: '',
  });

  const [loadingProfissionais, setLoadingProfissionais] = useState<any>([]);

  const [dates, setDates] = useState<any>();
  const [datesToSelect, setDatesToSelect] = useState<any>([]);

  const [datesWithSchedule, setDatesWithSchedule] = useState<any>();
  const [selectedDayTimes, setSelectedDayTimes] = useState<any>([]);
  const [selectedDay, setSelectedDay] = useState<any>();
  const [patientsUbs, setPatientsUbs] = useState<any>([]);

  const [showComponentsAgenda, setShowComponentsAgenda] = useState(false);

  const location = useLocation();

  const objToEditPatient = {
    listProfessionals,
    selectedProfessional,
    emptySchedule,
    dates,
    datesWithSchedule,
    selectedDay,
    value,
    selectedDayTimes,
    showComponentsAgenda,
    patientData,
    patientSelected,
    realizeSchedule,
    cancelSchedule,
  };

  const { setLoading } = useContext(LoadingContext);
  const handleCloseModal = () => {
    setPatientSelected('');
    setPatientData({
      time: '',
      appointmentStatus: '',
      patient: '',
      exam: '',
      scheduleId: null,
      scheduleData: {},
      estabelecimentoId: '',
      estabelecimentoEndereco: '',
    });

    setCancelSchedule(false);
    setNotScheduled(false);
    setRealizeSchedule(false);
  };

  const handleOpenModal = ({
    data,
    modalToOpen,
    scheduleId,
    scheduleData,
    estabelecimentoId,
    estabelecimentoEndereco,
  }: any) => {
    const [time, appointmentStatus, patient, exam] = data.split('-');

    setPatientData({
      time: time?.trim(),
      appointmentStatus: appointmentStatus?.trim(),
      patient: patient?.trim() || '',
      exam: exam?.trim() || '',
      scheduleId: modalToOpen === 'cancel_schedule' ? scheduleId : null,
      scheduleData,
      estabelecimentoId: selectedProfessional?.estabelecimentoProfissional,
      estabelecimentoEndereco: selectedProfessional?.enderecoEstabelecimento,
    });

    setPatientSelected(patient || '');
    setRealizeSchedule(true);

    if (modalToOpen === 'realize_schedule') {
      setRealizeSchedule(true);
      setCancelSchedule(false);
    } else if (modalToOpen === 'cancel_schedule') {
      setCancelSchedule(true);
      setRealizeSchedule(false);
    }
  };

  const { getPermissao, hasPermission } = useContext(PerfilContext);

  const permissao = getPermissao();

  const userToken = getUserToken();

  const { currentEstablishment } = useContext(EstablishmentContext);

  const currentEstablishmentId = localStorage.getItem('id_last_establishment');

  const isSuperAdmin = userToken.permissao_atual.nome === 'SuperAdmin';

  const getOptionsProfessionals = async () => {
    setLoadingProfissionais(true);
    try {
      const response = await getUsersAgenda({
        estabelecimentoId: String(currentEstablishmentId),
      });

      const optionsWithEstablishment = response.data.map((item) => {
        return {
          ...item,
        };
      });
      setListProfessionals(optionsWithEstablishment);
    } catch (error) {
      return;
    }
    setLoadingProfissionais(false);
  };

  const handleClickSearchProfessionalSchedule = async () => {
    if (!selectedProfessional) {
      return;
    }
    setLoading(true);
    try {
      const responseProfessionalEstablishment = await api.get(
        `/profissionais/${selectedProfessional.idProfissional}`
      );

      const establishmentId = isSuperAdmin
        ? responseProfessionalEstablishment.data.estabelecimentos[0]
        : responseProfessionalEstablishment.data.estabelecimentos.find(
            (item) =>
              item.estabelecimento.id === currentEstablishment.estabelecimentoId
          );

      if (
        !establishmentId &&
        !isSuperAdmin &&
        (permissao === 'isPadrao' ||
          !hasPermission(['ZONE_ESTADO', 'ZONE_PAIS', 'ZONE_ALL']))
      ) {
        toast.error(
          'Profissional não pertence à este estabelecimento, contate o suporte.',
          { position: 'bottom-right' }
        );
        return;
      }

      if (establishmentId) {
        const response = await getProfessionalSchedule({
          profissional_id: selectedProfessional.idProfissional,
          estabelecimento_id: establishmentId.estabelecimento.id,
        });

        if (
          response.data['Teste de HPV']?.ativado === false ||
          !response.data['Teste de HPV']
        ) {
          setDates(null);
          setDatesWithSchedule(null);
          setSelectedDayTimes(null);
          setSelectedDay(null);
          setValue(null);
          setEmptySchedule(true);
          setShowComponentsAgenda(true);
          setLoading(false);

          return;
        } else {
          setShowComponentsAgenda(true);
          setDates(response.data['Teste de HPV']?.horariosDisponiveis);

          const datesAvailables =
            response.data['Teste de HPV']?.horariosDisponiveis;
          let arrDatesWithHours: any[] = [];

          for (const date in datesAvailables) {
            if (
              response.data['Teste de HPV']?.horariosDisponiveis[date].length >
              1
            ) {
              arrDatesWithHours.push({
                date: date,
                schedule:
                  response.data['Teste de HPV']?.horariosDisponiveis[date],
              });
            }
          }

          setDatesWithSchedule(arrDatesWithHours);

          const objOffDateWithHour = arrDatesWithHours.find(
            (item, index) => index === 0
          );

          setSelectedDay(objOffDateWithHour?.date);
          const firstDateWithHour = moment(
            objOffDateWithHour.date,
            'YYYY-MM-DD'
          );
          setValue(firstDateWithHour);
          setSelectedDayTimes(objOffDateWithHour.schedule);

          setEmptySchedule(false);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSelectProfessional = async (newValue) => {
    if (newValue) {
      setSelectedProfessional(newValue);
      setShowComponentsAgenda(false);
    } else {
      setDates(null);
      setDatesWithSchedule(null);
      setSelectedDay(null);
      setEmptySchedule(true);
      setSelectedProfessional(null);
      setValue(null);
    }
  };

  const data: { [date: string]: { hour: string; disponivel: boolean }[] } =
    dates;

  const handleChangeDatePicker = (newValue) => {
    const originalDate = moment(newValue);
    const convertedDate = originalDate.format('YYYY-MM-DD');

    setSelectedDayTimes(dates[convertedDate]);
    setSelectedDay(convertedDate);

    setValue(newValue);

    const hasHoursInThisDaySelected = !!datesWithSchedule.find((item) => {
      return item.date === convertedDate;
    });
    if (hasHoursInThisDaySelected) {
      setEmptySchedule(false);
    } else {
      setEmptySchedule(true);
    }
  };
  useEffect(() => {
    if (data) {
      const selectedDates = Object.entries(data)
        .filter(([date, schedule]) =>
          schedule.some(
            (item) =>
              item.hasOwnProperty('hour') && item.hasOwnProperty('disponivel')
          )
        )
        .reduce((filteredData, [date, schedule]) => {
          filteredData[date] = schedule;
          return filteredData;
        }, {});
      const selectedDatesArray = Object.entries(selectedDates).map(
        ([date, schedule]) => ({ date, schedule })
      );

      setDatesWithSchedule(selectedDatesArray);
    }
  }, [data]);

  useEffect(() => {
    getOptionsProfessionals();
  }, []);

  // useEffect(() => {
  //   const setListPatientsToModal = async () => {
  //     const response = await getPeopleNoParams();
  //   };

  //   setListPatientsToModal();
  // }, []);

  useEffect(() => {
    setDatesToSelect(datesWithSchedule?.map((dateObj) => dateObj.date));
  }, [datesWithSchedule]);

  // useEffect(() => {
  //   const getProfessionalsList = async () => {
  //     const res = await api.get(
  //       `/profissionais/estabelecimento/${Number(idEstablishment)}`
  //     );
  //   };

  //   getProfessionalsList();
  // }, []);

  useEffect(() => {
    if (!!location.state?.objAllAgenda && !disableAutomaticFill) {
      const locationParsed =
        JSON.parse(JSON.parse(location.state?.objAllAgenda)) ?? {};

      setListProfessionals(locationParsed.listProfessionals);
      setSelectedProfessional(locationParsed.selectedProfessional);
      setEmptySchedule(locationParsed.emptySchedule);
      setDates(locationParsed.dates);
      setDatesWithSchedule(locationParsed.datesWithSchedule);
      setSelectedDay(locationParsed.selectedDay);
      setValue(locationParsed.value);
      setSelectedDayTimes(locationParsed.selectedDayTimes);
      setShowComponentsAgenda(locationParsed.showComponentsAgenda);
      setPatientData(locationParsed.patientData);
      setPatientSelected(locationParsed.patientSelected);
      setRealizeSchedule(locationParsed.realizeSchedule);
      setCancelSchedule(locationParsed.cancelSchedule);
      setDisableAutomaticFill(true);
      setValueInForm('professional', locationParsed.selectedProfessional);
    }
  }, []);

  useEffect(() => {
    if (disableAutomaticFill) {
      window.history.replaceState({}, '');
    }
  }, [disableAutomaticFill]);

  return (
    <Box>
      <Box mt={3} display="flex" justifyContent="space-between" gap={3}>
        <Box flex={1}>
          <Controller
            control={control}
            name="professional"
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  options={listProfessionals}
                  getOptionLabel={(option) => option?.label}
                  value={value ? value : null}
                  noOptionsText={
                    loadingProfissionais ? 'Carregando...' : 'Não há opções'
                  }
                  onChange={(_, newValue) => {
                    handleSelectProfessional(newValue);

                    onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Profissional"
                      placeholder="Pesquise pelo nome do profissional da sua UBS "
                    />
                  )}
                />
              );
            }}
          />
        </Box>

        <Button
          variant="contained"
          type="button"
          onClick={() => handleClickSearchProfessionalSchedule()}
        >
          Buscar
        </Button>
      </Box>

      {showComponentsAgenda && (
        <>
          <Box mt={3} className={styles.scheduleContainer}>
            <Box className={styles.datePickerWrapper}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  label="Week picker"
                  value={value}
                  shouldDisableDate={(date) => {
                    if (!dates) {
                      return true;
                    }
                    const originalDate = moment(date);
                    const convertedDate = originalDate.format('YYYY-MM-DD');

                    const disableDate = !!datesWithSchedule?.find((item) => {
                      return item?.date === convertedDate;
                    });
                    return !disableDate;
                  }}
                  onChange={(newValue) => {
                    handleChangeDatePicker(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>

            {!emptySchedule && (
              <Box className={styles.schedules}>
                {selectedDayTimes?.map((item) => {
                  const id = item.marcadoPor ? item.marcadoPor.id : null;
                  if (item.disponivel === false) {
                    return (
                      <ScheduleListItem
                        active={true}
                        title={`${item.hour} - Agendado - ${item.marcadoPor.nome} - Teste HPV`}
                        onClick={() =>
                          handleOpenModal({
                            data: `${item.hour} - Agendado - ${item.marcadoPor.nome} - Teste HPV`,
                            modalToOpen: 'cancel_schedule',
                            scheduleId: id,
                            scheduleData: item.marcadoPor,
                            estabelecimentoId:
                              selectedProfessional?.estabelecimentoProfissional,
                            estabelecimentoEndereco:
                              selectedProfessional?.enderecoEstabelecimento,
                          })
                        }
                      />
                    );
                  } else {
                    return (
                      <ScheduleListItem
                        title={`${item.hour} - Horário livre`}
                        onClick={() =>
                          handleOpenModal({
                            data: `${
                              item.hour
                            } - Horário livre - ${''} - Teste de HPV`,
                            modalToOpen: 'realize_schedule',
                            scheduleId: null,
                            estabelecimentoId:
                              selectedProfessional?.estabelecimentoProfissional,
                            estabelecimentoEndereco:
                              selectedProfessional?.enderecoEstabelecimento,
                          })
                        }
                      />
                    );
                  }
                })}
              </Box>
            )}

            {emptySchedule && (
              <Box className={styles.emptySchedules}>
                <Typography
                  variant="h5"
                  fontWeight="700"
                  sx={{ color: 'rgba(0, 0, 0, 0.60)' }}
                >
                  Agenda Vazia
                </Typography>
                <img src={EmptySchedule} width="240" alt="Agenda Vazia" />

                <Box className={styles.configScheduleInfo}>
                  <Typography
                    variant="body1"
                    sx={{ color: 'rgba(0, 0, 0, 0.60)' }}
                  >
                    Não há nenhuma agenda configurada para este dia, verifique
                    outros dias, outros profissionais ou{' '}
                    <Link
                      to="/configuracao-agenda-digital"
                      state={{ listProfessionals, selectedProfessional }}
                    >
                      Configure a Agenda
                    </Link>
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}

      {notScheduled && (
        // <CancelScheduleModal
        //   title="Cancelar Agendamento"
        //   open={scheduled}
        //   handleClose={handleCloseModal}
        //   patientName={patientSelected}
        //   examName={patientData.exam}
        //   appointmentTime={patientData.time}
        // />
        <ModalScheduling
          title="Cancelar Agendamento"
          open={notScheduled}
          handleClose={handleCloseModal}
          patientName={patientSelected}
          examName={patientData.exam}
          appointmentTime={patientData.time}
          listTimes={selectedDayTimes}
          optionsPatients={patientsUbs}
          estabelecimentoId={patientData.estabelecimentoId}
          estabelecimentoEndereco={selectedProfessional.enderecoEstabelecimento}
          nomeFantasiaEstabelecimento={
            selectedProfessional.nomeFantasiaEstabelecimento
          }
        />
      )}

      {realizeSchedule && (
        <RealizeSchedule
          open={realizeSchedule}
          appointmentTime={patientData.time}
          appointmentDate={selectedDay}
          patientName={patientSelected}
          profissionalName={selectedProfessional?.nomeProfissional}
          handleClose={handleCloseModal}
          profissionalId={selectedProfessional?.idProfissional}
          examName={patientData.exam}
          listTimes={selectedDayTimes}
          optionsPatients={patientsUbs}
          estabelecimentoId={patientData.estabelecimentoId}
          estabelecimentoEndereco={selectedProfessional.enderecoEstabelecimento}
          objToEditPatient={objToEditPatient}
          nomeFantasiaEstabelecimento={
            selectedProfessional.nomeFantasiaEstabelecimento
          }
          estabelecimentoProfessionalId={
            selectedProfessional.estabelecimentoProfissional
          }
          handleClickSearchProfessionalSchedule={
            handleClickSearchProfessionalSchedule
          }
          disableAutomaticFill={disableAutomaticFill}
        />
      )}

      {cancelSchedule && (
        <CancelScheduleModal
          scheduleId={patientData.scheduleId}
          handleClose={handleCloseModal}
          patientName={patientSelected}
          examName={patientData.exam}
          optionsDays={datesToSelect}
          appointmentTime={patientData.time}
          open={cancelSchedule}
          scheduleData={patientData.scheduleData}
          appointmentDate={selectedDay}
          profissionalName={selectedProfessional?.nomeProfissional}
          datesWithSchedule={datesWithSchedule}
          estabelecimentoId={patientData.estabelecimentoId}
          estabelecimentoEndereco={selectedProfessional.enderecoEstabelecimento}
          nomeFantasiaEstabelecimento={
            selectedProfessional.nomeFantasiaEstabelecimento
          }
          handleClickSearchProfessionalSchedule={
            handleClickSearchProfessionalSchedule
          }
        />
      )}
    </Box>
  );
};
