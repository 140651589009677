import { Button, Collapse, Paper } from '@mui/material';
import { useState } from 'react';
import ScheduleItens from '../Components/ScheduleItens/ScheduleItens';
import ComponentHpv from '../Components/ComponentHpv';
import ListConvocations from '../Components/ListConvocations';

import arrowLeft from '../../../../../../../assets/ArrowLeft.svg';
import ListHpvs from '../Components/ListHpvs';
import moment from 'moment';

const DetailCanceled = ({ convocationsList, objConvocation }) => {
  console.log(objConvocation);
  const [showAllHpvs, setShowAllHpvs] = useState(false);
  const [showAllConvocations, setShowAllConvocations] = useState(false);
  const schedules = objConvocation.agendamentos ?? [];
  const hpvs = objConvocation.testes_hpv;

  const initialDateConvocation = moment(objConvocation?.convocacao_data_inicio);

  const hasTestHpvBeforeConvocation =
    hpvs?.filter((hpv) => {
      const dateHpv = moment(hpv.created_at);
      return dateHpv.isBefore(initialDateConvocation);
    })?.length > 0;

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        gap: showAllHpvs || showAllConvocations ? '12px' : '',
        flexDirection: showAllHpvs || showAllConvocations ? 'column' : 'row',
        borderRadius: '10px',
        padding: '32px',
        position: 'relative',
      }}
    >
      {(showAllHpvs || showAllConvocations) && (
        <div
          style={{
            textAlign: 'center',
            position: 'relative',
            width: '100%',
            marginBottom: '40px',
          }}
        >
          <p style={{ margin: 0, fontSize: '20px', fontWeight: 700 }}>
            {showAllHpvs && 'Testes de HPV'}
            {showAllConvocations && 'Convocatórias'}
          </p>
          <Button
            onClick={() => {
              if (showAllHpvs) {
                setShowAllHpvs(false);
              } else {
                setShowAllConvocations(false);
              }
            }}
            sx={{
              position: 'absolute',
              top: '40px',
              left: '50%',
              transform: 'translateX(-50%)',
              height: '22px',
              fontSize: '13px',
              color: '#212121',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <img src={arrowLeft} />
            {'VOLTAR'}
          </Button>
        </div>
      )}

      {objConvocation?.convocacoes?.length > 0 && !showAllHpvs && (
        <ListConvocations
          conditiionToDisableImg={
            schedules.length === 0 && objConvocation?.testes_hpv.length > 0
          }
          showAllConvocations={showAllConvocations}
          setShowAllConvocations={setShowAllConvocations}
          convocations={objConvocation?.convocacoes.sort((a, b) => {
            if (a.ordem < b.ordem) {
              return 1;
            }

            if (a.ordem > b.ordem) {
              return -1;
            }

            return 0;
          })}
          objTotalDataConvocation={objConvocation}
        />
      )}
      {/* {!showAllHpvs && !showAllConvocations && (
        <>
          <ScheduleItens
            schedules={schedules}
            objConvocation={objConvocation}
          />
        </>
      )} */}
    </Paper>
  );
};

export default DetailCanceled;
