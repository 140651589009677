import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const HeadersTableConvocation = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nome
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CPF
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CNS
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Idade
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estado
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Município
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento <br />
            Vinculado
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Endereço da
            <br />
            Paciente
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            ACS <br />
            Responsável
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Tipo de <br />
            Convocação
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data de envio da
            <br />
            Convocação
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data da Resposta
            <br /> da Convocação
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data do <br /> Agendamento
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', width: '161px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Última <br /> Atualização
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default HeadersTableConvocation;
