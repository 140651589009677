import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import useStatesFields from '../../../components/MultipleFilters/hooks/useStatesFields';
import ReturnButton from '../../../components/ReturnButton';
import { SideMenu } from '../../../components/SideMenu';
import { TableReportsCitologiaReflexo } from '../../../components/TableReports/TableCitologiaReflexo/TableReportsCitologiaReflexo';
import FiltersReportsCitologiaReflexo from './FiltersReportsCitologiaReflexo';
import useHandleChangeFilters from './FiltersReportsCitologiaReflexo/hooks/useHandleChangesFilters';
import { DisplayFilter, ItemsDisplayFilter } from '../../Convocation/styles';
import useRequestFilter from './FiltersReportsCitologiaReflexo/hooks/useRequestFilter';
import { optionsResultadoCitologia } from '../../../utils/variables';
import useSetPayload from './FiltersReportsCitologiaReflexo/hooks/useSetPayload';

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'estabelecimentoLaudoId', value: '' },
  { inputName: 'resultado', value: '' },
  { inputName: 'profissionalResultadoId', value: '' },
  { inputName: 'data_ini', value: '' },
  { inputName: 'data_fin', value: '' },
  { inputName: 'search', value: '' },
];

export const ReportsCitologiaReflexo = () => {
  const [tableData, setTableData] = useState({
    data: [],
    page: 1,
    count: 25,
    totalPages: 1,
    totalItems: 0,
  });
  const [period, setPeriod] = useState('period');
  const [showTableData, setShowTableData] = useState(false);
  const [listFilterShow, setListFilterShow] = useState<any[]>([]);
  const [listFilter, setListFilter] = useState<
    Array<{ inputName: string; value: string }>
  >(initialStateListFilter);
  const [selectedResultado, setSelectedResultado] = useState<string>();
  const [selectedLaboratorio, setSelectedLaboratorio] = useState<string>();
  const location = useLocation();
  const origin = location?.state?.origin;
  const fromTable =
    origin === 'tables' || !!localStorage.getItem('requestFromTable');

  const [dtColetaIni, setDataColetaIni] = useState<any>(null);

  const [dtColetaFinal, setDataColetaFinal] = useState<any>(null);

  const [selectedOptionsLaboratorio, setSelectedOptionsLaboratorio] = useState<
    any[]
  >([]);

  const [disabledProfissionalResultado] = useState(false);
  const [disabledLaboratorioResultado] = useState(false);

  const [loadingTable, setLoadingTable] = useState(true);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      page: 1,
      size: 25,
    },
  });

  const {
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    disableInputEstado,
    disableMunicipioInput,
    disableEstabelecimentoInput,

    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
  } = useStatesFields();

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    dtColetaFinal,
    dtColetaIni,
    selectedLaboratorio,
    selectedOptionsLaboratorio,
  });

  const { handleSetFilter } = useHandleChangeFilters({
    setListFilter,
  });

  const payloadFromPanelCharts = JSON.parse(
    String(localStorage.getItem('payload'))
  );
  console.log(
    '🚀 ~ ReportsCitologiaReflexo ~ payloadFromPanelCharts:',
    payloadFromPanelCharts
  );

  const { loadReportsCitologiaReflexo } = useRequestFilter({
    setTableData,
    setLoadingTable,
  });

  const fieldConnectedChart = {
    result: { fieldName: 'resultado', options: optionsResultadoCitologia },
    totalValue: {
      fieldName: 'realizou_atendimento',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },
      ],
    },
  };

  const chartNameSavedInStorage =
    localStorage.getItem('chartName') || location?.state?.table;

  const chartClickedIsTypeVirus = chartNameSavedInStorage === 'typeVirus';
  const chartClickedIsReasonNotProcedure =
    chartNameSavedInStorage === 'reasonNotProcedure';
  const chartClickedIsTotal = chartNameSavedInStorage === 'totalValue';

  const setFieldThatShowFieldClickedInTable = ({ fieldFatherName, value }) => {
    setValue(fieldFatherName, value);
  };

  const fieldFather = {
    typeVirus: {
      fieldFatherName: 'resultado',
      option: {
        value: 'POSITIVO',
        label: 'Detectável',
      },
    },
    reasonNotProcedure: {
      fieldFatherName: 'realizou_atendimento',
      option: 'Não',
    },
  };

  const setDisplayFilters = ({ optionSelected, field }) => {
    handleSetFilter(optionSelected, field.fieldName);
    setListFilterShow((prev) => {
      return [
        ...prev,
        { inputName: field.fieldName, value: optionSelected.label },
      ];
    });
  };

  const setValueInInputAndDisplay = () => {
    const chartName = chartNameSavedInStorage;
    if (!chartName || !fieldConnectedChart[chartName]) {
      setShowTableData(true);
      return;
    }

    const field = fieldConnectedChart[chartName];
    const value = localStorage.getItem('itemClicked');

    if (chartClickedIsTotal) {
      setValue('realizou_atendimento', 'Sim');
      handleSetFilter('Sim', 'realizou_atendimento');
      setListFilterShow((prev) => [
        ...prev,
        {
          inputName: 'realizou_atendimento',
          value: 'Realizou atendimento: Sim',
        },
      ]);
      return;
    }

    if (!field.options) {
      setShowTableData(true);
      return;
    }

    const optionSelected = field.options.find((opt) => opt.value === value);
    if (optionSelected) {
      setValue(field.fieldName, optionSelected);
      handleSetFilter(value, field.fieldName);
      setDisplayFilters({
        field: field.fieldName,
        optionSelected: optionSelected,
      });
    }

    setShowTableData(true);
  };

  const getDataByChartClicked = async () => {
    try {
      if (chartClickedIsTypeVirus || chartClickedIsReasonNotProcedure) {
        setFieldThatShowFieldClickedInTable({
          fieldFatherName: fieldFather[chartNameSavedInStorage].fieldFatherName,
          value: fieldFather[chartNameSavedInStorage].option,
        });
      }

      setShowTableData(true);
      setValueInInputAndDisplay();

      setPayload().then((payload: any) => {
        loadReportsCitologiaReflexo({
          ...payload,
          estados: payloadFromPanelCharts.params.estados,
          municipios: payloadFromPanelCharts.params.municipios,
          estabelecimentoSaudeId:
            payloadFromPanelCharts.params.estabelecimentos ?? null,
        });
      });
    } catch (error) {
      console.error('Erro em trazer dados: ', error);
      setShowTableData(false);
    }
  };

  useEffect(() => {
    if (fromTable) {
      getDataByChartClicked();
    }
  }, [fromTable]);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton
            backPage={fromTable ? '/paineis/teste-citologia-reflexo' : '/home'}
          />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h4" fontWeight="bold">
                Relatório Geral de Exames de Citologia - Reflexo
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
                sx={{ marginBottom: '24px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>

                {fromTable ? (
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to={'/paineis/teste-citologia-reflexo'}
                  >
                    {'Painel do Teste de Citologia - Reflexo'}
                  </Link>
                ) : (
                  <Typography color="text.primary">{'Relatórios'}</Typography>
                )}

                <Typography key="3" color="text.primary">
                  Relatório Geral de Exames de Citologia - Reflexo
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Filtros
            </Typography>
            <Divider />
            <p
              style={{
                fontSize: '12px',
                textAlign: 'right',
                color: '#d32f2f',
                margin: '0',
                marginTop: '20px',
                // marginBottom: '-20px',
              }}
            >
              <i>* Campo obrigatório</i>
            </p>

            <DisplayFilter style={{ marginTop: '24px' }}>
              {!listFilterShow?.find((item) => item.value !== '') && (
                <p style={{ margin: 0, width: '413px', color: '#838383' }}>
                  Os filtros selecionados aparecerão aqui.
                </p>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {listFilterShow.map((item) => (
                  <>
                    {item.value ? (
                      <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                    ) : undefined}
                  </>
                ))}
              </div>
            </DisplayFilter>

            <FiltersReportsCitologiaReflexo
              initialStateListFilter={initialStateListFilter}
              setLoadingTable={setLoadingTable}
              errors={errors}
              clearErrors={clearErrors}
              disabledLaboratorioResultado={disabledLaboratorioResultado}
              disabledProfissionalResultado={disabledProfissionalResultado}
              selectedOptionsLaboratorio={selectedOptionsLaboratorio}
              setSelectedOptionsLaboratorio={setSelectedOptionsLaboratorio}
              disableMunicipioInput={disableMunicipioInput}
              disableEstabelecimentoInput={disableEstabelecimentoInput}
              disableInputEstado={disableInputEstado}
              showTableData={showTableData}
              setListFilterShow={setListFilterShow}
              selectedResultado={selectedResultado}
              setTableData={setTableData}
              watch={watch}
              setError={setError}
              setShowTableData={setShowTableData}
              dtColetaFinal={dtColetaFinal}
              setPeriod={setPeriod}
              dtColetaIni={dtColetaIni}
              setDataColetaFinal={setDataColetaFinal}
              setDataColetaIni={setDataColetaIni}
              setSelectedResultado={setSelectedResultado}
              handleSubmit={handleSubmit}
              setValue={setValue}
              control={control}
              estadosSelected={estadosSelected}
              setEstadosSelected={setEstadosSelected}
              municipiosSelected={municipiosSelected}
              setMunicipiosSelected={setMunicipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              setEstabelecimentosSelected={setEstabelecimentosSelected}
              period={period}
              listFilter={listFilter}
              setListFilter={setListFilter}
              selectedLaboratorio={selectedLaboratorio}
              setSelectedLaboratorio={setSelectedLaboratorio}
            />

            <TableReportsCitologiaReflexo
              loadingTable={loadingTable}
              setLoadingTable={setLoadingTable}
              tableData={tableData}
              setValue={setValue}
              watch={watch}
              setTableData={setTableData}
              estadosSelected={estadosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              selectedLaboratorio={selectedLaboratorio}
              equipesSelected={equipesSelected}
              showTableData={showTableData}
            />
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};
