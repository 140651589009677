import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import { Warning, Cancel } from '@mui/icons-material';

export const ButtonIcon = styled(Box)`
  background-color: rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
`;

export const ButtonUpdate = styled(Button)`
  color: #ffffff;
  background-color: #238884;
  border-color: #238884;
  :hover {
    background-color: #238884;
    color: #ffffff;
    border-color: #238884;
  }
`;

export const WarningIcon = styled(Warning)`
  width: 100%;
  font-size: 5.2rem;
  margin: 1.5rem auto;
`
export const CancelIcon = styled(Cancel)`
  width: 100%;
  font-size: 5.2rem;
  margin: 1.5rem auto;
`