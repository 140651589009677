import {
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { ptBR } from 'date-fns/locale';
import { AgendaHeader, ContentContainer, Label, Value } from '../styles';
import { useContext, useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import jordanaWhite from '../../../assets/imgs/jordana-logo-white.svg';
import { IPaciente } from '../../../lib/getCitzenSchedule';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import {
  download,
  getDataCitizen,
  getHoursAvaliable,
  notAcceptSubmit,
  postAgendamento,
} from '../../../lib/getCidadaoAgenda';
import { LoadingContext } from '../../../context/LoadingContext';

import { decodeJWT } from '../../../utils/decodeJwt';
import WaitingListConfirmed from '../waitingListConfirmed';
import ScheduleNotRealized from '../scheduleNotRealized';
import ScheduleRealized from '../ScheduleRealized';

enum ComponentsToShowValues {
  Agenda = 'agenda',
  AgendamentoNaoRealizado = 'agenda-nao-realizada',
  AgendamentoRealizado = 'sucesso-agenda-realizada',
  ListaReservada = 'sucesso-lista-reservada',
}

const AgendaCitizenComponent = () => {
  const {
    handleSubmit: handleSubmitNotAccepted,
    control: controlNotAccepted,
    watch,
    setValue,
  } = useForm();

  const [componentToShow, setComponentToShow] = useState<any>();

  const [citizenData, setCitizenData] = useState<any | null>(null);

  const [payload, setPayload] = useState({});

  const [objSchedule, setObjSchedule] = useState<any>();
  const [objNotSchedule, setObjNotSchedule] = useState<any>({});

  const [selectedIfAcceptExam, setSelectIfAcceptExam] = useState<any>('');
  const [professional, setProfessional] = useState<any>('');
  const [professionalList, setProfessionalList] = useState<any>([]);
  const [errorHour, setErrorHour] = useState<any>(null);
  const [containHours, setContainHours] = useState(false);
  const [datesWithSchedule, setDatesWithSchedule] = useState<any>();
  const [optionsHours, setOptionsHours] = useState<any>([]);
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedDate, setSelectedDate] = useState<any>(null);

  const { setLoading } = useContext(LoadingContext);

  const storageToken = localStorage.getItem('token_paciente') ?? '';
  const token: any = decodeJWT(storageToken);
  const id = token.pessoa.id;

  const selectedReason = watch('motivo');

  const notWantScheduleAndSelectedOtherReasonButNotTypedOtherReason =
    selectedIfAcceptExam == 'nao' &&
    selectedReason == 13 &&
    !!watch('observacao') === false;
  const notWantScheduleAndNotSelectedReason =
    selectedIfAcceptExam === 'nao' && !!selectedReason === false;
  const notWantScheduleAndSelectNoHaveTimeButDesejaNotificarNotSelected =
    selectedIfAcceptExam === 'nao' &&
    selectedReason == 12 &&
    watch('filaEspera') === null;

  const wantScheduleAndNotSelectedProfisisonal =
    selectedIfAcceptExam === 'sim' && !professional;
  const wantScheduleAndNotSelectedHour =
    !selectedHour && selectedIfAcceptExam === 'sim';

  const { logradouro, numero, bairro, cep, estado, municipio } = (citizenData
    ?.estabelecimentoSaude?.endereco as any) ?? {
    municipio: { estado: {} },
  };

  const citizenObj = {
    nacionalidade: citizenData?.nacionalidade,
    nome: citizenData?.nome,
    localProcedimento: citizenData?.estabelecimentoSaude?.nome,
    cns: `***${citizenData?.cns.substring(4, 12)}***`,
    cpf: `***.${citizenData?.cpf.substring(3, 6)}.${citizenData?.cpf.substring(
      6,
      9
    )}-**`,
    endereco: `${logradouro}, ${numero} - ${bairro} - ${municipio} - ${estado} - ${cep}`,
    dtNascimento: citizenData?.dataNascimento
      ?.split('-')
      .reverse()
      .join('/')
      .toString(),
  };

  const loadHours = async () => {
    setLoading(true);
    try {
      const response = await getHoursAvaliable();

      setContainHours(response.length > 0);
      // setContainHours(false);
      setProfessionalList(response);
    } catch (error) {}

    setLoading(false);
  };

  const loadDataCitizen = async () => {
    try {
      const response = await getDataCitizen();

      setCitizenData({
        ...response,
        cpf: response.cpf.length > 1 ? response.cpf : '00000000000',
      });
      switch (response.statusAgendamento) {
        case 'Agendado':
          setObjSchedule({
            profissional: response.agendamentoDetalhado.medico.nome,
            day: `${
              daysWeeks[
                moment(
                  response.agendamentoDetalhado.data,
                  'YYYY-MM-DD'
                ).isoWeekday()
              ]
            }, ${response.agendamentoDetalhado.data}`,
            hour: response.agendamentoDetalhado.horario,
          });
          setComponentToShow(ComponentsToShowValues.AgendamentoRealizado);
          break;
        case 'Não agendado':
          setComponentToShow(ComponentsToShowValues.Agenda);
          break;
        default:
          const today = moment().format('YYYY-MM-DD');
          const differenceHoursToExam = moment(
            response.agendamentoDetalhado.data,
            'YYYY-MM-DD'
          ).diff(today, 'hours');

          if (differenceHoursToExam <= 24) {
            setComponentToShow(ComponentsToShowValues.Agenda);
          } else {
            setObjSchedule({
              profissional: response.agendamentoDetalhado.medico.nome,
              day: `${
                daysWeeks[
                  moment(
                    response.agendamentoDetalhado.data,
                    'YYYY-MM-DD'
                  ).isoWeekday()
                ]
              }, ${response.agendamentoDetalhado.data}`,
              hour: response.agendamentoDetalhado.horario,
            });
            setComponentToShow(ComponentsToShowValues.AgendamentoRealizado);
          }

          break;
      }
    } catch (error) {}
  };

  const handleHourChange = (event, newValue) => {
    setSelectedHour(newValue);
  };

  const daysWeeks = {
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
    7: 'Domingo',
  };

  const handleAgendamento = async () => {
    setLoading(true);
    if (selectedIfAcceptExam === 'sim') {
      if (containHours) {
        setObjSchedule({
          profissional: professional.medico.nome,
          day: `${daysWeeks[moment(selectedDate).isoWeekday()]}, ${moment(
            selectedDate,
            'YYYY-MM-DD'
          ).format('DD/MM/YYYY')}`,
          hour: selectedHour,
        });

        const response = await postAgendamento({
          data: moment(selectedDate).format('YYYY-MM-DD'),
          horario: selectedHour,
          medicoId: professional.medico.id,
          statusAgendamento:
            citizenData.statusAgendamento === 'Não agendado'
              ? 'NaoAgendado'
              : 'Agendado',
        });

        if (response.status === 201) {
          setComponentToShow('sucesso-agenda-realizada');
        } else if (response.status === 400) {
          const error = await response.json();

          toast.error(error.error.message, { position: 'bottom-center' });
        }
      } else {
        setComponentToShow('sucesso-lista-reservada');
      }
      setLoading(false);
    } else {
      submitNotAccepted(watch());
    }
  };

  const handleProfessionalChange = async (event: any, newValue: any) => {
    setSelectedDate(null);
    setSelectedHour('');
    if (!newValue) {
      setProfessional('');
      setOptionsHours([]);

      return;
    }

    const datesList = newValue.horarios_disponiveis.map((hour) => hour.data);

    setDatesWithSchedule(datesList);

    setProfessional(newValue);
  };

  const handleDateChange = async (newValue: any) => {
    const originalDate = moment(newValue);
    const convertedDate = originalDate.format('YYYY-MM-DD');

    const listHours = professional.horarios_disponiveis.find(
      (hour) => hour.data === convertedDate
    )?.horarios;

    setOptionsHours(listHours);

    setSelectedDate(newValue);
  };

  const submitNotAccepted = async (data) => {
    setLoading(true);
    const response = await notAcceptSubmit({
      filaEspera: data.filaEspera === 'true' ? true : false,
      motivo: Number(data.motivo),
      observacao: data.observacao,
    });

    if (response.status === 201) {
      const objMotivo = {
        9: 'Não quero realizar',
        10: 'Moro longe da unidade de saúde',
        11: 'Já realizei o exame em outro local',
        12: 'Não tenho disponibilidade para as datas disponíveis',
        13: 'Outro',
      };
      setObjNotSchedule({ motivo: objMotivo[data.motivo] });

      setComponentToShow(ComponentsToShowValues.AgendamentoNaoRealizado);
    }

    if (response.status === 404) {
      toast.error(response.error.message, { position: 'bottom-center' });
    }

    setLoading(false);
  };

  const setDataWhenPacienteIsAgendado = () => {
    setSelectIfAcceptExam('sim');

    const { medico, data, horario } = citizenData.agendamentoDetalhado;
    const dataMedico = professionalList.find(
      (professional) => professional.medico.id === medico.id
    );
    const datesList = dataMedico?.horarios_disponiveis.map((hour) => hour.data);
    const hoursList = dataMedico?.horarios_disponiveis.find(
      (horario) => horario.data === data
    )?.horarios;

    setProfessional(dataMedico);
    setDatesWithSchedule(datesList);
    setSelectedDate(moment(data, 'YYYY-MM-DD'));
    setOptionsHours([
      ...hoursList,
      horario.split(':')[0] + ':' + horario.split(':')[1],
    ]);
    setSelectedHour(horario.split(':')[0] + ':' + horario.split(':')[1]);
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      const blob = await download();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'comprovante.pdf');
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro durante o download:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadHours();
    loadDataCitizen();
  }, []);

  useEffect(() => {
    if (professionalList.length > 0) {
      if (citizenData?.statusAgendamento === 'Reagendado') {
        setDataWhenPacienteIsAgendado();
      }
    }
  }, [citizenData, professionalList]);

  if (!citizenData) return <p>Carregando...</p>;

  return (
    <>
      <AgendaHeader>
        <Typography
          sx={{ fontSize: '20px', color: 'white', fontWeight: '700' }}
        >
          Agendamento
        </Typography>
        <img src={jordanaWhite} />
      </AgendaHeader>
      {componentToShow === 'agenda' && (
        <ContentContainer>
          <Grid
            container
            spacing={1}
            sx={{
              boxShadow: '0px 0px 14px -8px black',
              width: '360px',
              padding: '23.5px',
              margin: '0 auto',
            }}
          >
            <Grid item xs={12}>
              <Typography fontWeight="bold" fontSize="20px">
                Dados do Cidadão
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Label>Nº do Cartão do SUS</Label>
              <Value>{citizenObj.cns}</Value>
            </Grid>
            <Grid item xs={6}>
              <Label>CPF</Label>
              <Value>{citizenObj.cpf}</Value>
            </Grid>
            <Grid item xs={12}>
              <Label>Nome</Label>
              <Value>{citizenObj.nome}</Value>
            </Grid>
            <Grid item xs={6}>
              <Label>Data de Nascimento</Label>
              <Value>{citizenObj.dtNascimento}</Value>
            </Grid>
            <Grid item xs={6}>
              <Label>Nacionalidade</Label>
              <Value>{citizenObj.nacionalidade}</Value>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight="bold" fontSize="20px" marginTop="15px">
                Dados do Procedimento
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Label>Local do Procedimento</Label>
              <Value>{citizenObj.localProcedimento}</Value>
            </Grid>
            <Grid item xs={12}>
              <Label>Endereço</Label>
              <Value>{citizenObj.endereco}</Value>
            </Grid>
            <Grid item xs={12}>
              <Label>Procedimento</Label>
              <Value>Teste de HPV</Value>
            </Grid>
            <Grid item xs={12}>
              <Label>Você gostaria de agendar o Teste?</Label>
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                aria-labelledby="Tpoe de exame"
                name="exame-type"
                sx={{ flexDirection: 'row' }}
                onChange={(e, value) => {
                  setSelectIfAcceptExam(value);
                  setProfessional(null);
                  setSelectedDate(null);
                  setSelectedHour('');
                  setValue('motivo', null);
                }}
              >
                <FormControlLabel
                  value="sim"
                  control={
                    <Radio
                      color="primary"
                      checked={selectedIfAcceptExam === 'sim'}
                    />
                  }
                  label="Sim"
                />
                <FormControlLabel
                  value="nao"
                  control={
                    <Radio
                      color="primary"
                      checked={selectedIfAcceptExam === 'nao'}
                    />
                  }
                  label="Não"
                />
              </RadioGroup>
            </Grid>

            {selectedIfAcceptExam === 'nao' && (
              <>
                <Grid item xs={12}>
                  <Label>
                    Motivo da não realização do exame{' '}
                    <span style={{ color: '#CC0000' }}>*</span>
                  </Label>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="motivo"
                    control={controlNotAccepted}
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        aria-labelledby="Tpoe de exame"
                        name="exame-type"
                        value={value}
                        onChange={(e) => {
                          setValue('observacao', null);
                          setValue('filaEspera', null);
                          onChange(e);
                        }}
                      >
                        <FormControlLabel
                          value={9}
                          control={<Radio color="primary" />}
                          label="Não quero realizar"
                        />
                        <FormControlLabel
                          value={10}
                          control={<Radio color="primary" />}
                          label="Moro longe da unidade de saúde"
                        />
                        <FormControlLabel
                          value={11}
                          control={<Radio color="primary" />}
                          label="Já realizei o exame em outro local"
                        />
                        <FormControlLabel
                          value={12}
                          control={<Radio color="primary" />}
                          label="Não tenho disponibilidade para as datas disponíveis"
                        />
                        <FormControlLabel
                          value={13}
                          control={<Radio color="primary" />}
                          label="Outro"
                        />
                      </RadioGroup>
                    )}
                  />
                </Grid>

                {watch('motivo') == 12 && (
                  <Controller
                    name="filaEspera"
                    control={controlNotAccepted}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          <Grid item xs={12}>
                            <Label>
                              Deseja ser notificada quando tivermos
                              <br /> novos horários?
                              <span style={{ color: '#CC0000' }}>*</span>
                            </Label>
                          </Grid>
                          <RadioGroup
                            aria-labelledby="Tpoe de exame"
                            name="exame-type"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio color="primary" />}
                              label="Sim"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio color="primary" />}
                              label="Não"
                            />
                          </RadioGroup>
                        </>
                      );
                    }}
                  />
                )}
                {watch('motivo') == 13 && (
                  <Grid item xs={12}>
                    <Controller
                      name="observacao"
                      control={controlNotAccepted}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <StyledAsteriskTextField
                            required
                            onChange={onChange}
                            value={value}
                            label="Outro motivo"
                            size="small"
                            fullWidth
                          />
                        );
                      }}
                    />
                  </Grid>
                )}
              </>
            )}
            {!containHours && selectedIfAcceptExam === 'sim' ? (
              <>
                <div
                  style={{
                    backgroundColor: '#e8f4fe',
                    color: '#0d3c61',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    width: '100%',
                    gap: '12px',
                    padding: '6px 16px 6px 16px',
                    marginTop: '16px',
                    fontWeight: 400,
                    fontSize: '14px',
                  }}
                >
                  <InfoIcon
                    style={{
                      color: '#19B1F5',
                    }}
                  />
                  Infelizmente os horários para <br /> agendamento estão
                  atualmente <br />
                  preenchidos, mas caso deseje ser <br />
                  contactada assim que novos horários <br /> estiverem
                  disponíveis, selecione a <br /> opção de “Informar”.
                </div>
              </>
            ) : (
              <>
                {selectedIfAcceptExam === 'sim' && (
                  <>
                    <Grid item xs={12}>
                      <Autocomplete
                        options={professionalList}
                        noOptionsText="Não há profissionais disponíveis para agendamento."
                        getOptionLabel={(option: any) => option?.medico.nome}
                        onChange={handleProfessionalChange}
                        value={professional ? professional : null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Profissional"
                          />
                        )}
                      />
                    </Grid>
                    <>
                      <Grid item xs={6} marginTop="8px">
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptBR}
                        >
                          <DatePicker
                            disabled={professional === ''}
                            shouldDisableDate={(date) => {
                              const originalDate = moment(date);
                              const convertedDate =
                                originalDate.format('YYYY-MM-DD');
                              const disableDate = datesWithSchedule?.find(
                                (item) => item === convertedDate
                              );

                              return !disableDate;
                            }}
                            label="Data"
                            inputFormat="dd/MM/yyyy"
                            value={selectedDate}
                            onChange={handleDateChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!errorHour}
                                size="small"
                                placeholder="__/__/____"
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {errorHour ? (
                          <p
                            style={{
                              color: '#e74c3c',
                              margin: 0,
                              fontSize: '10px',
                            }}
                          >
                            {errorHour}
                          </p>
                        ) : undefined}
                      </Grid>
                      <Grid item xs={6} marginTop="8px">
                        <Autocomplete
                          disabled={!selectedDate}
                          options={optionsHours}
                          onChange={handleHourChange}
                          value={selectedHour ? selectedHour : ''}
                          getOptionLabel={(option: any) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              label="Horário"
                              placeholder="hh:mm"
                            />
                          )}
                        />
                      </Grid>
                    </>
                  </>
                )}
              </>
            )}

            <Grid xs={12}>
              <Button
                onClick={handleAgendamento}
                sx={{ width: '100%', marginTop: '15px' }}
                // disabled={disableButtonSubmitForm}
                disabled={
                  !selectedIfAcceptExam ||
                  notWantScheduleAndNotSelectedReason ||
                  notWantScheduleAndSelectNoHaveTimeButDesejaNotificarNotSelected ||
                  notWantScheduleAndSelectedOtherReasonButNotTypedOtherReason ||
                  (wantScheduleAndNotSelectedProfisisonal && containHours) ||
                  (wantScheduleAndNotSelectedHour && containHours)
                }
                variant="contained"
                type={'button'}
              >
                {selectedIfAcceptExam === 'nao' || !containHours
                  ? 'INFORMAR'
                  : citizenData?.statusAgendamento === 'Reagendado'
                  ? 'REAGENDAR'
                  : 'AGENDAR'}
              </Button>
            </Grid>
          </Grid>
        </ContentContainer>
      )}
      {componentToShow === ComponentsToShowValues.ListaReservada && (
        <WaitingListConfirmed
          citizenObj={citizenObj}
          handleDownload={handleDownload}
        />
      )}

      {componentToShow === ComponentsToShowValues.AgendamentoRealizado && (
        <ScheduleRealized
          citizenObj={citizenObj}
          objSchedule={objSchedule}
          handleDownload={handleDownload}
        />
      )}

      {componentToShow === ComponentsToShowValues.AgendamentoNaoRealizado && (
        <ScheduleNotRealized
          citizenObj={citizenObj}
          objNotSchedule={objNotSchedule}
          handleDownload={handleDownload}
        />
      )}
    </>
  );
};
export default AgendaCitizenComponent;
