/* eslint-disable react-hooks/exhaustive-deps */
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Divider,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import * as React from 'react';
import './styles.css';

import AddresInformation from './AddressInformation';
import BindingData from './BindingData';
import ComplementInformation from './ComplementInformation';
import ContactData from './ContactData';
import PessoalInformation from './PessoalInformation';
import { useParams } from 'react-router-dom';
import api from '../../api';
import { PerfilContext } from '../../context/PerfilContext';
import { ContentPaste } from '@mui/icons-material';
import ModalDeletePatient from '../ModalDeletePatient';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default function BoxInfoPatient(props: any) {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  const [tabSize, setTabSize] = React.useState('25%');
  const [modalDeleteOpen, setModalDeleteOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { id } = useParams();
  const [bindData, setBindData] = React.useState({});
  const { hasPermission } = React.useContext(PerfilContext);

  const setVinculoData = async () => {
    const { data } = await api.get('/vinculos/pessoa/' + id);

    const vinculoBinder = {
      cns: data.profissionalEquipe?.profissional?.pessoa?.cns,
      cbo: data.profissionalEquipe?.profissional.cbo,
      ine: data.profissionalEquipe?.equipe?.ine,
      area: data.profissionalEquipe?.area,
      cnes: data.estabelecimentoSaude?.cnes,
      nomeProfissional: data.profissionalEquipe?.profissional?.pessoa?.nome,
      cargoProfissional: data.profissionalEquipe?.profissional?.cargo,
      nomeEquipe: data.profissionalEquipe?.equipe?.nome,
      microarea: data.profissionalEquipe?.micro_area,
      nomeEstabelecimento: data.estabelecimentoSaude?.nome_fantasia,
    };
    setBindData(vinculoBinder);
  };

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    if (windowDimensions.width < 768) {
      setTabSize('100%');
    } else {
      setTabSize('20%');
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  React.useEffect(() => {
    setVinculoData();
  }, [id]);

  return (
    <>
      <Box sx={{ width: '100%' }} mt={3} data-testid="paciente-box">
        <Paper variant="outlined" sx={{ width: '100%', borderRadius: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              padding: '25px',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              style={{ margin: '5px' }}
            >
              <AssignmentIndIcon style={{ position: 'relative', top: '4px' }} />{' '}
              <span data-testid="header-nome">
                {String(props.data?.nome || '').toUpperCase() + ' '}
              </span>
              - Nº do Cartão SUS:
              <span data-testid="header-cns">
                {' ' + (props.data?.cns || '')}
              </span>
            </Typography>
            <Box>
              {props.viewButton && hasPermission(`SUPER_ADMIN`) && (
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    margin: '5px',
                    height: '30px',
                    color: '#d32f2f',
                    backgroundColor: '#FFFFFF',
                    borderColor: '#d32f2f',
                    '&:hover': {
                      backgroundColor: '#d32f2f',
                      color: '#FFFFFF',
                      borderColor: '#d32f2f',
                    },
                  }}
                  color="error"
                  disabled={!hasPermission(`SUPER_ADMIN`)}
                  onClick={() => setModalDeleteOpen(true)}
                  startIcon={<ContentPaste />}
                  data-testid={`button-patient-delete`}
                >
                  Excluir
                </Button>
              )}
              {props.viewButton && hasPermission('UPDATE_PACIENTE') && (
                <Button
                  size="small"
                  data-testid="button-ediatr_paciente"
                  sx={{
                    margin: '5px',
                    padding: '4px 10px 4px 10px',
                    height: '30px',
                    color: '#238884',
                    backgroundColor: '#FFFFFF',
                    borderColor: '#238884',
                    '&:hover': {
                      backgroundColor: '#238884',
                      color: '#FFFFFF',
                      borderColor: '#238884',
                    },
                  }}
                  variant="outlined"
                  onClick={props.buttonEditar}
                  startIcon={<EditIcon />}
                >
                  Editar
                </Button>
              )}
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{ borderBottom: 2, borderColor: '#238884', marginTop: '25px' }}
          >
            <Tabs
              orientation={
                windowDimensions.width < 768 ? 'vertical' : 'horizontal'
              }
              variant="scrollable"
              sx={{
                backgroundColor: '#ffffff',
                color: '#ffffff',

                '&& .Mui-selected': {
                  backgroundColor: '#238884',
                  color: '#FFFFFF',
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: '#238884',
                  color: '#FFFFFF',
                },
              }}
              value={value}
              onChange={handleChange}
            >
              <Tab
                sx={{ width: tabSize }}
                label="INFORMAÇÕES PESSOAIS"
                data-testid="button-info"
                {...a11yProps(0)}
              />
              <Tab
                sx={{ width: tabSize }}
                label="DADOS RESIDENCIAIS"
                data-testid="button-address"
                {...a11yProps(1)}
              />
              <Tab
                sx={{ width: tabSize }}
                label="DADOS DE CONTATO"
                data-testid="button-contact"
                {...a11yProps(2)}
              />
              <Tab
                sx={{ width: tabSize }}
                label="DADOS de VINCULAÇÃO"
                data-testid="button-linking"
                {...a11yProps(3)}
              />

              <Tab
                sx={{ width: tabSize }}
                label="INFORMAÇÕES COMPLEMENTARES"
                data-testid="button-complementary"
                {...a11yProps(4)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <PessoalInformation patientInformation={props} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AddresInformation addressData={props} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ContactData contactData={props} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <BindingData bindingData={bindData} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ComplementInformation complementInformation={props} />
          </TabPanel>

          <ModalDeletePatient
            open={modalDeleteOpen}
            patientData={props.data}
            setModal={setModalDeleteOpen}
          />
        </Paper>
      </Box>
    </>
  );
}
