import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface BarChartProps {
  data: number[];
  categories?: string[] | string[][];
  height?: number;
  colors: string[];
}

export function BarChart({ data, categories, colors }: BarChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();

  useEffect(() => {
    setChartData({
      series: [
        {
          name: '',
          data: data,
        },
      ],
      chart: {
        type: 'bar',
        height: 284,
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '56.03px',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 500,
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        type: 'category',
        categories,
        labels: {
          rotate: 0,
        },
      },
      yaxis: {
        min: 0,
        tickAmount: 4,
      },
      fill: {
        opacity: 1,
      },
      colors,
      legend: {
        show: true,
        labels: {
          colors: '#757575',
          useSeriesColors: false,
        },
        fontSize: '14px',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 500,
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            xaxis: {
              labels: {
                style: {
                  fontSize: '10px',
                },
              },
            },
          },
        },
      ],
    });
  }, [data, categories, colors]);

  return (
    <>
      {chartData ? (
        <>
          <div style={{ display: 'none' }}>
            <ul>
              <li data-testid="twentyFiveToThirtyFourYearsTested">{data[0]}</li>
              <li data-testid="thirtyFiveToFourtyFiveYearsTested">{data[1]}</li>
              <li data-testid="FourtySixToSixtyFourYearsTested">{data[2]}</li>
              <li data-testid="outRangeTested">{data[3]}</li>
            </ul>
          </div>
          {/**O componente acima foi construído para que os dados dos gráficos
           * possam ser localizados nos testes automatizados do Cypress
           */}
          <ReactApexChart
            options={chartData}
            series={chartData.series}
            type="bar"
            height={284}
          />
        </>
      ) : undefined}
    </>
  );
}

interface SquamousPreNeoplasticProps {
  data: number[];
  onChartClick?: (item: string) => void;
}

export function SquamousPreNeoplastic({
  data,
  onChartClick,
}: SquamousPreNeoplasticProps) {
  const [chartData, setChartData] = useState<ApexOptions>();

  const categories = [
    'NIC I',
    'NIC II',
    'NIC III',
    'Carcinoma Escamoso Invasor',
  ];

  useEffect(() => {
    setChartData({
      series: [
        {
          name: 'Total',
          data: data,
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
        events: {
          dataPointSelection: function (event, chartContext, config) {
            if (onChartClick && config.dataPointIndex >= 0) {
              const label = categories[config.dataPointIndex];
              console.log('SquamousPreNeoplastic clicked:', label);
              onChartClick(label);
            }
          },
        },
      },
      xaxis: {
        categories: categories,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '56.03px',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 500,
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      yaxis: {
        min: 0,
        tickAmount: 4,
      },
      fill: {
        opacity: 1,
      },
      colors: ['#F28787'],
      legend: {
        show: true,
        labels: {
          colors: '#757575',
          useSeriesColors: false,
        },
        fontSize: '14px',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 500,
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            xaxis: {
              labels: {
                style: {
                  fontSize: '10px',
                },
              },
            },
          },
        },
      ],
    });
  }, [data, onChartClick]);

  return (
    <>
      {chartData ? (
        <ReactApexChart
          options={chartData}
          series={chartData.series}
          type="bar"
          height={350}
        />
      ) : null}
    </>
  );
}
