import styled from '@emotion/styled';
import { Button, Box } from '@mui/material';
import { responsiveBreakpoints } from '../../../styles/breakpoinst';

export const FormContainer = styled(Box)`
  background: #ffff;
  overflow: hidden;
  width: 313px;
  border: 12px;
  margin-top: 60px;
  border-radius: 9.5px;
  min-height: 100px;
  height: auto;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: ${responsiveBreakpoints.small}) {
    margin-top: 60px;
  }

  text-align: center;
  padding: 15px 18px 15px 18px;

  @media (min-width: ${responsiveBreakpoints.small}) {
    padding: 40px;
  }
`;
