import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { formatCpf } from '../../../../../utils/formatters';
import moment from 'moment';

const Rows = ({ tableData }) => {
  const formatFaixa = (faixa) => {
    const split = faixa?.split('_');
    return split?.[1] ? `${split[0]} - ${split[1]}` : split;
  };

  const meioRastreamento = {
    listagem: 'Banco de Dados',
    outros: 'Outros',
  };

  const cellStyle = { width: '162px' };

  const TextCell = ({ children }) => (
    <Typography color="#000001de" variant="body2" fontSize="14px">
      {children}
    </Typography>
  );

  const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : '');

  return (
    <TableBody>
      {tableData?.data?.map((row) => (
        <TableRow key={row?.pessoa?.id}>
          <TableCell sx={cellStyle}>
            <TextCell>{String(row?.prioridade)}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{formatFaixa(row.faixa)}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.idade} Anos</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{formatDate(row?.dt_coleta_cito)}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>
              {row?.has_teste_hpv === 'Sim' ? 'Realizado' : 'Não Realizado'}
            </TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.nome}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.apelido}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.cpf ? formatCpf(row?.cpf) : ''}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.n_sus}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{formatDate(row?.dt_nascimento)}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.contato_preferencial}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.logradouro}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.numero}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.bairro}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.complemento}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.referencia}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.cep}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.municipio}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.estado}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.celular}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.nome_estabelecimento}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.acs_nome}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{row?.has_procedimento}</TextCell>
          </TableCell>
          <TableCell sx={cellStyle}>
            <TextCell>{meioRastreamento[row.rastreamento]}</TextCell>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default Rows;
