import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ButtonIcon, ButtonUpdate, WarningIcon } from './ModalStatus.style';
import api from '../../api';
import { Check, Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { formatDate } from '../../utils/formatters';
import { CancelIcon } from '../ModalDeletePatient/ModalStatus.style';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 432,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  padding: '16px 24px',
};

const schema = Yup.object({
  status: Yup.string(),
});

// interface ExamInfo {
//   status: string;
//   updated_at?: Date;
// }

interface Inputs {
  status: string;
}

export interface User {
  id: number;
  usuario: string;
  email: string;
  pessoa: {
    id: number;
    nome: string;
    apelido: string;
    cpf: number;
    dt_nascimento: string;
    cns: string;
    raca: string;
    nacionalidade: string;
    nome_mae: string;
    sexo: string;
    contato_preferencial: string;
    escolaridade: string;
    orientacao: number;
    identidade_genero: number;
  };
}

export interface TokenData {
  aud: string;
  exp: number;
  iat: number;
  scope: string;
  sub: number;
  user: User;
}

export default function ModalDelete({ setModal, open, examData }) {
  const handleClose = () => setModal(false);
  const [disabled, setDisabled] = useState(true);
  const [submited, setSubmited] = useState(false);
  const [countdown, setCountdown] = useState(2);

  useEffect(() => {
    setDisabled(true);
    setCountdown(2);
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      setDisabled(false);
      clearInterval(timer);
    }, 2400);

    return () => {
      clearInterval(timer);
      clearTimeout(timeout);
      setDisabled(true);
      setCountdown(2);
    };
  }, [open]);

  const onSubmit = async (e) => {
    e.preventDefault();

    setSubmited(true);
    let res: null|any = null;
    
    if(!examData.id && examData?.categoria?.id === 1)
      res = await api.delete(`/excludes/exam/hpv/${examData?.id}`);
    else
      res = await api.delete(`/excludes/exam/procedimento/${examData?.id}`);

    if (res?.data) {
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
    setSubmited(false);
  };

  const type = examData?.citologia
    ? 'Resultado da Citologia - Reflexo (após teste de HPV Positivo)'
    : examData?.colposcopia
    ? 'Resultado da Colposcopia'
    : examData?.histopatologia
    ? 'Resultado da Histopatologia'
    : examData?.hpv
    ? 'Teste de HPV'
    : examData?.intraepitelial
    ? 'Tratamento das Lesões Intraepiteliais'
    : examData?.atendimento_status === 'NAO_REALIZOU'
    ? 'Teste De HPV (Não Realizado)'
    : 'Exame';

  function getExcludeType(type: string) {
    switch (type) {
      case 'Resultado da Citologia - Reflexo (após teste de HPV Positivo)':
      case 'Resultado da Colposcopia':
      case 'Resultado da Histopatologia':
        return 'Resultado';
      case 'Teste de HPV':
        return 'Teste';
      case 'Tratamento das Lesões Intraepiteliais':
        return 'Tratamento';
      default:
        return 'Teste';
    }
  }
  console.log(examData);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            marginTop="1.5rem"
          >
            <Typography
              id="modal-title"
              variant="h6"
              component="h6"
              fontWeight="bold"
            ></Typography>
            <ButtonIcon onClick={handleClose}>
              <CloseIcon fontSize="small" style={{ color: '#BDBDBD' }} />
            </ButtonIcon>
          </Box>
          
          <CancelIcon color="error" />

          <form onSubmit={onSubmit}>
            <Typography
              id="modal-title"
              variant="h5"
              component="h5"
              align="center"
              fontWeight="bold"
              marginBottom="20px"
            >
              Excluir o {getExcludeType(type)}?
            </Typography>

            <Typography fontSize="14px" textAlign="left" mb={1}>
              Exclusão do {type} da paciente:{' '}
              <b>{examData?.pessoa?.nome || 'Não Informada'}</b>
            </Typography>

            <Typography fontSize="14px" textAlign="left" mb={4}>
              {examData.colposcopia || examData.citologia
                ? 'Resultado'
                : 'Realizado'}{' '}
              em: <b>{formatDate(examData.dt_coleta || examData.created_at)}</b>
            </Typography>
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
                gap="5rem"
                marginBottom="1.5rem"
              >
                <ButtonUpdate
                  variant="contained"
                  startIcon={<Check />}
                  disabled={disabled || submited}
                  type="submit"
                  data-testid={`button-confirm-delete`}
                >
                  {disabled ? `Aguarde ${countdown}s..` : 'Confirmar'}
                </ButtonUpdate>
                <Button
                  variant="outlined"
                  startIcon={<Close />}
                  disabled={submited}
                  onClick={() => {
                    handleClose();
                  }}
                  color="error"
                  data-testid={`button-cancel-delete`}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
