import { getReportsRastreamento } from '../../../../../lib/Reports/getReportsRastreamento';

const useRequestFilter = ({ setTableData }) => {
  const loadReportsRastreamentoHpv = async ({ payload }) => {
    const response = await getReportsRastreamento({
      payload,
    });

    setTableData(response);
  };

  return {
    loadReportsRastreamentoHpv,
  };
};

export default useRequestFilter;
