import { TableCell, TableRow, Typography } from '@mui/material';
import { formatCpf } from '../../../../../../utils/formatters';
import moment from 'moment';
import { useContext } from 'react';
import { PerfilContext } from '../../../../../../context/PerfilContext';
import CustomizedButton from '../../../../../../components/CustomizedButton';

export const StatusEnum = {
  Agendada: 0,
  Pendente: 1,
  NaoAtendida: 2,
  NaoElegivel: 3,
};
export enum ModalType {
  Agendada = 0,
  Pendente = 1,
  NaoAtendida = 2,
  NaoElegivel = 3,
}

export const getColorAndLabelStatus = (value) => {
  if (String(value?.includes('Pendente'))) {
    return '#FFB547';
  }

  if (String(value?.includes('Fora'))) {
    return '#7B61FF';
  }
};

const RowsTableConvocation = ({
  tableData,
  setModalAberto,
  setRegistroSelecionado,
}) => {
  return (
    <>
      {tableData?.items?.map((row, index) => {
        const endereco = `${String(
          row.endereco_paciente.logradouro
        ).toUpperCase()}, ${String(
          row.endereco_paciente.numero
        ).toUpperCase()} - ${String(row.endereco_paciente.bairro).toUpperCase()}
        `;
        return (
          <TableRow key={index}>
            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {String(row.nome_paciente).toUpperCase()}
              </Typography>
            </TableCell>

            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {String(row.cpf_paciente).toUpperCase()}
              </Typography>
            </TableCell>

            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {String(row.cns_paciente).toUpperCase()}
              </Typography>
            </TableCell>

            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {String(row.idade_paciente).toUpperCase()} Anos
              </Typography>
            </TableCell>

            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {String(row.estado_estabelecimento).toUpperCase()}
              </Typography>
            </TableCell>

            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {String(row.municipio_estabelecimento).toUpperCase()}
              </Typography>
            </TableCell>

            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {String(row.estabelecimento_vinculado_nome).toUpperCase()}
              </Typography>
            </TableCell>

            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {String(endereco.slice(0, 30))}...
                {/* {endereco} */}
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {row.acs_responsavel}
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {row.tipo_convocacao}
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {row.data_envio_convocacao
                  ? moment(row.data_envio_convocacao).format('DD/MM/YYYY')
                  : ''}
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {row.data_resposta_convocacao
                  ? moment(row.data_resposta_convocacao).format('DD/MM/YYYY')
                  : ''}
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {row.data_agendamento
                  ? moment(row.data_agendamento).format('DD/MM/YYYY')
                  : ''}
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '162px', height: '92px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {row.ultima_atualizacao}
              </Typography>
            </TableCell>
            {/** ----- */}
          </TableRow>
        );
      })}
    </>
  );
};

export default RowsTableConvocation;
