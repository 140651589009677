import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Modal } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckIcon from '@mui/icons-material/Check';
// import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import NotificationModalSavePerson from '../NotificationModalSavePerson';
import NotificationModalHpvResult from '../NotificationModalHpcResult';
import NotificationModalExam from '../NotificationModalExam';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { AlertListContext } from '../../context/AlertListContext';
import alert from '../../assets/imgs/alert.png';
import './style.scss';
import api from '../../api';
import { LoadingContext } from '../../context/LoadingContext';
import { confirmMissingHpv } from '../../lib/getAlertList';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  isOpen: boolean;
  closeModal: (modalIsOpen: boolean) => void;
  alertId: number;
  data?: any;
  fromCentral: boolean;
  // typeNotification: string;
}

export interface ExamePendente {
  id_exame_pendente?: number;
  id_gal?: string;
  nsus_paciente?: string;
  cpf_paciente?: string;
  data_coleta: string;
  data_recebimento?: string;
  hpv_16?: boolean;
  hpv_18?: boolean;
  hpv_outros?: boolean;
  observacao?: string;
  nome_paciente_gal?: string; // nome_paciente_gal
  resultado?: string;
  tipo_erro?: string;
  responsavel?: Responsavel;
  id_paciente?: number;
  dt_nascimento: string;
  data_coleta_jordana?: string;
  autocoleta?: boolean;
  nome?: string;
  cpf?: string;
  n_sus?: string;
  nome_mae?: string;
  sexo?: string;
  tipo?: string;
  status?: string;
  atendimento_status?: string;
  pessoaId?: number;
  dt_coleta?: string;
  gal_id?: string;
  created_by?: null | number;
  profissional_responsavel?: number;
}

interface Responsavel {
  id: number;
  cbo?: string;
  cnes?: string;
  created_at: string;
  updated_at: string;
}

const NotificationModal: React.FC<Props> = (props) => {
  const { alertList, setAlertList, setUpdateAlertList } =
    React.useContext(AlertListContext);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [
    showConfirmResultadoSemTesteModal,
    setShowConfirmResultadoSemTesteModal,
  ] = React.useState({ confirm: false, open: false });

  const [selectedDate, setSelectedDate] = React.useState<string | null>();
  const [selectedGalDate, setSelectedGalDate] = React.useState<boolean>(false);
  const [selectedJordDate, setSelectedJordDate] =
    React.useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);

  const arrayNotifications = props.fromCentral ? props.data?.data : alertList;

  const examePendente: any = arrayNotifications?.find(
    (item) => item.id === props.alertId
  );

  const [finalDate, setFinalDate] = React.useState<string | null>();

  React.useEffect(() => {
    if (selectedGalDate || selectedJordDate) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [selectedGalDate, selectedJordDate]);
  React.useEffect(() => {
    if (!showConfirmModal) {
      setSelectedGalDate(false);
      setSelectedJordDate(false);
    }
  }, [showConfirmModal]);

  const handleConfiirmButtonClick = async (id: number) => {
    setShowConfirmModal(true);
  };
  const handleAdicionarResultadoSemTeste = async (id: number) => {
    setLoading(true);
    try {
      await api.put(`importacao_gal/exame/hpv/alertas/salvar/exame/${id}`);
      setAlertList(alertList.filter((item) => item.id !== id));
    } catch (error) {
      console.error(error);
    } finally {
      setShowConfirmResultadoSemTesteModal({ confirm: false, open: false });
      setLoading(false);
      setUpdateAlertList((prev) => Number(prev + 1));
      props.closeModal(false);
    }
  };

  const handleAdiarButtonClick = async (id: number) => {
    try {
      api.delete(`importacao_gal/exame/hpv/resultados/${id}`).then(() => {
        setAlertList(alertList.filter((item) => item.id !== id));
      });
    } catch (error) {
      console.error(error);
    }
  };

  const { setLoading } = React.useContext(LoadingContext);

  const handleSaveButtonClick = async (id: number) => {
    try {
      setLoading(true);
      if (selectedGalDate) {
        await api
          .put(`importacao_gal/exame/hpv/resultados/${id}/gal`)
          .then(() => {
            setAlertList(alertList.filter((item) => item.id !== id));
          });
      } else {
        await api
          .put(`importacao_gal/exame/hpv/resultados/${id}/jordana`)
          .then(() => {
            setAlertList(alertList.filter((item) => item.id !== id));
          });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowConfirmModal(false);
      setLoading(false);
      setUpdateAlertList((prev) => Number(prev + 1));
      props.closeModal(false);
    }
  };

  const title = () => {
    const dados = {
      text: '',
      icon: <ErrorIcon color="warning" className="icon" />,
    };
    // if (!examePendente) {
    //   return <></>;
    // }
    if (examePendente?.type?.name === 'MISSING_HPV_EXAM') {
      dados.text = 'Inexistência de Dados do Teste de HPV';
      dados.icon = (
        <CancelIcon
          color="error"
          className="icon"
          sx={{ width: '40px', height: '40px' }}
        />
      );
    } else if (examePendente?.type?.name === 'MISMATCH_HPV_COLETA') {
      dados.text =
        'Falha de vinculação do Resultado do Teste de HPV por divergência de Dados do Exame Clínico';
      dados.icon = (
        <ErrorIcon
          color="warning"
          className="icon"
          sx={{
            color: '#FFB547',
            width: '40px',
            height: '40px',
            marginRight: '16px',
          }}
        />
      );
    } else if (examePendente?.tipo_erro === 'PACIENTE_NAO_CADASTRADO') {
      dados.text = `As informações do GAL não correspondem aos dados necessários para associar o resultado a uma paciente no Jordana.`;
      dados.icon = <HelpIcon color="error" className="icon" />;
    }

    return (
      <Box className="titleBoxModal">
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {dados.icon} {dados.text}
        </span>
        <IconButton
          style={{
            backgroundColor: '#D9D9D9',
            color: '#BDBDBD',
          }}
          onClick={() => props.closeModal(false)}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    );
  };

  // if (!examePendente) {
  //   return <></>;
  // }

  const handleConfirm = async ({ confirm }) => {
    setLoading(true);

    await confirmMissingHpv({
      user_notify_id: examePendente?.user_notify_id,
      confirm: confirm,
    });

    setLoading(false);

    props.closeModal(false);
  };

  const buttonText = (() => {
    if (examePendente?.tipo_erro === 'DATA_DIVERGENTE') {
      return 'Vincular Depois';
    } else if (examePendente?.tipo_erro === 'PACIENTE_NAO_CADASTRADO') {
      return 'Buscar Depois';
    } else if (examePendente?.tipo_erro === 'EXAME_NAO_CADASTRADO') {
      return 'Cadastrar Teste Depois';
    }
  })();

  return (
    <Modal
      open={props.isOpen}
      onClose={() => props.closeModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className="modalBody">
        <Modal
          open={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            style={{
              backgroundColor: 'white',
              width: '29rem',
              height: '27.625rem',
              borderRadius: '15px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <h1
              style={{
                width: '17.5rem',
                textAlign: 'center',
                fontSize: '1.5rem',
              }}
            >
              Alterar a Data de Coleta ?
            </h1>
            <ReportProblemIcon
              color="warning"
              style={{
                width: '104px',
                height: '104px',
              }}
            />
            <p
              style={{
                marginRight: '1.5rem',
                marginLeft: '1.5rem',
                textAlign: 'center',
              }}
            >
              Deseja <b>alterar a Data de Coleta do Teste de HPV</b> da paciente{' '}
              <b>{examePendente?.nome_paciente_gal}</b> para o dia{' '}
              <b>{finalDate}</b>?
            </p>
            <div
              style={{
                display: 'flex',
                gap: '4rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="contained"
                onClick={() =>
                  handleSaveButtonClick(Number(examePendente.id_exame_pendente))
                }
                color="primary"
              >
                <CheckIcon style={{ marginRight: '0.5rem' }} /> SIM, alterar
              </Button>
              <Button
                variant="outlined"
                onClick={() => setShowConfirmModal(false)}
                color="error"
              >
                <CloseIcon style={{ marginRight: '0.5rem' }} /> Cancelar
              </Button>
            </div>
          </Box>
        </Modal>
        <Box>
          {title()}
          {examePendente?.type?.name === 'MISMATCH_HPV_COLETA' && (
            <NotificationModalHpvResult
              examePendente={examePendente}
              selectedGalDate={selectedGalDate}
              selectedJordDate={selectedJordDate}
              setSelectedGalDate={setSelectedGalDate}
              setSelectedJordDate={setSelectedJordDate}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              finalDate={finalDate}
              setFinalDate={setFinalDate}
              closeModal={props.closeModal}
            />
          )}

          {examePendente?.tipo_erro === 'PACIENTE_NAO_CADASTRADO' && (
            <NotificationModalSavePerson examePendente={examePendente} />
          )}

          {examePendente?.type?.name === 'MISSING_HPV_EXAM' && (
            <NotificationModalExam
              examePendente={examePendente}
              closeModal={props.closeModal}
              setShowConfirmResultadoSemTesteModal={
                setShowConfirmResultadoSemTesteModal
              }
            />
          )}
        </Box>

        <Modal
          open={showConfirmResultadoSemTesteModal.open}
          onClose={() =>
            setShowConfirmResultadoSemTesteModal({
              confirm: false,
              open: false,
            })
          }
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box className="modalConfirmaResultadoBody">
            <>
              {showConfirmResultadoSemTesteModal.confirm ? (
                <>
                  <h1 style={{ fontSize: '24px', textAlign: 'center' }}>
                    Confirmar a vinculação do Resultado ?
                  </h1>
                </>
              ) : (
                <>
                  <h1 style={{ fontSize: '24px', textAlign: 'center' }}>
                    Não Confirmar a vinculação do <br /> Resultado ?
                  </h1>
                </>
              )}

              <img
                src={alert}
                alt=""
                width={104}
                height={104}
                style={{ marginTop: '32px', marginBottom: '32px' }}
              />

              {showConfirmResultadoSemTesteModal.confirm ? (
                <p>
                  Deseja <b>confirmar</b> a vinculação desse <b>Resultado</b> a
                  paciente,
                  <b>
                    sem <br /> um Teste de HPV?
                  </b>
                </p>
              ) : (
                <p>
                  Deseja <b>não confirmar</b> a vinculação desse{' '}
                  <b>Resultado</b> a paciente ?
                </p>
              )}

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="24px"
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    handleConfirm({ confirm: 'true' });
                    setShowConfirmResultadoSemTesteModal({
                      confirm: false,
                      open: false,
                    });
                    // handleConfirm({ confirm: 'true' });
                  }}
                  startIcon={<CheckIcon />}
                >
                  CONFIRMAR
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    handleConfirm({ confirm: 'false' });
                    setShowConfirmResultadoSemTesteModal({
                      confirm: false,
                      open: false,
                    });
                  }}
                  startIcon={<ClearIcon />}
                >
                  NÃO CONFIRMAR
                </Button>
              </Box>
            </>

            {/* 
            <ReportProblemIcon
              color="warning"
              style={{ height: '104px', width: '104px' }}
            />
            <p
              style={{
                textAlign: 'center',
                fontSize: '14px',
                lineHeight: '20.02px',
              }}
            >
              Você não poderá mais cadastrar um Teste para esse Resultado.
            </p>
            <p
              style={{
                textAlign: 'center',
                fontSize: '14px',
                lineHeight: '20.02px',
              }}
            >
              Deseja mesmo <b>vincular um resultado sem um teste</b> para a
              paciente <b>{examePendente?.nome_paciente_gal}</b>?
            </p>
            <div
              style={{
                width: '416px',
                height: '42px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAdicionarResultadoSemTeste(props.alertId)}
              >
                Sim, vincular
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() =>
                  setShowConfirmResultadoSemTesteModal({
                    confirm: false,
                    open: false,
                  })
                }
              >
                Cancelar
              </Button>
            </div> */}
          </Box>
        </Modal>
      </div>
    </Modal>
  );
};

export default NotificationModal;
