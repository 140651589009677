import moment from 'moment';
import smartphone from '../../../../../../../assets/imgs/Convocation/smartphone.svg';
import dot from '../../../../../../../assets/imgs/Convocation/dot.svg';
import connector from '../../../../../../../assets/imgs/Convocation/connector.svg';
import ComponentConvocations from '../ComponentConvocations';
import { Button, Grid } from '@mui/material';
import { useState } from 'react';

type Props = {
  convocations: Convocations[];
};

type Convocations = {
  acs: string;
  resultConvocation: {
    status: string;
    activeSearch: string;
  };
};

const ListConvocations = ({
  convocations,
  objTotalDataConvocation,
  showAllConvocations,
  setShowAllConvocations,
  conditiionToDisableImg,
}: any) => {
  console.log(objTotalDataConvocation);
  const numeroCorretoValue = (status) => {
    if (
      (status && status.includes('numero')) ||
      objTotalDataConvocation.numero_estava_correto === true
    ) {
      return 'Sim';
    } else {
      return 'Em aguardo';
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '246px',
        justifyContent: 'flex-start',
      }}
    >
      <Grid container>
        <ComponentConvocations
          tipo={convocations[0]?.tipo}
          conditiionToDisableImg={conditiionToDisableImg}
          data_realizacao={convocations[0]?.data_realizacao}
          numero_estava_correto={numeroCorretoValue(convocations[0]?.status)}
          data_resposta={convocations[0]?.data_resposta}
          acsResponsavelNome={
            convocations[0].acs_responsavel_convocatoria?.acs_pessoa_nome ?? ''
          }
          status={convocations[0].status}
          statusConvocacao={convocations[0].status_convocacao ?? ''}
          motivoCancelamento={convocations[0].motivo_status ?? ''}
        />
        {convocations.length > 1 && (
          <>
            {showAllConvocations && (
              <>
                {convocations.map((conv, index) => {
                  if (index > 0) {
                    return (
                      <ComponentConvocations
                        tipo={conv.tipo}
                        conditiionToDisableImg={conditiionToDisableImg}
                        data_realizacao={conv.data_realizacao}
                        numero_estava_correto={numeroCorretoValue(
                          convocations[0]?.status
                        )}
                        data_resposta={conv.data_resposta}
                        acsResponsavelNome={
                          conv?.acs_responsavel_convocatoria?.acs_pessoa_nome ??
                          ''
                        }
                        status={conv.status}
                      />
                    );
                  }
                })}
              </>
            )}
          </>
        )}
      </Grid>

      {!showAllConvocations && (
        <>
          {convocations.length > 1 && (
            <Button
              onClick={() => setShowAllConvocations(true)}
              sx={{
                height: '22px',
                fontSize: '13px',
                width: '246px',
                marginTop: '12px',
                color: '#212121',
              }}
            >
              + VER TODAS CONVOCAÇÕES ({convocations.length})
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default ListConvocations;
