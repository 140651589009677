import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import useStatesFields from '../../../components/MultipleFilters/hooks/useStatesFields';
import ReturnButton from '../../../components/ReturnButton';
import { SideMenu } from '../../../components/SideMenu';
import { Option } from '../../../types/Option';
import { TableReportsHistopatologia } from '../../../components/TableReports/TableHistopatologia/TableReportsHistopatologia';
import FiltersReportsHistopatologia from './FiltersReportsHistopatologia';
import useGetDetailsFilters from '../ReportsHpv/FiltersReportsHpv/hooks/useGetDetailsFilters';
import useHandleChangeFilters from './FiltersReportsHistopatologia/hooks/useHandleChangesFilters';
import { DisplayFilter, ItemsDisplayFilter } from '../../Convocation/styles';
import { LoadingContext } from '../../../context/LoadingContext';
import useSetFilterByPerfil from '../../../utils/hooks/useSetFilterByPerfil';
import useRequestFilter from './FiltersReportsHistopatologia/hooks/useRequestFilter';

// const RequiredText = () => {
//   return (
//     <div style={{ margin: '3px 0 0', fontSize: '12px' }}>
//       <p style={{ margin: 0, fontStyle: 'italic', color: 'rgba(0,0,0,0.6)' }}>
//         <span style={{ color: 'red' }}>* </span>Obrigatório
//       </p>
//     </div>
//   );
// };

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'realizou_tratamento', value: '' },
  { inputName: 'dt_coleta_inicio', value: '' },
  { inputName: 'dt_coleta_fim', value: '' },
  { inputName: 'origem', value: '' },
  { inputName: 'lesoes_benigno', value: '' },
  { inputName: 'lesoes_neoplasico_escamosa', value: '' },
  { inputName: 'lesoes_neoplasico_glandular', value: '' },
  { inputName: 'laboratorio_responsavel_laudo', value: '' },
  { inputName: 'profissional_responsavel_resultado', value: '' },
  { inputName: 'dt_resultado_inicio', value: '' },
  { inputName: 'dt_resultado_fim', value: '' },
  { inputName: 'search', value: '' },
];

export const ReportsHistopatologia = () => {
  const [tableData, setTableData] = useState({
    data: [],
    page: 1,
    count: 25,
    totalPages: 1,
    totalItems: 0,
  });
  const [selectedHasLesao, setSelectedHasLesao] = useState<any>([]);

  const [profissional, setProfissional] = useState('');
  const [, setEstadosSelecionados] = useState<Option[]>([]);
  const [, setMunicipiosSelecionados] = useState<Option[]>([]);
  const [, setLaboratoriosSelecionados] = useState<Option[]>([]);
  const [periodColeta, setTipePeriodColeta] = useState('period');
  const [periodResult, setTipePeriodResult] = useState('period');
  const [periodDtColeta, setPeriodDtColeta] = useState('period');
  const [periodDtResultado, setPeriodDtResultado] = useState('period');
  const [showTableData, setShowTableData] = useState(false);
  const [listFilterShow, setListFilterShow] = useState<any[]>([]);
  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);
  const [selectedResultado, setSelectedResultado] = useState();
  const [dtColetaIni, setDataColetaIni] = useState<any>(null);
  const [dtColetaFinal, setDataColetaFinal] = useState<any>(null);
  const [dtResultadoIni, setDataResultadoIni] = useState<any>(null);
  const [dtResultadoFinal, setDataResultadoFinal] = useState<any>(null);

  const [selectedOrigem, setSelectedOrigem] = useState<string[]>([]);
  const [selectedCarater, setSelectedCarater] = useState<string[]>([]);
  const [selectedEscamosa, setSelectedEscamosa] = useState<string[]>([]);
  const [selectedGlandular, setSelectedGlandular] = useState<string[]>([]);

  const [selectedLaboratorio, setSelectedLaboratorio] = useState<any>();

  const [selectedOptionsLaboratorio, setSelectedOptionsLaboratorio] = useState<
    any[]
  >([]);

  const [disabledProfissionalResultado, setDisabledProfissionalResultado] =
    useState(false);

  const [disabledLaboratorioResultado, setDisabledLaboratorioResultado] =
    useState(false);

  const [loadingTable, setLoadingTable] = useState(true);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      page: 1,

      size: 25,
    },
  });

  const {
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    disableInputEstado,
    disableMunicipioInput,
    disableEstabelecimentoInput,
    loadingEstados,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    setDisableInputEstado,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,
    setOptionsMunicipios,
    setEstadosOptions,
    setOptionsEstabelecimentos,
    setOptionsLaboratorios,
    setAllEstadosOptions,
    setLoadingEstados,
    optionsProfissionaisLaboratorio,
    setOptionsProfisisonaisLaboratorio,
  } = useStatesFields();

  const location = useLocation();
  const origin = location?.state?.origin;
  const fromTable =
    origin === 'tables' || !!localStorage.getItem('requestFromTable');

  // Add chart filter handling
  const chartNameSavedInStorage = localStorage.getItem('chartName');
  const itemClicked = localStorage.getItem('itemClicked');
  const payloadFromPanelCharts = JSON.parse(
    String(localStorage.getItem('payload'))
  );

  const getDataByChartClicked = async () => {
    // Format estados to remove quotes and brackets
    const formattedEstados = payloadFromPanelCharts.params.estados?.replace(
      /[\[\]"]/g,
      ''
    );
    const estadosArray = formattedEstados?.split(',') || [];
    const municipiosArray =
      payloadFromPanelCharts.params.municipios?.split(',') || [];
    const estabelecimentosArray =
      payloadFromPanelCharts.params.estabelecimentos?.split(',') || [];

    console.log('4. Estados formatados:', estadosArray);
    console.log('4.1 Municípios formatados:', municipiosArray);
    console.log('4.2 Estabelecimentos formatados:', estabelecimentosArray);

    // Set selected values
    setEstadosSelected(estadosArray);
    setMunicipiosSelected(municipiosArray);
    setEstabelecimentosSelected(estabelecimentosArray);

    // Set period if available
    if (payloadFromPanelCharts.params.dataInicial) {
      setDataColetaIni(payloadFromPanelCharts.params.dataInicial);
    }
    if (payloadFromPanelCharts.params.dataFinal) {
      setDataColetaFinal(payloadFromPanelCharts.params.dataFinal);
    }

    // Update filter display
    const newFilters = [
      {
        inputName: 'estado',
        value: `Estado${estadosArray.length > 1 ? 's' : ''}: ${
          estadosArray.length > 1 ? 'Vários' : formattedEstados
        }`,
      },
      municipiosArray.length > 0 && {
        inputName: 'municipio',
        value: `Município${municipiosArray.length > 1 ? 's' : ''}: ${
          municipiosArray.length > 1 ? 'Vários' : municipiosArray[0]
        }`,
      },
      estabelecimentosArray.length > 0 && {
        inputName: 'estabelecimento_saude',
        value: `Estabelecimento${
          estabelecimentosArray.length > 1 ? 's' : ''
        }: ${
          estabelecimentosArray.length > 1 ? 'Vários' : estabelecimentosArray[0]
        }`,
      },
      payloadFromPanelCharts.params.origem && {
        inputName: 'origem',
        value: `Origem: ${payloadFromPanelCharts.params.origem}`,
      },
    ].filter(Boolean);

    console.log('6. Novos filtros a serem exibidos:', newFilters);

    setListFilterShow(newFilters);
    setShowTableData(true);

    // Set filters based on chart clicked
    if (chartNameSavedInStorage === 'origemHistopatologia' && itemClicked) {
      console.log('7. Setando filtro de origem:', itemClicked);
      setSelectedOrigem([itemClicked]);
      setValue('origem', itemClicked);
    } else if (
      chartNameSavedInStorage === 'preNeoplasicaEscamosa' &&
      itemClicked
    ) {
      console.log('7. Setando filtro de lesão escamosa:', itemClicked);
      setSelectedEscamosa([itemClicked]);
      setValue('lesoes_neoplasico_escamosa', itemClicked);
    } else if (
      chartNameSavedInStorage === 'preNeoplasicaGlandular' &&
      itemClicked
    ) {
      console.log('7. Setando filtro de lesão glandular:', itemClicked);
      setSelectedGlandular([itemClicked]);
      setValue('lesoes_neoplasico_glandular', itemClicked);
    } else if (chartNameSavedInStorage === 'lesoesBenignas' && itemClicked) {
      console.log('7. Setando filtro de lesão benigna:', itemClicked);
      setSelectedCarater([itemClicked]);
      setValue('lesoes_benigno', itemClicked);
    }

    // Construct the final payload
    const requestPayload = {
      payload: {
        page: 1,
        size: 25,
        skip: 0,
        take: 25,
        estados: formattedEstados || null,
        municipios: payloadFromPanelCharts.params.municipios || null,
        estabelecimentoSaudeId:
          payloadFromPanelCharts.params.estabelecimentos || null,
        dataInicial: payloadFromPanelCharts.params.dataInicial || null,
        dataFinal: payloadFromPanelCharts.params.dataFinal || null,
        origem:
          itemClicked && chartNameSavedInStorage === 'origemHistopatologia'
            ? itemClicked
            : null,
        lesoes_neoplasico_escamosa:
          itemClicked && chartNameSavedInStorage === 'preNeoplasicaEscamosa'
            ? itemClicked
            : null,
        lesoes_neoplasico_glandular:
          itemClicked && chartNameSavedInStorage === 'preNeoplasicaGlandular'
            ? itemClicked
            : null,
        lesoes_benigno:
          itemClicked && chartNameSavedInStorage === 'lesoesBenignas'
            ? itemClicked
            : null,
      },
    };

    // Remove null fields from the payload
    Object.keys(requestPayload.payload).forEach(
      (key) =>
        requestPayload.payload[key] === null &&
        delete requestPayload.payload[key]
    );

    console.log('9. Payload final enviado para API:', requestPayload);

    // Call API with the wrapped payload
    await loadReportsHistopatologia(requestPayload);

    // Clear localStorage after successful API call
    localStorage.removeItem('payload');
    localStorage.removeItem('chartName');
    localStorage.removeItem('itemClicked');
    localStorage.removeItem('requestFromTable');
  };

  useEffect(() => {
    console.log('=== Debug useEffect Histopatologia ===');
    console.log('fromTable:', fromTable);
    console.log('Location state:', location?.state);
    console.log('Payload do localStorage:', localStorage.getItem('payload'));

    if (fromTable) {
      getDataByChartClicked();
    }
  }, [fromTable]);

  const clearValuesInputs = () => {
    setEstabelecimentosSelected([]);
    setDataColetaFinal('');
    setDataColetaIni('');
    setDataResultadoIni('');
    setDataResultadoFinal('');
    setSelectedOrigem([]);
    setSelectedCarater([]);
    setSelectedEscamosa([]);
    setSelectedGlandular([]);
    setSelectedGlandular([]);

    setListFilter(initialStateListFilter);

    setValue('profissionalLaudoId', '');
  };

  const handleChange = (event) => {
    setProfissional(event.target.value);
  };

  const handleSetSelectedOptions = (setter) => (options) => {
    setter(options);
  };

  const handleSetPeriodColeta = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTipePeriodColeta((event.target as HTMLInputElement).value);
  };

  const handleSetPeriodResult = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTipePeriodResult((event.target as HTMLInputElement).value);
  };

  const { handleSetFilter } = useHandleChangeFilters({
    setListFilter,
  });

  const { getMunicipioDetails, getEstabelecimentoDetails, getAcsDetails } =
    useGetDetailsFilters();

  const { setLoading } = useContext(LoadingContext);

  const { loadReportsHistopatologia } = useRequestFilter({
    setTableData,
    setLoadingTable,
  });

  const { loadFiltersByPerfil } = useSetFilterByPerfil({
    loadReports: loadReportsHistopatologia,
    setListFilter: setListFilter,
    setListFilterShow: setListFilterShow,
    setSelectedOptionsLaboratorio: setSelectedOptionsLaboratorio,
    setShowTableData: setShowTableData,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    setOptionsMunicipios,
    setEstadosOptions,
    setOptionsEstabelecimentos,
    setOptionsLaboratorios,
    setOptionsProfisisonaisLaboratorio,
    setValue,
    fieldProfissionalResultado: 'profissional_responsavel_resultado',
    fieldLaboratorioResultado: 'laboratorio_responsavel_laudo',
    setSelectedLaboratorio,
    selectedLaboratorio,
  });

  useEffect(() => {
    const payloadFromDash = localStorage.getItem('payload');
    // const chartName = localStorage.getItem('chartName');
    // const itemClicked = localStorage.getItem('itemClicked');

    if (payloadFromDash) {
      console.log('=== Debug Reports Histopatologia ===');
      console.log(
        '1. Received payload from dashboard:',
        JSON.parse(payloadFromDash)
      );
      const payload = JSON.parse(payloadFromDash);

      if (payload.params.estados) {
        const estados = payload.params.estados.split(',');
        setEstadosSelected(estados);
        handleSetFilter(
          estados.length > 1 ? 'Estados: Vários' : `Estado: ${estados[0]}`,
          'estado'
        );
      }

      if (payload.params.municipios) {
        const municipios = payload.params.municipios.split(',');
        setMunicipiosSelected(municipios);
        handleSetFilter(
          municipios.length > 1
            ? 'Municípios: Vários'
            : `Município: ${municipios[0]}`,
          'municipio'
        );
      }

      if (payload.params.estabelecimentos) {
        const estabelecimentos = payload.params.estabelecimentos.split(',');
        setEstabelecimentosSelected(estabelecimentos);
        handleSetFilter(
          estabelecimentos.length > 1
            ? 'Estabelecimentos: Vários'
            : `Estabelecimento: ${estabelecimentos[0]}`,
          'estabelecimento'
        );
      }

      setListFilterShow((prev) => [...prev]);

      loadReportsHistopatologia({ payload: payload.params });
    } else {
      loadFiltersByPerfil();
    }
  }, []);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton
            backPage={fromTable ? '/paineis/teste-histopatologia' : '/home'}
          />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h4" fontWeight="bold">
                Relatório Geral de Exames de Histopatologia
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
                sx={{ marginBottom: '24px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>
                {fromTable ? (
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to={'/paineis/teste-histopatologia'}
                  >
                    {'Painel do Teste de Histopatologia'}
                  </Link>
                ) : (
                  <Typography color="text.primary">{'Relatórios'}</Typography>
                )}

                <Typography key="3" color="text.primary">
                  Relatório Geral de Exames de Histopatologia
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              // fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Filtros
            </Typography>
            <Divider />
            <p
              style={{
                fontSize: '12px',
                textAlign: 'right',
                color: '#d32f2f',
                margin: '0',
                marginTop: '20px',
                // marginBottom: '-20px',
              }}
            >
              <i>* Campo obrigatório</i>
            </p>

            <DisplayFilter style={{ marginTop: '24px' }}>
              {!listFilterShow?.find((item) => item.value !== '') && (
                <p style={{ margin: 0, width: '413px', color: '#838383' }}>
                  Os filtros selecionados aparecerão aqui.
                </p>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {listFilterShow.map((item) => (
                  <>
                    {item.value ? (
                      <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                    ) : undefined}
                  </>
                ))}
              </div>
            </DisplayFilter>

            <FiltersReportsHistopatologia
              initialStateListFilter={initialStateListFilter}
              setLoadingTable={setLoadingTable}
              selectedHasLesao={selectedHasLesao}
              setSelectedHasLesao={setSelectedHasLesao}
              errors={errors}
              clearErrors={clearErrors}
              setDisabledLaboratorioResultado={setDisabledLaboratorioResultado}
              setSelectedLaboratorio={setSelectedLaboratorio}
              selectedLaboratorio={selectedLaboratorio}
              disabledLaboratorioResultado={disabledLaboratorioResultado}
              disabledProfissionalResultado={disabledProfissionalResultado}
              selectedOptionsLaboratorio={selectedOptionsLaboratorio}
              setSelectedOptionsLaboratorio={setSelectedOptionsLaboratorio}
              disableMunicipioInput={disableMunicipioInput}
              disableEstabelecimentoInput={disableEstabelecimentoInput}
              disableInputEstado={disableInputEstado}
              clearValuesInputs={clearValuesInputs}
              selectedGlandular={selectedGlandular}
              selectedEscamosa={selectedEscamosa}
              selectedCarater={selectedCarater}
              selectedOrigem={selectedOrigem}
              setSelectedCarater={setSelectedCarater}
              setSelectedEscamosa={setSelectedEscamosa}
              setSelectedGlandular={setSelectedGlandular}
              setSelectedOrigem={setSelectedOrigem}
              dtResultadoIni={dtResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              setDataResultadoIni={setDataResultadoIni}
              setDataResultadoFinal={setDataResultadoFinal}
              estadosSelected={estadosSelected}
              setEstadosSelected={setEstadosSelected}
              setTableData={setTableData}
              setShowTableData={setShowTableData}
              municipiosSelected={municipiosSelected}
              setMunicipiosSelected={setMunicipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              setEstabelecimentosSelected={setEstabelecimentosSelected}
              periodDtColeta={periodDtColeta}
              periodDtResultado={periodDtResultado}
              control={control}
              handleSubmit={handleSubmit}
              setValue={setValue}
              setSelectedResultado={setSelectedResultado}
              setPeriodDtColeta={setPeriodDtColeta}
              setPeriodDtResultado={setPeriodDtResultado}
              setDataColetaFinal={setDataColetaFinal}
              setDataColetaIni={setDataColetaIni}
              dtColetaIni={dtColetaIni}
              dtColetaFinal={dtColetaFinal}
              selectedResultado={selectedResultado}
              watch={watch}
              setError={setError}
              showTableData={showTableData}
              listFilter={listFilter}
              setListFilter={setListFilter}
              setListFilterShow={setListFilterShow}
            />
            <TableReportsHistopatologia
              setLoadingTable={setLoadingTable}
              loadingTable={loadingTable}
              selectedResultado={selectedResultado}
              dtColetaFinal={dtColetaFinal}
              dtColetaIni={dtColetaIni}
              dtResultadoIni={dtResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              selectedGlandular={selectedGlandular}
              selectedEscamosa={selectedEscamosa}
              selectedCarater={selectedCarater}
              selectedOrigem={selectedOrigem}
              showTableData={showTableData}
              tableData={tableData}
              setValue={setValue}
              watch={watch}
              setTableData={setTableData}
              estadosSelected={estadosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              equipesSelected={equipesSelected}
              selectedHasLesao={selectedHasLesao}
              setSelectedHasLesao={setSelectedHasLesao}
            />
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};
