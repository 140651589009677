import { Button, Grid, Typography } from '@mui/material';
import { AgendaHeader, ContentContainer, Value } from '../styles';
import { Label } from '@mui/icons-material';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { download } from '../../../lib/getCidadaoAgenda';

interface Props {
  citizenObj: {
    nacionalidade: string | undefined;
    nome: string | undefined;
    localProcedimento: string | undefined;
    cns: string;
    cpf: string;
    endereco: string;
    dtNascimento: string | undefined;
  };
  handleDownload: any;
}

const WaitingListConfirmed = ({ citizenObj, handleDownload }: Props) => {
  return (
    <>
      <ContentContainer>
        <Grid
          container
          spacing={1}
          sx={{
            height: 'fit-content',
            '@media (min-width: 965px)': {
              boxShadow: '0px 0px 14px -8px black',
              width: '350px',
              padding: '0px 25px',
            },
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '30px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '16px',
                gap: '6px',
              }}
            >
              <Typography color="#3B873E" fontWeight={700} fontSize="20px">
                Lista de Espera Reservada
              </Typography>
              <CheckCircleOutlineIcon sx={{ color: '#3B873E' }} />
            </div>

            <Typography fontSize="12px" fontWeight={400} color="#666666">
              Nome
            </Typography>
            <Typography fontSize="16px" marginBottom="16px" fontWeight={400}>
              {citizenObj.nome}
            </Typography>
            <div style={{ display: 'flex', gap: '16px' }}>
              <div>
                <Typography fontSize="12px" fontWeight={400} color="#666666">
                  CPF
                </Typography>
                <Typography
                  fontSize="16px"
                  marginBottom="16px"
                  fontWeight={400}
                >
                  {citizenObj.cpf}
                </Typography>
              </div>
              <div>
                <Typography fontSize="12px" fontWeight={400} color="#666666">
                  Data de Nascimento
                </Typography>
                <Typography
                  fontSize="16px"
                  marginBottom="16px"
                  fontWeight={400}
                >
                  {citizenObj.dtNascimento}
                </Typography>
              </div>
            </div>
            <Typography fontSize="12px" fontWeight={400} color="#666666">
              Local do Procedimento
            </Typography>
            <Typography fontSize="16px" marginBottom="16px" fontWeight={400}>
              {citizenObj.localProcedimento}
            </Typography>

            <Typography fontSize="12px" fontWeight={400} color="#666666">
              Endereço
            </Typography>
            <Typography fontSize="16px" marginBottom="16px" fontWeight={400}>
              {citizenObj.endereco}
            </Typography>

            <Typography fontSize="12px" fontWeight={400} color="#666666">
              Procedimento
            </Typography>
            <Typography fontSize="16px" marginBottom="16px" fontWeight={400}>
              Teste de HPV
            </Typography>
          </div>

          <Grid item xs={12}>
            <Button
              sx={{ width: '100%', marginBottom: '24px' }}
              variant="contained"
              type="button"
              onClick={handleDownload}
            >
              BAIXAR COMPROVANTE
            </Button>
          </Grid>
        </Grid>
      </ContentContainer>
    </>
  );
};
export default WaitingListConfirmed;
